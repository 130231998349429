.eventFilesList {
	padding-bottom: 10px;
}

.eventFileItem {
	background-color: #fff;
	.boxShaddow-small;
	border-radius: 10px;
	padding: 10px 10px 0px 10px;
	position: relative;
	min-height: 60px;
	.boxSizing;
	margin-bottom: 10px;
}
.eventFileItem .options {
	position: absolute;
	right: 10px;
	top: 30px;
	display: flex;
}
.eventFileItem .options a {
	line-height: 30px;
	width: 30px;
	text-align: center;
	margin-left: 5px;
	display: block;
	color: @primaryColor;
}
.eventFileItem .options a:hover {
	color: @textColor !important;
}

.eventFileItem .assetTitle-backend {
	width: ~"calc(100% - 70px)";
	.boxSizing;
}

.eventFileItem .assetMailoption {
	font-size: 12px;
	display: flex;
	.boxSizing;
	color: @textColor;
	padding-top: 0px;
	margin-top: 15px;
	border-top: 1px solid @dividerColor;
}