.appContent {
	margin: 0px 20px;
	padding: 20px 0px;
	margin-bottom: 10px;
	border-bottom: 2px solid @dividerColor;
	color: @textColor;
}

.appContent h3 {
	font-size: 24px;
	line-height: 26px;
	padding: 10px 0px 10px 0px;
	color: @primaryColor;
}
.appContent h4 {
	font-size: 20px;
	line-height: 24px;
	padding: 30px 0px 10px 0px;
	color: @primaryColor;
}
h2.appContent-divider {
	background-color: @primaryColor;
	color: @primaryColor-text;
	.font-special;
	font-size: 24px;
	line-height: 24px;
	margin: 0px;
	padding: 10px 20px;
	margin-right: -10px;
	margin-top: 20px;
	.boxSizing;
	width: ~"calc(100% + 10px)";
}
.appContent p {
	padding: 0px 0px 10px 0px;
}

.contenttable-01 {
	max-width: 100%;
	background-color: @dividerColor;
	border-left: 2px solid #fff;
	border-top: 3px solid #fff;
	.boxShaddow-small-bright;
}
.contenttable-01 th {
	padding: 10px;
	font-weight: 700;
	color: @textColor;
	border-right: 2px solid #fff;
	border-bottom: 3px solid #fff;
	text-align: left;
	font-size: 12px;
}
.contenttable-01 td {
	padding: 10px;
	color: @textColor;
	border-right: 2px solid #fff;
	border-bottom: 3px solid #fff;
	font-size: 14px;
}

.contenttable-01 td ul {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-top: 0px;
	padding-bottom: 0px;
}
.contenttable-01 td ul li {
	margin: 0px;
	padding: 0px 0px;
}

.contenttable-01 .divider {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
.contenttable-01 .divider small {
	display: block;
}
.contenttable-01 .divider h4 {
	color: @primaryColor-text;
	padding: 0px;
	margin: 0px;
}


