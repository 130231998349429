/* COOKIE */
.cookieinfo {
	background-color: fade(#fff,90%) !important;
	border: none !important;
	.boxShaddow-big-medium;
	position: fixed !important;
	top: auto !important;
	left: 20px !important;
	right: auto !important;
	width: 280px;
	bottom: 20px !important;
	border-bottom: 3px solid @primaryColor !important;
	border-radius: 0px !important;
	font-size: 12px;
	line-height: 14px;
	padding: 20px !important;
	.boxSizing;
	display: flex;
	flex-wrap: wrap;
	
	@media (max-width: 640px) {
		left: 20px !important;
		right: 20px !important;
		top: 20px !important;
		bottom: auto !important;
		width: auto !important;
		padding: 10px !important;
	}
	
}
.cookieinfo > .cookieinfo-close {
	display: block;
	margin-top: 10px;
	width: 100%;
	.boxSizing;
	background-color: @primaryColor !important;
	color: @primaryColor-text !important;
	
	float: none !important;
	display: block;
	padding: 5px 8px;
	margin: 0px !important;
	cursor: pointer;
	text-align: center;
	order: 2;
	margin-top: 10px !important;
}
.cookieinfo > span {
	width: 100%;
	order: 1;
	color: @textColor !important;
	font-size: 12px !important;
	line-height: 16px !important;
	margin: 0px !important;
	padding: 0px !important;
}
.cookieinfo > span a {
	color: @primaryColor !important;
}
/* COOKIE */


.insideApp .cookieinfo {
	display: none !important;
}