.langSwitcher {
	background-color: @dividerColor;
	line-height: 30px;
	font-size: 15px;
	display: flex;
	margin-top: 40px;
}
public-event .langSwitcher {
	@media (max-width: 650px) {
		margin-top: -80px;
		margin-bottom: 120px;
	}
}


.langSwitcher .lang-icon {
	color: @primaryColor;
	display: block;
	margin-left: 20px;
	margin-right: 10px;
}
.langSwitcher .languages {
	display: flex;
}
.langSwitcher .languages li {
	margin-right: 10px;
}
.langSwitcher .languages a {
	display: block;
	font-size: 14px !important;
	color: @textColor;
	line-height: 30px !important;
	text-align: center;
}

.langSwitcher .languages > a {
	margin-left: 10px;
	position: relative;
	text-transform: uppercase;
}
.langSwitcher .languages > a:first-child {
	margin-left: 0px;
}
.langSwitcher .languages > a:after {
	content: '';
	display: block;
	left: 0px;
	top: 10px;
	bottom: 10px;
	width: 1px;
	background: rgba(0,0,0,0.15);
}

.langSwitcher .languages a:hover, .langSwitcher .languages a.active {
	color: @primaryColor !important;
}

.frame-language-switcher {
	margin: -40px 15px 15px 15px;
}
.frame-language-switcher .langSwitcher {
	border-radius: 10px;
}
	/* --------- bgImagePublic footer --------- */
	.bgImagePublic .langSwitcher {
		background-color: fade(@dividerColor,95%);
		backdrop-filter: blur(7px);
		margin: 0px auto;
		max-width: 1000px;
		border-top: 1px solid @primaryColor;

		@media (max-width: 1000px) {
			margin: 0px 15px !important;
		}


		@media (max-width: 600px) {
			margin: 0px 10px !important;
		}

	}


/* langSwitcher 
.langSwitcher {
	background-color: @dividerColor;
	background-color: #fff;
	line-height: 40px;
	width: 40px;
	height: 40px;
	text-align: center;
	border-radius: 100%;
	align-items: center;
	position: relative;
	cursor: pointer;
	display: block;
	
	@media (max-width: 650px) {
		display: flex;
		width: 100%;
		position: relative;
		border-radius: 5px;
		margin-left: -10px;
		margin-right: -10px;
		.boxShaddow-small-bright;
	}
	
}
.langSwitcher .lang-icon {
	color: @textColor;
	color: @accentColor;
	font-size: 15px;
	position: relative;
	z-index: 3;
	display: block;
	
	@media (max-width: 650px) {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 40px;
		text-align: center;
	}
}
.langSwitcher .languages {
	display: none;
	padding: 0px;
	margin: 0px;
	list-style: none;
	margin-right: 0px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding-bottom: 40px;
	padding-top: 10px;
	border-radius: 44px 44px;
	background-color: @dividerColor;
	z-index: 1;
	.boxShaddow-small-bright;
	
	@media (max-width: 650px) {
		display: flex;
		margin-left: 40px;
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		background-color: transparent;
		border-radius: 0px;
		.boxShaddow-none;
		padding: 0px;
	}
	
}

.langSwitcher:hover > .languages, .langSwitcher > .languages:hover {
	display: block;
	
	@media (max-width: 650px) {
		display: flex;
	}
}
.langSwitcher .languages a {
	display: block;
	margin-bottom: 2px;
	font-size: 12px !important;
	color: @textColor;
	line-height: 30px !important;
	text-align: center;
	
	@media (max-width: 650px) {
		line-height: 40px !important;
		width: 40px;
		text-align: center;
		margin: 0px;
		font-size: 14px;
	}
}
.langSwitcher .languages a:hover {
	color: @textColor-dark !important;
}

*/

.cp-phone-locale-options {
	.md-text span {
		margin-left: 10px;

		i {
			width: 15px;
			height: 15px;
			margin-top: 3px;
			margin-left: -18px;
			position: absolute;
		}
	}

	&--top {
		.md-text span {
			margin-left: 22px;
		}
	}
}