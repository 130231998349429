.page-break {
	display: none;
	
	@media print {
	  display: block;
	  page-break-after: always;
	}
}

.visitor-stat-container {
	display: flex;
	flex-wrap: wrap;
	margin: 10px 0px;
	
	@media print {
	  display: block;
	}
}

.visitor-stat-container .widget {
	width: ~"calc(50% - 20px)";
	.boxSizing;
	background-color: fade(@dividerColor,85%);
	margin: 10px;
	border-radius: 10px;
	/* .boxShaddow-big-medium; */
	
	@media (max-width: 600px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
	
	@media print {
	  page-break-inside: avoid !important;
	  width: 100% !important;
	  margin-left: 0px !important;
	  margin-right: 0px !important;
	  .boxShaddow-none;
	  border: 1px solid @textColor-light;
	  .boxSizing;
	}
	
}
	.visitor-stat-container .widget.width-100pz {
		width: ~"calc(100% - 20px)";
		.boxSizing;
		background-color: fade(@dividerColor,85%);
		margin: 10px;
		
		@media (max-width: 600px) {
			width: 100%;
			margin-left: 0px;
			margin-right: 0px;
		}
	}
	
	.visitor-stat-container .widget:nth-child(odd) {
	}
	.visitor-stat-container .widget:nth-child(even) {
		/*border-left: 1px solid @dividerColor;*/
	}

.visitor-stat-container .widget .widget-header h3 {
		font-size: 18px;
		line-height: 18px;
		color: @primaryColor-text;
		.font-special;
		text-align: center;
		background-color: @primaryColor;
		padding: 10px;
		border-radius: 10px 10px 0px 0px;
}

.visitor-stat-container .widget .widget-footer {
		
}


/* ######################################################## */
/* wt-number-new */
/* ######################################################## */
.wt-number-new .widget-content {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	padding: 40px 0px;
	
	@media print {
	  padding: 10px 0px;
	}
}
.wt-number-new .widget-content .item {
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	align-content: flex-start;
}
.wt-number-new .widget-content .item .main {
	display: block;
	font-size: 35px;
	width: 80px;
	line-height: 80px;
	border-radius: 100%;
	overflow: hidden;
	.font-special;
	color: @primaryColor;
	.boxSizing;
	background-color: #fff;
	text-align: center;
	border: 1px solid @primaryColor;
	position: relative;
	position: relative;
	/* .boxShaddow-inside-bright; */
}
	.wt-number-new .widget-content .item.big .main {
		font-size: 50px;
		width: 160px;
		line-height: 160px;
		border: 2px solid @primaryColor;
	}
.wt-number-new .widget-content .item .main .additional {
	display: block;
	font-size: 12px;
	position: absolute;
	bottom: 10px;
	padding: 0px;
	margin: 0px;
	font-weight: bold;
	left: 0px;
	right: 0px;
	text-align: center;
	line-height: 12px;
	color: @textColor-light !important;
}
	.wt-number-new .widget-content .item.big .label{
		font-size: 15px;
		line-height: 15px;
	}
.wt-number-new .widget-content .item .icon {
	display: block;
	position: absolute;
	top: -7px;
	z-index: 1;
	left: 50%;
	margin-left: -10px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	background-color: #fff;
	border-radius: 100%;
	color: @primaryColor;
}
	.wt-number-new .widget-content .item.big .icon {
		font-size: 24px;
		top: -10px;
		left: 50%;
		margin-left: -15px;
		width: 30px;
		height: 30px;
		line-height: 30px;
	}
	

	.wt-number-new .widget-content .item.color-system-ok .main {
		color: @systemColor-ok;
		border-color: @systemColor-ok;
	}
	.wt-number-new .widget-content .item.color-system-ok .icon {
		color: @systemColor-ok;
	}
	.wt-number-new .widget-content .item.color-system-warning .main {
		color: @systemColor-warning;
		border-color: @systemColor-warning;
	}
	.wt-number-new .widget-content .item.color-system-warning .icon {
		color: @systemColor-warning;
	}
	.wt-number-new .widget-content .item.color-system-notok .main {
		color: @systemColor-error;
		border-color: @systemColor-error;
	}
	.wt-number-new .widget-content .item.color-system-notok .icon {
		color: @systemColor-error;
	}
	
.wt-number-new .widget-content .item .label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	padding: 10px 5px 0px 5px;
	color: @textColor-light;
	width: 100%;
}
.wt-number-new .widget-content .item .label strong {
	font-size: 12px;
	display: block;
	line-height: 14px;
	padding-top: 5px
}

.wt-number-new .widget-footer {
	font-size: 14px;
	line-height: 15px;
	color: @textColor;
	padding: 10px;
	background-color: #fff;
	
	text-align: center;
	margin: 10px;
	margin-top: 0px;
	border-radius: 10px;
	position: relative;
	
	@media print {
	  margin-bottom: 0px;
	}
}

.wt-number-new .widget-footer:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 10px;
	border-color: #fff transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -10px;
	left: 50%;
	margin-left: -5px
}

.wt-number-new .special-item {
	margin: -20px 10px 20px 10px;
	position: relative;
	background-color: @textColor-light;
	border-radius: 10px;
	height: 20px;
	.boxSizing;
	.boxShaddow-big-bright;
	overflow: hidden;
	
	@media print {
	  margin-top: 0px;
	  margin-bottom: 0px;
    .boxShaddow-none;
    border: 1px solid @textColor-light;
    .boxSizing;
	}
}
.wt-number-new .special-item .main {
	margin-left: 10px;
	width: 100%;
	position: relative;
}
.wt-number-new .special-item .main .percentage {
	position: absolute;
	display: block;
	left: 0px;
	top: 0px;
	height: 20px;
	background-color: @primaryColor;
	.boxShaddow-default;
}
.wt-number-new .special-item .icon {
	color: @primaryColor-text !important;
	left: -1px;
	font-size: 16px;
	top: -1px;
	z-index: 1;
	display: block;
	position: absolute;
	line-height: 23px;
	width: 21px;
	height: 22px;
	overflow: hidden;
	text-align: center;
	background-color: #fff;
	border-radius: 100%;
}
.wt-number-new .special-item .label {
	color: @primaryColor-text !important;
	left: 25px;
	top: 0px;
	z-index: 1;
	line-height: 20px;
	display: block;
	position: absolute;
	font-size: 12px;
	text-transform: uppercase;
}

/* ######################################################## */
/* wt-number */
/* ######################################################## */
.wt-number .widget-content {
	display: flex;
	justify-content: center;
}
.wt-number .widget-content .item {
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
}
.wt-number .widget-content .item:after {
	position: absolute;
	right: 0px;
	top: 30px;
	bottom: 30px;
	display: block;
	content: '';
	width: 1px;
	background-color: darken(@dividerColor, 5%);
}
.wt-number .widget-content .item:last-child:after {
	display: none;
}
.wt-number .widget-content .item .main {
	display: block;
	font-size: 75px;
	line-height: 75px;
	padding: 20px 0px 0px 0px;
	text-align: center;
	.font-special;
	color: @primaryColor;
	width: 100%;
	.boxSizing;
	
	@media (max-width: 400px) {
			font-size: 50px;
			line-height: 50px;
	}
}
	.wt-number .widget-content .item.color-system-ok .main, .wt-number .widget-content .item.color-system-ok .label {
		color: @systemColor-ok;
	}
	.wt-number .widget-content .item.color-system-warning .main, .wt-number .widget-content .item.color-system-warning .label {
		color: @systemColor-warning;
	}
	.wt-number .widget-content .item.color-system-notok .main, .wt-number .widget-content .item.color-system-notok .label {
		color: @systemColor-error;
	}
	
.wt-number .widget-content .item .label {
	display: block;
	font-size: 12px;
	line-height: 50px;
	text-align: center;
	margin: 10px;
	margin-bottom: 20px;
	background-color: #fff;
	color: @primaryColor;
	border-radius: 44px;
	padding: 5px 15px;
	line-height: 12px;
	border: 1px solid @dividerColor;
}

.wt-number .widget-footer {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
	padding: 10px;
	background-color: darken(@dividerColor,5%);
	
	text-align: center;
	margin: 10px;
	border-radius: 10px;
	position: relative;
}
.wt-number .widget-footer:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 10px;
	border-color: darken(@dividerColor,5%) transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -10px;
	left: 50%;
	margin-left: -5px
}


/* ######################################################## */
/* wt-smartList intelligente auswahlliste */
/* ######################################################## */
.wt-smartList .widget-content {
	padding: 10px;
}
.wt-smartList .stat-exportButton {
	background: @accentColor;
	color: @accentColor-text;
	padding: 5px 10px;
	.dropShaddow-small;
	border: none !important;
	border-radius: 7px;
	font-size: 13px;
	line-height: 13px;
	text-transform: uppercase;
	margin: 5px 0px;
}

.wt-smartList .widget-content table {
	width: 100%;
	border-bottom: 1px solid @textColor-light;
	border: none !important;
	border-radius: 0px 0px 10px 10px;
	overflow: hidden;
	margin-bottom: 1;
}
.wt-smartList .widget-content table th {
	font-size: 12px;
	line-height: 12px;
	text-align: left;
	padding: 10px 5px 5px 5px;
	font-weight: 400;
	color: @textColor-light;
	text-transform: uppercase;
}
.wt-smartList .widget-content table td {
	border-top: 1px solid lighten(@textColor-light,15%);
	padding: 10px 5px;
	font-size: 14px;
	line-height: 16px;
	text-align: left;
	vertical-align: top;
	color: @textColor;
	background-color: #fff;
	
	@media print {
	  font-size: 12px;
	  line-height: 14px;
	  padding: 5px 3px;
	}
}
.wt-smartList .widget-content table td.stat {
	max-width: 80px;
	
	@media (max-width: 400px) {
			font-size: 14px;
	}
}
.wt-smartList .widget-content table td .guest {
	padding-bottom: 5px;
	font-size: 14px;
	
	@media (max-width: 400px) {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid @dividerColor;
		border-radius: 7px;
		padding-left: 15px;
		position: relative;
		padding: 5px;
		padding-left: 10px;
		margin-bottom: 5px;
	}
	
}
.wt-smartList .widget-content table td .guest .guest-menge{
	display: inline-block;
	font-weight: 700;
	background-color: @primaryColor;
	padding: 2px 0px;
	.boxSizing;
	border-radius: 44px;
	width: 30px;
	text-align: center;
	color: @primaryColor-text;
	margin-right: 10px;
	
	@media (max-width: 400px) {
		display: block;
		font-weight: 400;
		background-color: @dividerColor;
		padding: 0px;
		border-radius: 100%;
		width: 18px;
		height: 18px;
		line-height: 16px;
		text-align: center;
		color: @primaryColor;
		margin-right: 0px;
		position: absolute;
		top: 50%;
		margin-top: -9px;
		left: -9px;
		font-size: 12px;
	}
}
.wt-smartList .widget-content table td .guest .guest-name {
	color: @primaryColor;
	display: inline-block;
	font-weight: 400;
	@media (max-width: 400px) {
		width: 100%;
		display: block;
		text-align: left;
		font-size: 15px;
		padding-bottom: 3px;
	}
}
.wt-smartList .widget-content table td .guest .guest-email {
	display: inline-block;
	padding-left: 10px;
	@media (max-width: 400px) {
		width: 100%;
		padding-left: 0px;
		padding-bottom: 3px;
	}
}
.wt-smartList .widget-content table td .guest .guest-email a {
	color: @primaryColor;
	font-size: small;
}
.wt-smartList .widget-content table td .guest .guest-date {
	font-size: small;
	display: inline-block;
	padding-left: 10px;
	@media (max-width: 400px) {
		width: 100%;
		padding-left: 0px;
	}
}

/* ######################################################## */
/* wt-balken */
/* ######################################################## */
.wt-balken .widget-content {
	display: flex;
	justify-content: center;
	padding: 5px;
	
	@media (max-width: 400px) {
			padding: 5px 0px;
	}
}

.wt-balken .widget-content .item {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 5px;
	margin-bottom: 0px;
	.boxSizing;
	max-width: ~"calc(25% - 10px)";
}
.wt-balken .widget-content .item .main {
	display: block;
	padding: 20px 0px 0px 0px;
	text-align: center;
	.font-special;
	color: @primaryColor;
	width: 100%;
	.boxSizing;
	position: relative;
	display: flex;
	height: 150px;
	background-color: darken(@dividerColor,5%);
	border-radius: 10px;
	overflow: hidden;
	
}
.wt-balken .widget-content .item .main .number {
	position: absolute;
	font-size: 15px;
	line-height: 15px;
	bottom: 5px;
	color: #fff;
	left: 0px;
	right: 0px;
	text-align: center;
	z-index: 2;
}
.wt-balken .widget-content .item .main .balken {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: @primaryColor;
}
.wt-balken .widget-content .item .label {
	display: block;
	font-size: 12px;
	line-height: 50px;
	text-align: center;
	margin: 10px 0px;
	background-color: #fff;
	color: @primaryColor;
	border-radius: 44px;
	padding: 5px 15px;
	line-height: 12px;
	border: 1px solid @dividerColor;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.wt-balken .widget-footer {
	font-size: 13px;
	color: @textColor;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 10px;
	background-color: darken(@dividerColor,5%);
	margin: 10px;
	border-radius: 10px;
	position: relative;
}
.wt-balken .widget-footer:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 10px;
	border-color: darken(@dividerColor,5%) transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -10px;
	left: 50%;
}
.wt-balken .widget-footer span.intro {
	font-size: 13px;
	line-height: 15px;
	width: 100%;
	.boxSizing;
	color: @textColor;
	padding: 5px;
}
.wt-balken .widget-footer span.additional {
	font-size: 13px;
	line-height: 13px;
	color: @textColor;
	display: block;
	padding: 3px 8px;
	background-color: #fff;
	color: @textColor;
	margin: 5px;
	border-radius: 5px;
}


/* ######################################################## */
/* wt-balken */
/* ######################################################## */
.wt-balken-quer .widget-content {
	display: flex;
	justify-content: center;
	padding: 5px;
	flex-wrap: wrap;
	padding-bottom: 10px;
}
.wt-balken-quer .displayNone {
	display: none	!important;
}
.wt-balken-quer .widget-content .item {
	width: 100%;
	margin: 5px;
	margin-bottom: 0px;
	.boxSizing;
	position: relative;
}
.wt-balken-quer .widget-content .item .main {
	display: block;
	text-align: center;
	.font-special;
	color: @primaryColor;
	width: 100%;
	.boxSizing;
	position: relative;
	display: flex;
	background-color: darken(@dividerColor,5%);
	border-radius: 10px;
	overflow: hidden;
	order: 2;
	height: 30px;
}
.wt-balken-quer .widget-content .item .main .number {
	position: absolute;
	font-size: 15px;
	line-height: 15px;
	bottom: 5px;
	color: #fff;
	left: 10px;
	right: auto;
	text-align: left;
	z-index: 2;
}
.wt-balken-quer .widget-content .item .main .balken {
	position: absolute;
	left: 0px;
	bottom: 0px;
	top: 0px;
	background-color: @primaryColor;
	background-color: @textColor;
}
.wt-balken-quer .widget-content .item .label {
	display: block;
	font-size: 12px;
	line-height: 50px;
	text-align: center;
	background-color: #fff;
	color: @primaryColor;
	border-radius: 10px;
	padding: 0px 10px;
	line-height: 20px;
	order: 1;
	text-align: right;
	position: absolute;
	right: 5px;
	top: 5px;
}

.wt-balken-quer .widget-footer {
	font-size: 13px;
	color: @textColor;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 10px;
	background-color: darken(@dividerColor,5%);
	margin: 10px;
	border-radius: 10px;
	position: relative;
}
.wt-balken-quer .widget-footer:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 10px;
	border-color: darken(@dividerColor,5%) transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -10px;
	left: 50%;
}
.wt-balken-quer .widget-footer span.intro {
	font-size: 13px;
	line-height: 15px;
	width: 100%;
	.boxSizing;
	color: @textColor;
	padding: 5px;
}
.wt-balken-quer .widget-footer span.additional {
	font-size: 13px;
	line-height: 13px;
	color: @textColor;
	display: block;
	padding: 3px 8px;
	background-color: #fff;
	color: @textColor;
	margin: 5px;
	border-radius: 5px;
}


/* wt-balken-quer > Subhead */
.wt-balken-quer .widget-subContent {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	padding: 20px 0px 10px 0px;
	
	@media print {
	  padding: 10px 0px;
	}
}
.wt-balken-quer .widget-subContent .item {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	justify-content: center;
	align-items: flex-start;
	position: relative;
	align-content: flex-start;
}
.wt-balken-quer .widget-subContent .item .main {
	display: block;
	font-size: 35px;
	width: 60px;
	height: 60px;
	line-height: 58px;
	padding-top: 2px;
	border-radius: 100%;
	overflow: hidden;
	.font-special;
	color: @textColor;
	.boxSizing;
	background-color: #fff;
	text-align: center;
	border: 1px solid @textColor-light;
	position: relative;
	position: relative;
	/* .boxShaddow-inside-bright; */
}
.wt-balken-quer .widget-subContent .item .main .additional {
	display: block;
	font-size: 12px;
	position: absolute;
	bottom: 10px;
	padding: 0px;
	margin: 0px;
	font-weight: bold;
	left: 0px;
	right: 0px;
	text-align: center;
	line-height: 12px;
	color: @textColor-light !important;
}
	.wt-balken-quer .widget-subContent .main .color-system-ok {
		color: @systemColor-ok;
		border-color: @systemColor-ok;
	}
	.wt-balken-quer .widget-subContent .main .color-system-warning {
		color: @systemColor-warning;
		border-color: @systemColor-warning;
	}
	.wt-balken-quer .widget-subContent .main .color-system-notok  {
		color: @systemColor-error !important;
		border-color: @systemColor-error;
	}
	
.wt-balken-quer .widget-subContent .item .label {
	display: block;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	padding: 10px 5px 0px 5px;
	color: @textColor-light;
	width: 100%;
}
.wt-balken-quer .widget-subContent .item .label strong {
	font-size: 12px;
	display: block;
	line-height: 14px;
	padding-top: 5px
}

/* ######################################################## */
/* wt-image */
/* ######################################################## */
.visitor-stat-container .widget.wt-image {
	width: ~"calc(100% - 20px)";
	
	@media (max-width: 600px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}
.wt-image .widget-content {
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
}
.wt-image .widget-content .item {
	width: 12.5%;
	/*padding: 2px;*/
	.boxSizing;
	position: relative;
	.transition-default;
	z-index: 1;
	position: relative;
	background-color: #fff;
	border-radius: 10px;
	overflow: hidden;
	
	@media (max-width: 900px) {
		width: 20%;
	}
	
	@media (max-width: 600px) {
		width: 25%;
	}
	
	@media (max-width: 400px) {
		width: 33%;
	}
	
	@media (max-width: 320px) {
		width: 50%;
	}
	
	@media print {
		width: 12.5% !important;
	}
	
}
.wt-image .widget-content .item:hover {
	.transition-default;
	.scaleUp-big;
	.boxShaddow-big;
	z-index: 2;
	position: relative;
}

.wt-image .widget-content .item .label {
	position: absolute;
	bottom: 5px;
	left: 5px;
	right: 5px;
	background-color: fade(#000,35%);
	padding: 5px;
	font-size: 12px;
	line-height: 12px;
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: 10px;
}

.wt-image .widget-content .item a {
	display: block;
}
.wt-image .widget-content .item a img {
	width: 100%;
	height: auto;
	margin-bottom: -6px;
}


.wt-image .widget-footer {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
	border-radius: 0px 0px 9px 9px;
	text-align: center;
	padding: 10px 10px 10px 10px;
	background-color: darken(@dividerColor,5%);
	border-top: 1px solid fade(#fff,75%);
}

/* ######################################################## */
/* wt-file */
/* ######################################################## */
.visitor-stat-container .widget.wt-file {
	width: ~"calc(100% - 20px)";
	
	@media (max-width: 600px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}
.wt-file .widget-content {
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
}
.wt-file .widget-content .item {
	margin: 5px;
	display: block;
	background-color: #fff;
	padding: 5px 15px;
	color: @primaryColor;
	border-radius: 44px;
	border: 1px solid @primaryColor;
	font-size: 14px;
	line-height: 14px;
	cursor: pointer;
	.transition-default;
}
.wt-file .widget-content .item:hover {
	color: @primaryColor-text;
	background-color: @primaryColor;
	.boxShaddow-small;
	.transition-default;
}
.wt-file .widget-content .item .icon {
	display: inline-block;
	margin-left: 10px;
}



.wt-file .widget-footer {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
	border-radius: 0px 0px 9px 9px;
	text-align: center;
	padding: 10px 10px 10px 10px;
	background-color: darken(@dividerColor,5%);
	border-top: 1px solid fade(#fff,75%);
}

/* ######################################################## */
/* wt-file */
/* ######################################################## */
.visitor-stat-container .wt-textinfo {
	width: ~"calc(50% - 20px)";
	
	@media (max-width: 600px) {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}
.wt-textinfo .widget-content {
	display: flex;
	flex-wrap: wrap;
	padding: 5px;
}
.wt-textinfo .widget-content .item {
	margin: 5px;
	display: block;
	background-color: #fff;
	padding: 5px 7px;
	color: @primaryColor;
	border-radius: 7px;
	border: 1px solid @primaryColor;
	font-size: 12px;
	line-height: 14px;
	cursor: pointer;
	.transition-default;
}
.wt-textinfo .widget-content .item .preformatted {
	white-space: pre-wrap;
}
.wt-textinfo .widget-content .item:hover {
	.boxShaddow-small;
	.transition-default;
	.scaleUp-big;
}
.author {
	font-size: 10px;
	display: block;
	color: @textColor-light;
	padding-top: 3px;
}

.wt-textinfo .widget-footer {
	font-size: 13px;
	color: @textColor;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 10px;
	background-color: darken(@dividerColor,5%);
	margin: 10px;
	border-radius: 10px;
	position: relative;
}
.wt-textinfo .widget-footer:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 10px;
	border-color: darken(@dividerColor,5%) transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -10px;
	left: 50%;
}
.wt-textinfo .widget-footer .intro {
	font-size: 13px;
	line-height: 15px;
	width: 100%;
	.boxSizing;
	color: @textColor;
	padding: 5px;
}