.filter-dialog {
	color: @textColor;
	font-size: 15px;
	line-height: 17px;
	padding: 40px 20px;
	display: flex;
	flex-wrap: wrap;
}

.filter-dialog .miniCta {
	border-radius: 3px;
	background-color: @primaryColor;
	padding: 3px 10px !important;
	color: #fff;
	display: inline-block;
	
	-webkit-box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
	-moz-box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
	box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
}
.filter-dialog .noLabel label, .filter-dialog .noLabel .contentField--label {
	display: none;
}

.filter-dialog .filter-option {
	margin-bottom: 40px;
	width: 100%;
	.boxSizing;
}
	.filter-dialog .filter-option.width-50:nth-child(odd) {
		width: 50%;
		.boxSizing;
		padding-right: 5px;
		@media (max-width: 900px) {
			width: 100%;
			padding-left: 0px;
			padding-right: 0px;
		}
	}
	.filter-dialog .filter-option.width-50:nth-child(even) {
		width: 50%;
		.boxSizing;
		padding-left: 5px;
		@media (max-width: 900px) {
			width: 100%;
			padding-left: 0px;
			padding-right: 0px;
		}
	}

.filter-dialog .filter-option header {}

.filter-dialog .filter-option header .label {
	display: block;
	font-size: 20px;
	line-height: 20px;
	color: @primaryColor;
	padding-bottom: 5px;
	.font-main;
	text-align: center;
}
	.filter-dialog .filter-option.width-50 header .label {
		text-align: left;
		
		@media (max-width: 900px) {
			text-align: center;
		}
	}

.filter-dialog .filter-option header .additional {
	display: block;
	color: @textColor-light;
	font-size: 14px;
	line-height: 16px;
	padding-bottom: 10px;
	text-align: center;
}

.filter-dialog .filter-option .option {
	
}
.filter-dialog .filter-option .option .error-msg	 {
	color: @systemColor-error;
	font-size: 12px;
	line-height: 12px;
	display: block;
	padding: 5px 0px 0px 0px;
	display: none;
}

/* ######################################################## */
/* filterSmartList */
/* ######################################################## */
.filterSmartList {

}
.filterSmartList .filterSmartList-group-wrapper {

}
.filterSmartList h5 {
	margin: 0px;
	padding: 0px;
	color: @primaryColor;
	font-size: 14px;
	line-height: 14px;
	padding-bottom: 0px;
	font-weight: 400;
}
.filterSmartList .filterSmartList-group {
	border: 1px solid lighten(@textColor-light,15%);
	background-color: #fff;
	.boxShaddow-big-bright;
	border-radius: 7px;
	padding: 10px;
	margin-bottom: 15px;
}

.filterSmartList .filterSmartList-group {
	border: 1px solid lighten(@textColor-light,15%);
	background-color: #fff;
	.boxShaddow-big-bright;
	border-radius: 7px;
	padding: 10px;
	padding-bottom: 0px;
	margin-top: 15px;
}

.filterSmartList .filterSmartList-group-options-wrapper {
	flex-wrap: wrap;
	display: flex;
}
.filterSmartList .filterSmartList-group-option {
	display: block;
	border-radius: 5px;
	border: 1px solid lighten(@textColor-light,15%);
	padding: 5px 10px;
	margin: 0px 10px 10px 0px;
	color: @textColor-light;
	font-size: 12px;
	line-height: 12px;
}
.filterSmartList .filterSmartList-group-option.active {
	border-color: @primaryColor;
	background-color: @primaryColor;
	color: @primaryColor-text;
}
.filterSmartList .filterSmartList-group-option:hover {
	border-color: @primaryColor;
}


/* ######################################################## */
/* inputs */
/* ######################################################## */

/* date */
.filter-dialog .filter-option .option.typ-date label {
	display: none;
}

.filter-dialog .filter-option .option.typ-date md-input-container {
	margin: 0px !important;
}
.filter-dialog .filter-option .option.typ-date .md-datepicker-input {
	max-width: 100% !important;
}

md-input-container small.labelLike {
	display: block;
	padding: 0px 0px 3px 10px;
	color: @textColor;
}
/* ######################################################## */
/* tags */
/* ######################################################## */

.filter-dialog .filter-option .option.typ-tags {
	
}
.filter-dialog .filter-option .option.typ-tags .tag-holder {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}
.filter-dialog .filter-option .option.typ-tags .tag-holder .tag {
	white-space: nowrap;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	padding: 5px 10px;
	font-size: 16px;
	line-height: 16px;
	background-color: @dividerColor;
	margin: 5px;
	cursor: pointer;
	border-radius: 5px;
	outline: none;
	border-color: @primaryColor;
}
.filter-dialog .filter-option .option.typ-tags .tag-holder .tag:hover {
	background-color: darken(@dividerColor,15%);
}
.filter-dialog .filter-option .option.typ-tags .tag-holder .tag.selected {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
	
	
		.filter-dialog .filter-option .option.typ-tags .tag-holder .tag.selected {
			background-color: @primaryColor;
			color: @primaryColor-text;
		}


/* ######################################################## */
/* select-block */
/* ######################################################## */

.filte-dialog .filter-option .option.typ-select-block {
	
}

.filter-dialog .filter-option .option.typ-select-block .option-holder {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap: wrap;
}

.filter-dialog .filter-option .option.typ-select-block .option-holder .option {
	white-space: nowrap;
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	padding: 5px 10px;
	font-size: 16px;
	line-height: 16px;
	background-color: @dividerColor;
	margin: 5px;
	cursor: pointer;
	border-radius: 3px;
	outline: none;
}

.filter-dialog .filter-option .option.typ-select-block .option-holder .option .icon {
	
}
.filter-dialog .filter-option .option.typ-select-block .option-holder .option .icon.icon-only {
	
}
.filter-dialog .filter-option .option.typ-select-block .option-holder .option .label {
	
}
.filter-dialog .filter-option .option.typ-select-block .option-holder .option .label.label-only {
	
}

.filter-dialog .filter-option .option.typ-select-block .option-holder .option:hover {
	background-color: darken(@dividerColor,15%);
}

.filter-dialog .filter-option .option.typ-select-block .option-holder .option.selected {
	background-color: @primaryColor-dark;
	color: @primaryColor-text;
}


/* ######################################################## */
/* typ-checkbox*/
/* ######################################################## */
.filter-dialog .filter-option .option.typ-checkbox {
	padding-top: 20px;
}

.filter-dialog .filter-option .option.typ-checkbox .checkbox-holder {
	.blankList;
	border-top: 2px solid @dividerColor;
}
.filter-dialog .filter-option .option.typ-checkbox .checkbox-holder .checkbox-container {
	outline: none;
}
.filter-dialog .filter-option .option.typ-checkbox .checkbox-holder .checkbox-container cp-toggle-option > span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 2px solid @dividerColor;
	padding: 10px 0px;
	color: @textColor;
	outline: none;
}
.filter-dialog .filter-option .option.typ-checkbox .checkbox-holder .checkbox-container .checkbox {
	display: block;
	font-size: 22px;
	line-height: 22px;
	min-height: 23px;
	width: 50px;
	text-align: left;
}
.filter-dialog .filter-option .option.typ-checkbox .checkbox-holder .checkbox-container .label {
	display: block;
	font-size: 14px;
}

/* ######################################################## */
/* detailOptions */
/* ######################################################## */
.detailOptions {
	.blankList;
}
.detailOptions li {
	padding: 0px 0px;
}

/* ######################################################## */
/* info-section */
/* ######################################################## */
.filter-dialog .info-section {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
}
.filter-dialog .info-section p {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
	margin: 0px;
	padding: 0px;
	padding-top: 5px;
}
.filter-dialog .info-section h2 {
	font-size: 20px;
	line-height: 22px;
	color: @primaryColor;
	margin: 0px;
	padding: 0px;
	padding-top: 5px;
}
.filter-dialog .info-section h3 {
	font-size: 15px;
	line-height: 17px;
	color: @primaryColor;
	margin: 0px;
	padding: 0px;
	padding-top: 5px;
}

.filter-dialog ul.tag-like {
	.blankList;
	display: flex;
	flex-wrap: wrap;
}
.filter-dialog ul.tag-like li {
	white-space: nowrap;
	display: block;
	padding: 5px 10px;
	border-radius: 5px;
	color: @textColor;
	font-size: 12px;
	line-height: 12px;
	margin: 5px 5px 0px 0px;
	background-color: @dividerColor;
}
.filter-dialog ul.tag-like li li {
	color: @textColor-light;
}
.filter-dialog ul.tag-like li.copyThis {
	cursor: pointer;
}
.filter-dialog ul.tag-like li.copyThis:hover {
	background-color: @primaryColor;
	color: @primaryColor-text;
}

	.filter-dialog ul.tag-like li.copyThis.payedEventOnly {
		/* ??? */
	}


.filter-dialog ul.tag-like li .icon {
	line-height: 12px;
	display: inline-block;
	margin-left: 5px;
	font-size: 10px;
	text-align: center;
	color: @primaryColor;
}
.filter-dialog ul.tag-like li.copyThis:hover .icon {
	color: @primaryColor-text;
}

.filter-dialog ul.tag-like li a.editTagLikeElement {
	display: inline-block;
	font-size: 12px;
	margin-left: 2px;
	padding-left: 5px;
	border-left: 1px solid #fff;
	color: @primaryColor;
	margin-right: -3px;
}
.filter-dialog ul.tag-like li a.editTagLikeElement:hover {
	color: @primaryColor-text;
}

/* ######################################################## */
/* senden dialoge */
/* ######################################################## */
.hinweisAlterText {
	margin-top: 15px;
	margin-bottom: -5px;
	border-radius: 10px;
	padding: 10px 10px 10px 40px;
	border: 1px solid @systemColor-warning;
	.boxSizing;
	color: @textColor;
	position: relative;
}
	.hinweisAlterText.colorNeutral {
		border-color: @textColor-light !important;
	}
.hinweisAlterText a {
	display: block;
	margin-top: 3px;
	color: @primaryColor;
}
.hinweisAlterText .icon {
	display: block;
	position: absolute;
	left: 10px;
	top: 50%;
	line-height: 20px;
	font-size: 15px;
	margin-top: -10px;
	color: @systemColor-warning;
}
	.hinweisAlterText.colorNeutral .icon {
		color: @textColor-light !important;
	}