.dashboard-clean {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	margin: 20px;
}
.dashboard-clean .db-item {
	margin: 20px;
	background-color: #fff;
	.boxShaddow-big-bright-bottomFocused;
	/* border: 1px solid darken(@dividerColor,5%); */
	.boxSizing;
	border-radius: 10px;
}
	.dashboard-clean .db-item.db-item-size-1-2 {
		width: ~"calc(50% - 40px)";
	}
	.dashboard-clean .db-item.db-item-size-1-1 {
		width: ~"calc(100% - 40px)";
	}
	.dashboard-clean .db-item.db-item-size-1-3 {
		width: ~"calc(33.33% - 40px)";
	}
	.dashboard-clean .db-item.db-item-size-2-3 {
		width: ~"calc(66.77% - 40px)";
	}
.dashboard-clean .db-item header {
	position: relative;
}
.dashboard-clean .db-item header h2 {
	font-size: 20px;
	color: @primaryColor;
	padding: 0px 100px 0px 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0px;
	line-height: 50px;
	text-transform: none;
	font-weight: 400;
}
	.dashboard-clean .db-item header h2 a {
		color: @primaryColor;
	}
	.dashboard-clean .db-item header h2 a:hover {
		color: @primaryColor-dark;
	}
.dashboard-clean .db-item header .db-icon {
	display: block;
	background-color: @primaryColor;
	color: @primaryColor-text;
	line-height: 30px;
	height: 30px;
	width: 30px;
	border-radius: 100%;
	.dropShaddow-small;
	position: absolute;
	left: -15px;
	top: 10px;
	z-index: 1;
	text-align: center;
	font-size: 16px;
}
.dashboard-clean .db-item header .db-introtext {
	padding: 0px 20px;
	margin: 0px;
	color: @primaryColor-dark;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
}
.dashboard-clean .db-item header a.db-cta {
	display: block;
	line-height: 28px;
	height: 28px;
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 1;
	text-align: right;
	padding: 0px 10px;
	font-size: 14px;
	text-transform: uppercase;
	color: @accentColor-text;
	background-color: @accentColor;
	.dropShaddow-small;
	border-radius: 7px;
}
	.dashboard-clean .db-item header a.db-cta:hover {
		background-color: @primaryColor;
		color: @primaryColor-text;
	}



.dashboard-clean .db-item.headerMessage {
	background-color: fade(@accentColor,90%);
	color: @accentColor-text;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	border-radius: 3px;
}
.dashboard-clean .db-item.headerMessage .image {
	width: 80px;
	height: 80px;
}
.dashboard-clean .db-item.headerMessage .image img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	border: 2px solid #fff;
	.boxShaddow-big;
	.boxSizing;
}
.dashboard-clean .db-item.headerMessage .text {
	width: ~"calc(100% - 80px)";
	.boxSizing;
	padding-left: 10px;
}
.dashboard-clean .db-item.headerMessage .text a {
	display: inline-block;
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	padding: 2px 5px;
	border-radius: 3px;
	border: 1px solid 	#fff;
	margin-left: 10px;
}
.dashboard-clean .db-item.headerMessage .text a.notImportant {
	color: fade(#fff,50%);
	border-color: fade(#fff,50%);
}


/* content */
.dashboard-clean .db-item-content-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	margin: 0px;
}
.dashboard-clean .db-content {
	margin: 20px;
}
	.dashboard-clean .db-content.db-content-size-1-1 {
		width: ~"calc(100% - 40px)";
	}
	.dashboard-clean .db-content.db-content-size-1-2 {
		width: ~"calc(50% - 60px)";
	}
	.dashboard-clean .db-content.db-content-size-1-2:nth-child(odd) {
		margin-right: 60px;
	}
.dashboard-clean .db-content h3 {
	font-size: 14px;
	line-height: 14px;
	color: @textColor-light;
	padding: 0px;
	margin: 0px;
	font-weight: 700;
	.textShaddow-white;
	padding-bottom: 5px;
	.font-main;
}
.dashboard-clean .db-content .db-content-listing {
	.blankList;
	border-top: 2px solid @dividerColor;
}


.dashboard-clean .db-content .db-content-listing > li {
	border-bottom: 2px solid @dividerColor;

	> a, span.linkLike {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 10px 0px;
		color: @primaryColor;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		position: relative;
		/*white-space: nowrap;*/
		overflow: hidden;
		text-overflow: ellipsis;
		.boxSizing;
		padding-right: 25px;

		.icon {
			position: absolute;
			right: 5px;
			top: 50%;
			margin-top: -10px;
			display: block;
			line-height: 20px;
			padding: 0px;
		}

		/* types */
		.date {
			font-size: 14px;
			font-weight: 700;
			display: inline-block;
			padding-right: 10px;
		}
		.title {
			display: block;
			padding-right: 5px;
			white-space: nowrap;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 150px;
		}
		.title .status {
			font-size: 12px;
			display: inline-block;
			width: 80px;
		}
		.additional-item {
			font-size: 12px;
			display: block;
			padding: 3px 5px;
			font-size: 12px;
			line-height: 12px;
			border: 1px solid @dividerColor;
			background-color: fade(#fff,35%);
			border-radius: 3px;
			color: @primaryColor-dark;
			white-space: nowrap;
			margin: 5px 10px 5px 0px;

			a {
				margin: 2px;
				color: @primaryColor;
			}
		}

		.title-additional {
			font-size: 14px;
			display: inline-block;
		}

		.icons {

		}
	}
}

	/* news */
	.dashboard-clean .db-content .db-content-listing.content-news > li a {
		white-space: normal;
		text-overflow: initial;
		overflow: auto;
		flex-wrap: nowrap;
	}
	.dashboard-clean .db-content .db-content-listing.content-news > li a .image {
		width: 60px;
		height: 60px;
		margin-right: 10px;
	}
	.dashboard-clean .db-content .db-content-listing.content-news > li a .image img {
		width: 60px;
		height: 60px;
		border-radius: 100%;
		.boxShaddow-small;
	}
	.dashboard-clean .db-content .db-content-listing.content-news > li a .title {
		display: block;
		padding-right: 25px;
		white-space: normal;
		max-width: auto;
		overflow: auto;
		text-overflow: initial;
		min-width: auto;
		
	}
	.dashboard-clean .db-content .db-content-listing.content-news > li a .title small {
		display: block;
		font-size: 12px;
		color: @primaryColor-dark;
	}


@media (max-width: 850px) {
	.dashboard .introduction.importantIntroduction div {
		margin: 20px !important;
	}
	
	.dashboard-clean {
		margin: 10px;
		padding-top: 10px;
	}
	.dashboard-clean .db-item {
		margin: 10px;
	}
		.dashboard-clean .db-item.db-item-size-1-2 {
			width: ~"calc(50% - 20px)";
		}
		.dashboard-clean .db-item.db-item-size-1-1 {
			width: ~"calc(100% - 20px)";
		}
		.dashboard-clean .db-item.db-item-size-1-3 {
			width: ~"calc(33.33% - 20px)";
		}
		.dashboard-clean .db-item.db-item-size-2-3 {
			width: ~"calc(66.77% - 20px)";
		}
}

@media (max-width: 650px) {
	
	.dashboard-clean .db-item-content-container {
	}
	.dashboard-clean .db-content {
		margin: 10px;
	}
		.dashboard-clean .db-content.db-content-size-1-1 {
			width: ~"calc(100% - 20px)";
		}
		.dashboard-clean .db-content.db-content-size-1-2 {
			width: ~"calc(50% - 40px)";
		}
		.dashboard-clean .db-content.db-content-size-1-2:nth-child(odd) {
			margin-right: 60px;
		}
	.dashboard-clean .db-content h3 {
		padding-left: 10px;
	}
	
}

@media (max-width: 660px) {
	.dashboard-clean .db-item-content-container {
	}
	.dashboard-clean .db-content {
		margin: 10px;
	}
		.dashboard-clean .db-content.db-content-size-1-1 {
			width: ~"calc(100% - 20px)";
		}
		.dashboard-clean .db-content.db-content-size-1-2 {
			width: ~"calc(100% - 20px)";
		}
		.dashboard-clean .db-content.db-content-size-1-2:nth-child(odd) {
			margin-right: 10px;
		}
}

@media (max-width: 560px) {
	.dashboard-clean .db-item.db-item-size-1-2 {
		width: ~"calc(100% - 20px)";
	}
	.dashboard-clean .db-item.db-item-size-1-3 {
		width: ~"calc(100% - 20px)";
	}
	.dashboard-clean .db-item.db-item-size-2-3 {
		width: ~"calc(100% - 20px)";
	}
}

@media (max-width: 350px) {
	.dashboard-clean .db-content .db-content-listing > li a .title-additional {
		display: block;
		line-height: 12px;
		margin-top: -5px;
		padding-bottom: 5px;
	}
}


.dashboard-footer {
	text-align: center;
}
.dashboard-footer .disclaimer {
	display: inline-block;
	padding: 5px 10px;
	background-color: fade(#fff,85%);
	border-radius: 10px;
}