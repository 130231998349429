.header-eventlink {
	display: block;
	font-size: 18px;
	position: absolute;
	left: 10px;
	top: 50%;
	line-height: 30px;
	margin-top: -15px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 0px;
	padding-right: 8px;
	border-right: 1px solid rgba(255,255,255,0.35);
	display: flex;
	align-items: center;
}
.header-eventlink small {
	font-size: 10px;
	display: block;
	padding-left: 3px;
}



.checkout-overview, .checkout-numbers {
	padding-bottom: 120px;
}

.checkout-overview header {
	/*background-color: @dividerColor;*/
	padding: 10px;
	text-align: center;
}

.checkout-overview header .image {
	width: 110px;
	height: 110px;
	overflow: hidden;
	margin: 0px auto 10px auto;
	border-radius: 100%;
	.boxShaddow-big-bright;
	background-color: #fff;
	.boxSizing;
}
.checkout-overview header .image img {
	width: 110px;
	height: 110px;
	border-radius: 100%;
	margin-bottom: -6px;
}

.checkout-overview header h2 {
	color: @primaryColor;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 10px;
	position: relative;
}
.visitorLanguage {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 100%;
	overflow: hidden;
	font-size: 12px;
	font-weight: bold;
	background-color: @accentColor;
	color: @accentColor-text;
	.font-main;
}
.isVIP-corner-ribbon {
	width: 200px;
	background: @accentColor;
	position: absolute;
	text-align: center;
	line-height: 30px;
	letter-spacing: 1px;
	color: @accentColor-text;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	
	top: 67px;
  left: -65px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
	.boxShaddow-small-bright;
	
	background: linear-gradient(-45deg, @accentColor, lighten(@accentColor, 15%), @accentColor);
	background-size: 200% 200%;
	-webkit-animation: vipBGGradient 3s ease infinite;
	-moz-animation: vipBGGradient 3s ease infinite;
	animation: vipBGGradient 3s ease infinite;
	
}

@-webkit-keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}



.checkout-overview .formContainer {
	padding: 5px 0px 2px 0px;
	margin: 0px -5px;
	background: @dividerColor;
	border-radius: 5px;
	margin-top: 20px;
}


.checkout-overview header .checkout-company {
	display: block;
	color: @primaryColor;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 5px;
}
.checkout-overview header .checkout-basicComment {
	display: block;
	color: @textColor;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 10px;
	padding-bottom: 5px;
	white-space: pre;
	.font-main;
}

.checkout-overview header .checkout-user-status {
	display: inline-block;
	background-color: @textColor-light;
	color: #fff;
	border-radius: 44px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
	margin: 10px 0px;
	margin-bottom: 0px;
}


.checkout-subinfo {
	text-align: center;
	margin-top: 10px;
	border-bottom: 2px solid @dividerColor;
	padding-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
	.checkout-overview header .checkout-subinfo {
		border: none;
		padding-bottom: 0px;
	}
.checkout-subinfo .label {
	display: block;
	font-size: 12px;
	color: @textColor-light;
}
.checkout-subinfo .content {
	display: block;
	font-size: 16px;
	line-height: 18px;
	color: @textColor;
}
.checkout-subinfo strong {
	color: @primaryColor;
}

.checkout-subinfo .content.tag-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.checkout-subinfo .content.tag-container .tag {
	display: block;
	padding: 3px 5px;
	font-size: 12px;
	line-height: 12px;
	background-color: @textColor-light;
	color: #fff;
	margin: 5px;
	border-radius: 5px;
}


.checkout-subinfo-divider {
	background-color: @textColor-light;
	color: #fff;
	line-height: 12px;
	font-size: 12px;
	padding: 5px;
	text-align: center;
	display: block;
}

.checkout-numbers {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 10px;
}
.checkout-numbers .number {
	display: block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	font-size: 30px;
	color: @accentColor-text;
	background-color: @accentColor;
	margin: 10px;
	border-radius: 3px;
}

#checkout-footer {
	background-color: #fff;
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	.boxShaddow-small;
	background-color: @primaryColor;
	padding-bottom: 10px;
	z-index: 9;
	
	@supports (padding-bottom: env(safe-area-inset-bottom)) {
	  /*bottom: ~"calc(44px + env(safe-area-inset-bottom))";*/
	  padding-bottom: ~"calc(env(safe-area-inset-bottom) + 10px)";
	}
}
.footer-message {
	color: @primaryColor-text;
	font-size: 12px;
	line-height: 12px;
}
	
	.insideApp #checkout-footer {
		padding-bottom: 10px !important;
		
		@supports (margin-bottom: env(safe-area-inset-bottom)) {
		  /*bottom: ~"calc(44px + env(safe-area-inset-bottom))";*/
		  bottom: env(safe-area-inset-bottom);
		}
	}
	
	
/* md-dialog-actions 	.checkout-button {
	display: block;
	color: @textColor;
	text-align: center;
	line-height: 50px;
	border-radius: 3px;
	margin: 0px 10px;
	width: ~"calc(50% - 20px)";
	font-size: 16px;
	color: #fff;
}
md-dialog-actions .checkout-button.important {
	color: @primaryColor;
	background: #fff;
	.boxShaddow-big-bright;
	font-size: 24px;
} */

#checkout-footer .checkout-button {
	display: block;
	color: @textColor;
	text-align: center;
	line-height: 16px;
	border-radius: 7px;
	margin: 0px 10px;
	width: ~"calc(50% - 20px)";
	font-size: 16px;
	color: #fff;
	padding: 20px 0px 15px 0px;
}
#checkout-footer .checkout-button .icons {
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	padding-bottom: 7px;
	display: block;
}
#checkout-footer .checkout-button small {
	font-size: 9px;
	line-height: 9px;
	display: block;
	text-align: center;
	text-transform: uppercase;
}
#checkout-footer .checkout-button.important {
	color: @primaryColor;
	background: #fff;
	.boxShaddow-big-bright;
}

/* note */
.checkout-subinfo.hasForm textarea {
	background-color: #fff;
	border: 1px solid @primaryColor;
	padding: 5px;
	border-radius: 5px;
	color: @primaryColor;
	font-size: 17px;
	line-height: 17px;
	.boxSizing;
	width: 100%;
}


/* escort */
.escort-items {
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0px 20px 0px;
}
.escort-items .escort-item {
	display: block;
	width: 100%;
	padding: 0px;
	.boxSizing;
	color: @primaryColor;
	position: relative;
	
	margin-bottom: 10px;
}
.escort-items .escort-item .option {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -7px;
	height: 14px;
	font-size: 15px;
	line-height: 15px;
	display: flex;
	align-items: center;
}
.escort-items .escort-item .name {
	display: flex;
	padding-right: 35px;
}
.escort-items .escort-item .name small {
	color: @textColor;
	font-size: 12px;
	display: block;
	text-align: center;
	padding-top: 3px;
}
.escort-items .escort-item .name input {
	background-color: #fff;
	border: 1px solid @primaryColor;
	padding: 5px;
	border-radius: 5px;
	color: @primaryColor;
	font-size: 17px;
	line-height: 17px;
	.boxSizing;
	width: ~"calc(50% - 5px)";
}
.escort-items .escort-item .name input:last-child {
	margin-left: 10px;
}
.escort-items .escort-add {
	display: block;
	width: 100%;
	text-transform: uppercase;
	padding: 10px 10px;
	border-radius: 7px;
	background-color: @accentColor;
	color: @accentColor-text;
	position: relative;
	font-size: 13px;
	line-height: 13px;
}

.escort-items .save-abort {
	display: flex;
	width: 100%;
	margin-top: 10px;
}
.escort-items .save-abort .cancel-btn {
	display: block;
	width: ~"calc(50% - 5px)";
	text-align: center;
	background-color: transparent;
	padding: 10px 0px;
	font-size: 15px;
	line-height: 15px;
	color: @systemColor-error;
	background-color: #fff;
	border-radius: 7px;
	.boxSizing;
	border: 1px solid @systemColor-error;
}
.escort-items .save-abort .save-btn {
	display: block;
	width: ~"calc(50% - 5px)";
	text-align: center;
	margin-left: 10px;
	background-color: @systemColor-ok;
	font-size: 15px;
	padding: 10px 0px;
	line-height: 15px;
	color: #fff;
	border-radius: 7px;
	.boxSizing;
	border: 1px solid @systemColor-ok;
}

.event-count {
	background-color: fade(@primaryColor,100%);
	display: flex;
	flex-wrap: wrap;
	padding: 5px 5px 10px 10px;
	justify-content: center;
	position: relative;
	z-index: 1;
	.boxShaddow-big-bright;
}
.event-count .tag {
	margin: 5px 5px 0px 0px;
	display: block;
	font-size: 11px;
	line-height: 11px;
	padding: 0px 5px;
	color: @primaryColor-light;
	border-left: 1px solid rgba(255,255,255,0.5);
	background-color: transparent;
}
.event-count .tag:first-child {
	border-left: none;
}


/* Checkout-options */
.checkout-option-print {
	margin: 20px 10px;
	color: @textColor;
	text-transform: uppercase;
}

/* guest-checkout */

.guest-checkout {
	
}
.guest-checkout .header {
	padding: 10px 20px;
	text-align: left;
	color: @textColor;
	font-size: 13px;
	line-height: 16px;
}
.guest-checkout .header span.guest {
	display: block;
}

.guest-checkout .guest-list {
	.blankList;
}
.guest-checkout .guest-list a {
	line-height: 50px;
	font-size: 16px;
	position: relative;
	padding: 0px 30px;
	border-radius: 10px;
	background-color: #fff;
	margin: 10px;
	cursor: pointer;
	.boxShaddow-big-bright;
	color: @textColor;
	outline: none;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
	.guest-checkout .guest-list a.checkedOut {
		color: @primaryColor;
		background: @dividerColor;
		.boxShaddow-none;
		cursor: default;
	}
	.guest-checkout .guest-list a.selected {
		color: @primaryColor-text;
		background: @primaryColor;
	}
.guest-checkout .guest-list a .checkedOut-icon {
	position: absolute;
	left: 10px;
	top: 0px;
	z-index: 1;
	display: block;
	display: none;
}
	.guest-checkout .guest-list a.checkedOut .checkedOut-icon {
		display: block;
	}
.guest-checkout .guest-list a .selected-icon-false {
	position: absolute;
	left: 10px;
	top: 0px;
	z-index: 1;
	display: block;
}
	.guest-checkout .guest-list a.selected .selected-icon-false {
		display: none;
	}
	.guest-checkout .guest-list a.checkedOut .selected-icon-false {
		display: none;
	}
.guest-checkout .guest-list a .selected-icon-true {
	position: absolute;
	left: 10px;
	top: 0px;
	z-index: 1;
	display: none;
}
	.guest-checkout .guest-list a.selected .selected-icon-true {
		display: block;
	}
	
	
	
	
	
.guest-options {
	margin-top: 40px;
}
.guest-options a {
	border: 1px solid @primaryColor;
	display: block;
	border-radius: 7px;
	margin: 10px;
	margin-bottom: 20px;
	position: relative;
	padding: 10px;
	color: @primaryColor;
	text-align: left;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
}
.guest-options a .icon {
	display: inline-block;
	padding-right: 10px;
}