/*
	ToDo
	[] fixed header
*/


.between-section-divider {
	margin-top: 50px;
	height: 20px;
	border-top: 1px solid @primaryColor;
}


/* ######################################################## */
/* quickFilter */
/* ######################################################## */
/*.faq-section {
	margin-top: 40px;
	padding: 10px;
	border-top: 1px dashed @textColor-light;
	position: relative;
}
.faq-section .icon {
	display: block;
	position: absolute;
	top: -11px;
	left: 3px;
	color: @textColor-light;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	width: 18px;
	height: 18px;
	overflow: hidden;
	background-color: #fff;
	border-radius: 100%;
}
.faq-section h3 {
	font-size: 18px;
	color: @primaryColor;
	margin: 0px;
	padding: 0px;
	line-height: 18px;
	display: none;
}*/

.faq-section {
	margin-top: 40px;
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-top: 1px solid @dividerColor;

	@media (max-width: 650px) {
		display: block;
	}
}
	.faq-section.withBG {
		/*border: none;
		background-color: fade(#fff,25%);
		.boxShaddow-big-bright;
		margin-left: 10px;
		margin-right: 10px;*/
	}
.faq-section .image {
	width: 80px;
	height: 80px;
	margin-right: 10px;

	@media (max-width: 650px) {
		margin: 0px auto;
	}
}
.faq-section .image img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	.boxShaddow-big-bright;
}
.faq-section ul {
	.blankList;
	display: flex;
	flex-wrap: wrap;
	width: ~"calc(100% - 90px)";

	@media (max-width: 650px) {
		width: 100%;
		display: block;
	}
}
.faq-section ul li {

}
.faq-section ul li a {
	background-color: @textColor-light;
	color: #fff;
	border-radius: 7px;
	display: block;
	padding: 5px 10px;
	white-space: nowrap;
	max-width: 280px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	line-height: 12px;
	margin: 10px 10px 0px 0px;
	@media (max-width: 650px) {
		margin-right: 0px;
		max-width: 100%;
	}
}
	.withBG.faq-section ul li a {
		background-color: @primaryColor;
		color: @primaryColor-text;
	}
.faq-section ul li a:hover {
	background-color: @primaryColor-dark;
	color: @primaryColor-text;
}

/* ######################################################## */
/* quickFilter */
/* ######################################################## */
.filterItem {

}
.quickFilter {
	display: none;
}
.header-item:hover .quickFilter {
	display: block;
	position: absolute;
	right: 7px;
	left: 7px;
	top: 44px;
	background-color: #fff;
	.boxShaddow-big-bright;
	z-index: 9;
	border-radius: 10px;

	@media (max-width: 810px) {
		right: auto;
		width: 260px;
	}
}
.header-item:hover .quickFilter.rightPosition {
	@media (max-width: 810px) {
		right: 7px;
		left: auto;
	}
	@media (max-width: 320px) {
		width: 240px;
	}
}

.header-item:hover .quickFilter ul {
	.blankList;
}
.header-item:hover .quickFilter ul li {
	border-bottom: 1px dashed @dividerColor;

	/*
	@media (max-width: 750px) {
		overflow: hidden;
		width: 30px;
		white-space: nowrap;
	}
	*/

}
.header-item:hover .quickFilter ul li a {
	display: block;
	padding: 15px 15px;
	font-size: 14px;
	line-height: 14px;
	color: @accentColor;
}
.header-item:hover .quickFilter ul li a span {
	display: inline-block;
	width: 30px;
}
.header-item:hover .quickFilter ul li a:hover {
	color: @primaryColor;
}
.header-item:hover .quickFilter ul li:last-child {
	border-bottom: none;
}

/* ######################################################## */
/* overviewHeader-lotOfContent */
/* ######################################################## */
.overviewHeader-lotOfContent {
	margin: 0px;
	padding: 0px;
	margin-bottom: 30px;
	border-bottom: 1px solid @dividerColor;
	padding-right: 10px;
	backdrop-filter: blur(7px);
}

.overviewHeader-lotOfContent .filter-dialog {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 0px;

	@media (max-width: 750px) {
		display: block;
	}
}

.overviewHeader-lotOfContent .filter-dialog .filter-option {
	width: ~"calc(33.33% - 10px)";
	margin-left: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;

	@media (max-width: 750px) {
		width: 100%;
	}
}
.overviewHeader-lotOfContent .filter-dialog .filter-option .tag-holder .tag,
.cleanUp-modal .tag-holder .tag {
	color: @primaryColor;
	border: 1px solid @textColor-light;
	font-size: 12px !important;
	background-color: #fff !important;
}

.overviewHeader-lotOfContent .filter-dialog .filter-option .tag-holder .tag.selected,
.cleanUp-modal .tag-holder .tag.selected {
	background-color: @primaryColor !important;
	border-color: @primaryColor !important;
}

.cleanUp-modal .tag-holder {
	display: flex;
	flex-wrap: wrap;
	padding-top: 5px;
}

.cleanUp-modal .tag-holder .tag {
	color: @primaryColor;
	padding:5px 8px;
	border: 1px solid @primaryColor;
	font-size: 13px !important;
	line-height: 13px !important;
	background-color: #fff !important;
	outline: none;
	border-radius: 5px;
	margin: 10px 10px 0px 0px;
	white-space: nowrap;
	width: auto;
	cursor: pointer;
}

.cleanUp-modal .tag-holder .tag.selected {
	background-color: @primaryColor !important;
	border-color: @primaryColor !important;
	color: @primaryColor-text;
}

/* ######################################################## */
/* overviewHeader */
/* ######################################################## */
.overviewHeader {
	padding: 10px 0px;
	margin-right: 10px;
	margin-right: 0px;
	padding-right: 10px;
	background-color: #fff;
	border-bottom: 1px solid @dividerColor ;
	padding-bottom: 20px;
	margin-bottom: 20px;

	@media (max-width: 650px) {
		padding-bottom: 10px;
		padding-top: 0px;
		margin-bottom: 10px;
	}

	@media print {
	  display: none;
	}
}
	.overviewHeader.inDetails {

	}
	/*
	.overviewHeader.isFixed {
		position: fixed;
		z-index: 5;
		width: ~"calc(100% - 10px)";
		padding: 0px;
		padding-top: 5px;
	}
	.overviewHeaderisFixed-spacer {
		display: block;
		height: 70px;
	}
	*/
.overviewHeader .header-item  {
	position: relative;
	width: ~"calc(33.333% - 10px)";
	display: inline-block;
	float: left;
	margin: 10px 0px 0px 10px;
}
	.overviewHeader .header-item.minusRefresh  {
		width: ~"calc(33.333% - 70px)";
	}

.overviewHeader .header-item.filterItem.size-1-2, .overviewHeader .header-item.searchItem.size-1-2  {
	width: ~"calc(50% - 10px)";
}
.overviewHeader .header-item.searchItem.size-2-3  {
	width: ~"calc(66.666% - 10px)";
}
.overviewHeader .header-item.size-1-1  {
	position: relative;
	width: ~"calc(100% - 10px)" !important;
	display: inline-block;
	float: left;
	margin: 10px 0px 0px 10px;
}

.overviewHeader .header-item.size-1-1-minusSquare {
	width: ~"calc(100% - 10px - 50px - 10px)" !important;
}
.overviewHeader .header-item.size-square {
	display: block;
	width: 50px;
	line-height: 50px;
	margin-left: 10px;
	text-align: center;
	color: @accentColor;
}

a.overviewHeader-add {
	display: block;
	line-height: @header-height;
	background-color: @accentColor;
	color: @accentColor-text;
	.boxSizing;
	.boxShaddow-small-bright;
	padding: 0px;
	height: @header-height;
	overflow: hidden;
	padding-left: @header-height;

	border-radius: 20px;
	.dropShaddow-small;
}
	a.overviewHeader-add.inactive {
		opacity: 0.5;
	}
a.overviewHeader-add:hover {
	color: @primaryColor-text;
	background-color: @primaryColor;
}


a.overviewHeader-add span.bigIcon {
	text-align: center;
	width: @header-height;
	height: @header-height;
	overflow: hidden;
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	font-size: 24px;
	line-height: @header-height;
}

.overviewHeader-select {
	background: #fff !important;
	border: 1px solid @primaryColor !important;
	.boxShaddow-default;
	.boxSizing;
	height: @header-height;
	padding: 0px;
	width: 100%;
	outline: none;
	color: @primaryColor;
	border-radius: 0px !important;
}


input.overviewHeader-search {
	.boxShaddow-inside;
	background-color: #fff;
	border: none;
	.boxSizing;
	height: @header-height;
	overflow: hidden;
	padding: 0px 10px 0px 35px;
	width: 100%;
	outline: none;
	color: @accentColor;
	border-radius: 20px !important;
	border: 1px solid @accentColor;
}


a.overviewHeader-filter {
	display: block;
	line-height: @header-height;
	color: @accentColor;
	background-color: #fff;
	font-size: 13px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	height: @header-height;
	overflow: hidden;
	.boxSizing;
	padding-left: @header-height;
	padding-right: 35px;
	border-radius: 7px;
	border: 1px solid @accentColor;
}
a.overviewHeader-filter span.bigIcon {
	text-align: center;
	width: @header-height;
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	font-size: 24px;
	line-height: @header-height;
}
a.overviewHeader-filter i.filterKriterium {
	padding: 0px 3px;
	display: inline-block;
	font-size: 16px;
}
a.overviewHeader-filter .placeholder {
	color: @textColor-light;
	font-size: 14px;
	font-style: italic;
}


.overviewHeader .icon-search {
	position: absolute;
	left: 10px;
	top: 0px;
	line-height: @header-height;
	z-index: 2;
	color: @accentColor;
	font-size: 18px;
}
.overviewHeader .icon-deleteSearch {
	position: absolute;
	right: 20px;
	top: 0px;
	line-height: @header-height;
	z-index: 2;
	color: @accentColor;
	font-size: 20px;
	cursor: pointer;
	cursor: pointer;
}
.overviewHeader .icon-deleteSearch:hover {
	color: @primaryColor;
}

/* ######################################################## */
/* overviewMain */
/* ######################################################## */
.overviewMain {
	padding: 0px 10px;
}

.overviewMain .bigButton-delete a {
	display: block;
	background-color: @systemColor-error !important;
	color: #fff !important;
	margin-top: 10px;
	text-align: center;
	font-size: 20px;
	line-height: 50px;
	border-radius: 7px;
	.dropShaddow-small;
}
.overviewMain .bigButton-delete a .icon .fa-trash {
	color: #fff !important;
	font-size: 15px;
}
.overviewMain .bigButton-delete a:hover {
	background-color: @primaryColor !important;
}

/* ######################################################## */
/* TEST RESULTS */
/* ######################################################## */
.eventVisitorResult {
	.blankList;
	padding: 10px
}
.eventVisitorResult > li {
	background: @dividerColor;
	margin-bottom: 10px;
	border-radius: 10px;
	padding: 10px
}
.eventVisitorResult > li .result-name {
	color: @primaryColor;
	.font-special;
	font-size: 18px;
	line-height: 18px;
	padding-bottom: 10px;
	padding-top: 10px;
}
.eventVisitorResult > li .result {
	background-color: #fff;
	border-radius: 8px;
	margin-top: 10px;
	.boxShaddow-bigger-bright;
	padding: 10px;
	font-size: 13px;
}

/* ######################################################## */
/* progress-message */
/* ######################################################## */

.progress-message {

	border-radius: 10px;
	margin-top: 10px;
	color: @accentColor;
	border: 1px solid @accentColor;
	display: flex;
	align-items: center;
	.boxShaddow-big-bright;

	background: linear-gradient(92deg, @accentColor, @primaryColor);
	background: #fff;
	background-size: 400% 400%;

	-webkit-animation: AnimationName 4s ease infinite;
	-moz-animation: AnimationName 4s ease infinite;
	-o-animation: AnimationName 4s ease infinite;
	animation: AnimationName 4s ease infinite;

	@-webkit-keyframes AnimationName {
	    0%{background-position:0% 51%; border-color: @accentColor;}
	    50%{background-position:100% 50%; border-color: @primaryColor;}
	    100%{background-position:0% 51%; border-color: @accentColor;}
	}
	@-moz-keyframes AnimationName {
	    0%{background-position:0% 51%; border-color: @accentColor;}
	    50%{background-position:100% 50%; border-color: @primaryColor;}
	    100%{background-position:0% 51%; border-color: @accentColor;}
	}
	@-o-keyframes AnimationName {
	   0%{background-position:0% 51%; border-color: @accentColor;}
	   50%{background-position:100% 50%; border-color: @primaryColor;}
	   100%{background-position:0% 51%; border-color: @accentColor;}
	}
	@keyframes AnimationName {
	    0%{background-position:0% 51%; border-color: @accentColor;}
	    50%{background-position:100% 50%; border-color: @primaryColor;}
	    100%{background-position:0% 51%; border-color: @accentColor;}
	}
}

.progress-message .icon {
	border-right: 1px solid fade(@accentColor,15%);
	padding: 10px 10px;
}

.progress-message .message {
	font-size: 12px;
	line-height: 12px;
	padding: 10px 10px;
	display: block;
}


/* ######################################################## */
/* result-1-legend */
/* ######################################################## */


/* - - - */
.result-1-legend {
	font-size: 12px;
	color: @textColor-light;
	padding: 5px;
	background-color: #fff;
	border: 1px dashed @textColor-light;
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	margin-top: @margin;
	text-align: center;
	justify-content: center;
	position: relative;
	margin-bottom: 20px;
}
.result-1-legend a {
	color: @primaryColor;
}
.result-1-legend.showDialog {
	padding: 0px;
}
.result-1-legend.showDialog > a {
	display: block;
	text-align: center;
	text-transform: uppercase;
	color: @textColor-light;
}
.result-1-legend a.hideLegend {
	display: block;
	font-size: 15px;
	text-align: center;
	color: @textColor-light;
	.boxSizing;
	border-top: 1px solid darken(@dividerColor,5%);
	padding: 0px;
	line-height: 15px;
	margin: 5px;
	width: ~"calc(100% - 10px)";
	margin-bottom: -5px;
}

.result-1-legend div {
	margin: 5px;
}
.result-1-legend div.result {
	margin: 5px;
	width: ~"calc(100% - 10px)";
	border-top: 1px solid darken(@dividerColor,5%);
	padding-top: 5px;
	font-size: 14px;
	line-height: 14px;
}
.result-1-legend.tagLikeContent {
	border-radius: 0px;
	border-color: @dividerColor;
	background: transparent;
	padding: 0px;
	border: none;
}
	.result-1-legend .tagLike {
		display: inline-block;
		padding: 3px 7px;
		margin: 5px;
		background-color: @dividerColor;
		white-space: nowrap;
		border-radius: 7px;
		.boxShaddow-small-bright;
		cursor: pointer;
		background: #ffffff !important;
		border: 2px solid @dividerColor;
	}
	.result-1-legend .tagLike.active {
		.boxShaddow-big;
		background: #ffffff !important;
		border: 2px solid @systemColor-ok !important;
	}

.result-1-legend-highlight {
	margin-left: -10px;
	display: flex;
}
.result-1-legend-highlight .legendObject {
	margin-left: 10px;
	margin-top: 10px;
	background-color: @textColor-light;
	color: #fff;
	.boxShaddow-small-bright;
	position: relative;
}
.result-1-legend-highlight .legendObject .label {
	display: block;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	padding: 20px 5px 0px 5px;
	line-height: 12px !important;
}
.result-1-legend-highlight .legendObject .input {
	display: block;
	font-size: 30px;
	line-height: 30px !important;
	padding: 5px;
	line-height: 12px;
	padding-bottom: 5px;
}
.result-1-legend-highlight .legendObject a.legendObjectBigLink {
	color: @accentColor-text;
	display: block;
	text-align: center;
	font-size: 11px;
	line-height: 11px;
	text-transform: uppercase;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 10px;
}
.result-1-legend-highlight .legendObject a.legendObjectBigLink i.fa {
	display: block;
	font-size: 26px;
	line-height: 26px;
	padding-bottom: 4px;
}
	.result-1-legend-highlight .legendObject.size-1-2 {
		width: ~"calc(50% - 10px)";
	}
	.result-1-legend-highlight .legendObject.size-1-1 {
		width: ~"calc(100% - 10px)";
	}
	.result-1-legend-highlight .legendObject.size-1-3 {
		width: ~"calc(33.3333333% - 10px)";
	}

/* ######################################################## */
/* overviewOptions */
/* ######################################################## */
.overviewMain-options {
	display: flex;
	flex-wrap: wrap;
}
.overviewMain-options .option {
	display: block;
	padding: 5px 10px;
	font-size: 13px;
	background-color: @primaryColor;
	border-radius:  0px 3px 3px 0px;
	line-height: 13px;
	text-transform: uppercase;
	color: @primaryColor-text;
	font-weight: 400;
	.boxShaddow-small;
	margin-right: 10px;
}
	.overviewMain-options .option.delete {
		color: @systemColor-error;
	}
.overviewMain-options .option:hover {
	background-color: @systemColor-ok;
	color: @dividerColor;
}
	.overviewMain-options .option.delete:hover {
		background-color: @systemColor-error;
		color: @dividerColor;
	}

.overviewDetail-options {
	margin-left: -10px;
	margin-top: 10px;
	text-align: left;
}
.overviewDetail-options .option {
	display: inline-block;
	padding: 5px 10px;
	font-size: 12px;
	background-color: @dividerColor;
	border-radius:  0px 3px 3px 0px;
	line-height: 12px;
	text-transform: uppercase;
	color: @systemColor-ok;
	font-weight: 400;
}
.overviewDetail-options .option i {
	color: @systemColor-ok;
}
.overviewDetail-options .option:hover {
	background-color: @systemColor-ok;
	color: @dividerColor;
}
.overviewDetail-options .option:hover i {
	color: @dividerColor;
}

.optionsSmaller.overviewMain-options {

}
.optionsSmaller.overviewMain-options .option {
	background-color: #fff;
	border: 1px solid @primaryColor;
	color: @primaryColor;
	border-radius: 10px;
	font-size: 11px;
	.boxShaddow-none;
}
.optionsSmaller.overviewMain-options .option.delete {
	background-color: #fff;
	color: @systemColor-error;
	border-color: @systemColor-error;
}

/* ######################################################## */
/* results-default */
/* ######################################################## */
.loading-small {
	color: @primaryColor;
	padding: 40px 20px;
	text-align: center;
	font-size: 25px;
}
.results-default {
	.blankList;
}

.results-default > li {
	background-color: #fff;
	/* .boxShaddow-small; */
	border: 1px solid darken(@dividerColor,5%);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	z-index: 0;
	margin-top: 10px;
	border-radius: 80px 20px 20px 80px !important;
	cursor: pointer;
	padding: 5px 10px;
	padding-right: 50px;
	color: @textColor-light;
	outline: none;
	background: lighten(@dividerColor,5%);

	@media (max-width: 750px) {
		border-radius: 10px !important;
	}
}
	.results-default > li.preset {
		border: 1px dashed darken(@dividerColor,5%);
	}

	.results-default > li.inactive-element .mainInfo .title {
		text-decoration: line-through;
	}

	.results-default > li.has-image {
		padding-left: 5px;
	}
.results-default > li:nth-child(even) {
	background-color: @dividerColor;

}

.results-default > li:hover {
	border-color: @primaryColor;
}

	.results-default.oversizeImages > li.has-image {
		min-height: 72px;
		.boxSizing;
		padding-left: 75px;


		@media (max-width: 750px) {
			min-height: 52px;
			padding-left: 55px;
			border-radius: 22px 10px 10px 10px !important;
		}
	}



	/* MultiSelect */

	.results-default.multiSelect > li  {
		.boxSizing;
		margin-left: 25px;
	}
	.results-default.multiSelect > li.selected {
		border: 1px solid @accentColor;
		.boxShaddow-big-bright;
	}
		.results-default.multiSelect > li.selected .title {
			colo	r: @accentColor !important;
		}


	.multiSelect .selectArea {
		display: block !important;
		width: 25px;
		padding-left: 0px;
		.boxSizing;
		outline: none;
		position: absolute;
		left: -25px;

	}

	.multiSelect li:hover .selectArea, .multiSelect li.selected .selectArea {
		display: block !important;
	}

	.multiSelect .selectArea a {
		display: block;

		/*@media (max-width: 400px) {
			text-align: center;
		} */
	}
	.multiSelect .selectArea .checked {
		color: @accentColor;
	}
		.multiSelect .selected .selectArea .checked {
			display: block;
		}
	.multiSelect .selectArea .not-checked {
		color: @textColor-light;
	}
		.multiSelect .selected .selectArea .not-checked {
			display: none;
		}


	/* // MultiSelect */


.results-default > li .image {
	margin: 0px;
	margin-right: 10px;
	width: 40px !important;
	height: 40px !important;
	overflow: hidden;
	.boxSizing;
}
.results-default > li .image img {
	width: 40px;
	height: 40px;
	border: 2px solid #fff;
	.boxSizing;
	border-radius: 100%;
}
	.results-default.bigImages > li .image {
		width: 80px !important;
		height: 80px !important;
	}
	.results-default.bigImages > li .image img {
		width: 80px;
		height: 80px;
	}


.results-default.oversizeImages > li .image {
	width: 60px !important;
	height: 60px !important;
	//margin: -7px;
	background: #fff;
	.dropShaddow-small;
	border-radius: 100%;
	position: absolute;
	left: 5px;
	top: 5px;

	@media (max-width: 750px) {
		width: 40px !important;
		height: 40px !important;
		margin: 0px;
		margin-right: 10px;
	}
}

	.results-default.oversizeImages > li .image.isBlank {
		border: 2px dashed darken(@dividerColor, 15%);
		.boxSizing;
		.boxShaddow-none;
		background: transparent;
	}


.results-default.oversizeImages > li .image img {
	width: 60px;
	height: 60px;
	border: none;
	.boxSizing;

	@media (max-width: 750px) {
		width: 40px !important;
		height: 40px !important;
	}
}


.results-default > li .mainInfo {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	.boxSizing;
	text-overflow: ellisis;
	min-height: 40px;
	flex-grow: 2;

	@media (max-width: 750px) {
		/* align-items: flex-start; */
	}

}


.results-default > li .item-badges {
	display: flex;
	position: absolute;
	left: -6px;
	top: -6px;
	flex-wrap: wrap;
	width: 16px;

}
.results-default > li .item-badges span {
	color: @primaryColor-dark;
	display: block;
	background-color: #fff;
	border-radius: 100%;
	font-size: 9px;
	line-height: 16px !important;
	width: 16px;
	height: 16px;
	overflow: hidden;
	text-align: center;
	overflow: hidden;
	margin-bottom: 3px;
	.dropShaddow-small;
}
.results-default > li .item-badges .isVIP {
}
.results-default > li .item-badges .hasNotification {
	font-size: 10px;
}

.results-default > li .mainInfo .title {
	color: @primaryColor;
	font-size: 16px;
	line-height: 16px;
	display: block;
	padding-right: 20px;
	flex-grow: 2;
	text-overflow: ellipsis;
	max-width: ~"calc(100% - 50px)";
	.boxSizing;

	@media (max-width: 375px) {
		font-size: 16px;
		line-height: 16px;
		padding-right: 10px;
	}
}

.results-default > li:nth-child(even) .mainInfo .title {
	color: @primaryColor-dark;
}

/* ####### INFO EMPTY ####### */
.empty-list-info {
	border: 2px dashed @dividerColor;
	margin: 60px 10px;
	padding: 20px;
	color: @textColor-light;
	.font-special;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	border-radius: 10px;
}

.empty-list-info h3 {
	padding: 0px 0px 20px 0px;
	color: @textColor-light;
	.font-special;
	font-size: 28px;
	line-height: 30px;
	text-align: center;
}

.empty-list-info p {
	color: @textColor-light !important;
	padding: 0px;
	margin: 0px;
	.font-special;
	font-size: 18px !important;
	line-height: 26px !important;
	text-align: center;
}

/* ####### // INFO EMPTY ####### */

.results-default > li .mainInfo .title .emailInsteadOfName {

	@media (max-width: 375px) {
		display: block;
		max-width: 135px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.results-default > li .mainInfo .title .company {
	display: inline-block;
	padding-left: 10px;

	@media (max-width: 375px) {
		display: block;
		width: 100%;
		.boxSizing;
		padding-left: 0px;
		margin-top: 5px;
	}
}
	.results-default.bigImages > li .mainInfo .title .company {
		display: block;
		padding-left: 0px;
		padding-top: 5px;
	}

.results-default > li > .status {
	padding-right: 10px;
	@media (max-width: 750px) {
		position: absolute;
		left: 10px;
		top: 15px;
		line-height: 18px;
		z-index: 1;
		padding-right: 0px;
	}
}

.results-default > li .mainInfo .title .status {
	display: inline-block;
	padding-right: 5px;

	@media (max-width: 375px) {
		padding-right: 5px;
	}
}

	.results-default > li > .singleStatus {
		display: block;
		padding-right: 0px;
		width: 24px !important;
		height: 24px !important;
		border-radius: 100%;

		background: #fff;
		text-align: center;
		line-height: 24px !important;
		background-color: #fff;
		font-size: 12px !important;


		position: absolute;
		left: 23px !important;
		top: 23px !important;
		.boxShaddow-small;

		@media (max-width: 750px) {
			width: 20px !important;
			height: 20px !important;
			border-radius: 100% !important;
			line-height: 20px !important;
			font-size: 12px !important;
			left: 30px !important;
			top: 30px !important;
		}

	}

	.results-default > li .status.singleStatus {
		display: inline-block;
		margin-right: 5px;
		padding: 0px !important;
		width: 30px;
		height: 30px;
		border-radius: 100%;

		background: #fff;
		text-align: center;
		line-height: 30px;
		background-color: #fff;
		font-size: 16px;

		.dropShaddow-small;
	}


.results-default > li .mainInfo .subTitle {
	font-size: 14px;
	line-height: 14px;
	display: block;
	padding-right: 10px;

	filter: grayscale(100%) !important;
	color: @textColor-light;

	@media (max-width: 375px) {
		font-size: 12px;
		line-height: 12px;
		margin-right: 0px;
	}
}
.results-default > li a {
	color: @primaryColor;
}
.results-default > li .mainInfo .subTitle .specialInfoStatistic {
	white-space: nowrap;
	padding: 5px 10px;
	background: #fff;
	border-radius: 20px;
	font-size: 13px;
	line-height: 13px;
	.dropShaddow-small;
}

.results-default > li .subInfo {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	@media (max-width: 750px) {
		margin-top: 10px;
		border-top: 1px solid darken(@dividerColor,5%);
		width: 100%;
		padding-top: 10px;
	}
}
	.results-default.oversizeImages > li .subInfo {
		@media (max-width: 750px) {

		}
	}

	.results-default.oversizeImages > li.has-image .subInfo {
		@media (max-width: 750px) {
			margin-left: -50px;
			width: ~"calc(100% + 50px)";
		}
	}

	.results-default.bigImages > li .subInfo {
		display: none;
	}

.results-default > li .subInfo .subInfo-item {
	margin-right: 20px;
	font-size: 12px;
	line-height: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin: 5px 10px 5px 0px;

	padding: 5px 10px;
	background: #fff;
	border-radius: 20px;
	.dropShaddow-small;
	text-transform: uppercase;
	color: @textColor;

	@media (max-width: 750px) {
		margin: 2px 5px 3px 0px;
	}
}
.grayscale {
	filter: grayscale(100%) !important;
}

.results-default > li .subInfo .subInfo-item md-switch {
	height: auto !important;
	line-height: auto !important;
}
.results-default > li .subInfo .subInfo-item > small {
	font-size: 12px;
	line-height: 12px;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge {
	color: @systemColor-warning;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge.neutral {
	color: @textColor-light;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge.isok {
	color: @systemColor-ok;
}
/*
.results-default > li .subInfo .subInfo-item > small {
	display: inline-block;
	padding: 2px 5px;
	border-radius: 3px;
	background-color: fade(#000,25%);
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
	font-size: 400;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge {
	display: inline-block;
	padding: 2px 5px;
	border-radius: 3px;
	background-color: @systemColor-error;
	color: #fff;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
	font-size: 400;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge.neutral {
	color: @textColor-light;
}
.results-default > li .subInfo .subInfo-item .subInfoBadge.isok {
	color: @systemColor-ok;
}
*/

.results-default > li .options {
	position: absolute;
	right: 0px;
	width: 50px;
	top: 0px;
	bottom: 0px;
	z-index: 1;
	font-size: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid darken(@dividerColor,5%);
	border-radius: 0px 10px 10px 0px;
	color: @primaryColor;
	outline: none;
}
.results-default > li:hover .options {
	border-left: 1px solid darken(@dividerColor,10%);
}

/* options */
.results-default > li .options .option-details {
	position: absolute;
	width: 225px;
	left: -225px;
	top: -5px;
	z-index: 5;
	background-color: #fff;
	.boxShaddow-big;
	.boxSizing;
	padding: 0px 10px;
	display: none;
	border-radius: 10px;
}
	.results-default > li:hover {
		z-index: 4;
	}
	.results-default > li .options.showOptions .option-details {
		display: block;
	}
.results-default > li .options .option-details ul {
	.blankList;
	width: 100%;
}
.results-default > li .options .option-details ul li {
	border-bottom: 2px solid @dividerColor;
	padding: 10px 0px;
	position: relative;
	text-align: left	;
	font-size: 13px;
	line-height: 13px;
}
	.results-default > li .options .option-details ul li.seperator {
		border-bottom: 1px solid @textColor-light;
		border-top: 1px solid @textColor-light;
		padding: 5px 10px;
		margin: 0px -10px;
		margin-top: -2px;
		z-index: 1;
		position: relative;
		text-align: left	;
		font-size: 10px;
		line-height: 10px;
		background-color: @dividerColor;
		color: @textColor;
		text-transform: uppercase;
		text-align: left;
	}
	.results-default > li .options .option-details ul li.seperator.massEdit {
		color: @accentColor-text;
		border-bottom-color: @accentColor;
		border-top-color: @accentColor;
		background: @accentColor;
	}

.results-default > li .options .option-details ul li a {
	display: block;
	color: @primaryColor !important;
}
	.results-default > li .options .option-details ul li a.massEdit {
		color: @accentColor !important;
	}

.results-default > li .options .option-details ul li.delete, .results-default > li .options .option-details ul li.delete a, .results-default > li .options .option-details ul li.delete a span {
	color: @systemColor-error !important;
}
.results-default > li .options .option-details ul li.positive, .results-default > li .options .option-details ul li.positive a {
	color: @systemColor-ok !important;
}

.results-default > li .options .option-details ul li a:hover {
	display: block;
	color: @primaryColor-dark !important;
}

.results-default > li .options .option-details ul li .icon {
	display: inline-block;
	width: 20px;
}
.results-default > li .options .option-details ul li:last-child {
	border-bottom: none;
}



	/* Sonderstatus: noShaddow // OFFLINE
	.results-default.noShaddow > li {
		background-color: @dividerColor;
		.boxShaddow-none;
	}
	.results-default.noShaddow > li:hover {
		background-color: darken(@dividerColor,3%)!important;
	}
	.results-default.noShaddow > li:nth-child(even) {
		background-color: darken(@dividerColor,5%)!important;
		.boxShaddow-none;
	}
	.results-default.noShaddow > li .options {
		background-color: transparent;
		border-left: 1px solid fade(@textColor, 10%);
	}
	.results-default.noShaddow > li .subInfo {
		border-color: fade(@textColor, 10%);
	}
	*/

	/* Sonderstatus: deleted */
	.results-default > li.deleted {
		opacity: 0.5;
		.transition-default;
		color: @systemColor-error;
	}
	.results-default > li.deleted:hover {
		opacity: 1;
		.transition-default;
	}
	.results-default > li.deleted .mainInfo .title {
		color: @systemColor-delete;
		text-decoration: line-through;
	}

	/* Sonderstatus: invitationPending */
	.results-default > li.invitationPending, .results-default.noShaddow > li.invitationPending {
		border-color: @systemColor-warning !important;
	}
	.results-default > li.invitationPending .title, .results-default.noShaddow > li.invitationPending .title, .results-default > li.invitationPending .subInfo-item, .results-default > li.invitationPending .options {
		color: @systemColor-warning !important;
	}

	/* Sonderstatus: noPremiumAccount / mailBounced */
	.results-default > li.noPremiumAccount, .results-default > li.mailBounced, .noShaddow > li.mailBounced:nth-child(even), .results-default > li.mailBounced, .results-default.noShaddow > li.mailBounced {
		border-color: @systemColor-error !important;
	}
	.results-default > li.mailBounced .title, .results-default.noShaddow > li.mailBounced .title, .results-default > li.mailBounced .subInfo-item, .results-default > li.mailBounced .options {
		color: @systemColor-error !important;
	}


/* ### RESULTS list-status-guests ### */
.list-status-guests {
	background-color: #fff;
	.dropShaddow-small;
	border-radius: 44px;
	padding: 2px 0px 2px 3px;
	display: flex;
	align-content: center;
	align-items: center;
}
.list-status-guests .listStatus-count {
	font-weight: bold;
	font-size: 14px;
	display: block;
	margin-left: 3px;
	margin-right: 5px;
}

/* ######################################################## */
/* responsive Content */
/* ######################################################## */
@media (max-width: 750px) {

	a.overviewHeader-add {
		width: @header-height;
		.boxSizing;
	}
	a.overviewHeader-filter {
		width: @header-height;
		.boxSizing;
		padding-right: 0px;
	}
		.overviewHeader .header-item.size-1-1.filterItem a.overviewHeader-filter  {
			width: 100%;
			padding-right: 10px;
		}
	.filterItem .icon-deleteSearch {
		display: none;
	}
		.overviewHeader .header-item.size-1-1.filterItem .icon-deleteSearch {
			display: block;
		}

	.overviewHeader .header-item.addItem, .overviewHeader .header-item.filterItem, .overviewHeader .header-item.filterItem.size-1-2  {
		width: @header-height !important;
		float: left;
	}

	.overviewHeader .header-item.searchItem  {
		width: ~"calc(100% - 130px)" !important;
		float: left;

    .clear-button {
      position: absolute;
      right: 10px;
      line-height: 20px;
      top: 50%;
      margin-top: -10px;
      color: @primaryColor;
    }
	}
		.overviewHeader .header-item.searchItem.minusRefreshMobile  {
			width: ~"calc(100% - 190px)" !important;
			float: left;
		}
	.overviewHeader .header-item.searchItem.size-1-2  {
		width: ~"calc(100% - 75px)" !important;
		float: left;
	}
	.overviewHeader .header-item.searchItem.size-2-3  {
		width: ~"calc(100% - 70px)" !important;
		float: right;
	}
	.overviewHeader .header-item.searchItem.size-2-3.withRefresh  {
		width: ~"calc(100% - 130px)" !important;
		float: left;
	}


	.overviewHeader .header-item.size-1-1, .overviewHeader .header-item.size-1-1.filterItem  {
		width: ~"calc(100% - 10px)" !important;
		margin: 10px 0px 0px 10px;
	}

	.overviewHeader .header-item.size-1-1-minusSquare {
		width: ~"calc(100% - 10px - 50px - 10px)" !important;
	}
	.size-1-1-minusSquare a.overviewHeader-filter {
		width: 100% !important;
	}
	.overviewHeader .header-item.size-square {
		width: 50px;
	}

}

@media (max-width: 650px) {
	.overviewFooter .overviewFooter-item {
		margin: 10px;
	}

	.result-1-legend.tagLikeContent {
		margin-left: 0px;
		margin-right: 0px;
	}
		.result-1-legend .tagLike {
			padding: 3px 5px;
			margin: 2px;
		}


}

@media (max-width: 420px) {


}

@media (max-width: 375px) {

}
@media (max-width: 320px) {

}


/* ######################################################## */
/* Paging */
/* ######################################################## */

.paging-container {
	/* background-color: fade(@primaryColor,75%);
	backdrop-filter: blur(7px);
	.boxShaddow-small; */
	background: @dividerColor;
	margin-top: 20px;
	display: flex;
	width: 100%;
	.boxSizing;
	border-radius: 10px;
	@media (max-width: 750px) {
		flex-wrap: wrap;
		border-radius: 10px;
	}

	display: none;
}

/* pages */
.paging-container {
  .mat-form-field-underline {
    display: none !important;
  }
  .mat-form-field-wrapper {
    padding: 0px;
  }
  mat-form-field {
    background: @dividerColor !important;
  }
  mat-form-field {
    width: 100% !important;
  }
  .boxShaddow-big-bright-bottomFocused;
  border-radius: 24px;
  overflow: hidden;
}

.paging-container .pages {
	display: flex;
	.blankList;
	justify-content:flex-start;
	align-items: stretch;

	@media (max-width: 750px) {
		width: 100%;
		border-bottom: 1px solid fade(#000,15%);
	}
}

.paging-container .pages > li {
	border-right: 1px solid fade(#000,15%);
}
.paging-container .pages > li:last-child {
  border-right: none;
  @media (max-width: 750px) {
    border-right: none;
	}
}

.paging-container .pages > li.choose-page {
	.boxSizing;
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: center;
  color: @textColor-light;

	@media (max-width: 750px) {
		width: ~"calc(100% - 100px)";
	}

  & > span {
    padding: 0px 15px;
    border-left: 1px solid fade(#000,15%);
    display: flex;
    align-items: center;
    white-space: nowrap;
  }


}
.paging-container .pages .prev-next {
  display: flex;
  justify-content:center;
  align-items: center;
  align-content: center;
}
.paging-container .pages .prev-next a {
	display: block;
	width: 50px;
	line-height: 50px;
	padding: 0px;
	margin: 0px;
	text-align: center;
	font-size: 20px;
	color: @accentColor;
  cursor: pointer;
}


/* options */
.paging-container .options {
	display: flex;
	.blankList;
	flex-grow: 2;
	justify-content:flex-end;
	align-items: stretch;
  align-content: stretch;

	@media (max-width: 450px) {
		flex-wrap: wrap;
	}
}
.paging-container .options > li {
	border-left: 1px solid fade(#000,15%);
  flex-grow: 1;
	min-height: 50px;

	@media (max-width: 450px) {
		min-width: 50%;
		.boxSizing;
	}


}
.paging-container .options .option {

	@media (max-width: 450px) {
		width:  100%;
		order: 2;
		border-right: none;
		border-top: 1px solid fade(#000,15%);
	}
}
.paging-container .options .option a {
	display: block;
	line-height: 50px;
	padding: 0px 10px;
	margin: 0px;
	text-align: center;
	font-size: 13px;
	color: @primaryColor-text;
	white-space: nowrap;
}
.paging-container .options .noIcon .icon {
	display: none;
}

/* md-select */
.select {
	display: flex;
	justify-content:flex-start;
	align-items: stretch;

}
.paging-container md-select {
	margin: 0px 10px;
	width: 100%;
	color: @primaryColor-text !important;
}
.paging-container .md-select-value > span:not(.md-select-icon) {
	font-size: 13px;
	color: @primaryColor-text;
}
.paging-container .md-select-value > span.md-select-icon {
	color: fade(@primaryColor-text,75%);
}

/* ######################################################## */
/* quick-buttons */
/* ######################################################## */
.quick-buttons {
	position: sticky;
	top: 100px;
	background-color: #fff;
	padding: 10px 0px;
	margin: 0px -5px;
	width: ~"calc(100% + 10px)";
	.boxSizing;
	display: none;
	z-index: 5;
	border-bottom: 1px solid	 @dividerColor;

	@media (max-width: 750px) {
		display: flex;
	}
}

.quick-buttons a {
	display: block;
	padding: 10px 5px;
	background-color: @accentColor;
	color: @accentColor-text;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	margin: 5px;
	width: ~"calc(50% - 10px)";
	border-radius: 10px;
}
.quick-buttons a:hover {
	color: @accentColor-text;
}
.quick-buttons.disabled a {
	background-color: darken(@dividerColor,15%);
	color: @accentColor-text;
	pointer-events: none;
}


/* ######################################################## */
/* overviewOptions */
/* ######################################################## */
.overviewMain-multiOptions {
	display: flex;
	background-color: @primaryColor;
	border-radius: 0px;
	.boxShaddow-small-bright;
	padding: 0px;
	margin-top: 10px;
	border-radius: 10px;

	@media (max-width: 750px) {
		border-radius: 10px;
	}

}
.overviewMain-multiOptions .selectOptions {
	width: ~"calc(100% - 51px)";
}
.overviewMain-multiOptions .selectOptions a {
	display: inline-block;
	line-height: 30px;
	margin-left: 11px;
	position: relative;
	text-transform: uppercase;
	font-size: 12px;
	color: @primaryColor-text;
}
.overviewMain-multiOptions .selectActions {
	width: 51px;
	text-align: right;
	position: relative;
	outline: none;
}
.overviewMain-multiOptions .selectActions .hoverLink {
	display: block;
	color: @primaryColor-text;
	line-height: 30px;
	padding: 0px;
	font-size: 12px;
	text-transform: uppercase;
	width: 51px;
	.boxSizing;
	border-left: 1px solid fade(#fff,15%);
	text-align: center;
	position: relative;
}
.overviewMain-multiOptions .selectActions .hoverLink .icon {
	font-size: 15px;
	display: block;
	text-align: center;
}
.overviewMain-multiOptions .selectActions .hoverLink .text {
	display: block;
	line-height: 30px;
	width: 110px;
	position: absolute;
	left: -120px;
	text-align: right;
	z-index: 1;
}
.overviewMain-multiOptions .selectActions .hoverLink .text strong{
	font-size: 16px;
}


.overviewMain-multiOptions .action-list {
	display: none;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list {
	display: block;
	position: absolute;
	z-index: 11;
	background-color: #fff;
	top: 31px;
	right: -1px;
	width: 280px;
	border-radius: 10px;
	overflow: hidden;
	.boxShaddow-big-bright;

}
.overviewMain-multiOptions .selectActions.showOptions .action-list ul {
	.blankList;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list li {
	border-bottom: 1px dashed @dividerColor;
	text-align: left;
}
	.overviewMain-multiOptions .selectActions.showOptions .action-list li.seperator {
		background-color: @primaryColor;
		color: @primaryColor-text;
		padding: 2px 10px;
		font-size: 10px;
		text-transform: uppercase;
		border: none;
	}
	.overviewMain-multiOptions .selectActions.showOptions .action-list li.seperator.massAction {
		background-color: @accentColor;
		color: @accentColor-text;
	}



.overviewMain-multiOptions .selectActions.showOptions .action-list li:last-child {
	border-bottom: none;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list li a {
	display: block;
	line-height: 14px;
	font-size: 14px;
	padding: 15px 10px;
	color: @primaryColor;
}
	.overviewMain-multiOptions .selectActions.showOptions .action-list li a.massAction {
	color: @accentColor;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list li a .icon {
	display: inline-block;
	width: 20px;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list li a.delete {
	color: @systemColor-error;
}
.overviewMain-multiOptions .selectActions.showOptions .action-list li a:hover {
	background-color: @dividerColor;
}

.overviewMain-multiOptions .selectActions .textmessage {
	text-align: left;
	font-size: 12px;
	line-height: 16px;
	color: @textColor;
	padding: 15px;
}
.overviewMain-multiOptions .selectActions .textmessage .icon-textmessage {
	text-align: center;
	font-size: 36px;
	line-height: 36px;
	display: block;
	color: @accentColor;
	padding-bottom: 10px;
	border-bottom: 1px solid @dividerColor;
	margin-bottom: 10px;
}
.overviewMain-multiOptions .selectActions .textmessage a {
	display: inline !important;
	font-size: 12px !important;
	line-height: 16px !important;
	width: auto !important;
	height: auto !important;
	padding: 0px !important;
}




html.noBGimportant,
html.noBGimportant body {
	background-image: none !important;
}


.legende {
  color: @textColor-light;
}

/* ######### */
/* container-box-style-v3 */
/* ######### */
.categoryExtended .container-box-style-v3 {
  padding-left: 280px;

  @media (max-width: 1024px) {
    padding-left: 180px;
  }
  @media (max-width: 550px) {
    padding-left: 10px;
  }
}
.container-box-style-v3 {
  padding: 10px;

  .item {
    border: 1px solid darken(@dividerColor, 15%);
    border-radius: 24px;
    padding: 10px;
    padding-bottom: 5px;
    position: relative;
    min-height: 62px;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding-left: 65px;
    padding-right: 60px;
    display: flex;
    align-items: center;

    &.inactiveItem {
      border-color: @systemColor-warning;
      font-style: italic;
      opacity: 0.5;
    }
    &.deletedItem {
      border-color: @systemColor-error;
      font-style: italic;
      text-decoration: line-through;
      opacity: 0.5;
    }
    &.statuslessItem {
      border-color: @systemColor-error;
      font-style: italic;
      text-decoration: line-through;
      opacity: 0.5;
    }

    &.noIcon {
      padding-left: 20px;
    }

    &:nth-child(even) {
      background: @dividerColor;
    }
    &:hover {
      background: darken(@dividerColor,5%);
    }

    & > .icon {
      position: absolute;
      width: 50px;
      height: 50px;
      background: @textColor-light;
      top: 5px;
      left: 5px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      text-align: center;
      color: #fff;

      &.positive {
        background: @systemColor-error;
      }
      &.negative {
        background: @systemColor-ok;
      }
      &.unknown {
        background: @systemColor-warning;
      }

      .graphic {
        display: block;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        width: 100%;
      }

      .description {
        display: block;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        width: 100%;

        font-weight: bold;

        span {
          display: block;
          padding-top: 5px;
          text-align: center;
        }
      }
    }

    .options {
      position: absolute;

      top: 5px;
      bottom: 5px;
      right: 0px;
      border-left: 1px solid rgba(0,0,0,0.1);

      line-height: 50px;
      text-align: center;

      a {
        display: block;
        color: @accentColor;
        width: 40px;
        text-align: center;
        margin-right: 10px;
        font-size: 26px;
      }
    }
    .preview {
      margin-left: -10px;
      margin-right: 20px;
      align-self: flex-start;

      @media (max-width: 550px) {
        margin-right: 10px;
      }

      img {
        width: 80px;
        border-radius: 20px;
        height: auto;
        .boxShaddow-big-bright-bottomFocused;

        @media (max-width: 550px) {
          width: 60px;
        }
      }
    }
    .options-selection {
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: 0px;
      border-left: 1px solid rgba(0,0,0,0.1);

      line-height: 50px;
      text-align: center;
      outline: none;
      display: flex;

      @media (max-width: 550px) {
        line-height: 40px;
      }

      .options-icon {
        display: block;
        color: @accentColor;
        width: 40px;
        text-align: center;
        margin-right: 10px;
        font-size: 26px;
        cursor: pointer;

        @media (max-width: 550px) {
          width: 40px;
          margin-right: 5px;
        }
      }
    }

    /* options */
    .options-selection .option-details {
      position: absolute;
      width: 225px;
      left: -225px;
      top: -5px;
      z-index: 5;
      background-color: #fff;
      .boxShaddow-big;
      .boxSizing;
      padding: 0px 10px;
      display: none;
      border-radius: 10px;
    }
    &:hover {
      z-index: 4;
    }
    .options-selection.showOptions .option-details {
      display: block;
    }
    .options-selection .option-details ul {
      .blankList;
      width: 100%;
    }
    .options-selection .option-details ul li {
      border-bottom: 2px solid @dividerColor;
      padding: 10px 0px;
      position: relative;
      text-align: left	;
      font-size: 13px;
      line-height: 13px;
    }
    .options-selection .option-details ul li.seperator {
      border-bottom: 1px solid @textColor-light;
      border-top: 1px solid @textColor-light;
      padding: 5px 10px;
      margin: 0px -10px;
      margin-top: -2px;
      z-index: 1;
      position: relative;
      text-align: left	;
      font-size: 10px;
      line-height: 10px;
      background-color: @dividerColor;
      color: @textColor;
      text-transform: uppercase;
      text-align: left;
    }
    .options-selection .option-details ul li.seperator.massEdit {
      color: @accentColor-text;
      border-bottom-color: @accentColor;
      border-top-color: @accentColor;
      background: @accentColor;
    }

    .options-selection .option-details ul li a {
      display: block;
      color: @primaryColor !important;
      cursor: pointer;
    }
    .options-selection .option-details ul li a.massEdit {
      color: @accentColor !important;
    }

    .options-selection .option-details ul li.delete,
    .options-selection .option-details ul li.delete a,
    .options-selection .option-details ul li.delete a span {
      color: @systemColor-error !important;
    }
    .options-selection .option-details ul li.positive,
    .options-selection .option-details ul li.positive a {
      color: @systemColor-ok !important;
    }

    .options-selection .option-details ul li a:hover {
      display: block;
      color: @primaryColor-dark !important;
    }

    .options-selection .option-details ul li .icon {
      display: inline-block;
      width: 20px;
    }
    .options-selection .option-details ul li:last-child {
      border-bottom: none;
    }









    .details {

      .primary {
        display: block;
        font-size: 16px;
        line-height: 16px;
        strong {
          color: @primaryColor;
          font-weight: normal;
          .font-special;
        }
      }

      .secondary {
        display: block;
        margin-left: -5px;
        .subItem {
          display: inline-block;
          border-radius: 10px;
          background: #fff;
          border: 1px solid @dividerColor;
          padding: 3px 10px;
          font-size: 12px;
          line-height: 12px;
          margin: 5px 5px 0px 0px;
          color: @textColor;

          @media (max-width: 550px) {
            font-size: 10px;
          }
        }
      }

      .special {
        position: relative;
        background: #fff;
        border-radius: 10px;
        background: #fff;
        border: 1px solid @dividerColor;
        padding: 10px;
        margin-top: 10px;
        margin-left: -5px;
        margin-bottom: 5px;
        color: @textColor;
        font-size: 14px;
        line-height: 16px;
        word-break: break-word;

        &.error {
          border-color: @systemColor-error;
          color: @textColor !important;

          strong {
            color: @systemColor-error;
          }
        }

        &.done {
          border-color: @systemColor-ok;
        }

        strong {
          font-weight: bold;
          display: block;
          font-size: 14px;
          line-height: 14px;
          padding-bottom: 10px;
        }
      }

    }

  }


}


/* ######### */
/* filterBar-v01 */
/* ######### */

.filterBar-v01 {
  position: sticky;
  top: 80px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin-top: -10px;
  z-index: 9;
  font-size: 18px;

  @media (max-width: 1024px) {
    top: 50px;
    font-size: 14px;
  }

  .filter-multiple {
    margin: 5px;
    background: @dividerColor;
    border-radius: 44px;
    padding: 5px;

    @media (max-width: 400px) {
      margin: 5px 2px;
    }

    .filter-status {
      color: @primaryColor;
      border: 1px solid transparent;
      border-radius: 40px;
      padding: 5px 20px;
      cursor: pointer;
      outline: none;
      .boxSizing;

      @media (max-width: 1024px) {
        padding: 5px 9px;
      }

      background: @primaryColor;
      color: @primaryColor-text;
      .dropShaddow-small;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;


      @media (max-width: 400px) {
        max-width: 75px;
      }

      @media (max-width: 320px) {
        max-width: 60px;
      }
    }
    &.categoryExtended .allOptions {
      display: block;
    }
    .allOptions {
      display: none;



      position: fixed;
      left: 0px;
      width: 260px;
      top: 180px;
      bottom: 20px;
      .boxShaddow-big-bright-bottomFocused;
      background: #dfeaec;
      z-index: 99;
      border-radius: 0px 24px 24px 0px;
      .boxSizing;

      overflow-x: hidden;
      overflow-y: scroll; /* has to be scroll, not auto */
      -webkit-overflow-scrolling: touch;
      /* scrollbars*/
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -ms-overflow-style: none;

      padding: 20px 0px;

      @media (max-width: 1024px) {
        bottom: 70px;
        top: 210px;
        width: 160px;
      }

      @media (max-width: 550px) {
        top: 185px;
        width: 220px;
      }

      ul {
        .blankList;

        li {

          border-bottom: 1px solid #fff;
          color: #646F79;
          font-size: 13px;
          cursor: pointer;
          outline: none;

          a {
            display: block;
            padding: 15px 20px;
            color: @primaryColor;
            .boxSizing;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (max-width: 1024px) {
              padding: 5px 9px;
            }
          }
          &.filter-reset a {
            color: @textColor-light;
          }
          a.categorySelected {
            color: @accentColor;
          }

          a .icon {display: none;}

        }

      }

    }


  }

  .filter {
    margin: 5px;
    background: @dividerColor;
    border-radius: 44px;
    padding: 5px;

    @media (max-width: 400px) {
      margin: 5px 2px;
    }


    ul {
      .blankList;
      display: flex;

      li {
        color: @primaryColor;
        border: 1px solid transparent;
        border-radius: 40px;
        padding: 5px 20px;
        cursor: pointer;
        outline: none;
        @media (max-width: 1024px) {
          padding: 5px 9px;
        }

        &.active {
          background: @primaryColor;
          color: @primaryColor-text;
          .dropShaddow-small;
        }
      }

    }
  }

  .filter-icon {
    cursor: pointer;
    a {
      display: block;
      width: 50px;
      line-height: 50px;
      text-align: center;
      color: @primaryColor;
      margin: 5px;
      cursor: pointer;

      @media (max-width: 1024px) {
        width: 30px;
      }

      @media (max-width: 400px) {
        width: 20px;
      }
    }
  }

}





/* ######### */
/* DRAG and DROP */
/* ######### */
.dragAndDrop {
  background: @dividerColor;
  border: 1px dashed @textColor-light;
  border-radius: 24px;
  margin-top: 20px;
}
.o-upload a.download-item {
  display: block;
  background: @primaryColor;
  border-radius: 24px;
  margin-bottom: 10px;
  padding: 10px 40px 10px 20px;
  color: @primaryColor-text;
  .boxShaddow-big-bright-bottomFocused-small;
  margin-top: 20px;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}
.o-upload a.download-item .download-icon {
  position: absolute;
  background: @primaryColor-text;
  color: @primaryColor;
  right: 8px;
  line-height: 20px;
  width: 20px;
  text-align: center;
  display: block;
  border-radius: 100%;
  top: 50%;
  margin-top: -10px;
}



/* ######################################################## */
/* imageGallery-v01 */
/* ######################################################## */

.imageGallery-v01 {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;

  .galleryItem {
    width: 33.33%;
    max-width: 100%;
    position: relative;

    .options {
      position: absolute;
      top: 10px;
      right: 10px;

      a {
        display: block;
        background: #fff;
        border-radius: 100%;
        width: 40px;
        line-height: 40px;
        height: 40px;
        overflow: hidden;
        text-align: center;
        cursor: pointer;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }

    .preview {

      img {
        width: 100%;
        height: auto;
        .boxShaddow-big-bright-bottomFocused;
        border-radius: 20px;
      }

    }


  }


}
