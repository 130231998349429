/* The Magnificent Clearfix: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* Patti */
input[type="date"], input[type="datetime-local"] {
  	._hideDateFieldOptions;

    &:hover {
    	._hideDateFieldOptions;
    }
}

._hideDateFieldOptions {
	-webkit-appearance: textfield;
	   -moz-appearance: textfield;
	        appearance: textfield;

    &::-webkit-clear-button {
        display: none;
    }
    &::-webkit-inner-spin-button {
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
}

input.cp-datepicker-input {
	min-height: 30px;
}

.datepicker-hideinput {
  .md-datepicker-input-container input,
  .md-datepicker-input-container button {
    display: none;
  }
}
