//individualise
@import "less/_colors.less";
@import "less/_fonts.less";
@import "less/extensions/style";

@import	"less/core/____general.less";

//basics *do not change *
@import "less/core/style.less";

@import	"less/overrides/style";


.subHeading {
  margin: 20px 0 10px 0;
  padding: 10px 15px !important;
  font-size: 13px;
  line-height: 1;
  background: #85929f !important;
  color: #fff !important;
  border-radius: 0px;
  border-radius: 5px;

}
