md-toast {
	position: fixed;
	z-index: 99999 !important;
	top: 0px;
	left: 0px;
	right: 0px;
	padding: 10px;
	bottom: auto !important;
	height: auto !important;
}
.insideApp md-toast {
	top: ~"env(safe-area-inset-top)" !important;
}
.md-toast .md-toast-content {
	padding: 0px !important;
}
md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content {

}
md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content {
	background-color: fade(#fff,95%) !important;
	border-radius: 7px;
	border: 2px solid @systemColor-ok !important;
	color: @systemColor-ok !important;
	-webkit-box-shadow: fade(#000, 25%) 0px 0px 17px;
	-moz-box-shadow: fade(#000, 25%) 0px 0px 17px;
	box-shadow: fade(#000, 25%) 0px 0px 17px;
}
md-toast.md-default-theme.toast-error .md-toast-content, md-toast.toast-error .md-toast-content {
	background-color: fade(#fff,95%) !important;
	border-radius: 7px !important;
	color: @systemColor-error !important;
	border: 2px solid @systemColor-error !important;
	-webkit-box-shadow: fade(#000, 25%) 0px 0px 17px;
	-moz-box-shadow: fade(#000, 25%) 0px 0px 17px;
	box-shadow: fade(#000, 25%) 0px 0px 17px;
}
.md-toast-text {
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	padding: 0px;
}

md-toast.md-default-theme .md-toast-content .md-button.md-highlight, md-toast .md-toast-content .md-button.md-highlight {
	color: @textColor;
}

md-toast .md-button.md-default-theme:not([disabled]):hover, md-toast .md-button:not([disabled]):hover {
	background: transparent !important;
}