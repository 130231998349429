.fixed {
	position: fixed;
	top: 55px;
	width: 100%;
	z-index: 0;
}
.fixed.absolute {
	position: absolute;
}
.preformated {
	white-space: pre-line;
}
.preformatted {
	white-space: pre-line;
}