.addTag {
	margin-top: 10px;
	position: relative;
	padding-right: 80px;
}
.addTag a.addIcon {
	display: block;
	background-color: @accentColor;
	color: @accentColor-text;
	text-align: center;
	width: 70px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	border-radius: 3px;
	cursor: pointer;
	position: absolute;
	z-index: 2;
	bottom: 10px;
	right: 0px;
}




.modal-container .waiting {
	background-color: @systemColor-warning;
	color: #fff;
	border-radius: 7px;
	.boxShaddow-small-bright;
	text-align: center;
	padding: 10px;
	margin-bottom: 10px;
}
.modal-container .waiting .fa {
	display: block;
	font-size: 20px;
	padding-bottom: 5px;
}
.modal-container .waiting small {
	display: block;
	font-size: 12px;
	line-height: 14px;
	text-align: center;
}

.modal-container .header-image {
	width: 120px;
	height: 120px;
	overflow: hidden;
	margin: 0px auto;
	border-radius: 100%;
	.boxShaddow-small;
}
.modal-container .header-image img {
	width: 100%;
}	
.modal-container > p {
	margin: 0px;
	padding: 0px 0px 10px 0px;
	color: @textColor;
	line-height: 1.2em;
	font-size: 13px;
	font-weight: 400;
}
.modal-container > h3 {
	margin: 0px;
	padding: 10px 0px 10px 0px;
	font-size: 35px;
	line-height: 1.2em;
	text-align: center;
	color: @primaryColor;
	font-weight: 300;
}
.modal-container > h3 > small{
	display: block;
	margin: 0px auto;
	padding: 0px;
	font-size: 15px;
	line-height: 15px;
	color: @textColor;
	padding-bottom: 5px;
}
.modal-container > h3 > small i{
	display: inline-block;
	padding: 5px 10px;
	border-radius: 40px;
	font-size: 15px;
	line-height: 15px;
	color: @textColor;
	background-color:  @dividerColor;
	margin: 5px 1px;
}
.modal-container p:first-child {
	padding-top: 0px;
}
.modal-container p:last-child {
	padding-bottom: 11px;
}
.close {
	position: fixed;
	z-index: 9999;
	width: 50px;
	height: 50px;
	background-color: @primaryColor;
	left: 50%;
	margin-left: -25px;
	top: 5px;
	cursor: pointer;
	text-align: center;
	line-height: 50px;
	font-size: 35px;
	color: @primaryColor-text;
	border-radius: 100%;
}

.contenttyp-link {
	
}
.contenttyp-link .linkPresenter {
	margin: 5px 0px;
	border: 1px dashed @textColor-light;
	padding: 10px;
	color: @textColor;
	font-size: 12px;
	line-height: 14px;
	word-wrap:break-word;
	position: relative;
}
.contenttyp-link .linkPresenter .label {
	display: block;
	background-color: #fff;
	position: absolute;
	left: 5px;
	top: -10px;
	font-size: 10px;
	line-height: 10px;
	padding: 5px;
	z-index: 1;
	text-transform: uppercase;
}
.contenttyp-link .linkPresenter .label a {
	color: @primaryColor;
}
a.checkin-count {
	display: block;
	margin-bottom: 20px;
	width: 80px;
	height: 80px;
	overflow: hidden;
	line-height: 80px;
	font-size: 30px;
	text-align: center;
	background-color: @systemColor-ok;
	font-weight: 300;
	color: #fff;
	margin: 0px auto;
	margin-top: 30px;
	border-radius: 100%;
}
a.checkin-count:last-child {
	margin-bottom: 30px;
}
.bigPresenter {
	margin: -5px 0px 5px 0px;
	border: 1px dashed @textColor-light;
	background-color: @dividerColor;
	border-radius: 5px;
	padding: 10px;
	text-align: center;
	color: @primaryColor;
	font-size: 20px;
	line-height: 20px;
}

.contenttyp-link a.smallCTA {
	display: inline-block;
	padding: 3px 10px;
	border-radius: 7px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	margin: 10px 0px 0px 0px;
}


/* form */
> .modal-container a.modal-button, > .modal-container  button.modal-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	appearance: none !important;
	border: none !important;
	background-color: @primaryColor !important;
	color: @primaryColor-text !important;
	font-size: 24px !important;
	line-height: 24px !important;
	padding: 10px 5px !important;
	text-align: center !important;
	width: 100% !important;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	height: auto !important;
	box-sizing: border-box !important;
	
	display: block !important;
	font-weight: 300 !important;
	margin: 0px !important;
	margin-top: 10px !important;
	border-radius: 0px !important;
}
> .modal-container a.modal-button:hover, > .modal-container button.modal-button:hover {
	background-color: @primaryColor-dark !important;
}
	/* button types */
	> .modal-container a.modal-button.modal-button-notImportant, > .modal-container button.modal-button.modal-button-notImportant {
		background-color: @textColor !important;
	}
	> .modal-container a.modal-button.modal-button-notImportant:hover, > .modal-container button.modal-button.modal-button-notImportant:hover {
		background-color: @primaryColor-dark !important;
	}
	
	> .modal-container a.modal-button.modal-button-beCarefull, > .modal-container button.modal-button.modal-button-beCarefull {
		background-color: @systemColor-delete !important;
	}
	> .modal-container a.modal-button.modal-button-notImportant:hover, > .modal-container button.modal-button.modal-button-notImportant:hover {
		background-color: @primaryColor-dark !important;
	}
	
	> .modal-container a.modal-button.modal-button-doThis, > .modal-container button.modal-button.modal-button-doThis {
		background-color: @systemColor-add !important;
	}
	> .modal-container a.modal-button.modal-button-doThis:hover, > .modal-container button.modal-button.modal-button-doThis:hover {
		background-color: @primaryColor-dark !important;
	}
	
	.modal-button-nearlyInvincible {
		display: block;
		padding: 5px;
		color:  @textColor;
		margin-top: 10px;
		font-size: 14px;
	}
	
	
.selectFromList {
	padding: 15px 0px 15px 0px;
}
.selectFromList > p {
	display: block;
	margin: 0px;
	padding: 5px 0px 10px 0px;
	color: @textColor;
	line-height: 1.2em;
	font-size: 13px;
	font-weight: 400;
	color: @textColor;
}
.selectFromList > a.itemToSelect {
	display: block;
	background-color: @dividerColor;
	position: relative;
	z-index: 1;
	line-height: 50px;
	text-align: left;
	margin-bottom: 5px;
}
	.selectFromList > a.itemToSelect:hover {
		background-color: darken(@dividerColor,5%);
		color: @primaryColor-dark;
	}
.selectFromList > a.itemToSelect:after {
	display: block;
	position: absolute;
	color: @primaryColor;
	font-family: 'FontAwesome';
	content: '\f101';
	z-index: 2;
	right: 5px;
	top: 0px;
	font-size: 35px;
	line-height: 50px;
}
.selectFromList > a.itemToSelect .image {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-top: 5px;
	margin-left: 5px;
	float: left;
	margin-right: 10px;
	overflow: hidden;
	border-radius: 100%;
	border: 1px solid @textColor;
	.boxSizing;
}
.selectFromList > a.itemToSelect .image img {
	width: 100%;
}

.form-group {
	padding: 10px 0px 15px 0px;
	position: relative;
	.boxSizing;
}
.form-group-holder {
	position: relative;
	margin-bottom: 10px;
}
.form-group-holder > small {
	display: block;
	text-align: center;
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: -10px;
	color: @textColor-light;
}
.form-group.form-group-1-2 {
	width: calc(~"50% - 5px");
	display: inline-block;
	float: left;
}
.form-group.form-group-1-2:last-child {
	margin-left: 10px;
}
.form-group-headerInside {
	padding-top: 20px;
}
.form-group-headerInside h4 {
	margin: 0px;
	padding: 0px;
	color: @textColor;
	font-size: 20px;
	line-height: 20px;
}
.form-group-headerInside p {
	margin: 0px;
	padding: 0px;
	padding-top: 10px;
	font-size: 13px;
	line-height: 13px;
}

.form-group label {
	color: @primaryColor;
	font-size: 22px;
	font-weight: 300;
	line-height: 22px;
	display: block;
	text-align: center;
	margin: 0px;
	padding: 0px 0px 5px 0px;
}
.form-group label small.fa {
	font-size: 14px;
}
.form-group .error-msg {
	display: none;
}
.form-group .goodToKnow {
	display: block;
	margin: 0px;
	padding: 5px 0px 10px 0px;
	color: @textColor;
	line-height: 1.2em;
	font-size: 13px;
	font-weight: 400;
	color: @textColor-light;
}

/* reporting-list */
.reporting-list {
	padding: 10px;
	margin: 10px 0px 0px 0px;
}
.reporting-list label {
	display: block;
	font-size: 12px;
	font-weight: 400;
	color: @textColor-light;
	text-transform: uppercase;
	text-align: left	;
	padding-left: 35px;
}
.reporting-list ul {
	.blankList;
}
.reporting-list ul li {
	padding: 5px 10px;
	background-color: @dividerColor;
	margin-bottom: 3px;
	border-radius: 5px;
	font-size: 14px;
	line-height: 14px;
	text-align: left;
	color: @textColor;
}
.reporting-list ul li i {
	display: inline-block;
	width: 20px;
}


/* file */
.form-group .fileHolder {
	position: relative;
}
.form-group .fileHolder .downloadItem {
	position: relative;
	margin-bottom: 5px;
}
.form-group .fileHolder .downloadItem .downloadLink {
	display: block;
	margin-right: 40px;
	font-size: 14px;
	line-height: 30px;
	border-radius: 20px;
	background-color: @dividerColor;
	color: @textColor-light;
	padding: 0px 10px;
	text-align: left;
	text-transform: uppercase;
	cursor: pointer;
	
}
.form-group .fileHolder .downloadItem .deleteLink {
	display: block;
	right: 0px;
	font-size: 14px;
	line-height: 30px;
	border-radius: 100%;
	border: 1px solid @systemColor-delete;
	color: #fff !important;
	text-align: center;
	.boxSizing;
	width: 30px;
	position: absolute;
	top: 0px;
	overflow: hidden;
	cursor: pointer;
}

.form-group .fileHolder .downloadItem.newFile a {
	display: block;
	/* margin-right: 40px; */
	font-size: 14px;
	line-height: 30px;
	border-radius: 20px;
	border: 1px solid @systemColor-add;
	color: @systemColor-add;
	.boxSizing;
	padding: 0px 10px;
	text-align: left;
	text-transform: uppercase;
	cursor: pointer;
}
/* /file */

.form-group .inputHolder {
	position: relative;
}
.form-group .inputHolder > i.fa {
	position: absolute;
	z-index: 1;
	left: 0px;
	width: 35px;
	top: 0px;
	text-align: center;
	line-height: 46px;
	color: @textColor-light;
	font-size: 24px;
}

	
	.form-group.error label {
		color: @systemColor-error;
	}
	.form-group.error .error-msg {
		display: none;
	}
	.form-group.error .error-msg {
		display: block;
		font-size: 13px;
		padding: 5px 0px 0px 0px;
		text-align: center;
	}
	.form-group.error input {
		border-color: @systemColor-error;
		color: @systemColor-error;
	}
	.form-group.error .inputHolder > i.fa {
		color: @systemColor-error;
	}


.form-group input {
	color: @primaryColor;
	border: 1px solid @primaryColor;
	font-size: 22px;
	font-weight: 400;
	width: 100%;
	.boxSizing;
	text-align: center;
	margin: 0px;
	padding: 10px 5px 10px 30px;
	background-color: @dividerColor;
	outline: none;
}

	.form-group input.size-2-3 {
		width: 66%;
		margin-bottom: 5px;
	}
	.form-group input.size-1-3 {
		width: 34%;
		margin-bottom: 5px;
	}
	.form-group input.size-2-3.leftSide {
		width: calc(~"66% - 10px");
		margin-right: 10px;
		margin-bottom: 5px;
	}
	.form-group input.size-2-3.rightSide {
		width: calc(~"66% - 10px");
		margin-left: 10px;
		margin-bottom: 5px;
	}

.form-group textarea {
	color: @primaryColor;
	border: 1px solid @primaryColor;
	font-size: 22px;
	font-weight: 400;
	width: 100%;
	.boxSizing;
	text-align: center;
	margin: 0px;
	padding: 10px 5px 10px 50px;
	background-color: @dividerColor;
	outline: none;
}
	.form-group .muchText textarea {
		color: @primaryColor;
		border: 1px solid @primaryColor;
		font-size: 14px;
		font-weight: 400;
		width: 100%;
		.boxSizing;
		text-align: left;
		margin: 0px;
		padding: 10px 5px 10px 30px;
		background-color: @dividerColor;
		text-align: left;
		outline: none;
		line-height: 16px;
	}
	.form-group .muchText i.fa {
		font-size: 18px;
		line-height: 40px;
	}
.form-group .radioHolder {
	position: relative;
	text-align: center;
}
.form-group .radioHolder a.radioOption-onOff {
	font-size: 30px;
}

.form-group .optionHolder {
	position: relative;
	text-align: center;
}
.form-group .optionHolder .option {
	display: inline-block;
	width: 50px;
	height: 50px;
	background-color: @dividerColor;
	color: @textColor-light;
	line-height: 50px;
	text-align: center;
	margin: 0px 5px;
	font-size: 25px;
	cursor: pointer;
}
.form-group .optionHolder .option small {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
}
.form-group .optionHolder .option:hover {
	background-color: darken(@dividerColor,5%);
}
.form-group .optionHolder .option.selected {
	background-color: @primaryColor-dark;
	color: @primaryColor-text;
}
		/* special for Voting */
		.form-group .optionHolder.voting-special .option.selected i {
			color: #fff;
		}
		.form-group .optionHolder.voting-special .option.selected.fa-frown-o {
			background-color: @systemColor-error;
		}
		.form-group .optionHolder.voting-special .option.selected.fa-meh-o {
			background-color: @systemColor-warning;
		}
		.form-group .optionHolder.voting-special .option.selected.fa-smile-o {
			background-color: @systemColor-ok;
		}
		/* lockedSpecial */
		.form-group .optionHolder.lockedSpecial .option.selected i {
			color: #fff;
		}
		.form-group .optionHolder.lockedSpecial .option.selected.fa-lock {
			background-color: @systemColor-error;
		}
		.form-group .optionHolder.lockedSpecial .option.selected.fa-unlock {
			background-color: @systemColor-ok;
		}
		
		

.form-group select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	border-radius: 0px;
	color: @primaryColor;
	border: 1px solid @primaryColor;
	font-size: 22px;
	font-weight: 400;
	width: 100%;
	.boxSizing;
	text-align: center;
	margin: 0px;
	padding: 10px 30px 10px 5px;
	background-color: @dividerColor;
	outline: none;
	position: relative;
	z-index: 1;
}
.form-group .inputHolder.select > i.fa {
	left: auto;
	right: 5px;
}	


.form-group .tagHolder {

}
.form-group .tagHolder .tag {
	display: inline-block;
	white-space: nowrap;
	padding: 5px;
	line-height: 1em;
	font-size: 14px;
	margin: 5px;
	cursor: pointer;
	position: relative;
	background-color: @dividerColor;
	.borderRadius-smaller;
	padding-right: 20px;
	color: @textColor;
}
.form-group .tagHolder .tag:hover {
	background-color: darken(@dividerColor,5%);
}
.form-group .tagHolder .tag:after {
	display: block;
	position: absolute;
	color: @primaryColor;
	font-family: 'FontAwesome';
	content: '\f055';
	z-index: 2;
	right: 4px;
	top: 4px;
	height: 16px;
	width: 16px;
	border-radius: 100%;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
}
	.form-group .tagHolder .tag.selected {
		background-color: @primaryColor-dark;
		color: @primaryColor-text;
	}
	.form-group .tagHolder .tag.selected:after {
		color: @accentColor;
		content: '\f056';
	}
	
	
	/* modal-fieldset */
	.modal-fieldset {
		padding: 10px 0px;
		margin: 0px;
		border-top: 1px solid @dividerColor;
		font-size: 14px;
		color: @textColor;
	}
	.modal-fieldset a {
		color: @primaryColor;
	}
	.modal-fieldset:last-child {
		border-bottom: 1px solid @dividerColor;
		margin-bottom: 10px;
	}
	.modal-fieldset .fieldset-legend {
		display: block;
		text-transform: uppercase;
		font-size: 12px;
		color: @textColor-light;
	}
	.modal-fieldset .fieldset-content {
		
	}
	.modal-fieldset .fieldset-content  {
		
	}
	.modal-fieldset .image, .modal-fieldset.contenttype-fileImage a.image {
		display: block;
		width: 160px;
		height: 80px;
		.boxShaddow-small-bright;
		overflow: hidden;
		margin: 0px auto;
		position: relative;
	}
	.modal-fieldset .image:after, .modal-fieldset.contenttype-fileImage a.image:after {
		font-family: 'FontAwesome';
		content: '\f00e';
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		z-index: 2;
		width: 20px;
		height: 20px;
		font-size: 14px;
		line-height: 20px;
		color: @textColor-light;
		text-align: center;
		margin-top: -10px;
		margin-left: -10px;
		border-radius: 100%;
		background-color: fade(#fff,35%);
	}
	.modal-fieldset .image img, .modal-fieldset.contenttype-fileImage a.image img {
		width: 100%;
		height: auto;
		margin-top: -40px;
		display: block;
	}
	
	.modal-fieldset .fieldset-content .tag {
		display: inline-block;
		padding: 2px 5px;
		margin: 2px;
		background-color: @dividerColor;
		border-radius: 5px;
		font-size: 12px;
	}
	
	/* accountSwitcher */
	.accountSwitcher {
		display: block;
		position: relative;
		background-color: #fff;
		border: 1px solid @primaryColor;
		height: 60px;
		color: @primaryColor;
		margin-bottom: 10px;
		.boxShaddow-small-bright;
		width: 100%;
		.boxSizing;
	}
	.accountSwitcher .icon {
		line-height: 60px;
		bottom: 0px;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 50px;
		font-size: 24px;
		text-align: center;
	}
	.accountSwitcher .title {
		display: block;
		padding: 10px 0px 5px 60px;
		text-align: left;
		font-size: 20px;
		line-height: 20px;
	}
	.accountSwitcher .legend {
		display: block;
		padding: 0px 0px 5px 60px;
		text-align: left;
		font-size: 12px;
		line-height: 12px;
		color: @textColor-light;
	}
	
	/* - - - */
	.accountSwitcher.addAgency {
		display: block;
		position: relative;
		background-color: #fff;
		border: 1px solid @systemColor-ok;
		height: 70px;
		color: @systemColor-ok;
		margin-bottom: 10px;
		.boxShaddow-small-bright;
		width: 100%;
		.boxSizing;
	}
	.accountSwitcher.addAgency .icon {
		line-height: 70px;
		bottom: 0px;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 50px;
		font-size: 24px;
		text-align: center;
	}
	.accountSwitcher.addAgency .title {
		display: block;
		padding: 10px 0px 5px 60px;
		text-align: left;
		font-size: 14px;
		line-height: 14px;
	}
	.accountSwitcher.addAgency .legend {
		display: block;
		padding: 0px 60px 5px 60px;
		text-align: left;
		font-size: 16px;
		line-height: 16px;
		color: @textColor-light;
		position: relative;
	}
	.accountSwitcher.addAgency .legend input {
		display: block;
		padding: 5px;
		.boxSizing;
		width: 100%;
		.boxShaddow-inside-bright;
		border-radius: 3px;
		font-size: 15px;
		color: @primaryColor;
		text-align: left;
		outline: none;
		border: 1px solid @textColor-light;
	}
	.accountSwitcher.addAgency .legend button {
		display: block;
		padding: 3px 0px 0px 10px;
		.boxSizing;
		width: 50px;
		font-size: 22px;
		color: @primaryColor;
		text-align: left;
		outline: none;
		border: none;
		background-color: #fff;
		position: absolute;
		top: 0px;
		right: 10px;
	}
	
	.contenttype-checkboxList .label {
		text-align: left !important;
	}
	
	
	/* error messages */
	.modal-message {
		padding: 15px;
		border: 3px solid @textColor;
		.dropShaddow-small;
		margin: 10px 0px;
	}
		.modal-message.message-warning {
			border-color: @systemColor-warning;
		}
		.modal-message.message-error {
			border-color: @systemColor-error;
		}
	.modal-message h4 {
		font-size: 18px;
		font-weight: 400;
		padding: 10px 0px;
		margin: 0px;
		background-color: @textColor;
		color: #fff;
		display: none;
	}
		.modal-message.message-warning h4 {
			background-color: @systemColor-warning;
		}
		.modal-message.message-error h4 {
			background-color: @systemColor-error;
		}
	
	.modal-message p {
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		margin: 0px;
		padding: 10px 0px 10px 0px;
	}
		.modal-message.message-warning p {
			color: @systemColor-warning;
		}
		/*.modal-message.message-error p {
			color: @systemColor-error;
		}*/
	.modal-message .message {
		padding: 10px;
		background-color: @dividerColor;
		color: @textColor;
		font-weight: 400;
		font-size: 13px;
		line-height: 15px;
		margin-bottom: 5px;
	}
		.modal-message.message-error .message {
			background-color: fade(@systemColor-error, 10%);
		}
		.modal-message.message-warning .message {
			background-color: fade(@systemColor-warning, 10%);
		}
	.modal-message  .message strong {
		display: block;
		font-weight: 400;
		font-size: 16px;
		text-transform: uppercase;
		line-height: 16px;
		padding: 7px 0px;
		color: @textColor;
	}
		.modal-message.message-error strong {
			color: @systemColor-error;
		}
		.modal-message.message-warning strong {
			color: @systemColor-warning;
		}