/* inline-dialog */
.inline-dialog {
	color: @textColor;
	.font-main;
	font-size: 15px;
	line-height: 18px;
	
}
.inline-dialog.more-space {
	padding: 10px;
}
.inline-dialog h1 {
	.font-special;
	color: @primaryColor;
	font-weight: 400;
	font-size: 36px;
	line-height: 38px;
	margin: 0px;
	padding: 0px;
	padding-bottom: 10px;
}
.inline-dialog h1.error-headline {
	color: @systemColor-error;
	font-size: 28px;
	line-height: 30px;
}
.inline-dialog h2 {
	.font-special;
	color: @primaryColor;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
	padding-bottom: 10px;
}

.callToActionButton button {
	font-size: 20px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: @primaryColor;
	.font-main;
}
button.callToActionButton {
	font-size: 20px;
	padding-left: 20px;
	padding-right: 20px;
	.font-main;
	margin: 20px 15px;
	line-height: 50px;
	height: auto;
	.boxSizing;
	width: ~"calc(100% - 30px)";
	background-color: @primaryColor;
}
.inline-dialog .miniCta {
	border-radius: 3px;
	background-color: @primaryColor;
	line-height: 50px;
	padding: 0px 10px;
	color: #fff;
	font-size: 18px;
	display: inline-block;
	margin-top: 20px;
	
	-webkit-box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
	-moz-box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
	box-shadow: rgba(0,0,0,0.25) 0px 0px 3px;
}
.inline-dialog .miniCta-small {
	border-radius: 5px;
	background-color: #fff;
	border: 1px solid @primaryColor;
	padding: 1px 5px;
	color: @primaryColor;
	display: inline-block;
}

/* link-presenter */
.linkPresenter {
	margin: 20px 0px;
	border: 1px dashed darken(@dividerColor,15%);
	padding: 20px 10px;
	color: @textColor;
	font-size: 14px;
	line-height: 16px;
	word-wrap:break-word !important;
	position: relative;
	max-width: 100%;
	.boxSizing;
}
.linkPresenter pre {
	white-space: pre-wrap !important;
	word-wrap:break-word !important;
}
.linkPresenter .label {
	display: block;
	background-color: #fff;
	position: absolute;
	left: 5px;
	top: -12px;
	font-size: 12px;
	line-height: 12px;
	padding: 5px;
	z-index: 1;
	text-transform: uppercase;
	color: @primaryColor;
}
.linkPresenter .label a {
	color: @primaryColor;
}
/* email-container */
.email-container {
	font-size: 12px;
	line-height: 14px;
	background-color: @dividerColor;
	padding: 10px;
	border: 1px solid @textColor-light;
	color: @primaryColor;
	.boxSizing;
	width: 100%;
}

.inline-dialog .mailingList {
	.blankList;
	margin-top: 20px;
	border-top: 1px solid @dividerColor;
	width: 100%;
}
.inline-dialog .mailingList li {
	border-bottom: 1px solid @dividerColor;
}
.inline-dialog .mailingList li .mailing-details {
	position: relative;
	padding: 15px 60px 15px 0px;
	
}
.inline-dialog .mailingList .mailing-options {
	position: absolute;
	color: @textColor;
	display: flex;
	right: 0px;
	top: 50%;
	margin-top: -15px;
}
.inline-dialog .mailingList .mailing-options a {
	display: block;
	.boxSizing;
	border-left: 1px solid @dividerColor;
	text-align: center;
	line-height: 30px;
	width: 40px;
	font-size: 16px;
}
.inline-dialog .mailingList .mailing-options a span {
	display: none;
}
.inline-dialog .mailingList .mailing-options a span.icon {
	display: block;
	text-align: center;
}
.inline-dialog .mailingList .mailing-name {
	font-size: 18px;
	line-height: 20px;
	color: @primaryColor;
	display: block;
}
.inline-dialog .mailingList .mailing-subject {
	font-size: 14px;
	line-height: 16px;
	color: @textColor;
	display: block;
	padding-top: 5px;
}
.inline-dialog .mailingList .mailing-meta {
	font-size: 12px;
	line-height: 12px;
	margin-top: 5px;
	display: flex;
	flex-wrap: wrap;
}
.inline-dialog .mailingList .mailing-meta .meta-info {
	display: block;
	margin: 5px 5px 0px 0px;
	background-color: @dividerColor;
	border-radius: 7px;
	padding: 3px 5px;
	color: @textColor-light;
}




