.dynamicElements {

}
.dynamicElements .addCase {
	display: block;
	font-size: 20px;
	text-align: center;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 10px;
	border-radius: 5px;
	.boxShaddow-default;
	cursor: pointer;
	margin-top: 20px;
	margin-bottom: 20px;
}
.dynamicElements .addCase.last {
	margin-bottom: 20px;
}