@charset 'utf-8';

/* ######################################################## */
/* Overrides */
/* ######################################################## */

h1, h2, h3, h4, h5 {
  color: inherit;
}

.radio.disabled {
  cursor: not-allowed;
}

.md-dialog-container {
  width: 100%;
  height: 100%;
  //top: inherit !important;
  top: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
}



/* ######################################################## */
/* Textangular */
/* ######################################################## */

.ta-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .ta-button-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	button {
	  min-width: 0;
	  min-height: 0;
	  line-height: normal;
	  margin-left: 0;
	  color: @primaryColor-text;
	  md-icon {
		color: @primaryColor-text;
	  }
	  &.active {
		background-color: @dividerColor;
	  }
	}
  }
}

.ta-root {
  &.disabled {
	.ta-toolbar {
	  display: none;
	}

	p {
	  color: rgba(0, 0, 0, 0.38);
	}

	.ta-text-editor {
	  .ta-bind {
		background-position: bottom -1px left 0;
		background-size: 4px 1px;
		background-repeat: repeat-x;
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.38) 0, rgba(0, 0, 0, 0.38) 33%, transparent 0);
		border-bottom-color: transparent;
		color: rgba(0, 0, 0, 0.38);
	  }
	}
  }
}

.ta-text-editor {
  .ta-bind {
	border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
	&:focus {
	  border-bottom: 2px solid @primaryColor;
	  outline: none;
	}
  }

  p {
	margin: 0;
	padding: 0;
  }
}

.input-container {
  &--text-angular {
	label {
	  transform: translate3d(0, 6px, 0) scale(0.75) !important;
	}
  }
}


/* ######################################################## */
/* Headlines */
/* ######################################################## */
h1, h2, h3, h4, h5 {
  &.cp-light {
	color: white;
  }
}


/* ######################################################## */
/* Mixin Helpers */
/* ######################################################## */
/*
@mixin rgba-helper($property, $color, $background, $praefix: '') {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	#{$property}: $solid-color unquote($praefix);
	#{$property}: $color unquote($praefix);
}
*/