ul.planInfo {
	.blankList;
	padding-top: 10px;
}
ul.planInfo li {
	position: relative;
	margin-top: 10px;
	padding-left: 30px;
	color: @textColor;
}
ul.planInfo li .icon {
	display: block;
	top: 0px;
	left: 0px;
	position: absolute;
	z-index: 1;
	font-size: 15px;
}


/* abo-plan */

.abo-plan {
	.blankList;
	border-radius: 5px;
	background-color: @dividerColor;
	padding: 5px;
	margin: 20px 0px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	
	@media (max-width: 735px) {
		display: block;
	}
	
}
.abo-plan .abo-plan-headline {
	width: 100%;
	.boxSizing;
	padding: 10px;
}
.abo-plan .abo-plan-headline h3 {
	margin: 0px;
	padding: 0px;
	color: @primaryColor;
	padding-bottom: 5px;
}
.abo-plan .abo-plan-headline p {
	margin: 0px;
	padding: 0px;
	color: @textColor;
}
.abo-plan li.plan-choice {
	.blankList;
	border-radius: 5px;
	background-color: darken(@dividerColor,5%);
	padding: 10px;
	border: 1px solid darken(@dividerColor,8%);
	margin-bottom: 10px;
	color: @textColor;
	.transition-default;
	cursor: pointer;
	margin: 5px;
	scale: 1;
	width: ~"calc(25% - 10px)";
	.boxSizing;
	outline: none;
	
	@media (max-width: 735px) {
		width: auto;
		margin-bottom: 10px;
	}
}
.abo-plan li.plan-choice.plan-custom {
	width: ~"calc(100% - 10px)";
	margin-bottom: 10px;
}


.abo-plan.disabled li.plan-choice {
	pointer-events: none;
}
.abo-plan li.plan-choice:hover {
	.scaleUp-default;
	.boxShaddow-default-bright;
	.transition-default;
}
.abo-plan li.plan-choice .plan-name {
	display: block;
	font-size: 26px;
	line-height: 26px;
	padding-bottom: 5px;
	
}
.abo-plan li.plan-choice .plan-name small a {
	display: inline-block;
	font-size: 14px;
	line-height: 14px;
	background: #fff;
	padding: 3px 7px;
	border-radius: 7px;
	color: @textColor-light;
	margin-bottom: 5px;
}

.abo-plan li.plan-choice .plan-price {
	display: block;
	font-size: 15px;
	line-height: 15px;
}
.abo-plan li.plan-choice .plan-price small {
	display: block;
	padding-top: 5px;
	color: @textColor-light;
}
	.abo-plan li.plan-choice.active .plan-price small {
		color: @dividerColor;
	}
.abo-plan li.plan-choice.active {
	background-color: @primaryColor;
	color: @primaryColor-text;
	border-color: @primaryColor;
	.boxShaddow-default-bright;
}

.abo-plan li.plan-footer {
	width: 100%;
}
.abo-plan li.plan-footer p {
	padding: 10px;
	margin: 0px;
	font-size: 13px;
	line-height: 15px;
	color: @textColor-light;
}

.plan-agreements {
	width: 100%;
	@media (max-width: 735px) {
		padding-top: 20px;
	}
}
.plan-agreements .agreement {
	padding: 0px 10px;
	color: @textColor;
}
.plan-agreements .agreement a {
	color: @primaryColor;
}

.pendingMessage {
	border-radius: 5px;
	padding: 10px;
	.boxSizing;
	width: 100%;
	.boxSizing;
	background-color:  @systemColor-error;
	color: #fff;
}
.pendingMessage.typeGreen {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
.pendingMessage p {
	color: #fff;
	padding: 0px;
	margin: 0px;
}
.pendingMessage .actions {
	display: flex;
	margin: 10px -5px 0px -5px;
}
.pendingMessage .actions a {
	display: block;
	background-color: @systemColor-error;
	text-align: center;
	flex-grow: 1;
	border-radius: 5px;
	margin: 5px;
	font-size: 15px;
	line-height: 15px;
	padding: 15px 0px;
	line-height: 40px;
	color: #fff;
	.boxShaddow-default;
}
.pendingMessage .actions a.resend {
	background-color: #fff;
	color: @systemColor-ok;
}



/* ABO */
.aboMessage {
	display: none;
}
.insideApp .aboMessage {
	display: block;
}
.insideApp .abo-edit-container {
	display: none;
}


.insideApp .hideInsideApp {
	display: none !important;
}