.seating-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.seating-wrapper .seating-details {
	width: calc(100% - 770px);
	padding: 20px;
	.boxSizing;
	
	@media (max-width: 750px) {
		width: 100%;
	}
	
}
.seating-wrapper .seating-details.singleView {
	width: 100%;
	.boxSizing;
}


/* ##################### BEREICHE ##################### */
.seating-wrapper .seating-plan {
	margin: 0px 10px;
	width: 750px;
	.boxSizing;
	background-color: #fff;
	.boxShaddow-bigger-bright;
	.boxSizing;
	
	@media (max-width: 750px) {
		width: ~"calc(100% - 20px)";
		overflow: scroll;
	}
}


.seating-plan .seatingplan-areaOverview-editArea {
	width: 750px;
	overflow: scroll;
	position: relative;
	
	margin-bottom: 30px;
	padding-bottom: 20px;
	border-bottom: 1px solid darken(@dividerColor,5%);
}

.seating-plan .seatingplan-areaOverview-editArea .seatingplan-areaOverview-bg {
	position: relative;
	z-index: 0;
	width: 100%;
	height: auto;
}

.seating-plan .seatingplan-areaOverview-editArea .seatingplan-areaOverview-area {
	position: absolute;
	z-index: 1;
	width: 30px;
	height: 30px;
	border-radius: 10px;
	background-color: @primaryColor-text;
	color: @primaryColor;
	border: 3px solid @primaryColor;
	.boxShaddow-biggerrrr;
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
}
.seating-plan .seatingplan-areaOverview-editArea .seatingplan-areaOverview-area .area-name {
	font-size: 12px;
	font-weight: 700;
	display: block;
	text-align: center;
	text-transform: uppercase;
	line-height: 14px;
	
	display: none;
}


.seating-plan .seatingplan-areaOverview-options {
	margin-bottom: 20px;
	padding: 0px 20px;
}
.seating-plan .seatingplan-areaOverview-options .fileupload {
	margin-top: 10px !important;
}
/* ##################### // BEREICHE ##################### */



/* ##### TABLE ##### */
.seating-details-table {
	width: 100%;
}
.seating-details-table th {
	font-size: 12px;
	font-weight: normal;
	text-align: left !important;
	padding: 10px 0px;
	padding-bottom: 5px;
	border-bottom: 2px solid @systemColor-ok;
	vertical-align: bottom;
	color: @textColor-light;
}
	.seating-details-table.blocked th {
		border-color: @dividerColor;
	}
	
.seating-details-table td {
	font-size: 16px;
	font-weight: normal;
	text-align: left;
	padding: 10px 0px;
	border-bottom: 2px solid @dividerColor;
	vertical-align: middle;
}
	.seating-details-table .addRow td {
		border-color: @systemColor-ok;
	}
.seating-details-table td.colorPrev {
	width: 50px;
}


	.object-element {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		background-color: @dividerColor;
	}
	
	.object-typePK {
		border-radius: 100%;
	}
	.object-typeFloor {
		border-radius: 12px;
	}
	.object-dragMe {
		cursor: move;
	}


.seating-details-table td.color {
	width: 100px;
	padding-right: 10px;
	padding-bottom: 8px;
}
.seating-details-table td.name {
	width: auto;
}
.editMessage {
	color: @primaryColor;
	padding: 15px 0px;
}
.seating-details-table td.name input,
.seating-details-table	 .color-picker-wrapper .input-group .color-picker-input,
.seating-details-table md-input-container.md-default-theme .md-input,
.seating-details-table md-input-container .md-input {
	width: 100%;
	.boxSizing;
	border: 1px solid @textColor-light;
	color: @textColor;
	font-size: 15px;
	padding: 5px;
	border-radius: 7px;
	min-width: 60px;
}
	.seating-details-table .addRow td.name input,
	.seating-details-table .addRow .color-picker-wrapper .input-group .color-picker-input,
	.seating-details-table .addRow md-input-container.md-default-theme .md-input,
	.seating-details-table .addRow md-input-container .md-input {
		border-color: @systemColor-ok;
		color: @systemColor-ok;
	}
.seating-details-table td.option {
	width: 80px;
	padding-left: 10px;
}
	
.seating-details-table td.option a {
	display: block;
	text-align: center;
	padding: 5px 10px;
	background-color: @systemColor-ok;
	color: #fff;
	border-radius: 7px;
	font-size: 12px;
	text-transform: uppercase;
}
	.seating-details-table.blocked td.option a {
		opacity: 0.5;
		cursor: not-allowed;
		pointer-events: none;
		filter: grayscale(100%);
	}
	.seating-details-table td.option a.setInactiveLink {
		background-color: #fff;
		border: 1px solid @systemColor-warning;
		color: @systemColor-warning;
	}
	.seating-details-table td.option a.setActiveLink {
		background-color: #fff;
		border: 1px solid @systemColor-ok;
		color: @systemColor-ok;
	}
	.seating-details-table td.option a.removeLink {
		background-color: @systemColor-error;
	}
	.seating-details-table td.option a.removeLink .icon {
		display: none;
	}
	
.seating-details-table	 .legende td {
	color: @textColor-light;
	font-size: 18px;
	border-bottom: none !important;
}
.seating-details-table	 .legende td.legende-icon {
	text-align: center !important;
}

/* Color Picker */
.seating-details-table	 .color-picker-swatch.color-picker-swatch-left,
.seating-details-table .colorpicker-reset {
	display: none !important;
}
.seating-details-table	 .color md-input-container {
	margin: 0px !important;
}
.seating-details-table	  .color-picker-wrapper .color-picker-input-swatch {
	padding-left: 5px;
}

/* INPUT */
.seating-details-table	 md-input-container label {
	display: none !important;
}
.seating-details-table md-input-container.md-block {
	margin: 0px;
}

/* ##### Switcher ##### */

.seating-changer {
	width: 100%;
	padding: 20px 10px 20px 10px;
	.boxSizing;
}
.seating-changer select {
	display: block;
	width: 100%;
	.boxSizing;
	padding: 10px;
	font-size: 18px;
	line-height: 30px;
	border: 1px solid @primaryColor;
	background: #fff;
	color: @primaryColor;
}

/* ##################### SEATING-PLAN ##################### */
.seating-plan-full {
	margin: 10px;
	margin-bottom: 20px;
	.boxSizing;
	padding: 0px;
	background-color: #fff;
	.boxShaddow-bigger-bright;
	width: ~"calc(100% - 20px)";
}
.seating-plan-full .no-image {
	font-size: 35px;
	font-weight: bold;
	color: darken(@dividerColor,5%);
	text-align: center;
	padding: 50px 20px;
}
.seatingPlan-area {
		/*height: ~"calc(100vh - 350px)";*/
		overflow: scroll;
		margin: 0px auto;
		position: relative;
}


/* ##### toolArea ##### */
.toolArea {
	padding: 10px;
	.boxSizing;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	border-bottom: 1px solid @dividerColor;
	margin-bottom: 10px;
	background: #fff;
	position: sticky;
	top: 150px;
	z-index: 4;
}

.toolArea .main-tool {
	border-radius: 100%;
	background-color: @dividerColor;
	color: @textColor;
	text-align: center;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	font-size: 16px;
	margin-right: 5px;
	.boxSizing;
}
	.toolArea .main-tool a {
		color: @textColor;
		display: block;
	}

.toolArea .main-tool.selected {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
	.toolArea .main-tool.selected a {
		color: @primaryColor-text;
	}


.toolArea .main-tool.withSubs {
	border-radius: 44px;
	width: auto;
	display: flex;
	padding-right: 2px;
	margin-top: -3px;
	margin-bottom: -3px;
	height: 36px;
	padding-right: 3px;
}
.toolArea .main-tool.withSubs .icon {
	margin-right: 5px;
	line-height: 36px;
	padding-right: 7px;
	display: block;
	border-right: 1px solid #fff;
	padding-left: 7px;
}



.toolArea .toolSubElement {
	margin: 3px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	align-content: center;
	cursor: drag;
	border-radius: 44px;
	background: #fff;
	padding: 3px;
	background-color: #fff;
	outline: none;
}
	.toolArea .toolSubElement.selected {
		border: 1px solid @primaryColor;
		background-color: #fff;
		.dropShaddow-small;
	}

.toolArea .toolSubElement .color {
	width: 10px;
	height: 10px;
	display: block;
	overflow: hidden;
	border-radius: 100%;
	background: @dividerColor;
	
	margin-right: 3px;
}
.toolArea .toolSubElement .name {
	font-size: 12px;
	line-height: 12px;
	color: @primaryColor;
}

.dragArea .legende {
	display: none;
}

.toolArea .selectArea {
	justify-self: flex-end;
	margin-right: 20px;
}
.toolArea .selectArea select {
	border: 1px solid @primaryColor;
	background-color: #fff;
	color: @primaryColor;
}


.toolArea-upload {
	margin-bottom: 20px;
	padding: 0px 10px;
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid @dividerColor;
}
.toolArea-upload .fileupload {
	margin-top: 10px !important;
}

.toolArea .edit-button {
	display: block;
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 44px;
	line-height: 30px;
	padding: 0px 7px;
	margin-left: 10px;
	font-size: 13px;
	.dropShaddow-small;
	min-width: 30px;
	.boxSizing;
	text-align: center;
}


.zoom-options {
	display: flex;
	align-items: center;
	align-content: center;
	margin-left: 15px;
}
.zoom-options a {
	line-height: 28px;
	border: 1px solid @textColor-light;
	color: @primaryColor;
	border-radius: 100%;
	.boxSizing;
	width: 30px;
	text-align: center;
}
.zoom-options span {
	display: block;
	height: 2px;
	width: 20px;
	background-color: @textColor-light;
}


/* ### Sitzplatz ### */
.plan_place_info {
	padding: 0px 10px;
	color: @primaryColor;
	font-size: 12px;
	font-weight: bold;
	display: flex;
	align-items: center;
	align-content: center;
}
.plan_place_info .input {
	display: flex;
	align-items: center;
	align-content: center;
	margin-left: 10px;
}
.plan_place_info input {
	width: 50px;
	border-radius: 3px;
	padding: 2px 7px;
	.boxSizing;
	margin: 0px 5px;
	border: 1px solid @primaryColor;
	color: @primaryColor;
	background-color: #fff;
}
.plan_place_info .input a.option {
	display: block;
	margin: 0px 5px;
	color: @textColor-light;
	font-size: 15px;
}
.plan_place_info .input a.selected {
	color: @primaryColor !important;
}


.sitzplanCanvas-wrapper {
	width: 1000px;
	height: auto;
	margin: 0px auto;
	position: relative;
}
.sitzplanCanvas-wrapper .imageLayer {
	width: 100%;
	height: auto;
}

.sitzplanCanvas-wrapper .sitzplatz {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background: #fff;
	color: @accentColor;
	border: 3px solid @accentColor;
	cursor: pointer;
	
	position: absolute;
		z-index: 2;
}
.sitzplanCanvas-wrapper .sitzplatz.selected {
	background-color: @accentColor;
	color: @accentColor-text;
	.boxShaddow-big;
}



/* ##################### SEATING-PLAN ##################### */