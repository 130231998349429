.api-user-manager {
	padding: 20px;
}

.api-user-manager > header {
	
}
.api-user-manager > header .addUser {
	display: inline-block;
	background-color: @primaryColor;
	color: @primaryColor-text;
	line-height: 40px;
	padding: 0px 20px;
	border-radius: 3px;
	.boxShaddow-small;
}

.api-user-manager .api-user-container {
	display: flex;
	flex-wrap: wrap;
	margin: 20px -10px;
}

.api-user-manager .api-user-container .api-user {
	margin: 10px;
	width: ~"calc(100% - 20px)";
	border-radius: 3px;
	
	-webkit-box-shadow: rgba(0,0,0,0.15) 0px 0px 17px;
	-moz-box-shadow: rgba(0,0,0,0.15) 0px 0px 17px;
	box-shadow: rgba(0,0,0,0.15) 0px 0px 17px;
}

.api-user-manager .api-user-container .api-user .status {
	display: block;
	font-size: 10px;
	line-height: 14px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	border-radius: 3px 3px 0px 0px;
}
.api-user-manager .api-user-container .api-user .status.status-inactive {
	background-color: @systemColor-warning;
}
.api-user-manager .api-user-container .api-user .status.status-active {
	background-color: @systemColor-ok;
}


.api-user-manager .api-user-container .api-user header {
	
}
.api-user-manager .api-user-container .api-user header .user-id {
	font-size: 14px;
	line-height: 14px;
	color: @textColor-light;
	
	display: block;
	word-wrap: break-word;
	padding: 10px;
}
.api-user-manager .api-user-container .api-user header .user-created {
	font-size: 12px;
	line-height: 12px;
	color: @textColor-light;
	
	display: block;
	word-wrap: break-word;
	padding: 0px 10px;
}

.api-user-manager .api-user-container .api-user .api-user-table {
	width: ~"calc(100% - 20px)";
	border-top: 2px solid @dividerColor;
	margin: 10px;
}
.api-user-manager .api-user-container .api-user .api-user-table th {
	border-bottom: 2px solid @dividerColor;
	font-variant: 700;
	font-size: 12px;
	vertical-align: center;
	color: @textColor;
	width: 80px;
	padding: 5px 0px;
	padding-right: 20px;
	text-align: left;
}
.api-user-manager .api-user-container .api-user .api-user-table td {
	border-bottom: 2px solid @dividerColor;
	font-variant: 400;
	font-size: 14px;
	vertical-align: top;
	color: @textColor;
	word-wrap: break-word;
	padding: 5px 0px;
	text-align: left;
}
.api-user-manager .api-user-container .api-user .api-user-table td .key-container {
	word-wrap: break-word;
	width: 100%;
	padding: 0px;
	margin: 0px;
	border: none;
	background-color: #fff;
}
.api-user-manager .api-user-container .api-user .api-user-table td a.smallCta {
	display: inline-block;
	padding: 0px 5px;
	border-radius: 3px;
	font-size: 13px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	margin-left: 10px;
}

.api-user-manager .api-user-container .api-user .options {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}
.api-user-manager .api-user-container .api-user .options a {
	display: block;
	padding: 0px 10px;
	line-height: 30px;
	font-size: 14px;
	color: #fff;
	background-color: @textColor;
	border-radius: 3px;
	margin: 0px 0px 10px 10px;
}
.api-user-manager .api-user-container .api-user .options a .icon .fa-trash{
	color: #fff !important;
}





