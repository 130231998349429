/* ######################################################## */
/* Timeslots advanced */
/* ######################################################## */

.intelliList-cardStyle {

}

.intelliList-cardStyle > ul {
	.blankList;
}

.intelliList-cardStyle > ul > li {
	display: flex;
	flex-wrap: wrap;
	background: @dividerColor;
	border-radius: 10px;
	margin-top: 10px;
	padding: 10px 40px 10px 30px;
	position: relative;
}
.intelliList-cardStyle > ul > li .sortOrder  {
	display: block;
	position: absolute;
	left: 10px;
	top: 10px;
	width: 20px;
}
.intelliList-cardStyle > ul > li .sortOrder a  {
	display: block;
	text-align: left;
	color: @primaryColor;
	margin-bottom: 10px;
}
.intelliList-cardStyle > ul > li .options  {
	display: block;
	position: absolute;
	right: 8px;
	top: 8px;
	width: 24px;
	background: #fff;
	border-radius: 10px;
	padding-top: 5px;
	.dropShaddow-small;
}
.intelliList-cardStyle > ul > li .options a  {
	display: block;
	text-align: right;
	margin-bottom: 5px;
	text-align: center;
}

.intelliList-cardStyle > ul > li .timeslot-warning  {
	display: block;
	left: 10px;
	top: 40px;
}

.intelliList-cardStyle .mainInfos {
	width: 70%;
	.boxSizing;

	@media (max-width: 700px) {
		width: 100%;
	}
}

.intelliList-cardStyle input, .intelliList-cardStyle textarea, .intelliList-cardStyle select {
	width: 100%;
	.boxSizing;
	border: 1px solid @textColor-light;
	border-radius: 3px;
	color: @textColor;
	padding: 5px;
	font-size: 13px;
}
.intelligente-auswahl-backend.timeslots .intelliList-cardStyle .eventField-dateAndTime {
	width: 100%;
}

.intelliList-cardStyle .additionalInfos {
	width: 30%;
	.boxSizing;
	padding-left: 20px;

	@media (max-width: 700px) {
		width: 100%;
		padding-left: 0px;
		margin-top: 10px;
	}
}
.intelliList-cardStyle .labelLike {
	display: block;
	font-size: 12px;
	line-height: 12px;
	padding-bottom:  5px;
	color: @primaryColor;
}

.intelliList-cardStyle .languages-container {
	position: relative;
	padding-left: 25px;
	margin-top: 5px;
}
.intelliList-cardStyle .languages-container .lang-info {
	display: block;
	width: 20px;
	line-height: 20px;
	font-size: 10px;
	font-weight: 700;
	text-align: center;
	height: 20px;
	overflow: hidden;
	border-radius: 100%;
	background-color: #fff;
	color: @textColor-light !important;

	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -10px;
}

.intelliList-cardStyle .subItem {
	padding-top: 5px;
	padding-bottom: 10px;
}


/* ######################################################## */
/* ticketCounter2 */
/* ######################################################## */

.ticketCounter2 {
	display: block;
	width: 190px;
	position: fixed;
	.boxSizing;
	left: 50%;
	margin-left: -95px;
	border-radius: 44px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	font-size: 10px;
	line-height: 10px;
	text-align: left;
	text-transform: uppercase;
	padding: 5px 0px 5px 35px;
	.boxShaddow-big;
	margin-top: 10px;
	z-index: 3;
	outline: none;

	/*top: 65px;*/
	top: 5px;

	@media (max-width: 449px) {
		margin-left: -70px;
		width: 140px;
	}
}

.ticketCounter2 .desktopOnly {
	display: none;
	@media (min-width: 450px) {
		display: inline-block;
	}
}

	.ticketCounter2.inFooter {
		position: absolute;
		left: 7px;
		margin-left: 0px;
		.boxShaddow-big;
		margin-top: 0px;
		z-index: 3;
		top: auto;

		/*top: 65px;*/
		bottom: 12px;
	}


.ticketCounter2 .counterIcon {
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	background-color: @primaryColor-text;
	color: @primaryColor;
	position: absolute;
	left: 0px;
	top: -5px;
	.boxSizing;
	border-radius: 100%;
	outline: none;
	border: 2px solid @primaryColor;
	line-height: 26px;
	font-size: 16px;
}

/* ######################################################## */
/* Intelligente Auswahlliste > TIMESLOTS > FRONTEND */
/* ######################################################## */
.intelligente-auswahl-gast ul li {
	outline: none;
}
.intelligente-auswahl-gast ul li.outOfStock .input-field,
.intelligente-auswahl-gast ul li.outOfStock .input-field-label,
.intelligente-auswahl-gast ul li.toMuchSelected .input-field,
.intelligente-auswahl-gast ul li.toMuchSelected .input-field-label {
	opacity: 0.5;
}

.intelligente-auswahl-gast ul li.isSelected .input-field,
.intelligente-auswahl-gast ul li.isSelected .input-field-label {
	opacity: 1 !important;
}
.intelligente-auswahl-gast ul li.isSelected .input-field,
.intelligente-auswahl-gast ul li.isSelected .input-field-label {
	opacity: 1 !important;
}
.intelligente-auswahl-gast ul li.outOfStock.isSelected .input-field-label .verfuegbarkeit {
	border-color: @systemColor-ok !important;
	color: @systemColor-ok !important;
}



.timeslotDetails strong {
	display: block;
	font-weight: normal;
	color: @primaryColor;
}
.timeslotDetails small {

}
.intelligente-auswahl-gast ul.timeslots-options li {
	align-items: center;
}
.intelligente-auswahl-gast ul.timeslots-options li label {
	width: 100%;
}
ul.timeslots-options li .fa-check-square {
	color: @systemColor-ok !important;
}

.intelligente-auswahl-gast .label {
	color: @primaryColor;
}
.intelligente-auswahl-gast ul .input-field {
	width: 18px;
}
.intelligente-auswahl-gast ul .input-field.withAmmount {
	width: auto;
}



.intelligente-auswahl-backend.timeslots td.sort {
	position: relative;
}

.intelligente-auswahl-backend.timeslots .timeslot-warning {
	position: absolute;
	left: -15px;
	top: 15px;
	z-index: 1;
	font-size: 12px;
	line-height: 15px;
	display: block;
}



@media (max-width: 900px) {
	/* Verfügbarkeit */
	.intelligente-auswahl-gast ul.timeslots-options li .input-field-label {
		padding-left: 0px !important;
	}
	.intelligente-auswahl-gast ul.timeslots-options li .input-field-label label {
		display: block !important;
	}

	.intelligente-auswahl-gast ul.timeslots-options li .input-field-label .verfuegbarkeit {
	/*
	display: block;
	line-height: 14px;

	font-size: 10px;
	overflow: hidden;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: center;
	background-color: #fff;
	border: 1px solid #7ED321;
	color: #6c7174;
	border-radius: 44px;
	text-transform: uppercase;
	*/
	display: block;
	width: 80px;
	height: auto;
	.boxSizing;
	position: relative;
	left: auto;
	top: auto;
	margin-top: 3px;
	border-radius: 3px;
	padding: 0px 10px;
	}
}


/* ######################################################## */
/* Intelligente Auswahlliste > TIMESLOTS > BACKEND */
/* ######################################################## */

.intelligente-auswahl-backend.timeslots {
	margin-top: ;
}
.intelligente-auswahl-backend.timeslots .label-special {
	display: none !important;
}

.intelligente-auswahl-backend.timeslots .multiple-fields {
	max-width: 90px !important;
	width: 90px;
}
.intelligente-auswahl-backend.timeslots .multiple-fields input {
	margin-bottom: 5px;
}
.intelligente-auswahl-backend.timeslots table td {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	border-width: 2px !important;
}
.intelligente-auswahl-backend.timeslots .md-datepicker-input,
.framedInputs .intelligente-auswahl-backend.timeslots md-input-container .md-input {
	border-width: 1px !important;
}
.intelligente-auswahl-backend.timeslots .md-datepicker-input {
	width: 100px !important;
	padding-left: 3px !important;

}
.intelligente-auswahl-backend.timeslots .eventField-dateAndTime {
	width: 175px;
}
.intelligente-auswahl-backend.timeslots .eventField-dateAndTime .dateDeleteLink {
	left: -13px;
}
.intelligente-auswahl-backend.timeslots table td.field-date {
	padding-left: 15px;
}
.intelligente-auswahl-backend.timeslots table td.field-date {
	padding-top: 0px !important;
}






/* ### Backend nicht geändert ### */

/* ### Backend ### */
.intelligente-auswahl-backend.timeslots {
	color: @textColor;
	margin-top: -20px;
	width: 100%;
}
.intelligente-auswahl-backend.timeslots .switch-option.first {
	border-top: 1px dashed darken(@dividerColor,10%);
}
.intelligente-auswahl-backend.timeslots .switch-option {
	padding: 10px 0px;
	font-size: 14px;
	border-bottom: 1px dashed darken(@dividerColor,10%);
}
.intelligente-auswahl-backend.timeslots .switch-option input[type="number"] {
	width: 35px;
	padding: 3px 2px;
	border-radius: 5px;
	.boxSizing;
	border: 1px solid @systemColor-ok;
	background: #fff;
	color: @textColor;
	outline: none;
}
.intelligente-auswahl-backend.timeslots h4 {
	color: @primaryColor;
	font-size: 20px;
	line-height: 20px;
	padding: 30px 0px 10px 0px;
	margin: 0px;
	.font-special;
}
.intelligente-auswahl-backend.timeslots > small {
	color: @textColor-light;
	display: block;
	margin-top: 20px;
}
.intelligente-auswahl-backend.timeslots .small-select {
	.boxSizing;
	width: 100%;
	padding: 5px 5px;
	font-size: 12px;
	border: none;
	/*
	border-top: 1px solid @primaryColor;
	border-bottom: 1px solid @primaryColor;
	*/
	border: 1px solid @primaryColor;
	border-radius: 0px !important;
	background: #fff !important;
	color: @primaryColor;
	/* -webkit-appearance: none; */
	margin: 5px 0px;
	margin-bottom: 10px;
	outline: none;
	position: relative;
	cursor: pointer;
}
.intelligente-auswahl-backend.timeslots table td, .intelligente-auswahl-backend.timeslots table th {
	font-size: 14px;
	line-height: 14px;
	border-bottom: 1px solid darken(@dividerColor,10%);
	text-align: left;
	padding: 5px;
	vertical-align: top;
}
.intelligente-auswahl-backend.timeslots table tr.addRow td {
	border-bottom: 5px solid darken(@dividerColor,10%);
	border-top: 4px solid darken(@dividerColor,10%);
}
.intelligente-auswahl-backend.timeslots table {
	width: 100%;
}
.intelligente-auswahl-backend.timeslots table th {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
}
.intelligente-auswahl-backend.timeslots table th small {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: @textColor-light;
}
.intelligente-auswahl-backend.timeslots table .sort {
	width: 20px;
	padding: 5px 0px;
	text-align: center;
}
.intelligente-auswahl-backend.timeslots table .add {
	width: 30px;
	padding: 10px 0px 0px 0px;
	text-align: left;
}
.intelligente-auswahl-backend.timeslots table .remove, .intelligente-auswahl-backend.timeslots table .duplicate {
	text-align: center;
	width: 30px;
	padding: 10px 0px 0px 0px;
}



.intelligente-auswahl-backend.timeslots table .sort a {
	display: block;
	color: @textColor;
}
.intelligente-auswahl-backend.timeslots table .remove a {
	color: @systemColor-error;
	font-size: 18px;
	line-height: 18px;
	display: block;
	text-align: right;
}
.intelligente-auswahl-backend.timeslots table .add a {
	display: block;
	text-align: right;
	font-size: 18px;
	line-height: 18px;
	color: @systemColor-ok;
}
	.intelligente-auswahl-backend.timeslots table td.input-with-icon {
		padding-right: 25px;
		.boxSizing;
		position: relative;
	}
	.intelligente-auswahl-backend.timeslots table td.input-with-icon .reset-icon {
		position: absolute;
		right: 5px;
		top: 50%;
		margin-top: -10px;
		font-size: 15px;
		line-height: 20px;
		display: block;
		z-index: 1;
		width: 20px;
		color: @systemColor-error;
		text-align: right;
	}

.intelligente-auswahl-backend.timeslots table input {
	width: 100%;
	.boxSizing;
	color: @systemColor-ok;
	border: 1px solid @systemColor-ok;
	padding: 3px;
	background-color: #fff;
	border-radius: 3px;
}

.intelligente-auswahl-backend.timeslots table .languages-container {
	position: relative;
	padding-left: 25px;
	margin-top: 5px;
}
.intelligente-auswahl-backend.timeslots table .languages-container .lang-info {
	display: block;
	width: 20px;
	line-height: 20px;
	font-size: 10px;
	font-weight: 700;
	text-align: center;
	height: 20px;
	overflow: hidden;
	border-radius: 100%;
	background-color: @dividerColor;
	color: @textColor-light !important;

	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -10px;
}
.intelligente-auswahl-backend.timeslots table input.languages {
	border-style: dashed;
}

.intelligente-auswahl-backend.timeslots table select {
	.boxSizing;
	color: @systemColor-ok;
	border: 1px solid @systemColor-ok;
	padding: 3px;
	background-color: #fff;
	border-radius: 3px;
}
.intelligente-auswahl-backend.timeslots table .edit-option input, .intelligente-auswahl-backend.timeslots table .edit-option select {
	border-color: @textColor-light;
	color: @textColor;
}
.intelligente-auswahl-backend.timeslots table .value {
	padding: 0px 4px;
	display: block;
}

.intelligente-auswahl-backend.timeslots table small.statistik {
	font-size: 12px;
	line-height: 14px;
	display: inline-block;
	margin-left: 5px;
}
.intelligente-auswahl-backend.timeslots table small.statistik .fa-check-circle {
	color: @textColor !important;
}

/* timeslot-container */
.timeslot-container {
	margin-top: 10px;
	padding-bottom: 10px;
}
.timeslot-container p {
	color: @textColor;
	font-size: 14px;
	line-height: 17px;
	padding: 0px;
	margin: 0px;
}
.timeslot-container .timeslot-type {
	.blankList;
}
.timeslot-container .timeslot-type li {
	outline: none;
}
.timeslot-container .timeslot-type li.timeslot-choice {

	position: relative;
	min-height: 16px;
	border: 1px solid @dividerColor;
	padding: 5px;
	padding-left: 30px;
	display: flex;
	align-content: center;
	color: @textColor-light;
	cursor: pointer;
	margin-top: 5px;
	border-radius: 10px;

}
	.timeslot-container .timeslot-type li.timeslot-choice.active {
		color: @primaryColor;
	}
.timeslot-container .timeslot-type li.timeslot-choice .timeslot-icon {
	position: absolute;
	left: 5px;
	top: 50%;
	line-height: 16px;
	font-size: 16px;
	margin-top: -8px;

}
	.timeslot-container .timeslot-type li.timeslot-choice .timeslot-icon.iconNotChecked {

	}
	.timeslot-container .timeslot-type li.timeslot-choice.active .timeslot-icon.iconNotChecked {
		display: none;
	}
	.timeslot-container .timeslot-type li.timeslot-choice .timeslot-icon.iconChecked {
		display: none;
	}
	.timeslot-container .timeslot-type li.timeslot-choice.active .timeslot-icon.iconChecked {
		display: block;
	}
	.timeslot-container .timeslot-type li.timeslot-choice.active .timeslot-icon.iconChecked .fa-check-circle {
		color: @primaryColor !important;
	}

.timeslot-container .timeslot-type li.timeslot-choice .timeslot-info {
	width: 100%;
}


.timeslot-container md-select .md-select-value {
	border-bottom: none !important;
}

.timeslot-container md-select.md-default-theme .md-select-value,
.timeslot-container md-select .md-select-value {
	background: #fff !important;
	border-radius: 20px !important;
}
.timeslot-container md-input-container.md-input-focused .md-input,
.timeslot-container md-input-container.md-input-selected .md-input,
.timeslot-container md-select:not([disabled]):focus .md-select-value,
.timeslot-container md-input-container.md-input-focused:focus .md-input {
	padding-left: 5px !important;
	padding-right: 5px !important;
	border-bottom: none !important;
	.boxSizing;
}

.step-spacer {
	height: 300px;
	/*.transition-default;*/
}
.step-spacer.done {
	height: 0px;
	/*.transition-default;*/
}
