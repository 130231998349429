.choose-event-preset {

}

.choose-event-preset ul {
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 10px 0px;

    &.active a {
      background-color: @primaryColor;
      color: @primaryColor-text;
			border: 1px solid @primaryColor;
			-webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px;
      -moz-box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px;
    }

    a {
      padding: 0px 10px;
      position: relative;
      display: flex;
			min-height: 60px;
			.boxSizing;
      color: @primaryColor;
      align-items: center;
      border-radius: 10px;
			padding-left: 60px;
      background-color: #fff;
			border: 1px solid @primaryColor;
			color: @primaryColor;
			

      

      .text {
        margin: 20px 0px;

        .name {
          font-size: 22px;
          line-height: 22px;
          display: block;
        }

        .owner {
          font-size: 14px;
          line-height: 14px;
          display: block;
          padding-top: 5px;
        }
      }

      &:hover {
        background-color: @primaryColor;
				color: @primaryColor-text;
        
      }
    }
  }
}
.choose-event-preset ul .icon {
  font-size: 24px;
  margin: 5px 20px 5px 10px;
  border-radius: 100%;
  background-color: #fff;
  color: @primaryColor;
  line-height: 40px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
	position: absolute;
	left: 00px;
	top: 50%;
	margin-top: -20px;
}

.choose-event-preset ul .icon img {
  width: 100%;
	height: 100%;
}

.choose-event-preset ul li a {
	cursor: pointer;
}