html {
	.font-main;
	font-size: 100%;
	line-height: 1.4em;
}

a, a:visited {
	outline: none;
	text-decoration: none; 
}
a:not([class]), .text-link { 
	color: @primaryColor;
	text-decoration: none; 
}
/* a:hover, a:focus { color: @accentColor; outline: none; }; */
a:not([class]):hover, .text-link:hover {
	color: @accentColor;
	
}

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover, a:active { outline: 0; }

::-webkit-input-placeholder {
	color: @textColor-light;
	font-style: italic;
}
::-moz-placeholder {
	color: @textColor-light;
	font-style: italic;
}
:-ms-input-placeholder {
	color: @textColor-light;
	font-style: italic;
}
input:-moz-placeholder {
	color: @textColor-light;
	font-style: italic;
}

h1 {
	color: @primaryColor;
	font-size: 3.0rem;
	.font-special;
	font-weight: 400;
	margin: 0px;
	padding: 0px 0px @padding 0px;
}
h2 {
	color: @primaryColor;
	font-size: 3.0rem;
	.font-special;
	font-weight: 400;
	margin: 0px;
	padding: @padding 0px @padding 0px;
}
h3 {
	color: @primaryColor;
	font-size: 2.0rem;
	.font-special;
	font-weight: 400;
	margin: 0px;
	padding: 0px 0px @padding 0px;
	line-height: 1.4em;
}
h4 {
	color: @primaryColor;
	font-size: 1.8rem;
	.font-main;
	font-weight: 400;
	margin: 0px;
	padding: 0px 0px @padding 0px;
	line-height: 1.4em;
}
h5 {
	color: @primaryColor;
	font-size: 1.2rem;
	.font-main;
	margin: 0px;
	padding: 0px 0px @padding 0px;
	line-height: 1.4em;
}
p {
	color: @textColor;
	font-size: 1rem;
	padding: 0px 0px @padding 0px;
	.font-main;
	font-weight: 400;
}
p:last-child {
	padding-bottom: 0px;
}
strong {
	font-weight: bold;
}

nav ul {
	.blankList;
}

.hideMobile-375 {
	@media (max-width: 375px) {
		display: none !important;
	}
}

.callToAction-defualt-big {
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 7px;
	.boxShaddow-big-bright-bottomFocused;
	text-align: center;
	display: block;
	margin: 25px 0px;
	font-size: 18px;
	padding: 15px 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}