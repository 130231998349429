/* ******** */
/* MODAL */
/* ******** */
md-dialog#seatingplan-choose-seat-modal {
	max-width: 100% !important;
	max-height: 100% !important;
	width: calc(100vw - 40px);
}
md-dialog#seatingplan-choose-seat-modal md-dialog-actions.seatingplan-choose-seat-modal-actions {
	padding: 20px 10px;
	
	@media (min-width: 420px) {
		justify-content: center;
	}
	
}
md-dialog#seatingplan-choose-seat-modal md-dialog-actions.seatingplan-choose-seat-modal-actions button.md-button {
	
	font-size: 18px !important;
	
	@media (min-width: 420px) {
		min-width: 300px !important;
	}
}

/* ******** */
/* WRAPPER */
/* ******** */
.seating-plan {
	margin: 0px 10px;
}



/* ******** */
/* AREA SELECT IN ORDER */
/* ******** */

.sp-step-area {
	margin: 20px auto;
	width: 100%;
	max-width: 650px;
}

.sp-step-area .legende {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 18px;
	color: @textColor-light;
	text-align: center;
}

.sp-step-area .image-map {
	position: relative;
}

.sp-step-area .image-map .marker {
	position: absolute;
	z-index: 1;
	border-radius: 10px;
	width: 30px;
	height: 30px;
	.boxSizing;
	border: 2px solid #fff;
	.boxShaddow-big;
	cursor: pointer;
	background-color: #fff;
}
.sp-step-area .image-map .name {
	position: absolute;
	display: block;
	text-transform: uppercase;
	z-index: 2;
	top: 40px;
	width: 70px;
	left: -20px;
	.boxSizing;
	border-radius: 66px;
	padding: 3px;
	text-align: center;
	background: #fff;
	color: @textColor;
	font-size: 9px;
	line-height: 9px;
	font-weight: bold;
}

.sp-step-area .image-map .bg-image {
	width: 100%;
	height: auto;
	position: relative;
	z-index: 0;
}
.sp-step-area .area-select {
	margin-top: 20px;
	border-top: 1px solid darken(@dividerColor, 5%);
}


.sp-step-area .area-select .area {
	border-bottom: 1px solid darken(@dividerColor, 5%);
	position: relative;
	min-height: 60px;
	.boxSizing;
	padding: 10px 80px 10px 0px;
}
.sp-step-area .area-select .area .area-name {
	color: @textColor;
	font-size: 20px;
	line-height: 20px;
	
}
.sp-step-area .area-select .area .area-pks,
.pk-list .area-pks {
	
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-content: center;
	
	color: @textColor-light;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	font-weight: bold;
}
.sp-step-area .area-select .area .area-pks .area-pk-price {
	margin-top: 10px;
	margin-right: 10px;
}
.sp-step-area .area-select .area .area-pks .area-pk,
.pk-list .area-pks .area-pk {
	display: flex;
	align-content: center;
	align-items: center;
	margin-top: 10px;
	margin-right: 10px;
}
.sp-step-area .area-select .area .area-pks  .area-pk .area-pk-color,
.pk-list .area-pks .area-pk .area-pk-color {
	width: 16px;
	height: 16px;
	background-color: @dividerColor;
	border-radius: 100%;
	display: block;
	margin-right: 3px;
}

.sp-step-area .area-select .area .callToAction {
		position: absolute;
		right: 0px;
		border-radius: 7px;
		.dropShaddow-small;
		background-color: @accentColor;
		color: @accentColor-text;
		line-height: 40px;
		text-align: center;
		width: 70px;
		font-size: 18px;
		top: 50%;
		margin-top: -20px;
}


/* ******** */
/* PK SELECT IN ORDER */
/* ******** */

.sp-step-pk {
	margin: 20px auto;
	width: 100%;
	max-width: 650px;
}

.sp-step-pk .legende {
	margin-bottom: 20px;
	font-size: 14px;
	line-height: 16px;
	color: @textColor-light;
	text-align: left;
}


.sp-step-pk .pk-area-select {
	display: flex;
	align-items: center;
	align-content: center;
}
.sp-step-pk .pk-area-select .back {
	width: 40px;
	line-height: 40px;
	border-radius: 7px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	text-align: center;
	font-size: 20px;
	.boxSizing;
	display: block;
	margin-right: 20px;
	flex-grow: 0;
}
.sp-step-pk .pk-area-select .select {
	display: flex;
	align-items: center;
	align-content: center;
	flex-grow: 1;
}
.sp-step-pk .pk-area-select .select select {
	width: 100%;
	border: 1px solid @primaryColor;
	color: @primaryColor;
	background-color: #fff;
	font-size: 16px;
	.boxSizing;
}


.sp-step-pk .pk-area-promo-code {
	border-bottom: 2px solid darken(@dividerColor, 5%);
	padding: 5px 0px 15px 0px;
	margin-bottom: 17px;
}

.sp-step-pk .pk-area-promo-code.outOfBox {
	border-bottom: none;
	border-top: 2px solid darken(@dividerColor, 5%);
	padding: 20px 0px 0px 0px;
	margin-bottom: 17px;
}


.sp-step-pk .pk-area-pk-cta {
	margin-top: 20px;
	margin-bottom: 40px;
}
.sp-step-pk .pk-area-pk-cta .cta-button {
	margin-top: 20px;
	display: block;
	padding: 20px 10px;
	text-align: center;
	.boxSizing;
	border: 1px solid @accentColor;
	background-color: #fff;
	border-radius: 7px;
	color: @accentColor;
	font-size: 18px;
	line-height: 18px;
}
.sp-step-pk .pk-area-pk-cta .cta-button.mainAction {
	font-size: 22px;
	line-height: 22px;
	color: @accentColor-text;
	background-color: @accentColor;
	padding: 18px 10px;
	.dropShaddow-small;
}

.sp-step-pk .pk-area-pk-ticketCounter {
	border-top: 2px solid darken(@dividerColor, 5%);
	padding: 20px 0px;
}

.sp-step-pk .pk-area-pk-choice {
	/* nn border-bottom: 2px solid darken(@dividerColor, 5%); */
}

.sp-step-pk .pk-area-pk-choice .priceCategorie {
	/* nn border-bottom: 2px solid darken(@dividerColor, 5%); */
}

.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header {
	padding: 5px 0px;
	position: relative;
	display: flex;
	align-content: center;
	align-items: center;
	.boxSizing;
}
.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header .pk-info {
	width: 100%;
}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header .pk-info .pk-color {
		width: 20px;
		height: 20px;
		display: block;
		border-radius: 100%;
		margin-right: 10px;
		background-color: @dividerColor;
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header .pk-info .pk-name {
		color: #fff;
		font-size: 12px;
		line-height: 12px;
		font-weight: bold;
		padding-left: 10px;
	}
	
.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header .pk-legende {
	width: 40%;
	text-align: right;
	font-size: 10px;
	line-height: 12px;
	color: @textColor-light;
}

.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-header .addButton {
	width: 40px;
	line-height: 40px;
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 7px;
	.dropShaddow-small;
	text-align: center;
	font-size: 20px;
	display: block;
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -20px;
}


.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat {
	border-top: 1px solid darken(@dividerColor, 5%);
	padding: 15px 0px;
	position: relative;
	.boxSizing;
}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat:first-child {
		border-top: none !important;
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat:last-child {
		margin-bottom: 20px;
	}
	
.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat .pk-seat-info {
	display: block;
	.boxSizing;
	color: @textColor;
	background-color: @dividerColor;
	padding: 5px 7px;
	font-size: 12px;
	line-height: 10px;
	margin-top: 10px;
}
.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat .pk-seat-info span {
	display: inline-block;
	margin-right: 5px;
}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat .pk-seat-info .seat-row {
	
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat .pk-seat-info .seat-seat {
		
	}
	
	
	
.ticket-choice {
	margin-right: 50px;
	position: relative;
}
.ticket-choice .choose-ticket {
	position: absolute;
	right: 10px;
	color: @primaryColor;
	font-size: 20px;
	line-height: 20px;
	padding: 5px 0px;
	display: block;
	top: -7px;
	width: 30px;
	text-align: right;
	z-index: 2;
	outline: none;
}

.change-seats {
	display: block;
	line-height: 30px;
	border: 1px solid @accentColor;
	font-size: 12px;
	text-align: center;
	border-radius: 7px;
	margin-top: 5px;
	color: @accentColor;
}

.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.pk-ticketType {
	
}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.pk-ticketType .name {
		color: @primaryColor;
		font-size: 20px;
		padding-right: 40px;
		line-height: 20px;
		display: block;
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.pk-ticketType .additional {
		color: @textColor-light;
		font-size: 12px;
		line-height: 14px;
		display: block;
		padding-top: 5px;
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.pk-ticketType .additional .description {
		display: block;
	}
	.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.pk-ticketType .additional .price {
		display: block;
	}
	
.sp-step-pk .pk-area-pk-choice .priceCategorie .pk-seat	.removeButton {
	width: 40px;
	line-height: 25px;
	background-color: #fff;
	color: @textColor-light;
	border-radius: 7px;
	border: 1px solid @textColor-light;
	.boxSizing;
	text-align: center;
	font-size: 15px;
	display: block;
	position: absolute;
	right: 0px;
	top: 10px;
}

.ticket-choice .more-tickets {
	position: absolute;
	z-index: 3;
	background-color: #fff;
	.boxShaddow-bigger-bright;
	right: 0px;
	left: 0px;
	top: -10px;
	padding: 10px;
	border-radius: 10px;
}
.ticket-choice .more-tickets .pk-ticketType {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 2px solid lighten(@textColor-light,15%);
	cursor: pointer;
	position: relative;
	outline: none;
}
.ticket-choice .more-tickets .pk-ticketType .choose-icon {
	display: block;
	position: absolute;
	right: 0px;
	top: 50%;
	line-height: 20px;
	margin-top: -15px;
	font-size: 18px;
}
.ticket-choice .more-tickets .pk-ticketType:last-child {
	padding-bottom: 0px;
	margin-bottom: 0px;
	border-bottom: none;
}


/* ******** */
/* SEATING PLAN SELECT IN ORDER */
/* ******** */

.public-seatingplan {
	padding-top: 50px;
}

/* sp-header */
.public-seatingplan .sp-header {
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: 	center;
	justify-content: flex-start;
	border-bottom: 1px solid @dividerColor;
	position: absolute;
	z-index: 5;
	background: #fff;
	padding: 10px 10px 0px 10px;
	top: 0px;
	left: 0px;
	right: 0px;
}

.max-tickets {
	margin-bottom: 10px;
	color: @textColor-light;
	font-size: 13px;
	line-height: 20px;
}
.public-seatingplan .sp-header .area-step {
	display: block;
	width: 40px;
	text-align: center;
	line-height: 40px;
	margin-right: 10px;
	font-size: 20px !important;
	border-right: 1px solid @dividerColor;
	margin-bottom: 10px;
	color: @primaryColor;
}

.public-seatingplan .sp-header .area-select {
	padding-right: 10px;
	margin-right: 10px;
	border-right: 1px solid @dividerColor;
	margin-bottom: 10px;
}
.public-seatingplan .sp-header .area-select select {
	border: 1px solid @primaryColor;
	background-color: #fff;
	color: @primaryColor;
	font-size: 16px;
	.boxSizing;
	padding: 5px 10px;
	
}

/* sp-footer */
.seatingplan-footer-info {
	color: @accentColor;
	font-weight: bold;
}
.public-seatingplan .sp-footer {
	border: 1px dashed @dividerColor;
	padding: 10px;
	padding-top: 0px;
	margin: 40px 0px;
	border-radius: 10px;
	
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	align-items: 	center;
	justify-content: flex-start;
}

.public-seatingplan .sp-header .pk-list .area-pks .area-pk {
	margin-top: 10px;
}

.public-seatingplan .sp-footer .legende {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
	color: @textColor-light;
	font-size: 12px;
	font-weight: bold;
	line-height: 16px;
}
.public-seatingplan .sp-footer .legende .legende-item {
	display: block;
	margin-right: 10px;
	margin-top: 10px;
}
.public-seatingplan .sp-footer .legende .legende-item .icon {
	display: inline-block;
	margin-right: 5px;
}



/* sp-main */
.public-seatingplan .sp-main {
	
}


/* sp-choose */
.public-seatingplan .sp-main .sp-choose {
	position: relative;
	min-width: 1000px;
	max-width: 2000px;
	margin: 0px auto;
}
.public-seatingplan .sp-main .sp-choose .bg-image {
	position: relative;
	z-index: 0;
}
.public-seatingplan .sp-main .sp-choose .bg-image img {
	width: 100%;
	height: auto;
}

.public-seatingplan .sp-main .sp-choose .seat {
	display: block;
	z-index: 1;
	position: absolute;
	border: 2px solid @primaryColor;
	background-color: #fff;
	cursor: pointer;
	.transition-default;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	outline: none;
	.boxSizing;
}
.public-seatingplan .sp-main .sp-choose .seat .border {
	border: 1px solid #fff;
	content: '';
	display: block;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #fff;
	z-index: 1;
}
	.public-seatingplan .sp-main .sp-choose .seat.circle .border {
		border-radius: 100%;
	}
	.public-seatingplan .sp-main .sp-choose .seat.choosen .border {
		background-color: transparent;
	}

.public-seatingplan .sp-main .sp-choose .seat .seat-meta-plan {
	position: relative;
	z-index: 2;
	color: @textColor-light;
	font-size: 8px;
}
.public-seatingplan .sp-main .sp-choose .seat .seat-meta-plan.small {
	
}
.public-seatingplan .sp-main .sp-choose .seat.seat.choosen .seat-meta-plan {
	display: none;
}



.tooltip-inside .seat-details {
	font-size: 12px;
	line-height: 16px;
}
.tooltip-inside .seat-meta {
	display: inline-block;
	margin: 10px 5px 0px 0px;
}
.public-seatingplan .sp-main .sp-choose .seat:hover {
	
	@media (min-width: 750px) {
		.transition-default;
		.scaleUp-small;
		.boxShaddow-bigger-bright;
	}
}
.public-seatingplan .sp-main .sp-choose .seat.blocked:hover {
	@media (min-width: 750px) {
		.boxShaddow-none;
		.scaleUp-none;
	}
}

.public-seatingplan .sp-main .sp-choose .seat.blocked {
	border-color: @textColor-light !important;
}

.public-seatingplan .sp-main .sp-choose .seat .counter {
	display: none;
}
.public-seatingplan .sp-main .sp-choose .seat.choosen .counter {
	display: block;
	font-size: 10px;
	line-height: 10px;
	font-weight: bold;
	color: #fff;
}
.public-seatingplan .sp-main .sp-choose .seat.choosen .waiting {
	display: block;
	font-size: 10px;
	line-height: 10px;
	color: @textColor;
}


/* counter */

.ticketCounter3 {
	display: block;
	position: absolute;
	left: 10px;
	width: 190px;
	.boxSizing;
	border-radius: 44px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	font-size: 10px;
	line-height: 10px;
	text-align: left;
	text-transform: uppercase;
	padding: 5px 0px 5px 35px;
	.boxShaddow-big;
	outline: none;
	
	@media (max-width: 449px) {
		width: 140px;
	}
}

	.ticketCounter3.positionRelative {
		display: block;
		position: relative;
		left: auto;
		top: auto;
		bottom: auto;
		width: auto;
		
		@media (max-width: 449px) {
			width: auto;
		}
	}
	.ticketCounter3.positionRelative2 {
		display: block;
		position: sticky;
		top: 10px;
		bottom: auto;
		margin-bottom: 20px;
		margin-top: 20px;
		z-index: 3;
	}

.ticketCounter3 .desktopOnly {
	display: none;
	@media (min-width: 450px) {
		display: inline-block;
		bottom: auto;
	}
}
	
	
.ticketCounter3 .counterIcon {
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	background-color: @primaryColor-text;
	color: @primaryColor;
	position: absolute;
	left: 0px;
	top: -5px;
	.boxSizing;
	border-radius: 100%;
	outline: none;
	border: 2px solid @primaryColor;
	line-height: 26px;
	font-size: 16px;
	bottom: auto;
}


/* ******** */
/* addSeats-modal */
/* ******** */

md-dialog#addSeat-dialog {
	max-width: 300px !important;
	background-color: @accentColor;
	border-radius: 10px !important;
}

.addSeats-modal {
	padding: 20px 0px;
}
.addSeats-modal .legende {
	font-size: 14px;
	color: #fff;
	line-height: 18px;
	padding-bottom: 20px;
	font-weight: bold;
	text-align: center;
	text-shadow: 1px 1px 1px fade(#000000,15%);
}

.addSeats-modal .buttons {
	
}
.addSeats-modal .buttons a {
	display: block;
	margin-top: 20px;
	border-radius: 7px;
	color: #fff;
	text-align: center;
	font-size: 16px;
	line-height: 30px;
	border: 1px solid #fff;
}
.addSeats-modal .buttons a.mainAction {
	background-color: #fff;
	color: @accentColor;
	border: none;
	line-height: 60px;
	font-size: 22px;
	.dropShaddow-small;
}


.addSeats-modal .numbers {
	position: relative;
	margin: 0px auto;
	max-width: 300px;
	.boxSizing;
	padding: 10px 100px 15px 100px;
}
.addSeats-modal .numbers .addRemove {
	position: absolute;
	top: 10px;
	left: 10px;
	height: 80px;
	width: 80px;
	color: @accentColor;
	.boxShaddow-bigger-bright;
	background-color: fade(#fff,95%);
	border-radius: 100%;
	line-height: 80px;
	text-align: center;
	font-size: 35px;
	display: block;
	.boxSizing;
}
	.addSeats-modal .numbers .addRemove.disabled {
		color: @dividerColor !important;
		pointer-events: none;
	}
.addSeats-modal .numbers .addRemove:last-child {
	left: auto;
	right: 10px;
}
.addSeats-modal .numbers .count {
	text-align: center;
	color: #fff;
	.font-special;
	font-size: 65px;
	text-align: center;
	line-height: 80px;
	text-shadow: 1px 1px 2px fade(#000000,15%);
}












