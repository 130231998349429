.boughtTickets {
	display: flex;
	flex-wrap: wrap;
}
	.boughtTickets.centered {
		justify-content: center;
	}
.boughtTickets .ticket {
	display: block;
	margin: 0px 5px 5px 0px;
	border-radius: 5px;
	color: @primaryColor;
	padding: 5px;
	border: 1px solid @primaryColor;
	font-size: 13px;
	line-height: 13px;
	text-transform: none;
}
	.ticket-single {
		display: block;
		margin: 0px 5px 5px 0px;
		border-radius: 5px;
		color: @primaryColor;
		padding: 5px;
		border: 1px solid @primaryColor;
		font-size: 13px;
		line-height: 13px;
		text-transform: none;
		display: inline-block;
		margin: 0px !important;
	}
	
.boughtTickets.margin-top .ticket {
	margin: 5px 5px 0px 0px;
}
.boughtTickets .ticket.additionalTicket,
.ticket-single.additionalTicket {
	color: @textColor;
	border-color: @textColor;
}


.ticket-guest {
	color: @textColor;
	padding-bottom: 10px;
	border-bottom: 1px dashed @dividerColor;
	margin-bottom: 10px;
}
.ticket-guest:last-child {
	padding-bottom: 0px;
	border-bottom: none;
	margin-bottom: 0px;
}
.ticket-guest.escortPerson {
	color: @textColor-light;
}

.loadMoreSmall {
	display: inline-block;
	margin: 10px 0px;
	padding: 7px 15px;
	border-radius: 7px;
	font-size: 14px;
	line-height: 14px;
	background-color: @accentColor;
	color: @accentColor-text;
}