/* shaddows */
.dropShaddow-small {
  -webkit-box-shadow: fade(#000, 20%) 1px 1px 2px;
  -moz-box-shadow: fade(#000, 20%) 1px 1px 2px;
  box-shadow: fade(#000, 20%) 1px 1px 2px;
}

.dropShaddow-small-white {
  -webkit-box-shadow: #fff 1px 1px 1px;
  -moz-box-shadow: #fff 1px 1px 1px;
  box-shadow: #fff 1px 1px 1px;
}

.boxShaddow-small {
  -webkit-box-shadow: fade(#000, 35%) 0px 0px 3px;
  -moz-box-shadow: fade(#000, 35%) 0px 0px 3px;
  box-shadow: fade(#000, 35%) 0px 0px 3px;
}

.boxShaddow-small-bright {
  -webkit-box-shadow: fade(#000, 15%) 0px 0px 3px;
  -moz-box-shadow: fade(#000, 15%) 0px 0px 3px;
  box-shadow: fade(#000, 15%) 0px 0px 3px;
}

.boxShaddow-dialog {
	-webkit-box-shadow: fade(#000, 35%) 0px 0px 40px;
  -moz-box-shadow: fade(#000, 35%) 0px 0px 40px;
  box-shadow: fade(#000, 35%) 0px 0px 40px;
}
.boxShaddow-default {
  -webkit-box-shadow: fade(#000, 35%) 0px 0px 7px;
  -moz-box-shadow: fade(#000, 35%) 0px 0px 7px;
  box-shadow: fade(#000, 35%) 0px 0px 7px;
}

.boxShaddow-header {
  -webkit-box-shadow: fade(#000, 25%) 0px 0px 3px;
  -moz-box-shadow: fade(#000, 25%) 0px 0px 3px;
  box-shadow: fade(#000, 25%) 0px 0px 3px;
}
.boxShaddow-inputs {
  -webkit-box-shadow: fade(#000, 25%) 0px 0px 10px;
  -moz-box-shadow: fade(#000, 25%) 0px 0px 10px;
  box-shadow: fade(#000, 25%) 0px 0px 10px;
}
.boxShaddow-default-bright {
  -webkit-box-shadow: fade(#000, 15%) 0px 0px 7px;
  -moz-box-shadow: fade(#000, 15%) 0px 0px 7px;
  box-shadow: fade(#000, 15%) 0px 0px 7px;
}

.boxShaddow-default-bright {
  -webkit-box-shadow: fade(#000, 15%) 0px 0px 7px;
  -moz-box-shadow: fade(#000, 15%) 0px 0px 7px;
  box-shadow: fade(#000, 15%) 0px 0px 7px;
}

.boxShaddow-big {
  -webkit-box-shadow: fade(#000, 30%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 30%) 0px 0px 17px;
  box-shadow: fade(#000, 30%) 0px 0px 17px;
}

.boxShaddow-big-dark {
  -webkit-box-shadow: fade(#000, 50%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 50%) 0px 0px 17px;
  box-shadow: fade(#000, 50%) 0px 0px 17px;
}
.boxShaddow-big-darker {
  -webkit-box-shadow: fade(#000, 65%) 0px 0px 20px;
  -moz-box-shadow: fade(#000, 65%) 0px 0px 20px;
  box-shadow: fade(#000, 65%) 0px 0px 20px;
}
.boxShaddow-big-medium {
  -webkit-box-shadow: fade(#000, 25%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 25%) 0px 0px 17px;
  box-shadow: fade(#000, 25%) 0px 0px 17px;
}
.boxShaddow-bigger-bright {
  -webkit-box-shadow: fade(#000, 25%) 0px 0px 26px;
  -moz-box-shadow: fade(#000, 25%) 0px 0px 26px;
  box-shadow: fade(#000, 25%) 0px 0px 26px;
}
.boxShaddow-biggerrrr-bright {
  -webkit-box-shadow: fade(#000, 15%) 0px 0px 30px;
  -moz-box-shadow: fade(#000, 15%) 0px 0px 30px;
  box-shadow: fade(#000, 15%) 0px 0px 30px;
}
.boxShaddow-biggerrrr {
  -webkit-box-shadow: fade(#000, 35%) 0px 0px 30px;
  -moz-box-shadow: fade(#000, 35%) 0px 0px 30px;
  box-shadow: fade(#000, 35%) 0px 0px 30px;
}
.boxShaddow-big-bright {
  -webkit-box-shadow: fade(#000, 15%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 15%) 0px 0px 17px;
  box-shadow: fade(#000, 15%) 0px 0px 17px;
}

.boxShaddow-big-bright-bottomFocused {
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 30px, rgba(0, 0, 0, 0.1) 0px 4px 10px -2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 30px, rgba(0, 0, 0, 0.1) 0px 4px 10px -2px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 30px, rgba(0, 0, 0, 0.1) 0px 4px 10px -2px;
}
.boxShaddow-big-bright-bottomFocused-small {
  -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 20px, rgba(0, 0, 0, 0.1) 0px 4px 6px -2px;
}

.boxShaddow-big-dark {
  -webkit-box-shadow: fade(#000, 45%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 45%) 0px 0px 17px;
  box-shadow: fade(#000, 45%) 0px 0px 17px;
}
.boxShaddow-big-brighter {
  -webkit-box-shadow: fade(#000, 10%) 0px 0px 17px;
  -moz-box-shadow: fade(#000, 10%) 0px 0px 17px;
  box-shadow: fade(#000, 10%) 0px 0px 17px;
}

.boxShaddow-inside {
  -moz-box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.35);
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.35);
}

.boxShaddow-inside-bright {
  -moz-box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15);
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15);
}
.boxShaddow-inside-bright-inputs {
  -moz-box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.13);
  box-shadow: inset 0px -2px 5px rgba(0, 0, 0, 0.13);
}

.boxShaddow-none {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.textShaddow-default {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.35);
}

.textShaddow-default-dark {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
}

.textShaddow-white {
  text-shadow: 1px 1px 1px #fff;
}

/* borderRadius */
.borderRadius-smaller {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.borderRadius-small {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.borderRadius-default {
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
}

.borderRadius-big {
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  border-radius: 44px;
}

/* transitions */
.transition-quick {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.transition-default {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  transition: all .2s ease;
}

.transition-slow {
  -webkit-transition: all .7s ease-in-out;
  -moz-transition: all .7s ease;
  -o-transition: all .7s ease;
  transition: all .7s ease;
}

.scaleUp-smaller{
	-moz-transform: scale(1.02);
	-webkit-transform: scale(1.02);
	-o-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
}
.scaleUp-small{
	-moz-transform: scale(1.05);
	-webkit-transform: scale(1.5);
	-o-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}
.scaleUp-default {
	-moz-transform: scale(1.08);
	-webkit-transform: scale(1.08);
	-o-transform: scale(1.08);
	-ms-transform: scale(1.08);
	transform: scale(1.08);
}
.scaleUp-big {
	-moz-transform: scale(1.25);
	-webkit-transform: scale(1.25);
	-o-transform: scale(1.25);
	-ms-transform: scale(1.25);
	transform: scale(1.25);
}
.scaleUp-300 {
	-moz-transform: scale(3);
	-webkit-transform: scale(3);
	-o-transform: scale(3);
	-ms-transform: scale(3);
	transform: scale(3);
}
.scaleUp-none {
	-moz-transform: none;
	-webkit-transform: none;
	-o-transform: none;
	-ms-transform: none;
	transform: none;
}

.clear {
  clear: both;
  display: block;
}

/* smilieys */
.fa-smile-o {
  color: @systemColor-ok;
}

.fa-meh-o {
  color: @systemColor-warning;
}

.fa-meh-o.notVoted {
  color: @dividerColor;
}

.fa-frown-o {
  color: @systemColor-error;
}



/* options */
/*
.fa-mail-reply {
  color: @systemColor-add;
}
.fa-copy {
  color: @textColor;
}
.fa-lock {
  color: @systemColor-error;
}

.fa-unlock {
  color: @systemColor-ok;
}
*/

.fa-trash, .fa-minus-circle {
  color: @systemColor-error;
}
.fa-times-circle, .fa-times-circle-o {
  color: @systemColor-error;
}
.fa-toggle-on {
  color: @systemColor-add;
}
.fa-toggle-off {
  color: @systemColor-error;
}

/* colors */
.color-system-ok {
  color: @systemColor-ok;
}
.color-system-ok-important {
  color: @systemColor-ok !important;
}

.color-system-notok {
  color: @systemColor-delete;
}
.color-system-notok-important {
  color: @systemColor-delete !important;
}

.color-system-warning {
  color: @systemColor-warning;
}
.color-system-warning-important {
  color: @systemColor-warning !important;
}

.color-primaryColor {
  color: @primaryColor;
}

.color-accentColor {
  color: @accentColor;
}

.color-white {
  color: #fff;
}
.color-white-important {
  color: #fff !important;
}

.color-text {
  color: @textColor;
}
.color-text-light {
  color: @textColor-light;
}

/* - */
.bgColor-system-ok {
  background-color: @systemColor-ok !important;
}

.bgColor-system-notok {
  background-color: @systemColor-delete !important;
}


.bgColor-system-warning {
  background-color: @systemColor-warning !important;
}

.bgColor-primaryColor {
  background-color: @primaryColor !important;
}

.bgColor-accentColor {
  background-color: @accentColor !important;
}

.bgColor-white {
  background-color: #fff !important;
}

.bgColor-text {
  background-color: @textColor !important
}

/* mix */
.blankList {
  margin: 0px;
  padding: 0px;
  list-style: none;
  list-style-image: none;
}

.hidden {
  display: none;
}

.hide-mobile {
  display: none;
}

.inactive {
  opacity: 0.5;
}

.error {
  color: @systemColor-error !important;
}

.maybe {
  color: @systemColor-warning !important;
}

.ok {
  color: @systemColor-ok !important;
}

.fullsize-img {
  width: 100%;
  margin-bottom: -5px;
}

.boxSizing {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.defaultCta {
	display: inline-block;
	padding: 3px 7px;
	border-radius: 7px;
	border: 1px solid @primaryColor;
	color: @primaryColor;
}

/* Fonts */
.font-helvetica {
	font-family: Helvetica, Arial, sans-serif;
}
.font-arial {
	font-family: Arial, Helvetica, sans-serif;
}
.font-georgia {
	font-family: Georgia, serif;
}
.font-impact {
	font-family: Impact, Charcoal, sans-serif;
}
.font-trebuchet {
	font-family: 'Trebuchet MS', Helvetica, sans-serif;
}
.font-comicSans {
	font-family: 'Comic Sans MS', cursive, sans-serif;
}
.font-lucidaConsole {
	font-family: 'Lucida Console', Monaco, monospace;
}
.font-courier {
	font-family: Courier New, Courier, serif;
}



.positionRelative {
	position: relative;
}
