/* ######################################################## */
/* gastMenge-choices */
/* ######################################################## */
.visitorCount-choice-hint {
	width: 100%;
	margin: 20px 0px;
}
.visitorCount-choice-hint .icon {
	font-size: 125px;
	line-height: 125px;
	color: @primaryColor;
	text-align: center;
	margin-bottom: 20px;
}
.visitorCount-choice-hint .text {
	text-align: center;
	color: @textColor-light;
	font-size: 15px;
}

.visitorCount-choice-hint h3 {
	text-align: center;
	color: @primaryColor;
	font-size: 22px;
	line-height: 24px;
	.font-special;
	font-weight: 400;
	margin: 0px;
	padding: 0px 20px;
	margin-bottom: -20px;
	padding-top: 10px;
}


.gastMenge-choices {
	display: flex;
	flex-wrap: wrap;
	width: ~"calc(100% + 10px)";
	margin: 0px -5px;
}
.gastMenge-choices .choice {
	width: ~"calc(50% - 10px)";
	margin: 5px;
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	.boxSizing;
	.boxShaddow-big-bright;
	color: @primaryColor;
	.transition-quick;
	border: 1px solid @dividerColor;
}
	.gastMenge-choices .choice.size-small {
		width: ~"calc(33.33% - 10px)";
		.boxSizing;
		box-shadow: none !important;
	}
.gastMenge-choices .choice .icon {
	font-size: 18px;
	line-height: 30px;
	display: inline-block;
	padding-right: 10px;
	
	@media (max-width: 430px) {
		display: block;
		text-align: center;
		padding: 0px;
		padding-bottom: 5px;
		line-height: 22px;
		font-size: 22px;
	}
}
.gastMenge-choices .choice .info {
	font-size: 15px;
	line-height: 30px;
	
	@media (max-width: 430px) {
		display: block;
		text-align: center;
		font-size: 14px;
		line-height: 14px;
	}
}

	.gastMenge-choices .choice:hover, .gastMenge-choices .choice.active {
		background-color: @primaryColor;
		color: @primaryColor-text;
		.boxShaddow-none;
		.transition-quick;
		border-color: @primaryColor;
	}

/* ######################################################## */
/* ERROR Messages */
/* ######################################################## */
.error-msg-ref {
	font-size: 12px;
	color: @textColor-light;
	margin-top: 30px;
}



/* ######################################################## */
/* Eventdetails Register // Modale Dialofe */
/* ######################################################## */

body.md-default-theme, body, html.md-default-theme, html {
	background-color: #fff !important;
}

/* modal header */
.md-accent {
	.font-special;
}

/* inline-dialog */
.inline-dialog {
	color: @textColor;
	.font-main;
	font-size: 15px;
	line-height: 18px;

}
.inline-dialog.more-space {
	padding: 10px;
}
.inline-dialog h1 {
	.font-special;
	color: @primaryColor;
	font-weight: 400;
	font-size: 36px;
	line-height: 38px;
	margin: 0px;
	padding: 0px;
	padding-bottom: 10px;
}
.inline-dialog h1.error-headline {
	color: @systemColor-error;
	font-size: 28px;
	line-height: 30px;
}
.inline-dialog h2 {
	.font-special;
	color: @primaryColor;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
	padding-bottom: 10px;
}

.callToActionButton button {
	font-size: 20px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: @accentColor;
	.font-main;
}
button.callToActionButton {
	font-size: 20px;
	padding-left: 20px;
	padding-right: 20px;
	.font-main;
	margin: 20px 15px;
	line-height: 50px;
	height: auto;
	.boxSizing;
	width: ~"calc(100% - 30px)";
	background-color: @accentColor;
}
.link-callToActionButton {
	display: block;
	font-size: 18px;
	padding-left: 20px;
	padding-right: 20px;
	.font-main;
	margin: 10px 15px 20px 15px;
	line-height: 40px;
	height: auto;
	.boxSizing;
	width: ~"calc(100% - 30px)";
	background-color: @accentColor-text;
	border: 1px solid @accentColor;
	color: @accentColor;
	color: #fff;
	border-radius: 3px;
	text-align: center;
}



.frame-registerButton {
	font-size: 20px;
	display: block;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	.font-main;
	margin: 30px auto 0px auto;
	line-height: 50px;
	height: auto;
	.boxSizing;
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 7px;
	.boxShaddow-small;
	border: none !important;
	max-width: 650px;
	
	@media (max-width: 680px) {
		margin-left: 15px;
		margin-right: 15px;
		width: ~"calc(100% - 30px)";
	}
}
.frame-registerButton.smaller {
	font-size: 20px;
	line-height: 50px;
	display: block;
	width: ~"calc(100% - 20px)" !important;
	margin: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 7px;
	.boxShaddow-big-bright-bottomFocused-small;
	max-width: 100%;
	text-align: center;
	text-align: center !important;
	
	@media (max-width: 680px) {
		margin: 10px;
		width: ~"calc(100% - 20px)" !important;
	}
}

.frame-registerButton.smaller.notImportant {
	font-size: 16px;
	line-height: 40px;
	margin-bottom: 20px !important;
	.boxShaddow-none;
	border: 1px solid @accentColor !important;
	color: @accentColor;
	background-color: #fff;
	.boxSizing;
}




.frame-declineButton {
	display: block;
	width: 100%;
	font-size: 18px;
	padding-left: 20px;
	padding-right: 20px;
	.font-main;
	margin: 10px auto 0px auto;
	line-height: 50px;
	height: auto;
	.boxSizing;
	background-color: @accentColor-text;
	border: 1px solid @accentColor;
	color: @accentColor;
	border-radius: 7px;
	text-align: center;
	margin-bottom: 40px !important;
	max-width: 650px;
	@media (max-width: 680px) {
		margin-left: 15px;
		margin-right: 15px;
		width: ~"calc(100% - 30px)";
	}
}





.link-callToActionButton.specialMarginBottom {
	margin-bottom: 60px !important;
}

.inline-dialog .miniCta {
	border-radius: 7px;
	background-color: @primaryColor;
	line-height: 50px;
	padding: 0px 10px;
	color: #fff;
	font-size: 18px;
	display: inline-block;
	margin-top: 20px;

	.dropShaddow-small;
}

.info-message .miniCta-new {
	border-radius: 7px;
	background-color: @primaryColor;
	line-height: 50px;
	padding: 0px 10px;
	color: @primaryColor-text;
	font-size: 18px;
	display: inline-block;
	margin-top: 20px;
	display: block;
	width: 100%;
	.boxSizing;
	text-align: center;

	.dropShaddow-small;
}
.info-message .miniCta-new.notImportant {
	background-color: @primaryColor-text;
	color: @primaryColor;
	border: 1px solid @primaryColor;
}
	.inline-dialog .miniCta-new.disabled {
		pointer-events: none;
	}


/* agreements-container */
.agreements-container {}

.agreements-container .aggrement-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 10px;
}
.agreements-container .aggrement-item .switcher {
	margin-right: 10px;
}
.agreements-container .aggrement-item .switcher md-switch.md-default-theme.md-checked .md-thumb,
.agreements-container .aggrement-item .switcher md-switch.md-checked .md-thumb {
	background-color: @primaryColor;
}
.agreements-container .aggrement-item .switcher md-switch.md-default-theme.md-checked .md-ink-ripple,
.agreements-container .aggrement-item .switcher md-switch.md-checked .md-ink-ripple {
	color: @primaryColor;
}
.agreements-container .aggrement-item .switcher md-switch.md-default-theme.md-checked .md-bar,
.agreements-container .aggrement-item .switcher md-switch.md-checked .md-bar {
	background-color: fade(@primaryColor,50%);
}
.agreements-container .aggrement-item .switcher md-switch {
	margin: 0px;
}
.agreements-container .aggrement-item .text {
	font-size: 14px;
	line-height: 16px;
	.font-main;
	white-space: normal;
	word-wrap: break-word;
}
.agreements-container .error-msg {
	display: block;
	width: 100%;
	color: @systemColor-error;
	font-size: 12px;
	line-height: 14px;
	padding-top: 5px;
	padding-bottom: 15px;
}
md-input-container {
	.boxSizing;
	width: 100%;
}
md-select.md-default-theme .md-select-value, md-select .md-select-value {
	border-bottom-color: @textColor-light;
}
.md-datepicker-input {
	max-width: 100% !important;
}
.label-special {
	color: @textColor;
	font-size: 13px;
	line-height: 13px;
	background-color: @dividerColor;
	padding: 5px 10px;
	margin-bottom: 5px;
	display: block;
	width: 100%;
	.boxSizing;
}
md-checkbox {
	color: @textColor;
}
/* ######################################################## */
/* Field grid */
/* ######################################################## */


md-dialog-content.register-dialog-content {
	//padding: 15px;
	//padding-bottom: 50px;

	@media (max-width: 500px) {
		padding: 5px;
		padding-bottom: 50px;
	}
}

.register-form-fields-container {
	display: flex;
	flex-wrap: wrap;
	.boxSizing;
	/* width: 100%; */

	padding: 15px;
	//padding-bottom: 50px;
}

.register-form-fields-container-agreements {
	padding: 15px;
	padding-bottom: 50px;

	display: block;
	.boxSizing;
	
	@media (max-width: 650px) {
		padding-left: 5px;
		padding-right: 5px;
	}
}

md-dialog .register-form-fields-container {
	/* max-width: 1160px; */
	
	@media (max-width: 650px) {
		padding-left: 0px;
		padding-right: 0px;
	}
}

md-dialog-content {
	display: block;
}

.register-form-fields-container > .field {
	margin: 10px 5px 0px 5px;
	.boxSizing;
}
	.register-form-fields-container > .field.hasSubField {
		display: flex;
		flex-wrap: wrap;
		position: relative;
	}

	.register-form-fields-container > .field.hasSubField .sub-width-50  {
		margin: 10px 5px 0px 5px;
		width: ~"calc(50% - 10px)";

		@media (max-width: 500px) {
			width: ~"calc(100% - 10px)";
		}
	}
	.register-form-fields-container > .field.hasSubField .sub-width-100  {
		width: ~"calc(100% - 10px)";
		margin: 10px 5px 0px 5px;
	}


.register-form-fields-container > .field.field-size-50 {
	width: ~"calc(50% - 10px)";

	@media (max-width: 500px) {
		width: ~"calc(100% - 10px)";
	}
}
.register-form-fields-container > .field.field-size-100 {
	width: ~"calc(100% - 10px)" !important;

	@media (max-width: 500px) {

	}
}

.create-account-info {
	border-radius: 10px;
	background-color: #fff;
	margin-bottom: 25px !important;
	padding-bottom: 20px;
	margin-top: 20px;
	border: 1px dashed darken(@dividerColor,15%);
	margin-left: 10px;
	.boxSizing;
}
.create-account-info ul.specialPasswordInfo {
	margin-top: -10px;
}
.create-account-info p {
	color: @textColor;
	margin: 0px;
	padding: 0px;
	font-size: 14px;
	line-height: 16px;
	padding: 5px;
}

/* ######################################################## */
/* begleitpersonen Container */
/* ######################################################## */
.begleitperson-container {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 20px;
	.boxShaddow-big-bright;
	border: 1px solid @textColor-light;
	border-radius: 10px;
	.boxSizing;
	padding: 10px;
	position: relative;
	background-color: #fff;
}
.begleitperson-container .header {
	position: relative;
	color: @textColor-light;
	font-size: 12px;
	line-height: 30px;
	text-align: left;
	padding: 0px;
	margin: 0px;
}
	.checkin-subinfo .begleitperson-container .header {
		margin-top: -5px;
	}
.begleitperson-container .header span {
	color: @primaryColor;
	text-transform: uppercase;
}
.begleitperson-container .header.subHeader {
	margin-bottom: -15px;
	width: 100%;
}
.begleitperson-container .header.subHeader span {
	color: @textColor;
}
.begleitperson-container .header .option {
	position: absolute;
	display: block;
	right: 0px;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	top: -2px;
}
	.checkin-subinfo .begleitperson-container .header .option {
		top: 7px;
	}
.begleitperson-container .mainInfo {
	display: flex;
	flex-wrap: wrap;
}
.begleitperson-container .subField:last-child {
	padding-left: 0px !important;
}

.begleitperson-container .mainInfo .inputItem {
	text-align: left;
	width: 50%;
}

/* ######################################################## */
/* Field Types */
/* ######################################################## */

/* subfieldd */
.contentField.hasSubField {
	display: flex;
	width: 100%;
	max-width: 100% !important;
	flex-wrap: wrap;
}
	.register-form-fields-container .contentField.hasSubField {
		margin: 0px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
.contentField .subField {

}
.contentField .subField.width-30 {
	width: 30%;
	.boxSizing;
}
.contentField .subField.width-70 {
	width: 70%;
	.boxSizing;
}
.contentField .subField.width-50 {
	width: 50%;
	.boxSizing;
}
.contentField .subField.width-45 {
	width: 45%;
	.boxSizing;
}
.contentField .subField.width-10 {
	width: 10%;
	.boxSizing;
}
.contentField .subField:last-child {
	padding-left: 10px;
}
.contentField .subField.width-100 {
	width: 100%;
	padding-left: 0px !important;
	.boxSizing;
}

/* Image Upload*/

.image-file-upload.single-file {

}
.image-file-upload.single-file .upload-container {
	position: relative;
	width: 100%;
	padding-top: 5px;
	.boxSizing;
	height: 30px;
	display: block;
	border-bottom: 1px solid darken(@dividerColor,15%);
}
.image-file-upload.single-file .status {
	height: 5px;
	background-color: @systemColor-ok;
	width: 0px;
}

.image-file-upload.single-file .upload-container .uploadArea {

	padding: 0px 5px;
	.boxSizing;
	line-height: 25px;
	font-size: 14px;
	color: @textColor;
	display: block;
	width: 100%;
}

.image-file-upload.single-file .upload-container .file-preview {
	display: block;
	position: absolute;
	left: 0px;
	top: -2px;
	height: 42px;
	width: 50px;
	overflow: hidden;
	z-index: 1;
	.boxShaddow-small;
	background: #fff;
}
.image-file-upload.single-file .upload-container .file-preview img {
	width: 50px;
	margin-top: -5px;
	height: auto;
}

.image-file-upload.single-file .fileDownloadIcon {
	position: absolute ;
	right: 40px;
	width: 30px;
	text-align: right;
	display: block;
	top: 15px !important;
	z-index: 3;
	line-height: 25px;
	color: @textColor-light;
}

.image-file-upload.single-file .icon-delete {
	position: absolute ;
	right: 10px;
	width: 30px;
	text-align: right;
	display: block;
	top: 15px !important;
	line-height: 25px;
}

.image-file-upload.single-file .icon-delete a {
	color: @systemColor-error;
}
.image-file-upload.single-file .icon-edit {
	position: absolute;
	left: 60px;
	top: 10px !important;
	line-height: 25px;
	font-size: 14px;
}
.image-file-upload.single-file .icon-edit a {
	color: @textColor;
}

/* ######################################################## */
/* Overrides */
/* ######################################################## */


/* DIALOG */
.md-dialog-container {
	height: 100% !important;
	position: fixed !important;
	top: 0px !important;
}

md-dialog {
	width: 80%;
	max-width: 650px;
	border-radius: 0px !important;
	.boxShaddow-dialog;
	max-height: 100%;
	
	form {
		flex: 1 1 auto;
	}

	.frame-seatingplan {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	@media (max-width: 900px) {
		width: 90%;
	}

	@media (max-width: 650px) {
		width: 100%!important;
		max-height: 100% !important;
		max-width: 100% !important;
		border-radius: 0px !important;
	}
}

	
	.insideFrame .md-dialog-container {
		height: auto !important;
		position: fixed !important;
		top: 0px !important;
		left: 0px !important;
		right: 0px !important;
		bottom: 0px !important;
		
		overflow-y: auto;
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-ms-overflow-style: none;
	}
	.insideFrame md-dialog {
	
		@media (max-width: 650px) {
			width: 100% !important;
			max-height: 100% !important;
			max-width: 100% !important;
			border-radius: 0px !important;
			position: relative  !important;
			margin-top: 10px  !important;
			margin: 10px auto !important;
		}
		
	}	


.md-datepicker-input-container {
	width: 100%;
}

.layout-margin {
	margin: 8px 0px !important;
}
.md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
	white-space: nowrap;
	max-width: 80%;
	text-overflow: ellipsis;
	overflow: hidden;
}
md-input-container .md-errors-spacer {
	min-height: 5px !important;
}


md-input-container.md-default-theme .md-input, md-input-container .md-input {
	border-bottom: 1px solid darken(@dividerColor,20%);
}


/* VERSATZ */

.eventOverviewPage-html.iFramed .bugFix-framedScrolling {
	display: block;
	height: 60px;
	width: 100%;
}
.eventOverviewPage-html.iFramed md-toolbar {
	position: absolute !important;
}
.eventOverviewPage-html.iFramed .info-message {
top: 50px !important;
position: sticky !important;
z-index: 6;
}

.eventOverviewPage-html.iFramed .md-dialog-container {
	align-items: flex-start !important;
}

/*
.eventOverviewPage-html.iFramed .register-form-fields-container.registerFrame-form {
	padding-top: 60px !important;
}

.eventOverviewPage-html.iFramed md-toolbar {
	position: absolute !important;
}
*/

/* // VERSATZ */


/* ######################################################## */
/* Intelligente Auswahlliste */
/* ######################################################## */

.addNew-button {
	display: block;
	background-color: @accentColor;
	color: @accentColor-text;
	text-align: center;
	font-size: 16px;
	line-height: 16px;
	padding: 12px 0px;
	border-radius: 7px;
	.boxShaddow-big-bright-bottomFocused-small;
	margin: 10px 0px 20px 0px;
	cursor: pointer;
}



/* ### Backend ### */
.intelligente-auswahl-backend {
	color: @textColor;
	margin-top: -20px;
	width: 100%;
}
.intelligente-auswahl-backend .switch-option.first {
	border-top: 1px dashed darken(@dividerColor,10%);
}
.intelligente-auswahl-backend .switch-option {
	padding: 10px 0px;
	font-size: 14px;
	border-bottom: 1px dashed darken(@dividerColor,10%);
}
.intelligente-auswahl-backend .switch-option input[type="number"] {
	width: 35px;
	padding: 3px 2px;
	border-radius: 5px;
	.boxSizing;
	border: 1px solid @systemColor-ok;
	background: #fff;
	color: @textColor;
	outline: none;
}
.intelligente-auswahl-backend h4 {
	color: @primaryColor;
	font-size: 16px;
	line-height: 16px;
	padding: 25px 0px 10px 0px;
	margin: 0px;
}
.intelligente-auswahl-backend > small {
	color: @textColor-light;
	display: block;
	margin-top: 20px;
}
.intelligente-auswahl-backend .small-select {
	.boxSizing;
	width: 100%;
	padding: 5px 0px;
	font-size: 12px;
	border: none;
	/*
	border-top: 1px solid @primaryColor;
	border-bottom: 1px solid @primaryColor;
	*/
	border: 1px solid @primaryColor;
	border-radius: 0px !important;
	background: #fff !important;
	color: @primaryColor;
	/* -webkit-appearance: none; */
	margin: 5px 0px;
	margin-bottom: 10px;
	outline: none;
	position: relative;
	cursor: pointer;
}
.intelligente-auswahl-backend table td, .intelligente-auswahl-backend table th {
	font-size: 14px;
	line-height: 14px;
	border-bottom: 1px solid darken(@dividerColor,10%);
	text-align: left;
	padding: 5px;
	vertical-align: top;
}
.intelligente-auswahl-backend table tr.addRow td {
	border-bottom: 5px solid darken(@dividerColor,10%);
	border-top: 4px solid darken(@dividerColor,10%);
}
.intelligente-auswahl-backend table {
	width: 100%;
}
.intelligente-auswahl-backend table th {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
}
.intelligente-auswahl-backend table th small {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: @textColor-light;
}
.intelligente-auswahl-backend table .sort {
	width: 20px;
	padding: 5px 0px;
	text-align: center;
}
.intelligente-auswahl-backend table .add {
	width: 30px;
	padding: 10px 0px 0px 0px;
	text-align: left;
}
.intelligente-auswahl-backend table .remove {
	text-align: center;
	width: 30px;
	padding: 10px 0px 0px 0px;
}
.intelligente-auswahl-backend table .sort a {
	display: block;
	color: @textColor;
}
.intelligente-auswahl-backend table .remove a {
	color: @systemColor-error;
	font-size: 18px;
	line-height: 18px;
	display: block;
	text-align: right;
}
.intelligente-auswahl-backend table .add a {
	display: block;
	text-align: right;
	font-size: 18px;
	line-height: 18px;
	color: @systemColor-ok;
}
	.intelligente-auswahl-backend table td.input-with-icon {
		padding-right: 25px;
		.boxSizing;
		position: relative;
	}
	.intelligente-auswahl-backend table td.input-with-icon .reset-icon {
		position: absolute;
		right: 5px;
		top: 50%;
		margin-top: -10px;
		font-size: 15px;
		line-height: 20px;
		display: block;
		z-index: 1;
		width: 20px;
		color: @systemColor-error;
		text-align: right;
	}
	
.intelligente-auswahl-backend table input {
	width: 100%;
	.boxSizing;
	color: @systemColor-ok;
	border: 1px solid @systemColor-ok;
	padding: 3px;
	background-color: #fff;
	border-radius: 3px;
}

.intelligente-auswahl-backend table .languages-container {
	position: relative;
	padding-left: 25px;
	margin-top: 5px;
}
.intelligente-auswahl-backend table .languages-container .lang-info {
	display: block;
	width: 20px;
	line-height: 20px;
	font-size: 10px;
	font-weight: 700;
	text-align: center;
	height: 20px;
	overflow: hidden;
	border-radius: 100%;
	background-color: @dividerColor;
	color: @textColor-light !important;
	
	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -10px;
}
.intelligente-auswahl-backend table input.languages {
	border-style: dashed;
}

.intelligente-auswahl-backend table select {
	.boxSizing;
	color: @systemColor-ok;
	border: 1px solid @systemColor-ok;
	padding: 3px;
	background-color: #fff;
	border-radius: 3px;
}
.intelligente-auswahl-backend table .edit-option input, .intelligente-auswahl-backend table .edit-option select {
	border-color: @textColor-light;
	color: @textColor;
}
.intelligente-auswahl-backend table .value {
	padding: 0px 4px;
	display: block;
}

.intelligente-auswahl-backend table small.statistik {
	font-size: 12px;
	line-height: 14px;
	display: inline-block;
	margin-left: 5px;
}
.intelligente-auswahl-backend table small.statistik .fa-check-circle {
	color: @textColor !important;
}

/* ### Anmeldeseite + Gast bearbeiten ### */
.intelligente-auswahl-gast {
	color: @textColor;
	margin: 10px 0px 20px 0px;

    md-select.md-default-theme[disabled] .md-select-value, md-select[disabled] .md-select-value {
		background-image: none !important;
	}
}
.intelligente-auswahl-gast .label {
	font-size: 14px;
	line-height: 14px;
	padding-bottom: 10px;
	display: block;
}


.intelligente-auswahl-gast .intelli-tabs {
		display: flex;
		margin: 5px 0px 0px 0px;
}
.intelligente-auswahl-gast .intelli-tabs a {
		color: @textColor;
		font-size: 12px;
		line-height: 14px;
		padding: 10px;
		padding-bottom: 5px;
		padding-top: 5px;
		text-align: center;
		background-color: @dividerColor;
		margin-right: 5px;
		cursor: pointer;
}
.intelligente-auswahl-gast .intelli-tabs a:last-child {
		margin-right: 0px;
}
.intelligente-auswahl-gast .intelli-tabs a.active {
		color: @primaryColor-text;
		background-color: @primaryColor;
}

.intelli-tabs-select {
	position: relative;
	z-index: 0;
	font-size: 12px;
	color: @primaryColor-text;
}
.intelli-tabs-select select {
	-webkit-appearance: none;
	border: none;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 10px;
	border-radius: 0px;
	width: 100%;
	font-size: 12px;
	font-size: 16px;
	.boxShaddow-small;
	outline: none;	
	cursor: pointer;
}
.intelli-tabs-select:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 7px 7px 0;
	border-color: @primaryColor transparent;
	display: block;
	width: 0;
	z-index: 1;
	bottom: -7px;
	left: 23px;
}

.intelli-tabs-select .icon {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -9px;
	display: block;
	z-index: 2;
	font-size: 14px;
	line-height: 18px;
	
	
}

.intelligente-auswahl-gast ul {
	.blankList;
	border-top: 1px solid @textColor-light;
}
	.intelligente-auswahl-gast ul.hasTabs {
		border-top-color: @primaryColor;
		border-width: 2px 0px 0px 0px;
	}
	.intelligente-auswahl-gast ul.hasSelect {
		border-top: none;
	}

.intelligente-auswahl-gast ul li {
	border-bottom: 1px solid @textColor-light;
	padding: 10px 0px;

	display: flex;
}
.intelligente-auswahl-gast ul li .input-field-label {
	//display: inline-block;
	margin-left: 5px;
	font-size: 15px;
	line-height: 20px;
	//padding-left: 25px;
	padding-left: 90px;
	position: relative;

	display: flex;
	label {
		line-height: inherit;
		display: flex;
		align-items: center;
	}
}

.intelligente-auswahl-gast ul li .input-field-label.noLimit {
	padding-left: 2px;
}

.intelligente-auswahl-gast ul li.outOfStock .input-field-label {
	color: @textColor-light;

}
.intelligente-auswahl-gast ul li .input-field-label .verfuegbarkeit {
	/*color: #fff;
	display: inline-block;
	line-height: 18px;
	height: 20px;
	font-size: 10px;
	overflow: hidden;
	width: 20px;
	.boxSizing;
	text-align: center;
	background-color: #fff;
	border: 1px solid @systemColor-ok;
	color: @textColor;
	border-radius: 44px;
	text-transform: uppercase;
	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -10px;*/
	display: block;
	line-height: 14px;
	height: 16px;
	font-size: 10px;
	overflow: hidden;
	width: 80px;
	.boxSizing;
	text-align: center;
	background-color: #fff;
	border: 1px solid @systemColor-ok;
	color: @textColor;
	border-radius: 44px;
	text-transform: uppercase;
	position: absolute;
	left: 0px;
	top: 50%;
	margin-top: -8px;
}
.intelligente-auswahl-gast ul li.outOfStock .input-field-label .verfuegbarkeit {
	border-color: @systemColor-error !important;
	color: @systemColor-error;
}

.intelligente-auswahl-gast ul li .input-field select {
	border: 1px solid @textColor-light;
	background-color: #fff;
	color: @textColor-light;
	min-width: 55px;
	line-height: auto;
}

.intelligente-auswahl-gast ul li .input-field md-select {
	border: 1px solid @textColor-light;
	background-color: #fff;
	color: @textColor-light;
	max-width: 65px;
	line-height: auto;
	display: inline-block;
	margin: 0px;
	border-radius: 5px;
}
.intelligente-auswahl-gast ul li .input-field md-select .md-select-value {
	border: none;
	padding-left: 5px;
	.boxSizing;
}

/* ######################################################## */
/* Switcher */
/* ######################################################## */

md-switch .md-thumb:before {
	background-color: @systemColor-error;
}

md-switch.md-checked .md-thumb:before {
	background-color: @systemColor-ok;
}

md-switch.md-default-theme .md-bar, md-switch .md-bar {
	border: 1px solid @systemColor-error;
	box-sizing: border-box;
	background-color: #fff;
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
	border-color: @systemColor-ok;
	background-color: @systemColor-ok !important;
}


.md-dialog-container {
	backdrop-filter: blur(7px);
}
.mainInfo .subField md-autocomplete {
	min-width: auto !important;
}


/* ######################################################## */
/* font-awesome */
/* ######################################################## */
.animate-beat {
  animation: animate-beat 5s ease infinite;
}
@keyframes animate-beat {
  0% {
    transform:scale(1);
  }
  5% {
    transform:scale(1.25);
  }
  20% {
    transform:scale(1);
  }
  30% {
    transform:scale(1);
  }
  35% {
    transform:scale(1.25);
  }
  50% {
    transform:scale(1);
  }
  55% {
    transform:scale(1.25);
  }
  70% {
    transform:scale(1);
  }
}


/* ######################################################## */
/* font-awesome */
/* ######################################################## */
.rhichtext-container div.ng-pristine.ng-valid.ta-bind.ng-not-empty, .rhichtext-container .ta-root .ta-text-editor .ta-bind, .rhichtext-textarea, .rhichtext-container textarea {
	max-height: 350px !important;
	overflow: hidden !important;
	overflow-y: scroll !important;
	border: 1px solid #cdcdcd !important;
	padding: 10px 10px 20px 10px!important;
	border-radius: 5px !important;
	.boxShaddow-inside-bright;
	margin-top: 7px;
}



/* ######################################################## */
/* Komplett umrundete Inputs */
/* ######################################################## */
._md-datepicker-has-triangle-icon {
	padding-right: 0px;
	margin-right: 0px;
}

.md-datepicker-triangle-button.md-button.md-icon-button {
	right: 7px;
}

@input-borderRadius: 5px;

.inputStyle {
	.boxSizing;
	border: 1px solid darken(@dividerColor,15%);
	/*border-radius: @input-borderRadius;*/
	padding: 5px 0px;
	margin-top: 5px;
	font-size: 15px;
	
	border-width: 0px 0px 1px 0px !important;
	border-radius: 0px;
	color: @textColor;
	
	background-color: fade(@dividerColor,65%);
	color: @primaryColor;
	
	height: auto;

	min-height: 30px;
}

/* Schatten
.inputStyle {
	border-width: 0px 0px 1px 0px;
	.boxShaddow-inside-bright-inputs;
	border-radius: 0px;
}
*/

/* Hack not required */
.framedInputs md-input-container.md-default-theme.md-input-invalid.field-not-required .md-input,
.framedInputs md-input-container.md-input-invalid.field-not-required .md-input {
	border-color: darken(@dividerColor,15%) !important;
}

md-input-container.md-default-theme.md-input-invalid.field-not-required .md-char-counter,
md-input-container.md-input-invalid.field-not-required .md-char-counter,
md-input-container.md-default-theme.md-input-invalid.field-not-required .md-input-message-animation,
md-input-container.md-input-invalid.field-not-required .md-input-message-animation,
md-input-container.md-default-theme.md-input-invalid.field-not-required label,
md-input-container.md-input-invalid.field-not-required label {
	color: @textColor;
}
/* // Hack not required */

.framedInputs md-input-container.md-default-theme .md-input,
.framedInputs md-input-container .md-input {
    
		.inputStyle;
		
}

.framedInputs [disabled] md-input-container.md-default-theme .md-input, 
.framedInputs [disabled] md-input-container .md-input,
.framedInputs md-input-container.md-default-theme .md-input[disabled],
.framedInputs md-input-container .md-input[disabled],
.framedInputs md-select.md-default-theme[disabled] .md-select-value,
.framedInputs md-select[disabled] .md-select-value {
	background-image: none;
	border: none;
	color: @textColor-dark;
	padding: 0px;
	
	.boxSizing;
	border: 1px dashed darken(@dividerColor,15%);
	padding: 5px 0px;
	margin-top: 5px;
	font-size: 15px;
	border-width: 0px 0px 1px 0px !important;
}

.framedInputs md-input-container.md-input-focused .md-input {
	border-width: 1px;
	padding: 5px;
}

.framedInputs .image-file-upload.single-file .upload-container {
	
	.inputStyle;
	min-height: 38px !important;
}

.framedInputs .register-form-fields-container>.field {
	margin-top: 0px;
}
.framedInputs .register-form-fields-container>.field.framedWithMargin {
	margin-top: 10px;
}

.framedInputs md-select.md-default-theme .md-select-value,
.framedInputs md-select .md-select-value {

	.inputStyle;

}

.framedInputs md-select:not([disabled]):focus .md-select-value {
    
		.inputStyle;
}

.framedInputs .image-file-upload.single-file .fileDownloadIcon {
	top: 10px;
}
.framedInputs .image-file-upload.single-file .icon-delete {
	top: 10px;
}

.framedInputs md-input-container.md-input-focused .md-input,
.framedInputs md-input-container.md-input-resized .md-input,
.framedInputs md-input-container .md-input.ng-invalid.ng-dirty {
	border-width: 1px;
	padding: 5px 0px;
}

.framedInputs md-input-container.md-default-theme.md-input-invalid .md-input,
.framedInputs md-input-container.md-input-invalid .md-input {
	border-color: @systemColor-error !important;
	color: @systemColor-error !important;
}

.framedInputs .md-input-has-value input,
.framedInputs .md-input-has-value textarea,
.framedInputs .md-input-has-value md-select-value {
	background-color: #fff !important;
}

.framedInputs .label-special {
	background-color: @primaryColor;
	color: @primaryColor-text;
	margin-top: 15px;
	margin-bottom: 15x;
}
.framedInputs .label-special.more-text {
	background-color: transparent !important;
	color: @primaryColor !important;
	margin-top: 15px;
	margin-bottom: 15x;
	font-size: 13px;
	line-height: 16px;
	padding: 0px 0px 5px 0px !important;
}

/* Modal */
.insideApp md-backdrop.md-opaque.md-default-theme,
md-backdrop.md-opaque {
	background: @modalBG !important;
}
md-backdrop.md-opaque {
	opacity: 1 !important;
	backdrop-filter: blur(7px) !important;
	-webkit-backdrop-filter: blur(10px) !important;
}
.framedInputs label {
	color: @textColor;
}

.framedInputs md-autocomplete .md-show-clear-button button {
	position: absolute;
	right: 0px;
	bottom: 6px;
}


/* call To Action frame */
.registerCallToAction-scroll-wrapper {
	min-height: 0px !important;
	position: relative;
	overflow: visible;
}
.registerCallToAction-scroll {
	display: block;
	position: absolute;
	top: -20px;
	left: 50%;
	margin-left: -15px;
	height: 20px !important;
	width: 30px;
	font-size: 14px;
	background-color: @primaryColor !important;
	color: @primaryColor-text;
	text-align: center;
	line-height: 22px;
	overflow: hidden;
	padding: 0px;
	.boxShaddow-none;
	border-radius: 15px 15px 0px 0px !important;
	margin: 0px auto 0px auto;
}

.registerCallToAction-frame {
	width: 100%;
	.boxSizing;
	
	@media (min-width: 681px) {
		padding: 0px 20px 20px 20px;
	}

}

.registerCallToAction {
	width: 100%;
	.boxSizing;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	margin-top: -20px;
	padding-left: 5px;
	padding-right: 5px;
}
.registerCallToAction-button {
	width: 100%;
	max-width: 400px;
	.boxSizing;
	margin: 0px auto 80px auto;
	padding: 0px;
	font-size: 26px;
	line-height: 60px;
	border-radius: 7px;
	background-color: @accentColor;
	color: @accentColor-text;
	border: none;
	.boxShaddow-small-bright;
	.transition-default;
}
.registerCallToAction-button:hover {
	.boxShaddow-big;
	.scaleUp-default;
	.transition-default;
}

.register-form-fields-container.registerFrame-form {
	margin: 0px 10px !important;
	padding: 0px !important;
	padding-top: 10px !important;
	
	@media (max-width: 650px) {
		margin: 0px 10px !important;
		padding: 0px !important;
		padding-top: 10px !important;
	}
}
.register-form-fields-container.registerFrame-form.register-form-fields-container-agreements.registerFrame-form {
	margin: 0px 15px !important;
	padding: 0px !important;
	@media (max-width: 650px) {
		margin: 0px 15px !important;
		padding: 0px !important;
	}
}

/* ######################################################## */
/* DEPUTY MAIL */
/* ######################################################## */
.deputy_mail {
	border: 1px solid @primaryColor;
	padding: 5px 10px;
	border-radius: 10px;
	margin-bottom: 20px;
	background-color: #fff;
	.boxShaddow-small-bright;
}
.deputy_mail md-switch .md-label {
	color: @textColor;
	line-height: 16px;
	white-space: normal;
	@media (max-width: 650px) {
		padding-left: 15px;
	}
}
.deputy_mail .legende {
	border-top: 1px solid @dividerColor;
	padding-top: 10px;
	padding-bottom: 5px;
	color: @primaryColor;
	display: block;
	position: relative;
	padding-left: 43px;
}
.deputy_mail .legende .icon {
	position: absolute;
	left: 0px;
	width: 38px;
	text-align: center;
	top: 50%;
	margin-top: -7px;
	line-height: 14px;
	font-size: 15px;
}


/* ######################################################## */
/* SOCIAL PART */
/* ######################################################## */
#public_profil {
	border-radius: 10px;
	.boxShaddow-big-bright;
	background-color: #fff;
	margin: 5px;
	margin-top: 15px;
	width: ~"calc(100% - 10px)";
	display: flex;
	flex-wrap: wrap;
	.boxSizing;
	padding: 0px 5px;
}
#public_profil md-switch {
	border: 1px solid @dividerColor;
	padding: 10px 5px;
	font-size: 13px;
	line-height: 13px;
	color: @textColor;
	margin: 0px 0px 10px 0px !important;
	border-radius: 7px;
	height: auto !important;
}
#public_profil .public-info {
	width: 100%;
	font-size: 12px;
	line-height: 14px;
	color: @textColor-light;
	padding: 5px;
	padding-bottom: 15px;
	padding-top: 0px;
}
#public_profil .field {
	margin: 10px 5px 0px 5px;
	.boxSizing;
}
#public_profil .field.field-size-50 {
	width: ~"calc(50% - 10px)";

	@media (max-width: 500px) {
		width: ~"calc(100% - 10px)";
	}
}
#public_profil .field.field-size-100 {
	width: ~"calc(100% - 10px)";
}