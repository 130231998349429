.fileupload a {
  display: block;
  color: @primaryColor;
  margin: 0;
}

.fileupload .upload-dragover {
  background-color: fade(@primaryColor,35%);
	backdrop-filter: blur(7px);

  img {
    opacity: 0.2;
  }

  .uploadArea {
    opacity: 0.2;
  }
}

