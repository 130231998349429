/* ######################################################## */
/* iFRAME FIX */
/* ######################################################## */
/*.iFramed md-dialog.md-content-overflow {
	position: sticky !important;
	top: auto !important;
	bottom: auto !important;
	z-index: 1 !important;

	@media (min-width: 650px) {
		margin-bottom: 40px;
	}

}

.iFramed div.md-dialog-container {
	height: auto !important;
	position: fixed !important;

	@media (min-width: 650px) {
		top: 0px !important;
	}

}
*/


/*
Tipps

md-input:  floating-label-always
*/
md-dialog-content iframe {
	border: none !important;
}
md-toolbar.md-error-theme, .md-button.md-error-theme.md-primary.md-raised:not([disabled]):hover, .md-button.md-error-theme.md-primary.md-raised {
	background-color: @primaryColor !important;
}

md-input-container .md-placeholder, md-input-container label:not(.md-no-float):not(.md-container-ignore) {
	overflow: visible !important;
	text-overflow: visible;
	white-space: normal !important;
}


body.md-default-theme, body, html.md-default-theme, html {
	background-color: #fff !important;
}

md-tooltip {
	font-size: 13px !important;
	line-height: 15px !important;
	background: #fff !important;
	color: @primaryColor-dark !important;
	border: 2px solid @primaryColor-dark;
	.boxShaddow-big;
	padding: 10px;
	//opacity: 99% !important;
	.boxSizing;
}
md-input-container.md-default-theme .md-input, md-input-container .md-input {
	/* color: @textColor !important; */
}
md-input-container {
	margin-bottom: 0px !important;
}
label.ng-binding {
	color: @primaryColor-dark;
}
[disabled] md-input-container.md-default-theme .md-input, [disabled] md-input-container .md-input, md-input-container.md-default-theme .md-input[disabled], md-input-container .md-input[disabled] {
	color: @primaryColor-dark !important;
}

.ta-root .ta-text-editor .ta-bind {
	padding: 10px 0px !important;
}






/* Texteditor angleichen */
.ta-root .ta-text-editor .ta-bind p, .ta-root .ta-text-editor .ta-bind p a, .ta-root .ta-text-editor .ta-bind ul {
	color: @textColor !important;
	font-size: 15px !important;
	line-height: 17px !important;
}
.ta-root .ta-text-editor .ta-bind p a {
	color: @primaryColor !important;
}
.ta-root.disabled .ta-text-editor .ta-bind p {
	color: @textColor !important;
}
/* - */
.ta-root.disabled .ta-text-editor .ta-bind p,
.ta-root .ta-text-editor .ta-bind p {
	padding-bottom: 10px !important;
}
.ta-root.disabled .ta-text-editor .ta-bind p:last-child,
.ta-root .ta-text-editor .ta-bind p:last-child {
	padding-bottom: 0px !important;
}

.ta-root.disabled .ta-text-editor .ta-bind H2,
.ta-root .ta-text-editor .ta-bind H2 {
	font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
	font-size: 28px;
	line-height: 30px;
	font-weight: 400;
	color: @primaryColor;
	padding: 0px;
	margin: 11px 0px 22px 0px;
}

.ta-root.disabled .ta-text-editor .ta-bind H3,
.ta-root .ta-text-editor .ta-bind H3 {
	color: @primaryColor;
	font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
	font-size: 24px;
	line-height: 26px;
	font-weight: 400;
	padding: 0px;
	margin: 11px 0px 22px 0px;
}
.ta-root.disabled .ta-text-editor .ta-bind H5,
.ta-root .ta-text-editor .ta-bind H5,
.ta-root.disabled .ta-text-editor .ta-bind H6,
.ta-root .ta-text-editor .ta-bind H6 {
	font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
	font-size: 18px;
	line-height: 20px;
	font-weight: 400;
	color: @primaryColor;
	padding: 0px;
	margin: 5px 0px 10px 0px;
}


.ta-root.disabled .ta-text-editor .ta-bind ul,
.ta-root .ta-text-editor .ta-bind ul {
	.blankList;
	padding: 5px 10px;
	border-left: 7px solid @dividerColor;
	margin: 10px 0px 20px 10px;
}
.ta-root.disabled .ta-text-editor .ta-bind ul li,
.ta-root .ta-text-editor .ta-bind ul li {
	margin: 10px 5px;
	padding-left: 20px;
	position: relative;
	font-size: 14px;
	line-height: 16px;
}
.ta-root.disabled .ta-text-editor .ta-bind ul li:before,
.ta-root .ta-text-editor .ta-bind ul li:before {
	position: absolute;
	top: 0px;
	left: 0px;
	content: '»';
	display: block;
	z-index: 1;
}



/* Texteditor HTML */
text-angular textarea {
	min-height: 350px !important;
}




.md-button.button-with-border {
	border: 1px solid @primaryColor !important;
	.boxSizing;
	color: @primaryColor !important;
}
.md-button.button-with-border[disabled] {
	border-color: fade(@textColor-light,50%) !important;
	color: fade(@textColor-light,50%) !important;
}

.moment-picker {
  z-index: 10001;
}

.cpDateTimePicker {
  display: flex;
  flex-direction: row;

  &--date {

  }
  &--time {
	margin-left: 5px;
  }
}

div.md-datepicker-input-container {
	width: 100%;
	.boxSizing;
}


md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
	background-color: @primaryColor;
}
md-switch.md-default-theme.md-checked .md-ink-ripple, md-switch.md-checked .md-ink-ripple {
	background-color: @primaryColor;
}
md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
	background-color: fade(@primaryColor,50%);
}

/*div.field-spacer {
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background-color: #e4eaea;
}*/

md-chips.md-default-theme md-chip, md-chips md-chip {
	background-color: @primaryColor !important;
	color: @primaryColor-text !important;
	font-weight: 400 !important;
	font-size: 16px !important;
}
md-chips.md-default-theme md-chip md-icon, md-chips md-chip md-icon {
	color: @primaryColor-text !important;
}
.tag-chip {
  display: inline-block;
  white-space: nowrap;
  padding: 5px 15px;
  line-height: 1em;
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
  position: relative;
	margin: 10px 10px 0px 0px;
  background-color: @dividerColor;
	.boxShaddow-small;
  border-radius: 44px;
  color: @textColor;

  /*
	&:after {
	display: block;
	position: absolute;
	color: #ff4058;
	font-family: Font Awesome 5 Free;
	content: '\f055';
	z-index: 2;
	right: 4px;
	top: 4px;
	height: 16px;
	width: 16px;
	-webkit-border-radius: 100%;
	border-radius: 100%;
	font-size: 14px;
	line-height: 16px;
	text-align: right;
  }
	*/
}

.layout-small-padding {
  div {
	padding: 0 5px;
  }
}

md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
	background-color: @primaryColor;
}

.md-button.md-default-theme.md-primary.md-fab:not([disabled]).md-focused, .md-button.md-primary.md-fab:not([disabled]).md-focused, .md-button.md-default-theme.md-primary.md-fab:not([disabled]):hover, .md-button.md-primary.md-fab:not([disabled]):hover, .md-button.md-default-theme.md-primary.md-raised:not([disabled]).md-focused, .md-button.md-primary.md-raised:not([disabled]).md-focused, .md-button.md-default-theme.md-primary.md-raised:not([disabled]):hover, .md-button.md-primary.md-raised:not([disabled]):hover {
	background-color: @accentColor;
	color: @accentColor-text;
}
.md-button.md-raised[disabled] {
	opacity: 0.5;
	color: @primaryColor-text;
}

.md-button.md-default-theme.md-primary.md-fab, .md-button.md-primary.md-fab, .md-button.md-default-theme.md-primary.md-raised, .md-button.md-primary.md-raised {
	background-color: @primaryColor;
}

md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused .md-input, md-input-container:not(.md-input-invalid).md-input-focused .md-input, md-input-container.md-default-theme:not(.md-input-invalid).md-input-resized .md-input, md-input-container:not(.md-input-invalid).md-input-resized .md-input {
	border-color: @primaryColor;
}
md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused label, md-input-container:not(.md-input-invalid).md-input-focused label, md-input-container.md-default-theme:not(.md-input-invalid).md-input-focused md-icon, md-input-container:not(.md-input-invalid).md-input-focused md-icon {
	color: @primaryColor;
}
md-switch.md-default-theme.md-checked.md-primary .md-thumb, md-switch.md-checked.md-primary .md-thumb {
	background-color: @primaryColor;
}
md-switch.md-default-theme.md-checked.md-primary .md-ink-ripple, md-switch.md-checked.md-primary .md-ink-ripple {
	background-color: @primaryColor;
}
md-switch.md-default-theme.md-checked.md-primary .md-bar, md-switch.md-checked.md-primary .md-bar {
	background-color: fade(@primaryColor,75%);
}
.md-default-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator, .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
	border-color: @primaryColor;
}
.md-default-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-default-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator, .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
	background-color: @primaryColor;
}

md-chips.md-default-theme .md-chips.md-focused, md-chips .md-chips.md-focused {
	box-shadow: 0 2px @primaryColor !important;
}
.md-select-value > span:not(.md-select-icon) .md-text {
	color: @primaryColor;
}

md-checkbox.md-default-theme:not([disabled]).md-primary.md-checked .md-ink-ripple, md-checkbox:not([disabled]).md-primary.md-checked .md-ink-ripple {
	background-color: @primaryColor;
}
/* ######################################################## */
/* Overrides new */
/* ######################################################## */
.md-datepicker-input-container {
	width: 100%;
}


md-dialog-content {
	display: block;
}

.md-dialog-container {
	height: 100% !important;
	position: fixed !important;
	top: 0px !important;
	.boxSizing;
}
md-dialog {
	width: 80%;
	max-width: 850px;
	border-radius: 10px !important;
	max-height: 100%;
	overflow: hidden;
	.boxShaddow-dialog;

	/*height: 95% !important;
	max-height: 95% !important;*/

	.boxSizing;

	@media (max-width: 650px) {
		max-height: 100% !important;
		max-width: 100% !important;
		border-radius: 0px !important;
	}
}
md-dialog.biggerDialog {
	width: 90% !important;
	max-width: 1000px !important;
	border-radius: 10px !important;
	overflow: hidden;

	height: 95% !important;
	max-height: 95% !important;

	@media (max-width: 650px) {
		max-height: 100% !important;
		width: 100% !important;
		max-width: 100% !important;
		height: 100% !important;
		border-radius: 0px !important;
	}
}
	md-dialog.biggerDialog.smallHeight {
		height: auto !important;

		@media (max-width: 650px) {
			height: auto !important;
		}
	}



.layout-margin {
	margin: 8px 0px !important;
}
.md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
	white-space: nowrap;
	max-width: 80%;
	text-overflow: ellipsis;
	overflow: hidden;
}
md-input-container .md-errors-spacer {
	min-height: 5px !important;
}
md-input-container.md-default-theme .md-input, md-input-container .md-input {
	border-bottom: 1px solid darken(@dividerColor,20%);
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
	color: @primaryColor;
	background-color: @primaryColor;
}
md-tabs {
	width: 100%;
	.boxSizing;
}

md-input-container {
	.boxSizing;
	width: 100%;
}
.md-datepicker-input {
	max-width: 100% !important;
}

md-checkbox.md-default-theme:not([disabled]).md-primary.md-checked .md-icon, md-checkbox:not([disabled]).md-primary.md-checked .md-icon {
	background-color: @primaryColor;
}

/* colorpicker */

.colorpicker-reset {
		position: absolute;
		right: 0px;
		top: 50%;
		height: 30px;
		width: 30px;
		margin-top: -17px;
		z-index: 1;
		display: block;
		overflow: hidden;
		font-size: 20px;
		line-height: 30px;
		color: @systemColor-error;
		text-align: right;
}

.color-picker-wrapper .color-picker-input-wrapper {
	width: 100%;
	.boxSizing;
}
.color-picker-wrapper .color-picker-swatch:not(.input-group-addon) {
	position: absolute;
	top: 3px;
	width: 24px;
	height: 24px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 100%;
	vertical-align: middle;
	background-position: -80px 0;
	border: none;
	padding: 0;
	margin: 0;
	display: inline-block;
}

.md-dialog-container {
	backdrop-filter: blur(7px);
}

md-select:focus .md-select-value {
	border-color: @primaryColor !important;
}
md-select-menu md-content md-option[selected] {
	color: @primaryColor !important;
}

md-autocomplete.md-default-theme[disabled]:not([md-floating-label]), md-autocomplete[disabled]:not([md-floating-label]) {
	background: transparent;
}
md-autocomplete.md-default-theme, md-autocomplete {
	background: transparent;
}

.md-whiteframe-1dp, .md-whiteframe-z1 {
	.boxShaddow-none;
}

.insideApp md-backdrop.md-opaque.md-default-theme,
md-backdrop.md-opaque {
	background: @modalBG !important;
}
md-backdrop.md-opaque {
	opacity: 1 !important;
	backdrop-filter: blur(7px) !important;
	-webkit-backdrop-filter: blur(10px) !important;
}

md-tabs.md-default-theme .md-tab.md-active, md-tabs .md-tab.md-active, md-tabs.md-default-theme .md-tab.md-active md-icon, md-tabs .md-tab.md-active md-icon, md-tabs.md-default-theme .md-tab.md-focused, md-tabs .md-tab.md-focused, md-tabs.md-default-theme .md-tab.md-focused md-icon, md-tabs .md-tab.md-focused md-icon {
	color: @primaryColor;
}

.md-button.md-default-theme {
	border-radius: 7px !important;
}

/* Telefon-Formatierung */
.iti {
	width: 100% !important;
	.boxSizing;
	padding-left: 50px !important;
}
.iti--allow-dropdown input, .iti--allow-dropdown input[type=tel], .iti--allow-dropdown input[type=text], .iti--separate-dial-code input, .iti--separate-dial-code input[type=tel], .iti--separate-dial-code input[type=text] {
	padding-left: 0px !important;
}
.iti__selected-flag {
	outline: none !important;
}





/* ######################################################## */
/* angular 2022 overrides */
/* ######################################################## */
.mat-dialog-actions {
	justify-content: flex-end;
}
.mat-tab-group {
	margin-left: -24px;
	margin-right: -24px;
}

::ng-deep .mat-tab-labels {
	padding-left: 24px;
	padding-right: 24px;
}

.mat-dialog-content {
	margin-left: 0;
	margin-right: 0;
	padding-top: 24px;

	&.-containsTable {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}
}
.mat-tab-group {
	margin-left: -24px;
	margin-right: -24px;

	.cp-gui-item {
		margin: 20px !important;
	}

}


::ng-deep .mat-tab-labels {
	padding-left: 24px;
	padding-right: 24px;
}

.mat-dialog-content {
	margin-left: 0;
	margin-right: 0;
	padding-top: 24px;

	&.-containsTable {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

/*.mat-dialog-content {
	max-height: ~"calc(100vh - 90px)" !important;
	max-height: auto !important;
}

.cdk-global-overlay-wrapper > .cdk-overlay-pane {
	position: fixed;
	top: 0px !important;
	bottom: 0px !important;
	right: 0px !important;
	position: fixed !important;
}
.mat-dialog-actions {
	position: absolute !important;
	bottom: 20px !important;
	background: #fff;
	left: 0px !important;
	right: 0px !important;
	.boxShaddow-big-bright;
	padding-left: 20px !important;
	.boxSizing;
	z-index: 2;
}
.mat-tab-header {
  margin-left: -24px !important;
  margin-right: -24px !important;
	position: absolute !important;
	left: 0px !important;
	right: 0px !important;
	top: 0px !important;
	background: #fff !important;
	z-index: 2 !important;
}
.mat-tab-header-spacer {
	height: 40px;
}
*/
