/*
	[] iPhone x => 375 x 635

	APP
	body.insideApp.hasBackLink
*/

//.sidenav {
//  &--action {
//    a {
//      color: #fff;
//      background: @primaryColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
//      //padding-left: @header-height;
//      box-sizing: border-box;
//      line-height: 20px;
//      font-size: 20px;
//      height: @header-height;
//      padding-top: 6px;
//
//      display: inherit !important;
//    }
//  }
//}


/* loading */
.loading-event {
	margin: 20px;
	margin-top: 120px;
	/*background-color: @dividerColor;
	border: 2px solid darken(@dividerColor,5%);*/
	border-radius: 10px;
	padding: 20px;

	background-color: #fff;
	-webkit-box-shadow: fade(#000, 15%) 0px 0px 17px;
	-moz-box-shadow: fade(#000, 15%) 0px 0px 17px;
	box-shadow: fade(#000, 15%) 0px 0px 17px;
}
.loading-event .icon {
	font-size: 45px;
	color: @textColor;
	display: block;
	text-align: center;
	padding-bottom: 20px;
}
.loading-event .message {
	font-size: 15px;
	line-height: 15px;
	.font-main;
	color: @textColor;
	display: block;
	text-align: center;
}
/* /loading */

#mainNav > #importantActions > li.detailActions a > .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: @header-height;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
  }
}


.basic-mini-CTA {
	display: inline-block;
	padding: 2px 5px;
	border-radius: 5px;
	border: 1px solid @primaryColor;
	color: @primaryColor;
}

/* ######################################################## */
/* mainNav */
/* ######################################################## */
#mainNav {
	position: fixed;
	right: 0px;
	top: 0px;
	bottom: 0px;
	background: @backgroundColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
	z-index: 10;

	width: @mainNav-width-closed;

	overflow-x: hidden;
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
	/* scrollbars*/
	-ms-overflow-style: -ms-autohiding-scrollbar;
	-ms-overflow-style: none;
	.transition-default;
}
#mainNav::-webkit-scrollbar {
		display: none;
}

	.showMenue #mainNav {
		width: @mainNav-width-open;
		.transition-default;
	}

	@media (min-width: 845px) {
		.hiddenMenue #mainNav:hover, .hiddenMenue.showMenueOver #mainNav {
			width: @mainNav-width-open;
			.transition-slow;
			.boxShaddow-default;

			transition-delay:0.5s !important;
		}
		.hiddenMenue #mainNav:hover, .hiddenMenue.showMenueOver #mainNav  {
			width: @mainNav-width-open;
			.transition-slow;
			.boxShaddow-big;
			background-image: none !important;
		}
		.hiddenMenue #mainNav:hover a, .hiddenMenue.showMenueOver #mainNav a  {
			background-image: none !important;
		}

		.hiddenMenue #mainNav:hover > #importantActions > li.seperator {
			background-image: none !important;
		}
	}

	/* importantActions */

	#mainNav > #importantActions {
		z-index: 2;
		width: @mainNav-width-open;
		margin-bottom: 30px * 4 + 10px;
	}
	#mainNav > #importantActions > li {
		margin-bottom: 1px;
		font-size: 20px;
		line-height: 50px;
		width: @mainNav-width-open;
		position: relative;
	}
	#mainNav > #importantActions > li a {
		color: @primaryColor;
		background: @dividerColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
		display: block;
	}
			#mainNav > #importantActions > li.hasInfo {
				height: 50px;
				overflow: hidden;
			}
			#mainNav > #importantActions > li.hasInfo a small {
				display: block;
				font-size: 11px;
				line-height: 11px;
				padding-top: 3px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			#mainNav > #importantActions > li.hasInfo a {
				line-height: 20px;
				font-size: 20px;
				height: @header-height;
				padding-top: 8px;
				padding-left: 50px;
			}
			#mainNav > #importantActions > li.hasInfo a > .fa {
				position: absolute;
				left: 0px;
				top: 0px;
				line-height: @header-height;
				text-align: center;
				width: @header-height;
				height: @header-height;
				z-index: 1;
			}

	#mainNav > #importantActions > li > a .fa {
		width: @header-height;
		display: inline-block;
		text-align: center;
		font-size: 22px;
		color: @textColor-light;
	}
	#mainNav > #importantActions > li a:hover, #mainNav > #importantActions > li a:hover > .fa {
		color: @primaryColor;
	}
	#mainNav > #importantActions > li.active a, #mainNav > #importantActions > li.active a:hover {
		background-color: @primaryColor;
		color: @primaryColor-text;
	}
	#mainNav > #importantActions > li.active a > .fa, #mainNav > #importantActions > li.active a:hover > .fa {
		color: @primaryColor-text;
	}

		/* detailActions */
		#mainNav > #importantActions > li.seperator {
			height: 6px;
			background: #fff url(/img/gui/mainNav-shaddow.png) repeat-y left top;
		}

		#mainNav > #importantActions > li.detailActions {}
		#mainNav > #importantActions > li.detailActions a {
			color: #fff;
			background: @primaryColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
			padding-left: @header-height;
			.boxSizing;
			line-height: 20px;
			font-size: 20px;
			height: @header-height;
			padding-top: 6px;
		}
			#mainNav > #importantActions > li.detailActions-add a {
				background-color: @systemColor-add;
			}
			#mainNav > #importantActions > li.detailActions-delete a {
				background-color: @systemColor-delete;
			}
		#mainNav > #importantActions > li.detailActions a small {
			display: block;
			font-size: 11px;
			line-height: 11px;
			padding-top: 5px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		#mainNav > #importantActions > li.detailActions a > .fa {
			color: #fff;
			position: absolute;
			left: 0px;
			top: 0px;
			line-height: @header-height;
			text-align: center;
			width: @header-height;
			height: @header-height;
			z-index: 1;
		}
		#mainNav > #importantActions > li.detailActions a:hover, #mainNav > #importantActions > li.detailActions a:hover > .fa {
			color: #fff;
			background-color: @primaryColor-dark;
		}
		#mainNav > #importantActions > li.detailActions.specialActivated a > .fa {
			color: @accentColor;
		}

		/* qrCode */
		#mainNav > #importantActions > li.qrCode {

		}
		#mainNav > #importantActions > li.qrCode > a {
			background-color: #fff;
			padding: 0px;
			.boxSizing;
			line-height: 1em;
		}
		#mainNav > #importantActions > li.qrCode > a img {
			width: 100%;
			mix-blend-mode:multiply;
		}
		#mainNav > #importantActions > li.qrCode > a small {
			display: block;
			font-size: 10px;
			color: @textColor-light;
			text-align: center;
			line-height: 12px;
		}


	#mainNav > #importantActions > li .attention-please {
		position: absolute;
		left: 5px;
		top: (@header-height - 4px) / 2;
		width: 4px;
		height: 4px;
		display: block;
		background-color: @primaryColor;
		background-image: none;
		border-radius: 100%;
	}
	#mainNav > #importantActions > li.active .attention-please {
		background-color: @accentColor;
	}

		/*profilActions */
		#mainNav > #importantActions > li.profilActions {
			margin-bottom: 1px;
			font-size: 11px;
			line-height: 1.2em;
			font-weight: 400;
			color: @textColor;
			height: @header-height;
			cursor: pointer;
			margin-bottom: 0px;
		}
		#mainNav > #importantActions > li.profilActions:after {
			content: '\f107';
			font-family: 'FontAwesome';
			position: absolute;
			right: 10px;
			top: 0px;
			line-height: 50px;
			color: @primaryColor;
			font-size: 15px;
			text-align: right;
		}
		#mainNav > #importantActions > li.profilActions .profilActions-image {
			display: block;
			position: absolute;
			z-index: 5;
			width: @header-height - 10px;
			height: @header-height - 10px;
			top: 5px;
			left: 5px;
			border-radius: 100%;
			overflow: hidden;
			border: 1px solid @textColor;
			background-color: @textColor-light;
			.boxSizing;
		}
		#mainNav > #importantActions > li.profilActions .profilActions-image img {
			width: @header-height - 10px;
			height: @header-height - 10px;
		}
		#mainNav > #importantActions > li.profilActions .profilActions-details {
			padding-left: 50px;
			display: block;
			padding-top: 12px;
			z-index: 6;
			position: relative;
		}
		#mainNav > #importantActions > li.profilActions .profilActions-details i {
			display: block;
			color: @textColor-light;
			font-style: normal;
		}

		.profilActions .profilActions-menue {
			position: absolute;
			z-index: 3;
			top: 5px;
			left: 5px;
			right: 5px;
			.boxShaddow-small;
			background-color: #fff;
			border-radius: 19px 3px 3px 3px;
			padding: @header-height - 5px 0px 0px 0px;
			display: none;
		}
		.profilActions.showProfilActions .profilActions-menue {
			display: block;
		}
		.profilActions .profilActions-menue > li {
			margin: 0px 10px;
			border-top: 1px solid @dividerColor;
		}
		.profilActions .profilActions-menue > li > a {
			font-size: 15px;
			color: @textColor;
			line-height: 50px;
			display: block;
			background: none !important;
			background-color: transparent !important;
		}
		.profilActions .profilActions-menue > li > a:hover {
			color: @primaryColor;
		}
		.profilActions .profilActions-menue > li > a > i {
			display: inline-block;
			width: @header-height - 15px;
			padding-right: 15px;
			text-align: center;
			.boxSizing;
		}
		.profilActions .profilActions-menue:after {
			content: '\f106';
			font-family: 'FontAwesome';
			position: absolute;
			right: 5px;
			top: -5px;
			z-index: 9;
			line-height: 50px;
			color: @primaryColor;
			font-size: 15px;
			text-align: right;
		}


		/* detailInfos */
		#mainNav > #importantActions > .detailInfos {
			height: @header-height;
			margin-bottom: 0px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 20px;
			line-height: 20px;
			color: @textColor-light;
			padding: 6px 10px 0px @header-height;
			.boxSizing;
		}
		#mainNav > #importantActions > .detailInfos:after {
			width: @header-height;
			height: @header-height;
			position: absolute;
			left: 0px;
			top: 0px;
			z-index: 1;
			font-family: 'Font Awesome 5 Free';
			content: '\f013';
			text-align: center;
			line-height: @header-height;
			display: block;
			color: darken(@dividerColor,5%);
			font-size: 25px;
			font-weight: 400;
			display: none;
		}
		#mainNav > #importantActions > .detailInfos small {
			display: block;
			font-size: 12px;
			line-height: 12px;
			padding-top: 3px;
		}


	/* additionalInfos */
	#mainNav > #additionalInfos {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: @mainNav-width-open;
		z-index: 1;
	}
	#mainNav > #additionalInfos > li {
		margin-top: 1px;
		font-size: 13px;
		line-height: 30px;
	}
		#mainNav > #additionalInfos > li > span {
			display: block;
		}
	#mainNav > #additionalInfos > li a {
		color: @textColor;
		background: @dividerColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
		display: block;
	}
		#mainNav > #additionalInfos > li.highlight a {
			background-color: @accentColor;
		}
	#mainNav > #additionalInfos > li a .fa {
		width: @header-height;
		display: inline-block;
		text-align: center;
		font-size: 16px;
		color: @textColor-light;
	}
	#mainNav > #additionalInfos > li a:hover, #mainNav > #additionalInfos > li a:hover > .fa {
		color: @primaryColor;
	}
	#mainNav > #additionalInfos > li.active a, #mainNav > #additionalInfos > li.active a:hover {
		background: @primaryColor url(/img/gui/mainNav-shaddow.png) repeat-y left top;
		color: @primaryColor-text;
	}
	#mainNav > #additionalInfos > li.active a > .fa, #mainNav > #additionalInfos > li.active a:hover > .fa {
		color: @primaryColor-text;
	}

/* ######################################################## */
/* outstanding-links */
/* ######################################################## */
.outstanding-spacer {
  width: 160px;

  @media (min-width: 1025px) {
    display: none;
  }

}

.outstanding-links {
  position: fixed;
  bottom: ~"calc(env(safe-area-inset-bottom) + 12px)";
  left: 50%;
  margin-left: -80px;
  display: flex;
  border: 1px solid @accentColor;
  border-radius: 50px;
  background: #fff;
  background: linear-gradient(0deg, rgba(224,224,224,1) 0%, rgba(255,255,255,1) 61%);
  .boxShaddow-big-bright-bottomFocused-small;
  z-index: 99;


  @media (min-width: 1025px) {
    bottom: ~"calc(env(safe-area-inset-bottom) + 12px)";
    bottom:0px;
    border-radius:  24px 24px 0px 0px;
    border-bottom-width: 0px;
  }



  &:after {
    content: '';
    width: 1px;
    position: absolute;
    display: block;
    top: 5px;
    bottom: 5px;
    background: @dividerColor;

    left: 79px;

    @media (min-width: 1025px) {
      left: 99px;
    }

  }
  &:before {
    content: '';
    width: 1px;
    position: absolute;
    display: block;
    top: 5px;
    bottom: 5px;
    background: #fff;

    left: 80px;

    @media (min-width: 1025px) {
      left: 100px;
    }
  }

  a {
    display: block;
    width: 80px;
    height: 55px;
    text-align: center;
    color: @primaryColor;
    color: #00b6cf;
    line-height: 55px;
    font-size: 20px;
    cursor: pointer;

    @media (min-width: 1025px) {
      font-size: 26px;
      line-height: 70px;
      height: 70px;
      width: 100px;
    }

  }
}

/* Anpassung MainNav */

#mainNavNew > ul > li.mainPage {

  @media (max-width: 1024px) {
    display: none;
  }

}

#mainNavNew > ul > li.mainPage.mobileLeft {

  @media (max-width: 1024px) {
    display: block;
    width: ~"calc(50% - 80px)";
  }

}
#mainNavNew > ul > li.mainPage.mobileRight {

  @media (max-width: 1024px) {
    display: block;
    width: ~"calc(50% - 80px)";
  }
  @media (min-width: 1025px) {
    display: none;
  }

}




/* ######################################################## */
/* MainNav */
/* ######################################################## */
#mainNavNew {
	@media print {
	  display: none;
	}

}
#mainNav {
	@media print {
	  display: none !important;
	}
}
@media (min-width: 1025px) {

	.headerBG {
		background: #163d79 !important;
	}
	/* Space at top */
	.hasNewMenue #header {
		//top: @mainNavNew-height;
	}
	.hasNewMenue .headerBG {
		//top: @mainNavNew-height;
	}
		.insideApp.hasNewMenue .headerBG {
			top: 0px;
		}
	.hasNewMenue #mainNav {
		top: @mainNavNew-height;
	}
		.insideApp.hasNewMenue #mainNav {
			top: env(safe-area-inset-top);
			padding-top: 0px !important;
		}
		.insideApp #mainNav > #importantActions {
			padding-top: 0px !important;
		}
	.hasNewMenue #content {
		//margin-top: @header-height + @mainNavNew-height;
	}
			.insideApp.hasNewMenue #content {
				margin-top: calc(@header-height ~"+ env(safe-area-inset-top)");
			}
	.hasNewMenue.noMenue #content {
		margin-right: 0px;
	}
	.hasNewMenue.hasSubNav #content {
		margin-top: @header-height * 2 + @mainNavNew-height;

		@media print {
		  margin-top: @header-height + @mainNavNew-height;
		}
	}
		.insideApp.hasNewMenue.hasSubNav #content {
			margin-top: calc(@header-height * 2  ~"+ env(safe-area-inset-top)");
		}
	.hasNewMenue.hasSubNav #subNav {
		top: @header-height + @mainNavNew-height;
	}
	.hasNewMenue.hasSubNav.noMenue #subNav {
		right: 0px;
	}
	.hasNewMenue a.header-logo {
		display: none !important;
	}

	/* style menue */
	#mainNavNew {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		height: @mainNavNew-height;
		z-index: 9;
		background-color: @primaryColor-dark;
		background: fade(#fff,85%);
		z-index: 5;

		@media print {
		  display: none;
		}
	}
	.insideApp #mainNavNew {
		display: none !important;
	}

	#mainNavNew > ul {
		width: 100%;

		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
	}
	#mainNavNew > ul > li {
		margin: 0px;
		background: transparent url(/img/gui/mainNavNew-bg-bright.png) repeat-x bottom left;
	}
	#mainNavNew > ul > li.active {
		color: @primaryColor;
		position: relative;
	}
		#mainNavNew > ul > li.active:after {
			background: @primaryColor;
			border-radius: 5px 5px 0px 0px;
			height: 3px;
			display: block;
			content: '';
			position: absolute;
			bottom: 0px;
			left: 5px;
			right: 5px;
		}

	#mainNavNew > ul > li.space {
		flex-grow: 1;
	}

	#mainNavNew > ul > li > a:hover, #mainNavNew > ul > li.active > a {
		color: @primaryColor !important;
	}

	#mainNavNew .mainNav-icon i.fa.fa-smile-o {
		color: @primaryColor-text !important;
	}
	#mainNavNew li.active .mainNav-icon i.fa.fa-smile-o {
		color: @primaryColor-light !important;
	}
	/* showFullMenue */
	#mainNavNew .showFullMenue {

	}
	#mainNavNew > ul > li.showFullMenue > a {
		color: @primaryColor;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-icon {
		display: block;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
		width: 30px;
		text-align: center;
		line-height: @mainNavNew-height;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-title {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-subtitle {
		display: none;
	}

	/* changeAccount */
	#mainNavNew .changeAccount {
		position: relative;
		padding-right: 10px;
	}
	#mainNavNew .changeAccount:after {
		position: absolute;
		right: 7px;
		top: 5px;
		bottom: 5px;
		width: 1px;
		background-color: @dividerColor;
		content: '';
	}
	#mainNavNew > ul > li.changeAccount > a {
		color: @primaryColor;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.changeAccount .showFullMenue-icon {
		display: block;
		text-align: center;
		font-size: 15px;
		line-height: 18px;
		width: 30px;
		text-align: center;
		line-height: @mainNavNew-height;
	}
	#mainNavNew > ul > li.changeAccount .showFullMenue-title {
		display: none;
	}
	#mainNavNew > ul > li.changeAccount .showFullMenue-subtitle {
		display: none;
	}

	/* mainPageLogo */
	#mainNavNew > ul > li.mainPageLogo {

	}
	#mainNavNew > ul > li.mainPageLogo > a {
		display: block;
		width: @header-height;
		text-align: center;
		height: @mainNavNew-height;
		overflow: hidden;
	}
	#mainNavNew > ul > li.mainPageLogo .mainPageLogo-logo {

	}
	#mainNavNew > ul > li.mainPageLogo .mainPageLogo-logo img {
		height: @mainNavNew-height;
		width: @header-height;
	}
	#mainNavNew > ul > li.mainPageLogo .mainPageLogo-title {
		display: none;
	}
	#mainNavNew > ul > li.mainPageLogo .mainPageLogo-subtitle {
		display: none;
	}

	/* mainPageDashboard */
	#mainNavNew > ul > li.mainPageDashboard {
		display: none;
	}

	/* mainPageFocus */
	#mainNavNew > ul > li.mainPageFocus {

	}
	#mainNavNew > ul > li.mainPageFocus > a {
		display: block;
		width: @header-height;
		text-align: center;
		height: @mainNavNew-height;
		overflow: hidden;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-icon {
		color: @accentColor;
		font-size: 18px;
		line-height: @mainNavNew-height;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-title {
		display: none;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-subtitle {
		display: none;
	}


	/* mainPage */
	#mainNavNew > ul > li.mainPage {

	}
	#mainNavNew > ul > li.mainPage > a {
		display: block;
		color: @primaryColor;
		padding: 0px 10px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.mainPage .mainNav-icon {
		display: none;
	}
	#mainNavNew > ul > li.mainPage .mainNav-subtitle {
		display: none;
	}
	#mainNavNew > ul > li.mainPage .mainNav-title {
		text-transform: uppercase;
		display: block;
		font-size: 14px;
		line-height: @mainNavNew-height;
		font-weight: regular;
	}

	/* userDetails */
	#mainNavNew > ul > li.userDetails {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
	}

		/* logout */
		#mainNavNew > ul > li.userDetails a.user-logout {
			width: @header-height;
			overflow: hidden;
			text-align: center;
			color: @systemColor-error;
			line-height:  @mainNavNew-height;
		}
		#mainNavNew > ul > li.userDetails a.user-logout .user-logout-title {
			display: none;
		}

		/* user-edit */
		#mainNavNew > ul > li.userDetails a.user-edit {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-start;
			align-items: stretch;
		}

		#mainNavNew .user-info {
			max-width: 150px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			text-align: right;
			color: @primaryColor;
		}
			#mainNavNew .user-info span {
				display: block;
				text-align: right;
				max-width: 120px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				.boxSizing;
				padding-right: 5px;
			}
			#mainNavNew .user-info span.user-title {
				font-size: 14px;
				line-height: 16px;
			}
			#mainNavNew .user-info span.user-subtitle {
				font-size: 10px;
				line-height: 10px;
			}



		#mainNavNew .user-image {
			width: @header-height;
			overflow: hidden;
			height:  @mainNavNew-height;
			display: block;
			position: relative;
		}
		#mainNavNew .user-image:after {
			position: absolute;
			z-index: 1;
			left: 0px;
			bottom: 0px;
			right: 0px;
			height: 20px;
			content: '';
			background: transparent url(/img/gui/mainNavNew-bg.png) repeat-x bottom left;
		}
		#mainNavNew .user-image img {
			width: @header-height;
			height: auto;
			margin-top: 0 - ((@header-height - @mainNavNew-height) / 2);
		}


		/* APP
		.insideApp.hasNewMenue #header, .insideApp.hasNewMenue #mainNav, .insideApp.hasNewMenue .headerBG {
			top: env(safe-area-inset-top) !important;
		}*/

}

@media (max-width: 1024px) {

	/* Space at top */
	.hasNewMenue #content {
		padding-bottom: @mainNavNew-mobile-height * 2 + 60px;
		padding-bottom: 0px;
	}
	.hasNewMenue #mainNav {
		bottom: @mainNavNew-mobile-height;
	}
	.hasNewMenue.hasSubNav.noMenue #subNav {
		right: 0px;
	}


	/* style menue */

	#mainNavNew {
		position: fixed;
		bottom: 0px;
		left: 0px;
		right: 0px;
		height: @mainNavNew-mobile-height;
		.boxShaddow-small;
		z-index: 9;
		background: @primaryColor;
		overflow: hidden;


		padding-bottom: env(safe-area-inset-bottom);
	}
	.insideApp #mainNavNew {
		top: 0px;
		bottom: auto;
		left: 0px;
		right: 0px;
		z-index: 11;

		display: none;
	}

	#mainNavNew > ul {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: stretch;
	}
	#mainNavNew > ul > li {
		margin: 0px;
		flex-grow: 1;
	}
	#mainNavNew > ul > li.active {
	}

	#mainNavNew > ul > li.space {
		display: none;
	}

	#mainNavNew > ul > li > a:hover, #mainNavNew > ul > li.active > a {
		color: @primaryColor-light !important;
	}

	/* mainPageLogo */
	#mainNavNew > ul > li.mainPageLogo {
		display: none;
	}

	/* mainPageDashboard */
	#mainNavNew > ul > li.mainPageDashboard {

	}

	#mainNavNew > ul > li.mainPageDashboard > a {
		color: @primaryColor-text;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.mainPageDashboard .mainNav-icon {
		display: block;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
		padding-top: 8px;
	}
	#mainNavNew > ul > li.mainPageDashboard .mainNav-title {
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-size: 10px;
		line-height: 10px;
		padding: 6px 5px 0px 5px;
		font-weight: 400;
	}
	#mainNavNew > ul > li.mainPageDashboard .mainNav-subtitle {
		display: none;
	}
	/* changeAccount */
	#mainNavNew .changeAccount {
		display: none;
	}
	/* mainPageFocus */
	#mainNavNew > ul > li.mainPageFocus {

	}
	#mainNavNew > ul > li.mainPageFocus > a {
		color: @primaryColor-text;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-icon {
		display: block;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
		padding-top: 8px;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-title {
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-size: 10px;
		line-height: 10px;
		padding: 6px 5px 0px 5px;
		font-weight: 400;
	}
	#mainNavNew > ul > li.mainPageFocus .mainNav-subtitle {
		display: none;
	}

	/* showFullMenue */
	#mainNavNew > ul > li.showFullMenue {

	}
	#mainNavNew > ul > li.showFullMenue > a {
		color: @primaryColor-text;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-icon {
		display: block;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
		padding-top: 8px;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-title {
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-size: 10px;
		line-height: 10px;
		padding: 6px 5px 0px 5px;
		font-weight: 400;
	}
	#mainNavNew > ul > li.showFullMenue .showFullMenue-subtitle {
		display: none;
	}

	/* mainPage */
	#mainNavNew > ul > li.mainPage {

	}
	#mainNavNew > ul > li.mainPage > a {
		display: block;
		color: @primaryColor-text;
		text-align: center;
		padding: 0px;
		cursor: pointer;
	}
	#mainNavNew > ul > li.mainPage .mainNav-icon {
		display: block;
		text-align: center;
		font-size: 18px;
		line-height: 18px;
		padding-top: 8px;
	}
	#mainNavNew > ul > li.mainPage .mainNav-subtitle {
		display: none;
	}
	#mainNavNew > ul > li.mainPage .mainNav-title {
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-size: 10px;
		line-height: 10px;
		padding: 6px 5px 0px 5px;
		font-weight: 400;
	}

	/* userDetails */
	#mainNavNew > ul > li.userDetails {
		display: none;
	}

		/* logout */
		#mainNavNew > ul > li.userDetails a.user-logout {
		}
		#mainNavNew > ul > li.userDetails a.user-logout .user-logout-title {
		}

		/* user-edit */
		#mainNavNew > ul > li.userDetails a.user-edit {
		}
		#mainNavNew .user-info {
		}
			#mainNavNew .user-info span {
			}
			#mainNavNew .user-info span.user-title {
			}
			#mainNavNew .user-info span.user-subtitle {
			}

		#mainNavNew .user-image {
		}
		#mainNavNew .user-image:after {
		}
		#mainNavNew .user-image img {
		}


}

/* iphoneX */
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  	#mainNavNew {
			padding-bottom: 20px;
      /* NEUES MENÜ */
      padding-bottom: env(safe-area-inset-bottom);
	}
}




@media (max-width: 845px) {
	.hasNewMenue #mainNav {
		bottom: 0px;
	}
}
@media (max-width: 560px) {

	#mainNavNew > ul > li:nth-child(10n+0) {
		display: none;
	}
	#mainNavNew > ul > li:nth-child(9n+0) {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue {
		display: block !important;
	}

}
@media (max-width: 500px) {

	#mainNavNew > ul > li:nth-child(9n+0) {
		display: none;
	}
	#mainNavNew > ul > li:nth-child(8n+0) {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue {
		display: block !important;
	}

}
@media (max-width: 450px) {

	#mainNavNew > ul > li:nth-child(8n+0) {
		display: none;
	}
	#mainNavNew > ul > li:nth-child(7n+0) {
		display: none;
	}
	#mainNavNew > ul > li:nth-child(6n+0) {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue {
		display: block !important;
	}

}
@media (max-width: 374px) {

	#mainNavNew > ul > li:nth-child(6n+0) {
		display: none;
	}
	#mainNavNew > ul > li:nth-child(5n+0) {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue {
		display: block !important;
	}

}
@media (max-width: 310px) {

	#mainNavNew > ul > li:nth-child(5n+0) {
		display: none;
	}
	#mainNavNew > ul > li.showFullMenue {
		display: block !important;
	}

}



/* = = = = = = = = = = = = = = = = = = = = = */
/* App */
/* = = = = = = = = = = = = = = = = = = = = = */
.insideApp #mainNavNew {
		padding-bottom: 0px !important;
}
/* header */
.insideApp #header {
	top: ~"env(safe-area-inset-top)";
}
.insideApp .headerBG {

	@supports (margin-top: env(safe-area-inset-top)) {
		height: calc(@header-height + env(safe-area-inset-top));
	}
}
.hasSubNav.insideApp .headerBG {

	@supports (margin-top: env(safe-area-inset-top)) {
		height: calc(@header-height * 2 + env(safe-area-inset-top));
	}
}

/* Space at top */
.insideApp #content {

	@supports (margin-top: env(safe-area-inset-top)) {
		margin-top: calc(@header-height + env(safe-area-inset-top));
	}
}
.insideApp.hasSubNav #content {

	@supports (margin-top: env(safe-area-inset-top)) {
		margin-top: calc(@header-height * 2 + env(safe-area-inset-top));
	}
}

/* SubNav */
.insideApp #subNav {

	@supports (top: env(safe-area-inset-top)) {
		top: calc(@header-height + env(safe-area-inset-top));
	}
}

/* rightNav */
.insideApp #mainNav > #importantActions {
	padding-top: env(safe-area-inset-top);
}

.hasSubNav.insideApp .field-edit-container {

	@supports (top: env(safe-area-inset-top)) {
		top: calc(100px + env(safe-area-inset-top));
	}
}

.insideApp .md-dialog-container {
	top: env(safe-area-inset-top) !important;
	bottom: env(safe-area-inset-bottom) !important;
	height: auto !important;
}

.insideApp md-backdrop.md-opaque.md-default-theme,
md-backdrop.md-opaque {
	background: rgba(255,255,255,0.75) !important;
}
md-backdrop.md-opaque {
	opacity: 1 !important;
	backdrop-filter: blur(7px) !important;
	-webkit-backdrop-filter: blur(10px) !important;
}



/* = = = = = = = = = = = = = = = = = = = = = */
/* MODAL */
/* = = = = = = = = = = = = = = = = = = = = = */
.mainNavNew-sortable {
	.blankList;
	/* max-width: 600px; */
	margin: 0px auto;
}
.mainNavNew-sortable > li {
	border-bottom: 1px solid darken(@dividerColor,10%);
	position: relative;
}
	/* drag and drop */
	.mainNavNew-sortable.angular-ui-tree-drag {
		margin: 0px;
		width: 300px !important;
		background-color: #fff !important;
		.boxShaddow-big;
	}
	.mainNavNew-sortable.angular-ui-tree-drag > li {
		border-bottom: none;
		position: relative;
		width: 300px !important;
		.boxSizing;
		background-color: #fff !important;
	}
	.mainNavNew-sortable.angular-ui-tree-drag > li.sort-item {
		visibility: hidden !important;
	}

.mainNavNew-sortable > li:nth-child(even) {
	background-color: @dividerColor;
	padding-right: 60px;
}
.mainNavNew-sortable > li:hover {
	background-color: darken(@dividerColor,3%);
}
	.mainNavNew-sortable > li.mainPage.notImportant {
		background-color: #fff;
	}
	.mainNavNew-sortable > li.mainPage.notImportant a span {
		color: @textColor-light;
	}
	.mainNavNew-sortable > li.mainPage.importantRow {
		background-color: @accentColor;
	}
	.insideApp .mainNavNew-sortable > li.mainPage.notImportant.websiteLink {
		display: none;
	}

.mainNavNew-sortable > li a {
	display: block;
	height: 40px;
	cursor: pointer;
	padding-left: 40px;
}
.mainNavNew-sortable > li a:hover {
	color: @primaryColor-dark;
}
.mainNavNew-sortable > li a span {
	display: block;
}
.mainNavNew-sortable > li .sort-item {
	display: block;
	line-height: 40px;
	font-size: 18px;
	width: 50px;
	color: @textColor-light;
	cursor: move;
	position: absolute;
	right: 0px;
	top: 0px;
	z-index: 1;
	text-align: center;
}
	.insideApp .mainNavNew-sortable > li .sort-item {
		display: none!important;
	}

.mainNavNew-sortable > li .mainNav-icon, .mainNavNew-sortable > li .mainNav-icon, .mainNavNew-sortable > li .mainNav-icon {
	display: block;
	line-height: 40px;
	font-size: 20px;
	width: 40px;
	color: @primaryColor;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 1;
	text-align: center;
}

.mainNavNew-sortable > li .mainNav-title, .mainNavNew-sortable > li .mainNav-title, .mainNavNew-sortable > li .mainNav-title {
	font-size: 16px;
	line-height: 16px;
	padding-top: 7px;
}
.mainNavNew-sortable > li .mainNav-subtitle, .mainNavNew-sortable > li .mainNav-subtitle, .mainNavNew-sortable > li .mainNav-subtitle {
	font-size: 10px;
	line-height: 10px;
	padding-top: 2px;
}

/* userDetails-modal */

.userDetails-modal {
	margin: 30px 11px;
}
	.insideApp .userDetails-modal {
		display: none;
	}
.userDetails-modal a.user-edit {
	cursor: pointer;
	display: flex;
	background-color: @dividerColor;
	margin: 0px auto;
	display: block;
	border-radius: 10px;
	min-height: 102px;
	.boxSizing;
	position: relative;
	padding: 11px;
	display: flex;
	align-content: center;
	align-items: center;
}
.userDetails-modal .user-edit .user-info {
	padding: 0px 0px 0px 102px;
	display: block;
}
.userDetails-modal .user-edit .user-info .user-title {
	display: block;
	text-align: left;
	font-size: 22px;
	line-height: 22px;
	padding: 0px;
	color: @primaryColor;
	padding-bottom: 3px;
	.font-special;
}
.userDetails-modal .user-edit .user-info .user-subtitle {
	display: block;
	text-align: left;
	font-size: 12px;
	line-height: 12px;
	color: @textColor;
	padding-bottom: 11px;
	text-transform: uppercase;
}
.userDetails-modal .user-edit .user-info .user-edit {
	display: block;
	text-align: left;
	font-size: 10px;
	line-height: 20px;
	max-width: 150px;
	text-transform: uppercase;
	padding: 0px 10px;
	color: @primaryColor;
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 7px;
}
.userDetails-modal .user-edit .user-image {
	width: 80px;
	height: 80px;
	display: block;
	position: absolute;
	left: 11px;
	top: 50%;
	margin-top: -40px;
}
.userDetails-modal .user-edit .user-image img {
	width: 80px;
	height: 80px;
	border-radius: 100%;
	.boxShaddow-big;
}




.changeRole-Logout {
	display: flex;
	margin: 30px 0px 20px 0px;
}
.insideApp .changeRole-Logout {
	display: none;
}

.userDetails-modal .user-changeRole {
	display: block;
	font-size: 16px;
	text-align: center;
	background-color: @primaryColor;
	cursor: pointer;
	color: #fff;
	width: ~"calc(50% - 10px)";
	line-height: 40px;
	flex-grow: 1;
	margin-right: 10px;
	border-radius: 7px;
}

.userDetails-modal .user-logout {
	display: block;
	font-size: 16px;
	text-align: center;
	background-color: @systemColor-error;
	color: #fff;
	line-height: 40px;
	flex-grow: 1;
	margin-left: 10px;
	border-radius: 7px;
	width: ~"calc(50% - 10px)";
}

/* ######################################################## */
/* Header */
/* ######################################################## */
#header {
	position: fixed;
	z-index: 9;
	top: 0px;
	left: 0px;
	right: @mainNav-width-closed;

	height: @header-height;
	padding: 0px @header-height * 2; /* NEU */

	.transition-default;

	@media print {
	  position: relative;
	}

}
	.hasSubNav #header {
	}
	.showMenue #header {
		right: @mainNav-width-open;
		.transition-default;
	}
	.noMenue #header {
		right: 0px;
	}
	.hasBeenScrolled #header {
		/*opacity: 0.95;*/
		.transition-default;
		/*
		opacity: 0.75;
		backdrop-filter: blur(5px);
		*/
	}
	.noMenue #header {
		right: 0px;
	}


#header h1 {
	.font-main;
	font-size: 20px;
	color: @primaryColor-text;
	text-align: center;
	line-height: 22px;
	padding: 10px 0px 0px 0px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	text-transform: uppercase;
	font-weight: 400;
}
	#header.specialHeader h1 {
		color: @accentColor-text;
	}
#header h1 small {
	display: block;
	font-size: 12px;
	padding-top: 2px;
	font-weight: 400;
	line-height: 10px;
}
#header h1 small a {
	colo	r: #fff !important;
	font-weight: bold !important;
}
#header .logoLinkCenter {
	display: block;
	position: absolute;
	left: 50%;
	width: 130px;
	padding-top: 5px;
	.boxSizing;
	height: @header-height;
	margin-left: -65px;
	overflow: hidden;
	text-align: center;
}
#header .logoLinkCenter img {
	width: 125px;
}
#header .header-icon {
	position: absolute;
	top: 0px;
	width: @header-height;
	height: @header-height;
	display: block;
	text-align: center;
	overflow: hidden;
	line-height: 50px;
	color: @primaryColor-text;
	font-size: 30px;
	cursor: pointer;
}
	#header.specialHeader .header-icon {
		color: @accentColor-text;
	}
#header .header-icon-left {
	left: 0px;
}
#header .header-icon-right {
	right: 0px;
}
.insideApp #header .header-icon-right.menueCloseIcon {
	display: none !important;
}
#header .header-icon-right-special {
	right: 50px;
	color: @accentColor;
	cursor: pointer;
}
	#header .header-icon-right-special i {
		display: block;
		line-height: 30px;
		height: 30px;
		width: 30px;
		overflow: hidden;
		border: 1px solid @accentColor;
		.boxSizing;
		.boxShaddow-small;
		font-size: 20px;
		margin: 10px;
		border-radius: 100%;
	}
#header .header-logo {
	position: absolute;
	top: 2px;
	left: 5px;
	height: @header-height - 5px;
	display: block;
	text-align: center;
	overflow: hidden;
	color: @primaryColor-text;
	.transition-default;
}
	.insideApp #header .header-logo {
		display: none !important;
	}

#header .header-icon.header-icon-right.textStyle {
	width: 75px;
	font-size: 12px;
	font-weight: 400 !important;
	text-transform: uppercase;
	border: 2px solid @accentColor;
	color: #fff;
	border-radius: 50px;
	right: 3px;
	top: 5px;
	bottom: 5px;
	padding-top: 5px;
	line-height: 36px;
	height: 40px;
	.boxSizing;
	line-height: 12px;
}



#header .header-logo img {
	height: @header-height - 5px;
	.transition-default;
}
	.showMenue #header a.menueToggler {
		color: fade(@primaryColor-text,50%);
	}

	@media (min-width: 1200px) {
		.hasSubnav #header .header-logo {
			height: @header-height * 2 - 10px;
			.transition-default;
		}
		.hasSubnav #header .header-logo img {
			height: @header-height * 2 - 10px;
			.transition-default;
		}

	}

/* ######################################################## */
/* headerBG */
/* ######################################################## */
.headerBG {
	position: fixed;
	background: #163d79;
	background: #163d79;
	.headerBG-setting;
	top: 0px;
	left: 0px;
	right: 0px;
	height: @header-height;
	.boxShaddow-header;
	.transition-default;
	z-index: 6;
	display: block;

}
.insideApp .headerBG {
	background: #163d79 !important;
}

	.headerBG.specialHeader {
		background: #163d79;
		backdrop-filter: blur(7px);
	}

	.hasSubNav .headerBG {
		height: @header-height * 2;
	}

/* ######################################################## */
/* SubNav */
/* ######################################################## */
#subNav {
	position: fixed;
	z-index: 8;
	top: @header-height;
	left: 0px;
	right: @mainNav-width-closed;
	height: @header-height;
	text-align: center;
	.transition-default;

	@media print {
	  display: none;
	}
}
	.showMenue #subNav {
		right: @mainNav-width-open;
		.transition-default;
	}
	.hasBeenScrolled #subNav {
		/*opacity: 0.95;*/
		.transition-default;
		/*
		opacity: 0.75;
		backdrop-filter: blur(5px);
		*/
	}

#subNav > ul {
	display: flex;
	justify-content: center;
}

#subNav > ul > li {
	padding-top: 10px;
	display: block;
	margin: 0px 10px;

	@media (max-width: 380px) {
		margin: 0px 10px;

	}

	@media (max-width: 345px) {
		margin: 0px 5px;
	}
}

#subNav > ul.muchSubs > li {

	@media (max-width: 380px) {
		max-width: 20%;
	}
}


#subNav > ul > li > a {
	display: block;
	border-bottom: 2px solid transparent;
	color: @primaryColor-text;
	font-size: 14px;
	line-height: 14px;
	padding: 10px 0px 5px 0px;
	font-weight: 400 !important;
	text-transform: uppercase;
	position: relative;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
#subNav.hasIcons > ul > li > a i {
	font-size: 15px;
	line-height: 15px;

	@media (max-width: 650px) {
		font-size: 18px;
		line-height: 18px;
	}
}
#subNav.hasIcons > ul > li > a {
	margin-top: -12px;
	@media (max-width: 650px) {

	}
}
#subNav > ul > li > a small {
	font-size: 11px;
	line-height: 11px;
	display: block;
	padding-top: 5px;

	@media (max-width: 650px) {
		font-size: 10px;
		line-height: 10px;
		padding-top: 5px;
		max-width: 60px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	@media (max-width: 400px) {
		font-size: 8px;
		max-width: 50px;
	}
	@media (max-width: 330px) {
		font-size: 7px;
		max-width: 50px;
	}
}
#subNav > ul > li > a:hover {
	color: @primaryColor-light;
}
#subNav > ul > li.active > a, #subNav > ul > li.active > a:hover {
	border-bottom: 2px solid @primaryColor-light;
	color: @primaryColor-light;
}
#subNav > ul > li > a .attention-please {
	position: absolute;
	left: 50%;
	top: 0px;
	width: 4px;
	height: 4px;
	display: block;
	background-color: #fff;
	background-image: none;
	border-radius: 100%;
}
#subNav > ul > li.active > a .attention-please {
	background-color: @primaryColor-light;
}




/* ######################################################## */
/* content */
/* ######################################################## */
#content {
	margin: @header-height @mainNav-width-closed 0px 0px;
	.transition-default;
	display: block;
	padding-bottom: 50px;
}

	.hasSubNav #content {
		margin-top: @header-height * 2;
	}
	.showMenue #content {
		margin-right: @mainNav-width-open;
		.transition-default;
	}
	.noMenue #content {
		margin-right: 0px;
	}
	.noHeader #content {
		margin-top: 0px !important;
	}
	.noMenue #content {
		margin-right: 0px !important;
	}

/* ######################################################## */
/* diverses */
/* ######################################################## */

a.topLink {
	position: fixed;
	right: 15px;
	width: 30px;
	height: 30px;
	background-color: @primaryColor-dark;
	color: @primaryColor-text;
	text-align: center;
	line-height: 30px;
	font-size: 20px;
	display: block;
	.boxShaddow-small;
	border-radius: 3px;
	bottom: -50px;
	display: block;
	.transition-default;
	z-index: 9;
}
	.showMenue a.topLink {
		right: 15px + @mainNav-width-open;
	}
	.hiddenMenue a.topLink {
		right: 15px + @mainNav-width-closed;
	}
.showToplink a.topLink {
	bottom: 15px;
	top: auto;
	.transition-default;
}


/* cookie individual */
#cookie-msg {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background-color: fade(@primaryColor,85%);
	padding: 11px;
	font-size: 13px;
	color: #fff;
	z-index: 99;
}
#cookie-msg .cookie-readmore {
	color: @accentColor;
}
#cookie-msg .cookie-sayYes {
	color: @accentColor-text;
	background-color: @accentColor;
	display: inline-block;
	padding: 6px 11px;
	margin-left: 5px;
	font-weight: 700;
}
/* ######################################################## */
/* cookie */
/* ######################################################## */
#cookie-bar {
	background-color: fade(@dividerColor,95%);
	height: auto;
	text-align: center;
	padding: 0px;
	color: @textColor-light;
}

#cookie-bar.fixed {}
#cookie-bar.fixed.bottom {}
#cookie-bar p {
	margin:0; padding:6px 0px; color: @textColor-light; font-size: 12px; font-weight: 400;
}
#cookie-bar a {
	color:#fff;
	background-color: @textColor;
	display: inline-block;
	padding: 2px 5px;
	margin-left: 5px;
	font-weight: 700;
}
#cookie-bar .cb-enable {
	background-color: @textColor;
	color: #fff;
}
#cookie-bar .cb-enable:hover {color:@primaryColor; background-color:#fff;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}


.insideApp #cookie-bar {
	display: none	!important;
}


/* ######################################################## */
/* responsive */
/* ######################################################## */
@media (max-width: 845px) {

	#mainNav {
		width: @mainNav-width-mobileClosed;
		.boxShaddow-none;
		background-image: none !important;
	}

	#mainNav > #importantActions > li.seperator {
		background-image: none !important;
	}

	.showMenue #mainNav {
		.boxShaddow-big;
	}
	.showMenue a.topLink, .hiddenMenue a.topLink {
		right: 15px;
	}

	#mainNav a  {
		background-image: none !important;
	}
	#header, #subNav, .showMenue #header, .showMenue #subNav {
		right: @mainNav-width-mobileClosed;
	}

	#content, .showMenue #content {
		margin-right: @mainNav-width-mobileClosed;
	}
	.showMenue #header h1 {
		display: none;
	}
	.showMenue #header .header-logo {
		/*display: none;*/
		opacity: 0.5;
		.transition-default;
	}
	.showMenue #header {
		z-index: 10;
	}
	.showMenue #header .header-icon-right {
		right: @mainNav-width-open;
		.transition-default;
	}

	.showMenue .blender {
		position: fixed;
		display: block;
		left: 0px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		z-index: 9;
		background-color: fade(@primaryColor,75%);
		backdrop-filter: blur(5px);
	}

	#header .header-logo {
		/* position: absolute;
		top: 0px;
		left: 0px;
		height: @header-height;
		width: @header-height; */
	}
	#header .header-logo img {
		/* height: auto;
		width: @header-height; */
	}
}

@media (max-width: 500px) {
	#subNav.lotOfPoints > ul > li a {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	#subNav.lotOfPoints > ul > li {
		margin: 0px 5px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 70px;
	}
}

@media (max-width: 320px) {
	#subNav.lotOfPoints > ul > li {
		max-width: 55px;
	}
}


/* ######################################################## */
/* infofeld oben */
/* ######################################################## */

.show-introduction {
	position: relative;
	background-color: fade(@primaryColor,75%);
	backdrop-filter: blur(7px);

	@media print {
	  display: none;
	}
}
.show-introduction .icon {
	display: block;
	width: 20px;
	line-height: 15px;
	margin: 0px auto;
	line-height: 15px;
	text-align: center;
	overflow: hidden;
	color: @primaryColor-text;
	font-size: 16px;
	cursor: pointer;
}


.introduction {
	/*
	padding: 40px;
	position: relative;
	@media (max-width: 850px) {
		padding: 20px;
	}
	@media (max-width: 375px) {
		padding: 10px;
	}
	*/
}
.pageType-overview .introduction, .pageType-details .introduction {
	/* padding: 10px; */
}

.introduction > div {
	padding: 20px;
	font-size: 12px;
	line-height: 17px;
	color: @primaryColor-text;
	text-align: center;
	position: relative;
	z-index: 9;

	top: 60px;
	margin-left: 300px;
	margin: 0px 20px 60px 20px;
	border-radius: 10px;
	background-color: #fff;
	.boxShaddow-big-bright-bottomFocused;
}
.introduction > div:after {
    display: block;
    content: "";
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: transparent url('/img/defaults/guestoo-help-stefan.jpg') no-repeat;
    position: absolute;
    top: -45px;
    left: 50%;
    margin-left: -40px;
    background-color: #fff;
    border: 3px solid #fff;
		box-sizing: border-box;
		background-size: 100%;
		.boxShaddow-big-bright-bottomFocused;
}


.introduction > div div {
	padding-bottom: 50px;
}
.introduction > div > span {
	display: block;
}


	.introduction.introduction-dark {
		background-color: fade(@primaryColor-dark,75%);
		color: #fff;
	}
.introduction p {
	padding: 0px 0px 15px 0px;
	margin: 0px auto;
	font-size: 15px;
	line-height: 18px;
	color: @textColor-dark;
	font-weight: 400;
	text-align: center;
}

.introduction p.big {
	font-size: 16px;
	line-height: 20px;
	.font-special;
}


.introduction p a {
	color: @accentColor;
}
.introduction p a.miniCta {
	background-color: #fff;
	padding: 2px 5px;
	display: inline-block;
	border: 1px solid @accentColor;
	border-radius: 3px;
}

.introduction h3 {
	color: @accentColor;
	font-size: 30px;
	line-height: 30px;
	padding-top: 30px;
	padding-bottom: 25px;
}
	.introduction-dark.introduction p {
		color: #fff !important;
	}
	.introduction.important h3 {
		color: @systemColor-error;
	}
	.introduction.important p {
		color: @systemColor-error;
	}

.introduction a.closeIntroduction {
	background-color: @accentColor;
	border-radius: 7px;
	display: block;
	position: absolute;
	padding: 0px 10px;
	.boxSizing;
	text-align: center;
	font-size: 14px;
	line-height: 40px;
	bottom: 20px;
	color: @accentColor-text;
	text-transform: uppercase;
	width: 200px;
	left: 50%;
	margin-left: -100px;
	.transition-default;
	.boxShaddow-big-bright-bottomFocused;
}

.introduction a.closeIntroduction:hover {

	.transition-default;
}
	.introduction.important a.closeIntroduction {
		background-color: @systemColor-error;
	}


		/* indtroduction Important */
		.introduction.importantIntroduction {
			background-color: transparent;
			padding: 0px;
			padding-top: 10px;
		}
		.introduction.importantIntroduction div {
			background-color: #fff;
			border-radius: 0px;
			margin: 0px 10px 0px 10px;
			padding: 10px;
			padding-bottom: 50px;
			.boxShaddow-small-bright;
		}
		.introduction.importantIntroduction p {
			padding: 0px;
			margin: 0px;
			font-size: 14px;
			line-height: 18px;
			color: @primaryColor-text;
			text-align: center;
		}
		.introduction.importantIntroduction h3 {
		    padding: 10px 0px 15px 0px;
		    /* padding-bottom: 10px; */
		    margin: 0;
		    font-size: 24px;
		    line-height: 24px;
		    color: #fff;
		    text-align: center;
		}
		.introduction.importantIntroduction a.closeIntroduction {
			background-color: #fff;
			.boxShaddow-default;
			display: block;
			position: absolute;
			padding: 5px;
			font-size: 14px;
			bottom: 30px;
			color: @systemColor-ok;
			text-transform: uppercase;
			width: 200px;
			left: 50%;
			margin-left: -100px;
			border-radius: 44px;
		}
		.introduction.importantIntroduction a.closeIntroduction:hover {
			background-color: @primaryColor;
			color: @primaryColor-text;
		}

		/* Overrides */
		md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content {
			background-color: @primaryColor;
		}
		md-toast.md-default-theme.toast-error .md-toast-content, md-toast.toast-error .md-toast-content {
			background-color: @systemColor-error;
		}



		.bgImage {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-attachment: fixed;
		}




		/* fucking IE Hack

		@media all and (-ms-high-contrast:none) {
			 // IE10
			 #content {
			 	margin-top: @header-height * 2 + @mainNavNew-height;
			 	margin-right: 250px;
			 }
			 //IE11
			 *::-ms-backdrop, #content {
			 	margin-top: @header-height * 2 + @mainNavNew-height;
			 	margin-right: 250px;
			 }
		} */


/* noPermission */
#noPermission-frame {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: @systemColor-error;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	z-index: 99;
}
.noPermission-info {
	text-align: center;
	color: #fff;
}
.noPermission-icon {
	text-align: center;
	display: block;
	font-size: 80px;
}
.noPermission-info h1 {
	color: #fff;
	font-size: 18px;
	line-height: 18px;
	font-weight: 400;
	padding: 0px;
	margin: 30px 0px 10px 0px;
	text-transform: uppercase;
}
.noPermission-info p {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	margin: 0px;
	padding: 0px;
}
.noPermission-info a {
	display: inline-block;
	padding: 3px 10px;
	background-color: #fff;
	.boxShaddow-small;
	color: @systemColor-error;
	border-radius: 5px;
	margin: 20px 10px 10px 10px;
}



.insideApp .cookieinfo {
	display: none !important;
}
.insideApp .hideInsideApp {
	display: none !important;
}

.blender, .headerBG {
	@media print {
	  display: none !important;
	}
}

.hasNewMenue.hasSubNav #content {
	@media print {
		padding: 0px !important;
		margin-top: 30px !important;
	}
}


/* ######################################################## */
/* usefull Stuff */
/* ######################################################## */
a.miniCta-general {
	background-color: #fff;
	padding: 2px 5px;
	display: inline-block;
	border: 1px solid @primaryColor;
	color: @primaryColor;
	border-radius: 3px;
}
.msg-error {
	border: 1px solid @systemColor-error;
	padding: 10px !important;
	border-radius: 7px;
	background: fade(@systemColor-error, 15%);
}
.msg-warning {
	border: 1px solid @systemColor-warning;
	padding: 10px !important;
	border-radius: 7px;
	background: fade( @systemColor-warning, 15%);
}
	.msg-warning h3 {
		colo	r: @systemColor-warning;
		.font-special;
		font-size: 18px;
		line-height: 20px;
		padding: 0px;
		margin: 0px;
		padding-top: 10px;
	}
.msg-ok {
	border: 1px solid @systemColor-ok;
	padding: 10px !important;
	border-radius: 7px;
	background: fade(@systemColor-ok, 15%);
}
.msg-default {
	/*border: 1px solid @dividerColor;*/
	padding: 10px !important;
	border-radius: 10px;
	background: #fff;
	.boxShaddow-big-bright-bottomFocused-small;
}
.msg-default.margin-top-15 {
	margin-top: 15px;
}
			.msg-default .label {
				font-size: 16px;
				color: @textColor;
			}
			.msg-default .label .icon {

			}
			.msg-default a {
				font-size: 14px;
				line-height: 18px;
				padding: 5px 0px;
				display: block;
				color: @primaryColor;
				cursor: pointer;
			}
			.msg-default .legende {
				font-size: 12px;
				color: @textColor-light;
				line-height: 14px;
				padding-top: 10px;
			}


/* ######################################################## */
/* ABO */
/* ######################################################## */

li.change-abo-li {
	@media (max-width: 1024px) {
		display: none !important;
	}
}
a.change-abo {
	display: block;
	border-radius: 20px;
	padding: 0px 7px;
	border: 1px solid @primaryColor;
	font-size: 10px;
	color: @primaryColor;
	line-height: 14px;
	margin-right: 10px;
	text-transform: uppercase;
	margin-top: 6px;

	@media (max-width: 1024px) {
		display: none !important;
	}
}
a.change-abo:hover {
	border-color: @primaryColor-light;
}



/* ######################################################## */
/* PRINT*/
/* ######################################################## */
@media print {
	.overviewMain {
		margin: 0px !important;
		padding: 0px !important;
		width: 100% !important;
		.boxSizing;
	}
	#header {
		position: relative !important;
		margin-bottom: 20px;
		color: @primaryColor !important;
	}
	#header h1 {
		color: @primaryColor !important;
	}
	#content {
		width: calc(100% - 2px) !important;
		margin: 0px !important;
		padding: 0px !important;
	}
}


/* ######################################################## */
/* PRINT*/
/* ######################################################## */

.form-error {
  display: block;
	font-size: 12px;
	line-height: 14px;
	color: @systemColor-error;
	width: 100%;
}


.noMainMenu .headerBG {
  display: none;
}
.noMainMenu #content {
  margin-top: 0px;

  @media (min-width: 1025px) {
    margin-top: 30px;
  }
}




.mat-radio-label span.mat-radio-label-content {
	white-space: pre-wrap !important;
}