/* /app/style.css */

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto-condensed-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
       url('../../fonts/roboto-condensed-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-condensed-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-condensed-v16-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/roboto-condensed-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Italic'), local('RobotoCondensed-Italic'),
       url('../../fonts/roboto-condensed-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-condensed-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-condensed-v16-latin-italic.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto-condensed-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
       url('../../fonts/roboto-condensed-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-condensed-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-condensed-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-condensed-v16-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto-slab-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
       url('../../fonts/roboto-slab-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-slab-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-slab-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-slab-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-slab-v7-latin-regular.svg#RobotoSlab') format('svg'); /* Legacy iOS */
}


.font-special {
	font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
}


.font-main {
	font-family: 'Roboto Condensed', sans-serif;
}
/*
	font-weight: 400,700
*/

/* .font-special {
	.font-main;
} */

.font-special-home {
	font-family: 'Roboto Condensed', sans-serif;
	/*font-family: 'Dosis', sans-serif;*/
}
/*
	font-weight: 700
*/
