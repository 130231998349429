.header-eventlink {
	display: block;
	font-size: 18px;
	position: absolute;
	left: 10px;
	top: 50%;
	line-height: 30px;
	margin-top: -15px;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 0px;
	padding-right: 8px;
	border-right: 1px solid rgba(255,255,255,0.35);
	display: flex;
	align-items: center;
}
.header-eventlink small {
	font-size: 10px;
	display: block;
	padding-left: 3px;
}

.selfCheckinLink-CTA {
	background-color: @accentColor;
	color: @accentColor-text;
	display: inline-block;
	margin-top: 20px;
	padding: 15px 20px;
	font-size: 18px;
	border-radius: 10px;
	.boxShaddow-big-bright;
	margin-bottom: 15px;
	white-space: nowrap;
	max-width: 280px;
	.boxSizing;
	overflow: hidden;
	text-overflow: ellipsis;
}


.checkin-overview, .checkin-numbers {
	padding-bottom: 120px;
}

.checkin-overview header {
	/*background-color: @dividerColor;*/
	padding: 10px;
	text-align: center;
}

.header-status {
	display: block;
	margin: 0px auto;
	border-radius: 50px;
	padding: 10px 30px;
	text-align: center;
	background-color: @dividerColor;
	color: @textColor;

	padding: 5px 30px;
	width: auto;
}
.header-status.resultNegative {
	background: @systemColor-ok;
	color: #fff;
}
.header-status.resultPositive {
	background: @systemColor-error;
	color: #fff;
}
.header-status.resultUndefined {
	background: @systemColor-warning;
	color: #fff;
}

.test-result-warning {
	background: @systemColor-error;
	border-radius: 100px;
	padding: 2px 10px;
	font-size: 12px;
	display: inline-block;
	margin-left: 5px;
	color: #fff;
}


#test-Actions {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	display: flex;
	background-color: #fff;
	.boxShaddow-bigger-bright;
	padding: 5px;
	z-index: 9;
}

#test-Actions a {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	text-align: center;
	line-height: 60px;
	margin: 5px;
	border: 1px solid	@accentColor;
	color: @accentColor;
	width: 100%;
	min-width: ~"calc(50% - 10px)";
	max-width: ~"calc(100% - 10px)";
	.boxSizing;
	border-radius: 7px;
}

#test-Actions .footerAction-main {
	.dropShaddow-small;
	background: @accentColor;
	color: @accentColor-text;
}

.footerAction-sub.button-warning {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 16px;
	text-align: center;
	line-height: 60px;
	margin: 40px 0px;
	border: 1px solid	@systemColor-warning;
	color: @accentColor;
	.boxSizing;
	border-radius: 7px;

	background: #fff;
	color: @systemColor-warning;
	border-color: @systemColor-warning;
}

/* ****** */

.checkin-overview header .image {
	width: 110px;
	height: 110px;
	overflow: hidden;
	margin: 0px auto 10px auto;
	border-radius: 100%;
	.boxShaddow-big-bright;
	background-color: #fff;
	.boxSizing;
}
.checkin-overview header .image img {
	width: 110px;
	height: 110px;
	border-radius: 100%;
	margin-bottom: -6px;
}

.checkin-overview header h2 {
	color: @primaryColor;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 10px;
	position: relative;
}
.visitorLanguage {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 1;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 100%;
	overflow: hidden;
	font-size: 12px;
	font-weight: bold;
	background-color: @accentColor;
	color: @accentColor-text;
	.font-main;
}
.isVIP-corner-ribbon {
	width: 200px;
	background: @accentColor;
	position: absolute;
	text-align: center;
	line-height: 30px;
	letter-spacing: 1px;
	color: @accentColor-text;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);

	top: 67px;
  left: -65px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
	.boxShaddow-small-bright;

	background: linear-gradient(-45deg, @accentColor, lighten(@accentColor, 15%), @accentColor);
	background-size: 200% 200%;
	-webkit-animation: vipBGGradient 3s ease infinite;
	-moz-animation: vipBGGradient 3s ease infinite;
	animation: vipBGGradient 3s ease infinite;

}

@-webkit-keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes vipBGGradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}



.checkin-overview .formContainer {
	padding: 5px 0px 2px 0px;
	margin: 0px -5px;
	background: @dividerColor;
	border-radius: 5px;
	margin-top: 20px;
}


.checkin-overview header .checkin-company {
	display: block;
	color: @primaryColor;
	font-size: 14px;
	line-height: 14px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 5px;
}
.checkin-overview header .checkin-basicComment {
	display: block;
	color: @textColor;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	.font-special;
	margin: 0px;
	padding: 0px;
	padding-top: 10px;
	padding-bottom: 5px;
	white-space: pre;
	.font-main;
}

.checkin-overview header .checkin-user-status {
	display: inline-block;
	background-color: #fff;
	border: 1px solid darken(@dividerColor,5%);
	color: @textColor;
	border-radius: 44px;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
	margin: 10px 0px;
	margin-bottom: 0px;
}
	.checkin-overview header .checkin-user-status.checkedIn {
		border-color: @systemColor-ok;
		color: @systemColor-ok;
	}
	.checkin-overview header .checkin-user-status.checkedOut {
		border-color: @systemColor-error;
		color: @systemColor-error;
	}

.checkin-subinfo {
	text-align: center;
	margin-top: 10px;
	border-bottom: 2px solid @dividerColor;
	padding-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
	.checkin-overview header .checkin-subinfo {
		border: none;
		padding-bottom: 0px;
	}
.checkin-subinfo .label {
	display: block;
	font-size: 12px;
	color: @textColor-light;
}
.checkin-subinfo .content {
	display: block;
	font-size: 16px;
	line-height: 18px;
	color: @textColor;
}
.checkin-subinfo strong {
	color: @primaryColor;
}

.checkin-subinfo .content.tag-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}
.checkin-subinfo .content.tag-container .tag {
	display: block;
	padding: 3px 5px;
	font-size: 12px;
	line-height: 12px;
	background-color: @textColor-light;
	color: #fff;
	margin: 5px;
	border-radius: 5px;
}


.checkin-subinfo-divider {
	background-color: @textColor-light;
	color: #fff;
	line-height: 12px;
	font-size: 12px;
	padding: 5px;
	text-align: center;
	display: block;
	border-radius: 10px;
}

.checkin-numbers {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 10px;
}
.checkin-numbers .number {
	display: block;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	font-size: 30px;
	color: @accentColor-text;
	background-color: @accentColor;
	margin: 10px;
	border-radius: 3px;
}

#checkin-footer {
	background-color: #fff;
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	padding: 10px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	.boxShaddow-small;
	background-color: @primaryColor;
	padding-bottom: 10px;
	z-index: 9;

	@supports (padding-bottom: env(safe-area-inset-bottom)) {
	  /*bottom: ~"calc(44px + env(safe-area-inset-bottom))";*/
	  padding-bottom: ~"calc(env(safe-area-inset-bottom) + 10px)";
	}
}
.footer-message {
	color: @primaryColor-text;
	font-size: 12px;
	line-height: 12px;
}

	.insideApp #checkin-footer {
		padding-bottom: 10px !important;

		@supports (margin-bottom: env(safe-area-inset-bottom)) {
		  /*bottom: ~"calc(44px + env(safe-area-inset-bottom))";*/
		  bottom: env(safe-area-inset-bottom);
		}
	}


/* md-dialog-actions 	.checkin-button {
	display: block;
	color: @textColor;
	text-align: center;
	line-height: 50px;
	border-radius: 3px;
	margin: 0px 10px;
	width: ~"calc(50% - 20px)";
	font-size: 16px;
	color: #fff;
}
md-dialog-actions .checkin-button.important {
	color: @primaryColor;
	background: #fff;
	.boxShaddow-big-bright;
	font-size: 24px;
} */

#checkin-footer .checkin-button {
	display: block;
	color: @textColor;
	text-align: center;
	line-height: 16px;
	border-radius: 7px;
	margin: 0px 10px;
	width: ~"calc(50% - 20px)";
	font-size: 16px;
	color: #fff;
	padding: 20px 0px 15px 0px;
}
#checkin-footer .checkin-button .icons {
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	padding-bottom: 7px;
	display: block;
}
#checkin-footer .checkin-button small {
	font-size: 9px;
	line-height: 9px;
	display: block;
	text-align: center;
	text-transform: uppercase;
}
#checkin-footer .checkin-button.important {
	color: @primaryColor;
	background: #fff;
	.boxShaddow-big-bright;
}

/* note */
.checkin-subinfo.hasForm textarea {
	background-color: #fff;
	border: 1px solid @primaryColor;
	padding: 5px;
	border-radius: 5px;
	color: @primaryColor;
	font-size: 17px;
	line-height: 17px;
	.boxSizing;
	width: 100%;
}


/* escort */
.escort-items {
	display: flex;
	flex-wrap: wrap;
	margin: 5px 0px 20px 0px;
}
.escort-items .escort-item {
	display: block;
	width: 100%;
	padding: 0px;
	.boxSizing;
	color: @primaryColor;
	position: relative;

	margin-bottom: 10px;
}
.escort-items .escort-item .option {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -7px;
	height: 14px;
	font-size: 15px;
	line-height: 15px;
	display: flex;
	align-items: center;
}
.escort-items .escort-item .name {
	display: flex;
	padding-right: 35px;
}
.escort-items .escort-item .name small {
	color: @textColor;
	font-size: 12px;
	display: block;
	text-align: center;
	padding-top: 3px;
}
.escort-items .escort-item .name input {
	background-color: #fff;
	border: 1px solid @primaryColor;
	padding: 5px;
	border-radius: 5px;
	color: @primaryColor;
	font-size: 17px;
	line-height: 17px;
	.boxSizing;
	width: ~"calc(50% - 5px)";
}
.escort-items .escort-item .name input:last-child {
	margin-left: 10px;
}
.escort-items .escort-add {
	display: block;
	width: 100%;
	text-transform: uppercase;
	padding: 10px 10px;
	border-radius: 7px;
	background-color: @accentColor;
	color: @accentColor-text;
	position: relative;
	font-size: 13px;
	line-height: 13px;
}

.escort-items .save-abort {
	display: flex;
	width: 100%;
	margin-top: 10px;
}
.escort-items .save-abort .cancel-btn {
	display: block;
	width: ~"calc(50% - 5px)";
	text-align: center;
	background-color: transparent;
	padding: 10px 0px;
	font-size: 15px;
	line-height: 15px;
	color: @systemColor-error;
	background-color: #fff;
	border-radius: 7px;
	.boxSizing;
	border: 1px solid @systemColor-error;
}
.escort-items .save-abort .save-btn {
	display: block;
	width: ~"calc(50% - 5px)";
	text-align: center;
	margin-left: 10px;
	background-color: @systemColor-ok;
	font-size: 15px;
	padding: 10px 0px;
	line-height: 15px;
	color: #fff;
	border-radius: 7px;
	.boxSizing;
	border: 1px solid @systemColor-ok;
}

.event-count {
	background-color: fade(@primaryColor,100%);
	display: flex;
	flex-wrap: wrap;
	padding: 5px 5px 10px 10px;
	justify-content: center;
	position: relative;
	z-index: 1;
	.boxShaddow-big-bright;
}
.event-count .tag {
	margin: 5px 5px 0px 0px;
	display: block;
	font-size: 11px;
	line-height: 11px;
	padding: 0px 5px;
	color: @primaryColor-light;
	border-left: 1px solid rgba(255,255,255,0.5);
	background-color: transparent;
}
.event-count .tag:first-child {
	border-left: none;
}


/* Checkin-options */
.checkin-option-print {
	margin: 20px 10px;
	color: @textColor;
	text-transform: uppercase;
}

/* guest-checkin */

.guest-checkin {

}
.guest-checkin .header {
	padding: 10px 20px;
	text-align: left;
	color: @textColor;
	font-size: 13px;
	line-height: 16px;
}
.guest-checkin .header span.guest {
	display: block;
}

.guest-checkin .guest-list {
	.blankList;
}
.guest-list h3 {
	margin: 0px;
	padding: 0px;
	font-size: 15px;
	line-height: 15px;
	color: @primaryColor;
	padding: 5px 10px;
	background: @dividerColor;
	margin: 25px 0px 5px 0px;

}
	.guest-list h3.headline-checkedin {
		color: @systemColor-ok;
	}
	.guest-list h3.headline-checkedout {
		color: @systemColor-error;
	}
	.guest-list h3.headline-open {
		color: @textColor;
	}
.guest-list h3 .icon-count {
	display: inline-block;
	margin-right: 5px;
	background: @primaryColor-text;
	padding: 3px 10px 3px 10px;
	border-radius: 10px;
	.dropShaddow-small;
}
.guest-list h3 .icon-count .count {
	display: inline-block;
	padding-left: 5px;
	font-size: 14px;
}
.guest-list h3 small {
	font-size: 11px;
	font-weight: bold;
	.font-main;
}

.guest-checkin .guest-list a {
	line-height: 16px;
	font-size: 16px;
	position: relative;
	padding: 10px 10px 10px 30px;
	border-radius: 10px;
	background-color: #fff;
	margin: 10px;
	cursor: pointer;
	.boxShaddow-big-bright;
	color: @textColor;
	outline: none;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	.boxSizing;
	border: 1px solid darken(@dividerColor, 5%);
}
	.guest-checkin .guest-list a.checkedIn {
		color: @primaryColor;
		background: @dividerColor;
		.boxShaddow-none;
		cursor: default;
	}
	.guest-checkin .guest-list a.selected {
		color: @primaryColor;
		border-color: @primaryColor;
	}
.guest-checkin .guest-list a .checkedin-icon {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 1;
	display: block;
	display: none;
}
	.guest-checkin .guest-list a.checkedIn .checkedin-icon {
		display: block;
	}
.guest-checkin .guest-list a .selected-icon-false {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 1;
	display: block;
}
	.guest-checkin .guest-list a.selected .selected-icon-false {
		display: none;
	}
	.guest-checkin .guest-list a.checkedIn .selected-icon-false {
		display: none;
	}
.guest-checkin .guest-list a .selected-icon-true {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 1;
	display: none;
}
	.guest-checkin .guest-list a.selected .selected-icon-true {
		display: block;
	}

.guest-list .meta-infos {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: flex-start;
	margin-top: 5px;
	color: @textColor;
}
.guest-list .meta-infos small {
	display: block;
	border-radius: 44px;
	background-color: #fff;
	border: 1px solid darken(@dividerColor,5%);
	padding: 3px 10px;
	margin: 5px 5px 0px 0px;
	font-size: 12px;
	line-height: 12px;
}

.checkin-option-print	{
	margin-top: 20px;
	border-top: 1px dashed darken(@dividerColor,5%);
	padding-top: 0px;
	padding-bottom: 20px;
	position: relative;
}
.checkin-option-print .label	 {
	background: #fff;
	color: @textColor;
	font-size: 12px;
	line-height: 12px;
	position: absolute;
	display: block;
	left: 5px;
	top: -6px;
	padding: 0px 5px;
}

.guest-options {
	margin-top: 40px;
}
.guest-options a {
	border: 1px solid @primaryColor;
	display: block;
	border-radius: 7px;
	margin: 10px;
	margin-bottom: 20px;
	position: relative;
	padding: 10px;
	color: @primaryColor;
	text-align: left;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
}
.guest-options a .icon {
	display: inline-block;
	padding-right: 10px;
}

.checkin-subinfo .begleitperson-container .header .option small {
	display: inline-block;
	border-radius: 44px;
	border: 1px solid darken(@dividerColor,5%);
	line-height: 12px;
	padding: 3px 5px 3px 3px;
	margin-left: 5px;
	margin-top: -3px !important;
}
.checkin-subinfo .begleitperson-container .header .option small.checkedOut {
	color: @systemColor-error;
}
.checkin-subinfo .begleitperson-container .header .option small.checkedIn {
	color: @systemColor-ok;
}

/* CORONA TEST */

.visitorTest-steps {
	padding: 10px;
	padding-bottom: 100px;


}

.visitorTest-steps header {
	padding: 0px 0px 0px 0px;
}
.visitorTest-steps header h3 {
	.font-special;
	color: @primaryColor;
	font-size: 24px;
	line-height: 24px;
	padding: 0px;
	text-align: center;
	margin: 0px;
}
.visitorTest-steps header p {
	color: @primaryColor;
	font-size: 15px;
	line-height: 18px;
	padding: 0px;
	margin: 0px;
	text-align: center;
	padding-top: 10px;
}
.visitorTest-steps .chooseVisitor {
	.blankList;
}
.visitorTest-steps .chooseVisitor li {
	margin-top: 10px;
}
.visitorTest-steps .chooseVisitor a {
	display: block;
	border-radius: 7px;
	.dropShaddow-small;
	background: @accentColor;
	color: @accentColor-text;
	padding: 15px 30px 15px 10px;
	position: relative;
}
.visitorTest-steps .chooseVisitor a .mainInfo {
	display: block;
	font-size: 18px;
	line-height: 18px;
}
.visitorTest-steps .chooseVisitor a .subInfo {
	display: block;
	font-size: 14px;
	line-height: 14px;
	padding-top: 10px;
}
.visitorTest-steps .chooseVisitor a .icon {
	display: block;
	font-size: 20px;
	line-height: 20px;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -10px;
}
.visitorTest-steps .inputContainer {
	background: #fff;
	border-radius: 10px;
	padding: 10px;
	.boxShaddow-bigger-bright;
	border: 1px solid darken(@dividerColor, 15%);
}
.visitorTest-steps .inputContainer.success-border {
	border-color: @primaryColor;
}



.inputContainer .success-msg-big {
	text-align: center;
	margin: 20px;

}
.inputContainer .success-msg-big .icon {
	font-size: 150px;
	line-height: 150px;
	display: block;
	text-align: center;
	color: @primaryColor !important;
}

.inputContainer .success-msg-big .info {
	display: block;
	text-align: center;
	margin: 15px auto 40px auto;
	padding-bottom: 20px;
	font-size: 13px;
	color: @primaryColor;
	border-bottom: 1px solid @dividerColor;
}




.visitorTest-steps .inputContainer h3 {
	color: @accentColor;
	font-size: 16px;
	line-height: 16px;
	padding: 10px 0px 0px 10px;
	margin: 0px;
}

.visitorTest-steps .inputContainer .input-splitter {
	padding-right: 50px;
	position: relative;
}
.visitorTest-steps .inputContainer .input-splitter .button {
	position: absolute;
	right: 0px;
	bottom: 7px;
	border-radius: 7px;
	.dropShaddow-small;
	line-height: 40px;
	width: 40px;
	text-align: center;
	background: @accentColor;
	color: @accentColor-text;
	font-size: 18px;
}
.visitorTest-steps .inputContainer .input-splitter .button small {
	display: none;
}
.visitorTest-steps .inputContainer label {
	.boxSizing;
	padding-left: 15px !important;
}
.visitorTest-steps .inputContainer input {
	border: 1px solid @textColor !important;
	color: @primaryColor !important;
	background: #fff !important;
	font-size: 14px;
	height: 40px;
	.boxSizing;
	padding: 5px 10px;
	border-radius: 7px;
}
.visitorTest-steps .inputContainer textarea {
	border: 1px solid @textColor !important;
	color: @primaryColor !important;
	background: #fff !important;
	font-size: 14px;
	.boxSizing;
	padding: 5px 10px;
	border-radius: 7px;
}
.visitorTest-steps .inputContainer md-input-container .md-input[disabled] {
	color: @textColor !important;
}



.visitorTest-steps .button-mainAction {
	display: block;
	border-radius: 7px;
	.dropShaddow-small;
	background: @accentColor;
	color: @accentColor-text;
	padding: 20px 20px;
	text-align: center;
	margin-top: 40px;
	font-size: 20px;
}

.visitorTest-steps .button-subAction {
	display: block;
	border-radius: 7px;
	background: @accentColor-text;
	color: @accentColor;
	border: 1px solid @accentColor;
	line-height: 30px;
	margin-top: 30px;
	text-align: center;
	font-size: 16px;
}

.visitorTest-steps .testResultSelection {
	.blankList;
	margin-top: 20px;
	margin-bottom: 60px;
	padding-bottom: 40px;
	border-bottom: 1px solid @dividerColor;
}
.visitorTest-steps .testResultSelection li {
	margin-bottom: 10px;
	border: 2px solid @textColor-light;
	background-color: #fff;
	/*.boxShaddow-bigger-bright; */
	color: @textColor-light;
	border-radius: 7px;
	outline: none;
	cursor: pointer;
	line-height: 50px;
	position: relative;
	padding: 0px 10px;
}
.visitorTest-steps .testResultSelection li .icon {
	display: inline-block;
	padding-right: 20px;
}
.visitorTest-steps .testResultSelection li .checked {
	display: none;
}
.visitorTest-steps .testResultSelection li.active .checked {
	display: inline-block;
	color: #fff !important;
}
.visitorTest-steps .testResultSelection li.active .checked .fa-check-circle {
	color: #fff !important;
}
.visitorTest-steps .testResultSelection li.active .unchecked {
	display: none;
}
.visitorTest-steps .testResultSelection li.active {
	margin-bottom: 10px;
	border-color: @textColor-light;
	background: @textColor-light;
	color: #fff;
}

.visitorTest-steps .testResultSelection li.resultNegative {
	border-color: @systemColor-ok;
	color: @systemColor-ok;
}
.visitorTest-steps .testResultSelection li.active.resultNegative {
	background-color: @systemColor-ok;
	color: #fff;
}
.visitorTest-steps .testResultSelection li.resultPositive {
	border-color: @systemColor-error;
	color: @systemColor-error;
}
.visitorTest-steps .testResultSelection li.active.resultPositive {
	background-color: @systemColor-error;
	color: #fff;
}

.visitorTest-steps .testResultSelection li.resultUndefined {
	border-color: @systemColor-warning;
	color: @systemColor-warning;
}
.visitorTest-steps .testResultSelection li.active.resultUndefined {
	background-color: @systemColor-warning;
	color: #fff;
}
