md-input-container.cp-input-has-value {
  label.md-placeholder {
    opacity: 1;
    top: inherit;
  }

  label {
    //-webkit-transform: translate3d(0, 6px, 0) scale(0.75);
    transform: translate3d(0, 6px, 0) scale(0.75) !important;
  }

  md-select-value.md-select-placeholder span:first-child {
    opacity: 0;
  }
}

md-input-container.md-autocomplete-no-shadow {
  md-autocomplete-wrap {
    box-shadow: none;
  }
}

md-tooltip.md-tooltip {
  height: auto;
  white-space: normal;
}

md-switch.no-margin {
  margin: 0;
}

md-dialog.md-content-overflow {
  overflow: hidden;
}

md-dialog-content.large-dialog-content {
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: scrollbar;
}

md-switch {
  &[disabled] {
    .md-bar {
      background-color: rgba(0, 0, 0, 0.12) !important;
      border-color: rgba(0, 0, 0, 0.12) !important;
    }
    .md-container {
      .md-thumb {
        &.md-ink-ripple {
          background-color: rgb(189, 189, 189) !important;
        }

        &:before {
          background-color: rgba(0, 0, 0, 0.12) !important;
        }
      }

      cursor: not-allowed;
    }
  }
}

body.md-toast-animating {
  //overflow: auto !important;
  overflow: visible !important;
}

md-dialog .md-actions, md-dialog md-dialog-actions {
	border-top: 1px solid @primaryColor;
}
md-content.md-default-theme, md-content {
	background-color: #fff !important;
}

md-dialog md-dialog-actions {
	z-index: 3;
}


md-input-container.floating-label-always label {
  -webkit-transform: translate3d(0,6px,0) scale(.75) !important;
  transform: translate3d(0,6px,0) scale(.75) !important;
  -webkit-transition: width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
  transition: width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
  transition: transform .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1) !important;
  transition: transform .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1),-webkit-transform .4s cubic-bezier(.25,.8,.25,1) !important;
  opacity: 1 !important;
  top: auto !important;
}
md-input-container.floating-label-always .md-select-placeholder > span:not(.md-select-icon) {
  color: transparent;
}
md-input-container.floating-label-always .md-select-placeholder > span:not(.md-select-icon):after {
  content: none !important;
}