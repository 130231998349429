@primaryColor:        #424b5c; /* #383f47 // blau: #003b71 */
@primaryColor-light:  #d8ff00; /* #99a3af // hellblau_ #28b4cf || */
@primaryColor-dark:   #2b313d; /* #1e1e24 ||  */
@primaryColor-text:   #FFFFFF;

@accentColor:        	#ff0084; /* #1dda98 // gold: #be932a  // tuerkis: #42c096 */
@accentColor-text:    #ffffff; /* guestoo: #ffffff ||  */

@accentSecondaryColor: #00b6cf;
@accentSecondaryColor-text: #fff;

@textColor:   				#646F79; /* guestoo: #6c7174 ||  */
@textColor-light: 		#85929F; /* guestoo: #aab2b5 ||  */
@textColor-dark: 			#541c4b;

@dividerColor:				#dfeaec; /* guestoo: #eceff0 ||  */
@backgroundColor:			#FFFFFF; /* guestoo: #ffffff ||  */

@systemColor-error:   #ff0042;
@systemColor-ok:   		#7ed321;
@systemColor-warning: #ff8e0f;
@systemColor-neutral: #B6B6B6;
@systemColor-delete:  @systemColor-error;
@systemColor-add:  		@systemColor-ok;

@modalBG: rgba(255,255,255,0.75);

.headerBG-setting {
	/*
	background: fade(@primaryColor,85%);
	backdrop-filter: blur(7px);
	*/
	background: #163d79;
}

@borderRadius-content: 10px;
@borderRadius-CTA: 7px;
