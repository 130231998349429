.gridNew-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: flex-start;
	margin-right: 10px;
}

.gridNew-item {
	margin: 10px 0px 0px 10px;
}
	.gridNew-item.displayFlex {
		display: flex;
	}
	
/* items */	
.gridNew-size-1-1 {
	width: 100%;
}
.gridNew-size-1-2 {
	width: ~"calc(50% - 10px)";
}
.gridNew-size-1-3 {
	width: ~"calc(33.333% - 10px)";
}
.gridNew-size-1-4 {
	width: ~"calc(25% - 10px)";
}
.gridNew-size-3-4 {
	width: ~"calc(75% - 10px)";
}
.gridNew-size-1-5 {
	width: ~"calc(20% - 10px)";
}
.gridNew-size-2-5 {
	width: ~"calc(40% - 10px)";
}
.gridNew-size-4-5 {
	width: ~"calc(80% - 10px)";
}
.gridNew-size-2-3 {
	width: ~"calc(66.666% - 10px)";
}

/* ######################################################## */
/* responsive */
/* ######################################################## */
@media (max-width: 650px) {
	.gridNew-container {
		margin-left: 10px;
		margin-right: 10px;
		display: block;
	}
	.gridNew-container .gridNew-container {
		margin-left: 10px;
		margin-right: 10px;
	}
	.gridNew-item {
		width: auto;
		display: block;
		margin-left: 0px;
		margin-right: 0px;
	}
	
}