.referencelist-style01 {
	padding-top: 10px;
}

.referencelist-style01 .fileupload {
	border-radius: 7px;
	border: 1px dashed lighten(@textColor-light,15%);
	margin-bottom: 10px;
	background-color: @dividerColor;
	margin: 0px;
	margin-bottom: 20px;
}
.referencelist-style01 .fileupload a {
	display: block;
	text-align: center;
	padding: 20px;
}
.referencelist-style01 .fileupload a .icons {
	display: block;
	text-align: center;
	color: @primaryColor;
	font-size: 26px;
	line-height: 26px;
}
.referencelist-style01 .fileupload a .icons .icon {
	color: @primaryColor;
	font-size: 26px;
	line-height: 26px;
	display: inline-block;
	padding: 3px;
}
.referencelist-style01 .fileupload a .legende {
	font-size: 16px;
	line-height: 16px;
	color: @primaryColor;
}
.referencelist-style01 .fileupload a .legende small {
	font-size: 12px;
	display: block;
	color: @textColor-light;
	padding-top: 10px;
}

/* Warteschleife */

.referencelist-style01 .warteschleife {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: stretch;
}
.referencelist-style01 .warteschleife .referenceItem-inProgress {
	background-color: @dividerColor;
	margin: 10px;
	position: relative;
	
}
.referencelist-style01 .warteschleife .referenceItem-inProgress h3 {
	font-size: 12px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 20px 5px;
	text-align: center;
	color: @systemColor-warning;
	padding: 0px;
	padding-top: 30px;
	max-width: 130px;
}
.referencelist-style01 .warteschleife .referenceItem-inProgress .status-container {
	position: absolute;
	bottom: 0px;
	background-color: darken(@dividerColor,15%);
	height: 5px;
	left: 0px;
	right: 0px;
	z-index: 1;
}
.referencelist-style01 .warteschleife .referenceItem-inProgress .status-container .status {
	height: 5px;
	background-color: @systemColor-ok;
	width: 0px;
}
.referencelist-style01 .warteschleife .referenceItem-inProgress a.stop {
	position: absolute;
	top: 10px;
	border: 1px solid @systemColor-delete;
	.boxSizing;
	border-radius: 100%;
	color: @systemColor-delete;
	font-size: 15px;
	width: 30px;
	height: 30px;
	text-align: center;
	left: 50%;
	margin-left: -15px;
	line-height: 28px;
	z-index: 1;
}

/* presenter */

.referencelist-style01 .referenceItem-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: left;
}
.referencelist-style01 .referenceItem-container .referenceItem {
	margin: 10px;
	position: relative;
	max-width: 200px;
}
	.referencelist-style01 .referenceItem-container.contentIsPDF .referenceItem {
		max-width: 150px;
	}

.referenceItem-container.backendContainer {
	margin-left: -10px !important;
	margin-right: -10px !important;
}

.referencelist-style01 .referenceItem-container.backendContainer .referenceItem.backendItem {
	margin: 10px !important;
	width: ~"calc(20% - 20px)" !important;
	max-width: 100% !important;
	
	@media (max-width: 1280px) {
		width: ~"calc(25% - 20px)" !important;
		max-width: 100% !important;
	}
	
	@media (max-width: 900px) {
		width: ~"calc(33.33% - 20px)" !important;
		max-width: 100% !important;
	}
	
	@media (max-width: 650px) {
		width: ~"calc(50% - 20px)" !important;
		max-width: 100% !important;
	}
	
}
	
.referencelist-style01 .referenceItem-container .referenceItem .moreContent	{
	background-color: @dividerColor;
	border-radius: 10px;
	margin-top: 10px;
	position: relative;
	.boxSizing;
	padding: 20px 10px;
}
.referencelist-style01 .referenceItem-container .referenceItem .moreContent:after 
	{
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 10px 8px;
	border-color: @dividerColor transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -8px;
	left: 50%;
	margin-left: -10px;
	}
	.moreContent .edit-button {
		width: 100% !important;
		margin: 20px 0px -10px 0px !important;
		text-align: center !important;
		padding: 5px 0px !important;
		font-size: 12px !important;
		line-height: 12px !important;
	}
	.moreContent md-input-container .md-input {
		font-size: 14px !important;
		line-height: 16px !important;
		color: @textColor !important;
		padding: 3px 0px;
	}
	
	
.referencelist-style01 .referenceItem-container .referenceItem .header {
	position: relative;
	border-radius: 3px;
	.boxShaddow-big;
	overflow: hidden;
}
.referencelist-style01 .referenceItem-container .referenceItem .header .image {
	width: 100%;
}
.referencelist-style01 .referenceItem-container .referenceItem .header .image img {
	width: 100%;
	margin-bottom: -6px;
}
.referencelist-style01 .referenceItem-container .referenceItem .header h3 {
	font-size: 18px;
	font-weight: 700;
	padding: 10px;
	margin: 0px;
	text-align: center;
	
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: fade(@primaryColor,65%);
	color: @primaryColor-text;
	backdrop-filter: blur(7px);
}
	.referencelist-style01 .referenceItem-container.contentIsPDF .referenceItem .header h3 {
		font-size: 12px !important;
		font-weight: 400 !important;
		white-space: nowrap !important;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
	}
.referencelist-style01 .referenceItem-container .referenceItem .infos {
}

.referencelist-style01 .referenceItem-container .referenceItem .infos p {
	font-size: 13px;
	color: @textColor;
	text-align: center;
	font-weight: 400;
	padding: 10px 20px;
	margin: 0px;
}


.referencelist-style01 .referenceItem-container .referenceItem .options {
	position: absolute;
	left: 50%;
	top: 15px;
	width: 40px;
	margin-left: -20px;
	border-radius: 40px;
	overflow: hidden;
	background-color: fade(#fff,95%);
	.boxShaddow-small;
	z-index: 1;
	white-space: nowrap;
	
}
.referencelist-style01 .referenceItem-container .referenceItem .options a {
	display: inline-block;
	width: 40px;
	line-height: 40px;
	text-align: center;
	color: @systemColor-ok;
}