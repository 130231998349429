mat-dialog-container {
  position: relative !important;

  button.closeDialog {
    border-radius: 0 0 0 50%;
    position: absolute;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 0;
    border-top: 0;
    right: 0;
    top: 0;
    cursor: pointer;

    &:hover {
      background-color: #dbdbdb;
    }
  }
}
