.tag-select{
	.blankList;
	display: flex;
	flex-wrap: wrap;
}
.tag-select li {
	border-radius: 7px;
	border: 1px solid @primaryColor;
	background-color: #fff;
	color: @primaryColor;
	font-size: 13px;
	line-height: 13px;
	padding: 10px;
	margin: 0px 10px 10px 0px;
}
.tag-select li.active {
	background-color: @primaryColor;
}
.tag-select li a {
	color: @primaryColor;
	display: block;
}
.tag-select li.active a {
	color: @primaryColor-text;
}

/* ######################################################## */
/* important-errors */
/* ######################################################## */
.important-errors {
	margin-top: 20px;
}
.important-errors.detailsPageInfo {
	margin-top: 80px;
	width: 100%;
	width: ~"calc(100% - 20px)";
	margin-bottom: -40px;
	margin-left: 10px;
	margin-right: 10px;

	@media (max-width: 550px) {
		margin: 40px 10px 0px 10px;
	}
}


.important-errors .error-message {
	margin-bottom: 20px;
	border: 1px solid	@systemColor-error;
	color: @systemColor-error;
	padding: 10px;
	min-height: 30px;
	.boxSizing;
	border-radius: 7px;
	position: relative;
}
.important-errors .error-message .icon {
	font-size: 18px;
	line-height: 20px;
	width: 20px;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	left: 10px;
	display: block;
}
.important-errors .error-message .text {
	font-size: 13px;
	line-height: 15px;
	padding-left: 32px;
	display: block;
}
	.important-errors.just-warning .error-message {
		margin-bottom: 20px;
		border: 1px solid	@systemColor-warning;
		color: @systemColor-warning;
		padding: 10px;
		min-height: 30px;
		.boxSizing;
		border-radius: 7px;
		position: relative;
	}
	.important-errors.just-info .error-message {
		border: 1px solid	@primaryColor;
		color: @primaryColor;
	}
	.important-errors .error-message a {
		color: @accentColor;
	}

/* ######################################################## */
/* EDIT CONTAINER */
/* ######################################################## */
	a.choose-preset {
		display: block;
		border-radius: 10px;
		border: 1px dashed @textColor-light;
		margin: 80px 10px -20px 10px;
		background: #fff;
		color: @textColor-light;
		text-align: center;
		width: ~"calc(100% - 20px)";
		padding: 20px 10px;
		.boxSizing;
		.boxShaddow-inside-bright;

		@media (max-width: 550px) {
			margin: 40px 10px 0px 10px;
		}
	}
	a.choose-preset .icon {
		display: block;
		font-size: 28px;
		text-align: center;
		padding-bottom: 5px;
	}
	a.choose-preset .small {
		display: block;
		font-size: 14px;
		text-align: center;
	}
/* ######################################################## */
/* EDIT CONTAINER */
/* ######################################################## */
.field-edit-container {
	position: fixed !important;
	right: @mainNav-width-closed;
	top: @header-height;
	z-index: 5;
	background-color: fade(#fff,65%);
	display: flex;
	justify-content: flex-end;
	border-radius: 20px 0px 0px 20px;
	.transition-default;
  .boxShaddow-big-bright-bottomFocused;
	padding: 0px;
  padding-right: 5px;
  margin-top: 5px;

	@media (max-width: 845px) {
		right: @mainNav-width-mobileClosed;
	}

	@media (min-width: 1025px) {
		top: @header-height;
	}


}
.field-edit-container-spacerBig {
  display: block;
  min-height: 55px;
}

.insideApp .field-edit-container {
	top: @header-height;

	@supports (top: env(safe-area-inset-top)) {
		top: calc(@header-height~" + env(safe-area-inset-top)");
	}

	@media (min-width: 1025px) {
		top: calc(@header-height~" + env(safe-area-inset-top)");
	}
}

@supports (top: env(safe-area-inset-top)) {
	top: calc(100px + env(safe-area-inset-top));
}

.field-edit-container-space {

	@media (max-width: 650px) {
			display: block;
			height: 70px;
	}
}
.field-edit-container-space-noImage {
	display: block;
	height: 50px;

	@media (max-width: 650px) {
			display: block;
			height: 70px;
	}
}


		.hasSubNav .field-edit-container {
			top: @header-height * 2;
			.transition-default;
		}
		.showMenue .field-edit-container {
			right: @mainNav-width-open;
			.transition-default;

			@media (max-width: 845px) {
				right: @mainNav-width-mobileClosed;
			}
		}
		.noMenue .field-edit-container {
			right: 0px;
		}
		.noHeader .field-edit-container {
			top: 0px !important;
		}
		.noMenue .field-edit-container {
			right: 0px !important;
		}

		.hasNewMenue.hasSubNav .field-edit-container {
			@media (min-width: 1025px) {
				top: @header-height * 2 + @mainNavNew-height;
			}
		}

		.hasNewMenue.hasSubNav .field-edit-container {
			@media (min-width: 1025px) {
				top: @header-height * 2 + @mainNavNew-height;
			}
		}


button.createButton {
	.boxSizing;
	display: inline-block;
	width: auto;
	border: 1px solid @primaryColor;
	color: @primaryColor-text;
	background-color: @primaryColor;
	padding: 10px 15px;
	border-radius: 7px;
	.dropShaddow-small;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	margin-top: 10px;
	font-weight: 400;
}
a.deleteButton	 {
	.boxSizing;
	display: inline-block;
	width: auto;
	border: 1px solid @systemColor-error;
	color: #fff;
	background-color: @systemColor-error;
	padding: 10px 15px;
	border-radius: 7px;
	.dropShaddow-small;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	margin-top: 10px;
	font-weight: 400;
}
.contentField h5 {
	.font-special;
	font-size: 16px;
	padding: 10px 0px 10px 0px;
	font-weight: normal;
	color: @primaryColor;
}
.field-edit-container .edit-button {
	border-radius: 20px !important;
  text-align: center;
  min-width: 120px !important;
	background-color: @accentColor;
	color: @accentColor-text;
	line-height: 50px;
	padding: 0px 10px;
	margin: 5px;
	cursor: pointer;
	border: none !important;
	/*.boxShaddow-small-bright; */
	.dropShaddow-small;

	&.secondary {
		box-shadow: none !important;
		background: #fff;
		color: @accentColor;

		&:hover {
			background: #fff;
			color: #2b313d;
		}
	}

	@media (max-width: 650px) {
		line-height: 40px;
	}
}
	.field-edit-container .edit-button:hover {
		background-color: @primaryColor-dark;
	}

.field-edit-container .edit-button.saveMe {
	background-color: @systemColor-ok;
	color: #fff;
}
.field-edit-container .edit-button.saveMe.outlined {
	background-color: #fff !important;
	color: @systemColor-ok;
	border: 1px solid @systemColor-ok !important;
	.boxSizing;
}
.md-button {
	min-width: 50px;
}
.field-edit-container .edit-button.cancelMe {
	background-color: @systemColor-error;
	color: #fff;
}
.contentField small.field-legend {
	color: @textColor-light !important;
	font-size: 13px !important;
	line-height: 15px !important;
}
.contentField .edit-button {
	border-radius: 3px;
	background-color: @accentColor;
	color: @accentColor-text;
	line-height: 50px;
	padding: 0px 15px;
	margin: 10px;
	cursor: pointer;
	border: none !important;
	border-radius: 7px !important;
}
.contentField .edit-button.saveMeBig {
	margin-left: 0px;
	margin-right: 0px;
	width: 100%;
	border-radius: 7px !important;
}
.contentField .edit-button.cancelMe {
	background-color: @dividerColor;
	color: @textColor;
}
.contentField .edit-button.removeMe {
	background-color: #fff;
	padding: 5px 10px;
	border: 1px solid @systemColor-error !important;
	.boxSizing;
	color: @systemColor-error !important;
	white-space: nowrap;
}
.contentField  .removeMeFullSize {
	width: ~"calc(100%)";
	display: block;
	margin: 10px 10px 10px 10px;
	padding: 0px;
	line-height: 20px;
	font-size: 12px;
	text-align: center;
}

.contentField .edit-button.removeMe.removeMeBig {
	display: block;
	width: 100%;
	background-color: @systemColor-error;
	color: #fff !important;
	text-align: center;
	font-size: 18px;
	border-radius: 7px;
}


.contentField .edit-button.removeMe:hover {
	background-color: @systemColor-error;
	color: #fff !important;
}
.contentField .edit-button:hover {
	background-color: @primaryColor;
	color: @primaryColor-text;
}

/* ######################################################## */
/* dateTime + Switcher */
/* ######################################################## */
.contains-dateTimeAndSwitcher {
	position: relative;
}
.contains-dateTimeAndSwitcher .dateTimeSwitcher {
	position: absolute;
	display: block;
	right: 10px;
	bottom: 15px;
	border-radius: 10px;
	text-align: center;
	border: 1px solid @primaryColor;
	font-size: 14px;
	line-height: 20px;
	white-space: nowrap;
	padding: 0px 5px;
	color: @primaryColor;
	background-color: #fff;
	cursor: pointer;
}
.contains-dateTimeAndSwitcher .dateTimeSwitcher small {
	font-size: 10px;
	text-transform: uppercase;
}
.contains-dateTimeAndSwitcher.openEnd .dateTimeSwitcher {
	background-color: @primaryColor;
	color: @primaryColor-text !important;
}
.contains-dateTimeAndSwitcher.openEnd .dateTimeSwitcher .inactiveIcon {
	display: none;
}
.contains-dateTimeAndSwitcher .activeIcon {
	display: none;
}
.contains-dateTimeAndSwitcher.openEnd .dateTimeSwitcher .activeIcon {
	display: inline;
}
.contains-dateTimeAndSwitcher.openEnd input[type="time"]{
	visibility: hidden;
}
.contains-dateTimeAndSwitcher .onlyActive {
	display: none;
}
.contains-dateTimeAndSwitcher.openEnd .onlyActive {
	display: block;
	cursor: default;
}
/* ######################################################## */
/* details-inline-table */
/* ######################################################## */
.details-inline-table {
	width: 100%;
	margin-top: 20px;
	border-top: 1px solid darken(@dividerColor,10%);
	margin-bottom: 20px;
	.boxShaddow-big-bright;
}
.details-inline-table td {
	background-color: @dividerColor;
	border-bottom: 1px solid darken(@dividerColor,10%);
	padding: 5px;
	color: @textColor;
	font-size: 14px;
	vertical-align: center;
}
.details-inline-table tr:nth-child(2) td {
	background-color: #fff;
}
.details-inline-table td:last-child {
	text-align: right;
	font-size: 15px;
}


/* ######################################################## */
/* content Basics */
/* ######################################################## */
.content-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: flex-start;
	margin-bottom: 50px;
}
.form-section {
	width: 100%;
	border: 1px solid darken(@dividerColor,5%);
	.boxSizing;
	padding: 0px;
	padding-bottom: 30px;
	border-radius: 10px;
	/*.boxShaddow-big-bright;*/
	.boxShaddow-big-bright-bottomFocused-small;
	display: flex;
	margin: 10px;
	margin-top: 30px;
	flex-wrap: wrap;

	@media (max-width: 650px) {
		margin: 20px 0px;
	}
}

.form-section.hasSettings {
	position: relative;
}


.form-section.activatedContent.showMeNot {
	border-color: @systemColor-ok;
}
	.form-section.activatedContent.showMeNot:after {
		display: block;
		content: '';
		border-radius: 20px;
		background-color: @primaryColor;
		position: absolute;
		left: 50%;
		top: -4px;
		z-index: 1;
		height: 8px;
		width: 30px;
		margin-left: -15px;

		display: none;
	}

	.showActivateddInfo {
		display: none;
	}
	.form-section.activatedContent.showMeNot .showActivateddInfo {
		display: block;
		width: 22px;
		border-radius: 20px;
		position: absolute;
		background-color: #fff;
		left: 50%;
		top: -10px;
		z-index: 1;
		text-align: center;
		margin-left: -11px;
	}



	.form-section.importantContent {
		border: 1px solid @primaryColor;
	}

	/* CSECTION HELP */
	.section-help {
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		color: @accentColor;
		font-size: 20px;
		line-height: 20px;
		width: 30px;
		height: 30px;
		text-align: right;
		z-index: 3;
	}

	.section-options {
		display: flex;
		position: absolute;
		right: 10px;
		top: 10px;
		color: @accentColor;
		font-size: 20px;
		line-height: 20px;
		height: 30px;
		text-align: right;
		z-index: 3;
	}

	.section-options a {
		display: block;
		color: @accentColor;
		font-size: 18px;
		line-height: 20px;
		height: 30px;
		z-index: 3;
		margin-left: 10px;
	}


	.viewEdit {
		.font-special;
		font-size: 16px;
		border-radius: 44px;
		border: 1px dashed darken(@dividerColor,15%);
		line-height: 30px;
		text-transform: uppercase;
		display: block;
		text-align: center;
		width: 100%;
		color: @accentColor;
		margin-top: 60px;
		width: 280px;
		margin: 60px auto 0px auto;
	}



	/* COLLAPSABLE CONTENT */
	.collapsableContainer.form-section {
		position: relative;
		.transition-default;
	}
		.collapsableContainer.form-section .collapsableIcon {
			position: absolute;
			bottom: -20px;
			left: 50%;
			margin-left: -20px;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			.boxShaddow-big-bright;
			border: 1px solid darken(@dividerColor,5%);
			color: @primaryColor;
			line-height: 40px;
			text-align: center;
			background-color: #fff;
			overflow: hidden;
			outline: none;
		}
			.collapsableContainer.form-section .collapsableIcon a {
				display: block;
				.boxSizing;
				height: 40px;
				font-size: 26px;
				line-height: 40px;
				text-align: center;
			}
		.collapsableContainer.form-section.showMeNot .collapsableIcon a.showMore {
			color: @primaryColor;
		}
			.collapsableContainer.form-section.showMe .collapsableIcon a.showMore {
				display: none;
			}
		.collapsableContainer.form-section.showMeNot .collapsableIcon a.showLess {
			display: none;
		}
			.collapsableContainer.form-section.showMe .collapsableIcon a.showLess {
				display: block;
				color: @textColor-light;
			}

		.collapsableContainer.form-section.showMeNot .hiddenIfShowMeNot,
		.collapsableContainer.form-section.showMeNot .contentField.width-100,
		.collapsableContainer.form-section.showMeNot .contentField.width-50 {
			height: 0px;
			overflow: hidden;
			.transition-default;
			margin-bottom: 0px !important;
			margin-top: 0px !important;
			.boxSizing;

		}

		.collapsableContainer.form-section.showMe .contentField.width-100,
		.collapsableContainer.form-section.showMe .contentField.width-50 {
			height: auto;
			overflow: visible;
			.transition-default;
		}


	/* // COLLAPSABLE CONTENT */




.contentField {
	.boxSizing;
	padding: 0px 10px;
	margin-top: 30px;

	@media (max-width: 650px) {
		/*padding: 0px;*/
	}
}
.form-section .content-divider {
	margin-top: 20px !important;
}

.contentField.smallMargin {
	margin-top: 15px;
}
.contentField.noMargin {
	margin-top: 0px;
}
.contentField.bigMargin {
	margin-top: 50px;
}
.contentField h4 {
	color: @primaryColor-dark;
	padding: 0px 0px 0px 0px;
	font-size: 26px;
	line-height: 26px;
}
.contentField .callToAction-small {
	display: block;
	border: 1px solid @primaryColor;
	background-color: #fff;
	color: @primaryColor;
	font-size: 12px;
	line-height: 12px;
	text-transform: uppercase;
	padding: 10px;
	margin-top: 10px;
	border-radius: 10px;
	cursor: pointer;
}
	.contentField .callToAction-small.error {
		border-color: @systemColor-error;
		color: @systemColor-error;
	}
.contentField p {
	color: @primaryColor-dark;
	margin: 0px;
	padding-top: 10px;
	font-size: 14px;
	line-height: 16px;
}
.contentField p:first-child {
	padding-top: 0px;
}

.contentField .label-special {
	background-color: @dividerColor;
	color: @textColor;
	width: 100%;
	.boxSizing;
	display: block;
	padding: 5px;
	font-size: 12px;
	line-height: 12px;
	margin-bottom: 10px;
}

.label-special-2 {
	color: @textColor;
	.boxSizing;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 14px;
	text-align: left;
	position: relative;
	margin: 5px 5px 20px 5px;
	background-color: @dividerColor;
	border-radius: 10px;
	z-index: 0;

	@media (max-width: 650px) {
		margin: 0px;
		border-radius: 0px;
	}
}
.label-special-2 .help-icon {
	position: absolute;
	display: block;
	cursor: pointer;
	right: 10px;
	top: 5px;
	color: @primaryColor;
}

.contentField-addRow {
	display: block;
	background-color: @textColor-light;
	color: #fff;
	text-align: center;
	padding: 7px 0px;
	font-size: 13px;
	line-height: 13px;
	text-transform: uppercase;
	margin-top: 10px;
	border-radius: 5px;
}
.contentField.hasSubField {
	display: flex;
	position: relative;
	flex-wrap: wrap;
}
.contentField .subField {

}
.contentField .subField.width-30 {
	width: 30%;
	.boxSizing;
}
.contentField .subField.width-70 {
	width: 70%;
	.boxSizing;
}
.contentField .subField.width-50 {
	width: 50%;
	.boxSizing;
}
.contentField .subField.width-45 {
	width: 45%;
	.boxSizing;
}
.contentField .subField.width-10 {
	width: 10%;
	.boxSizing;
}
.contentField .subField:last-child, .contentField .subField.paddingLeft {
	padding-left: 10px;
}

.subField-option {
	position: absolute;
	right: 0px;
	align-items: center;
	z-index: 2;
	width: 30px;
	height: 30px;
	background-color: fade(#fff,50%);
	display: flex;
	overflow: hidden;
	justify-content: center;
	align-content: center;
	top: 50%;
	margin-top: -15px;
}


/* - - - */
.contentField .special-info {
	border: 1px dashed darken(@dividerColor, 5%);
	background-color: @dividerColor;
	padding: 10px;
	display: flex;
	align-items: center;
	border-radius: 10px;
}
.contentField .special-info .icon {
	font-size: 20px;
	line-height: 20px;
	color: @primaryColor;
	padding-right: 15px;
}
.special-info .mini-intro {
	font-size: 12px;
	line-height: 14px;
	color: @primaryColor-dark;
	padding: 0px 0px 15px 0px;
	display: block;
}

.contentField .special-info h4 {
	font-size: 15px;
	line-height: 17px;
	padding: 0px;
	margin: 0px;
	padding-bottom: 5px;
}
.contentField .special-info p {
	font-size: 13px;
	line-height: 15px;
	color: @textColor;
	padding: 0px;
	margin: 0px;
}
.contentField .special-info p a {
	color: @primaryColor;
}
.contentField .special-info p a .notImportentPart {
	color: @textColor-light;
}


.contentField .contentField--label {
  color: rgba(0, 0, 0, 0.38);
  //transform: scale(0.75);
  //transform: scale(0.75);
  //transform-origin: left top;
}

.special-info ul.tag-like {
	.blankList;
	display: flex;
	flex-wrap: wrap;
}
.special-info ul.tag-like li {
	white-space: nowrap;
	display: block;
	padding: 5px 10px;
	border-radius: 5px;
	color: @textColor;
	font-size: 12px;
	line-height: 12px;
	margin: 5px 5px 0px 0px;
	background-color: #fff;
	outline: none;
}
.special-info ul.tag-like li.active {
	color: @primaryColor-text;
	background-color: @primaryColor;
}

.special-info ul.tag-like li li {
	color: @textColor-light;
}

.special-info ul.tag-like.bigFont li.copyThis {
	font-size: 14px;
}

.special-info ul.tag-like li.copyThis {
	cursor: pointer;
}
.special-info ul.tag-like li.copyThis:hover {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
.special-info ul.tag-like li .icon {
	line-height: 12px;
	width: auto;
	margin: 0px;
	padding: 0px;
	display: inline-block;
	margin-left: 5px;
	font-size: 15px;
	text-align: center;
	color: @primaryColor;
}
.special-info ul.tag-like li.copyThis:hover .icon {
	color: @primaryColor-text;
}
	.special-info ul.tag-like li.copyThis {
		font-size: 0px;
	}
	.special-info ul.tag-like li.copyThis i {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: inline-block;
		max-width: 180px;

		font-size: 12px;
	}



	.special-info ul.tag-like li a.editTagLikeElement {
		display: inline-block;
		font-size: 12px;
		margin-left: 2px;
		padding-left: 5px;
		border-left: 1px solid @dividerColor;
		margin-right: -3px;
	}
	.special-info ul.tag-like li a:hover {
		color: @primaryColor-text;
	}

	.special-info ul.tag-like .addItem {
		border: 1px solid @primaryColor;
		border-radius: 5px;
	}
		.special-info ul.tag-like .addItem a {
			color: @primaryColor;
			display: block;
		}
		.special-info ul.tag-like .addItem:hover {
			background: @primaryColor;
		}
		.special-info ul.tag-like .addItem:hover a, .special-info ul.tag-like .addItem:hover a:hover {
			color: @primaryColor-text;
		}


.fieldset {
  border: none;
  margin: 0;
}

/* - */
.contentField.width-50 {
	width: 50%;

	@media (max-width: 650px) {
		width: 100%;
	}
}
.contentField.width-100 {
	width: 100%;
}
.width-100-override {
		width: 100% !important;
}
.contentField.width-33 {
	width: 33.33%;

	@media (max-width: 650px) {
		width: 100%;
	}
}


.dividerLine {
	border-top: 1px solid @dividerColor;
	width: 100%;
}

/* - */
.contentField.content-divider {
	width: 100%;
	margin-top: 40px;
	position: relative;
}

.contentField.content-divider h4 {
	.font-special;
	font-weight: 400;
	margin: 0px;
	padding: 0px;

	padding-bottom: 20px;
	border-bottom: 1px solid @dividerColor;
	margin-bottom: 10px;
}
.contentField.content-divider h4 .section-icon {
	color: @primaryColor-dark;
	display: inline-block;
	position: relative;
	top: -3px;
	padding-right: 5px;
}

.contentField.content-divider a.content-divider-cta {
	display: block	;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 10px 5px;
	text-align: center;
	border-radius: 5px;
	font-size: 15px;
	line-height: 15px;
}
.contentField.content-divider a.content-divider-mini-cta {
	display: inline-block	;
	background-color: #fff;
	color: @primaryColor;
	border: 1px solid @primaryColor;
	padding: 2px 5px;
	border-radius: 5px;
}
.contentField.content-divider .content-divider-link {
	display: block;
	padding: 0px 10px;
	line-height: 30px;
	background-color: #fff;
	color: @primaryColor;
	border: 1px solid @primaryColor;
	font-size: 12px;
	text-transform: uppercase;
	border-radius: 10px;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -15px;
}
.contentField.content-divider .content-divider-link.active {
	color: @primaryColor-text;
	background-color: @primaryColor;
	cursor: pointer;
}
.contentField.content-divider .content-divider-link svg.fa-check-circle {
	color: @primaryColor-text !important;
}

.contentField a.deaktivateSomething {
	display: inline-block;
	padding: 0px 10px;
	line-height: 30px;
	background-color: @accentColor;
	color: #fff;
	font-size: 15px;
	text-transform: uppercase;
	border-radius: 5px;
	margin-top: 10px;
	cursor: pointer;
}
.contentField a.deaktivateSomething.disabled {
	background-color: @textColor-light;
	cursor: not-allowed;
}
.contentField a.deaktivateSomething.critical {
	background-color: @systemColor-warning;
}
.contentField a.deaktivateSomething .icon {
	display: inline-block;
	margin-right: 10px;
	color: #fff !important;
}
.contentField .miniMsg {
	display: block;
	padding: 5px 10px;
	border: 1px solid @textColor-light;
	color: @textColor-light;
	font-size: 12px;
	line-height: 14px;
	border-radius: 5px;
}
.contentField .miniMsg .icon {
	display: inline-block;
	margin-right: 10px;
}
.contentField .miniMsg.type-warning {
	border-color: @systemColor-warning;
	color: @systemColor-warning;
}

/* Basic Mini MSG */
.miniMsg {
	display: block;
	padding: 5px 10px;
	border: 1px solid @textColor-light;
	color: @textColor-light;
	font-size: 12px;
	line-height: 14px;
	border-radius: 5px;
}
.miniMsg .icon {
	display: inline-block;
	margin-right: 10px;
}
.miniMsg.type-warning {
	border-color: @systemColor-warning;
	color: @systemColor-warning;
}

.contentField .example {
	display: block;
	color: @textColor-light;
}

.contentField .bordered-list {
	.blankList;
	margin-left: 20px;
	border-left: 7px solid @dividerColor;
	margin: 20px 0px;
}
.contentField .bordered-list li {
	padding: 10px 0px 10px 10px;
	font-size: 14px;
	line-height: 18px;
	color: @textColor;
}

/* ######################################################## */
/* begleitpersonen Container */
/* ######################################################## */
.begleitperson-container {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 20px;
	.boxShaddow-big-bright;
	border-radius: 10px;
	.boxSizing;
	padding: 10px;
	position: relative;
	background-color: #fff;
}
.begleitperson-container .header {
	position: relative;
	color: @textColor-light;
	font-size: 12px;
	line-height: 30px;
	text-align: left;
	padding: 0px;
	margin: 0px;
}
	.checkin-subinfo .begleitperson-container .header {
		margin-top: -5px;
	}
.begleitperson-container .header span {
	color: @primaryColor;
	text-transform: uppercase;
}
.begleitperson-container .header .option {
	position: absolute;
	display: block;
	right: 0px;
	height: 30px;
	line-height: 30px;
	font-size: 16px;
	top: -2px;
}
	.checkin-subinfo .begleitperson-container .header .option {
		top: 10px;
		right: 0px;
	}
.begleitperson-container .mainInfo {
	display: flex;
	flex-wrap: wrap;
}
.begleitperson-container .subField:last-child {
	padding-left: 0px !important;
}

.begleitperson-container .mainInfo .inputItem {
	text-align: left;
	width: 50%;
}

/* ######################################################## */
/* content-switcherAsRow */
/* ######################################################## */
.content-switcherAsRow  {
	padding: 0px 0px 10px 0px;
	.boxSizing;
	border-radius: 0px 0px 5px 5px;
	font-size: 12px;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 0px auto;
}
.content-switcherAsRow label {
	width: 100%;
	padding: 10px 10px 0px 0px;
	color: @textColor-light;
	font-size: 12px;
	line-height: 14px;
}
.content-switcherAsRow md-switch {
	padding: 0px;
	margin: 10px 10px 0px 0px;
	width: auto;
	border: 1px solid @dividerColor;
	border-radius: 77px;
	padding: 15px;
}


/* ######################################################## */
/* content-optionSwitcher-big */
/* ######################################################## */
.content-optionSwitcher-big {
	padding: 0px;

	li.disabled a {
	  cursor: not-allowed;
	  pointer-events: none;
	}
}
.content-optionSwitcher-big .legende {
	font-size: 12px;
	color: @textColor-light;
	text-align: center;
	line-height: 16px;
	padding: 10px 0px 0px 0px;
	display: none;

	@media (max-width: 645px) {
		display: block;
	}
}
.content-optionSwitcher-big .switcherSubOption  {
	padding: 0px 5px;
	border-radius: 0px 0px 7px 7px;
	margin-bottom: 20px;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px auto;

	@media (max-width: 700px) {
		width: auto;
	}
}
.content-optionSwitcher-big .switcherSubOption md-input-container {
	padding: 0px;
	margin: 0px;
	width: auto;
}
.content-optionSwitcher-big .switcherSubOption a {
	color: @primaryColor;
}
	.content-optionSwitcher-big .subOption {
		border-bottom: 1px dashed @dividerColor;
	}
	.content-optionSwitcher-big .subOption a {
		color: @primaryColor;
	}


.content-optionSwitcher-big .options {
	background-color: @dividerColor;
	padding: 5px;
	border-radius: 77px;
	margin-top: 20px;
	.blankList;
	display: flex;
	align-items: stretch;
	align-content: stretch;
	border: 6px solid @dividerColor;
	margin-left: -5px;
	margin-right: -5px;
}
.content-optionSwitcher-big .options li {
	flex-grow: 1;
}
.content-optionSwitcher-big .options li a {
	display: block;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 40px;
	color: @textColor;
	text-align: center;
	border-radius: 44px;
	.transition-default;
}
.content-optionSwitcher-big .options li a > i {
	color: @textColor !important;
}
.content-optionSwitcher-big .options li.active a {
	.boxShaddow-small-bright;
	background-color: @primaryColor;
	color: @primaryColor-text;
	.transition-default;
}
.content-optionSwitcher-big .options li a.status-warning {
	color: @systemColor-warning !important;
	svg {
	  color: @systemColor-warning;
	}
}
.content-optionSwitcher-big .options li a.status-ok {
	color: @systemColor-ok !important;
	svg {
	  color: @systemColor-ok;
	}
}
.content-optionSwitcher-big .options li a.status-error {
	color: @systemColor-error !important;

	svg {
	  color: @systemColor-error;
	}
}
.content-optionSwitcher-big .options li a.status-notImportant {
	color: @textColor-light !important;

	svg {
	  color: @primaryColor-text;
	}
}
.content-optionSwitcher-big .options li a.status-veryImportant {
	color: @accentColor !important;

	svg {
	  color: @accentColor-text;
	}
}

.content-optionSwitcher-big .options li.active a {
	svg {
	  color: @primaryColor-text;
	}
}
.content-optionSwitcher-big .options li.active a.status-warning {
	background-color: @systemColor-warning;
	color: #fff !important;
}

.content-optionSwitcher-big .options li.active a.status-ok {
	background-color: @systemColor-ok;
	color: #fff !important;
	svg {
	  color: #fff;
	}
}
.content-optionSwitcher-big .options li.active a.status-error {
	background-color: @systemColor-error;
	color: #fff !important;
	svg {
	  color: #fff;
	}
}
.content-optionSwitcher-big .options li.active a.status-notImportant {
	background-color: @textColor-light;
	color: #fff !important;
	svg {
	  color: #fff;
	}
}
.content-optionSwitcher-big .options li.active a.status-veryImportant {
	background-color: @accentColor;
	color: @accentColor-text !important;
	svg {
	  color: @accentColor-text;
	}
}


/* ######################################################## */
/* content-optionSwitcher-tab */
/* ######################################################## */
.stickySwitcherInfo {
		position: sticky;
		top: 115px;
		background: #fff;
		z-index: 3;

	@media (max-width: 650px) {
		top: 150px;

	}
}
.content-optionSwitcher-tab {
	padding: 0px;
	padding-top: 40px;
	li.disabled a {
	}

	@media (max-width: 650px) {
		padding-top: 0px;

	}
}
.content-optionSwitcher-tab .legende {
	font-size: 12px;
	color: @textColor-light;
	text-align: left;
	line-height: 16px;
	padding: 10px 0px 5px 0px;
	display: block;

	@media (max-width: 645px) {
		display: block;
	}
}

.content-optionSwitcher-tab .options {
	margin-top: 20px;
	.blankList;
	display: flex;
	border-bottom: 2px solid @primaryColor;
	background-color: #fff;

	@media (max-width: 650px) {
		margin: 0px 0px 0px 0px;
		padding: 0px 0px 0px 0px;
		border-bottom: 2px solid @primaryColor;
	}
}

	.stickySwitcherInfo	.content-optionSwitcher-tab .options {
		width: ~"calc(100% - 250px)";

		@media (max-width: 650px) {
			width: auto;
		}
	}



.content-optionSwitcher-tab .options li {
	flex-basis: 0;
	flex-grow: 1;
}
.content-optionSwitcher-tab .options li a {
	display: block;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 25px;
	color: @textColor;
	text-align: center;
	border-radius: 10px 10px 0px 0px;
	margin-bottom: 0px;
	.transition-default;
}
.content-optionSwitcher-tab .options li a > i {
	color: @textColor !important;
}
.content-optionSwitcher-tab .options li.active a {
	background-color: @primaryColor;
	color: @primaryColor-text;
	.transition-default;
}

/* ######################################################## */
/* mainimage */
/* ######################################################## */
.content-mainimage {
	display: block;
	width: 100%;
	.boxSizing;
	padding: 10px;
	margin: 0px;
	position: relative;


	@media (max-width: 650px) {
			width: 260px;
			margin: 0px auto;
	}
	@media (min-width: 850px) {
			position: sticky;
			top: 140px;
	}
}

.content-mainimage.content-mainimage-inlined {
	@media (max-width: 650px) {
			width: 100%;
	}
}

.content-mainimage a {
	&[disabled] {
	  cursor: not-allowed;
	}
}
.content-mainimage img {
	width: 100%;
	.boxShaddow-big;

}
.content-mainimage.account-style {
	margin-top: 20px;
}
.content-mainimage.account-style img {

	@media (max-width: 650px) {
		border-radius: 100% !important;
		margin-top: 40px;
	}


}
.content-mainimage .icon-edit {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -20px 0px 0px -20px;
	height: 40px;
	width: 40px;
	overflow: hidden;
	line-height: 40px;
	padding: 0px;
	text-align: center;
	background-color: fade(@primaryColor,25%);
	border-radius: 100%;
	color: #fff;
	z-index: 1;
	font-size: 20px;
	cursor: pointer;
	pointer-events: none;
}
.content-mainimage .icon-delete {
	margin: 10px auto !important;
	padding: 0px !important;
	display: block;
	width: 100% !important;
	padding: 0px !important;
	.boxSizing;
	border: 1px solid @systemColor-error;
	overflow: hidden;
	line-height: 20px;
	font-size: 14px;
	text-align: center !important;
	border-radius: 44px;
	background-color: #fff;
}
.content-mainimage .icon-delete a {
	display: block;
}
.content-mainimage .uploadArea {
	border: 2px dashed darken(@dividerColor,15%);
	padding: 40px 20px;

	text-align: center;
	color: @primaryColor;
	display: block;
	background-color: #fff;
	.boxShaddow-small-bright;

	@media (min-width: 651px) {
		border-radius: 10px;
	}
}
.content-mainimage .uploadArea:hover {
	background-color: @primaryColor-light;
	color: @primaryColor-text;
	.boxShaddow-big;

	background: linear-gradient(271deg, @primaryColor, @primaryColor-light);
	background-size: 400% 400%;

	-webkit-animation: HoverGradient 5s ease infinite;
	-moz-animation: HoverGradient 5s ease infinite;
	-o-animation: HoverGradient 5s ease infinite;
	animation: HoverGradient 5s ease infinite;

	border-color: @primaryColor-text;
	border-style: solid;
}



@-webkit-keyframes HoverGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-moz-keyframes HoverGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@-o-keyframes HoverGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}
@keyframes HoverGradient {
    0%{background-position:0% 51%}
    50%{background-position:100% 50%}
    100%{background-position:0% 51%}
}


.content-mainimage .uploadArea .icon {
	display: block;
	text-align: center;
	font-size: 26px;
}
.content-mainimage .uploadArea .legende {
	display: block;
	font-size: 12px;
	line-height: 12px;
	padding-top: 20px;
}

.content-mainimage .status {
	height: 5px;
	background-color: @systemColor-ok;
	width: 0px;
	border-radius: 20px;
}


/* ######################################################## */
/* bigImage mainimage */
/* ######################################################## */
@media (max-width: 650px) {

		.gridNew-item.gridNew-size-1-5.bigImage {
			margin: -70px -10px 0px -10px !important;
		}

		.bigImage .content-mainimage {
			.boxSizing;
			padding: 0px;
			align-items: center;
			.boxShaddow-big;
			width: 100%;
		}

		.bigImage .content-mainimage img {
			width: 100%;
			.boxShaddow-none;
			height: auto;
			margin-bottom: -6px;
		}
		.bigImage .content-mainimage .icon-edit {
			display: block;
			position: absolute;
			left: auto;
			right: 10px;
			top: auto;
			bottom: 10px;
			margin: 0px;
			height: 50px;
			width: 50px;
			overflow: hidden;
			line-height: 48px;
			padding: 0px;
			text-align: center;
			background-color: #fff;
			border-radius: 100%;
			color: @primaryColor;
			.boxSizing;
			border: 1px solid @primaryColor;
			z-index: 1;
			font-size: 20px;
			cursor: pointer;
			pointer-events: none;
		}
		.bigImage .content-mainimage .icon-delete {

			position: absolute;
			border: none;
			left: auto;
			left: 10px;
			top: auto;
			bottom: 10px;
			margin: 0px;
			height: 50px;

			overflow: hidden;
			z-index: 2;
			margin: auto !important;
			display: block;
			width: 50px !important;
			border: none;
			overflow: hidden;
			line-height: auto;
			font-size: 20px;
			text-align: center !important;
			border-radius: 0px;
			background-color: transparent;
		}
		.bigImage .content-mainimage .icon-delete a {
			display: block;
			font-size: 20px;
			line-height: 48px;
			width: 100%;
			cursor: pointer;
			line-height: 48px;
			text-align: center;
			.boxSizing;
			border: 1px solid @systemColor-error;
			overflow: hidden;
			text-align: center !important;
			border-radius: 100%;
			background-color: #fff;

		}






		.bigImage .content-mainimage .uploadArea {
			padding-top: 150px;
			.boxShaddow-none;
			border: none;
		}

		.bigImage .content-mainimage .uploadArea .icon {
			display: block;
			text-align: center;
			font-size: 26px;
		}
		.bigImage .content-mainimage .uploadArea .legende {
			display: block;
			font-size: 12px;
			line-height: 12px;
			padding-top: 20px;
		}

		.bigImage .content-mainimage .status {
			height: 5px;
			background-color: @systemColor-ok;
			width: 0px;
			border-radius: 20px;
		}


		.bigImage .contentField .label-special {
			background-color: @dividerColor;
			margin: 0px -20px;
			color: @textColor;
			width: auto;
			.boxSizing;
			display: block;
			padding: 5px 30px;
			font-size: 12px;
			line-height: 12px;
			text-align: center;
		}

}





/* ######################################################## */
/* contenttype-checkboxList */
/* ######################################################## */
.block-message {
	background-color: @systemColor-error;
	.boxShaddow-small;
	padding: 10px;
	color: #fff;
	font-size: 12px;
	line-height: 14px;
	width: 100%;
	.boxSizing;
	text-align: center;
	margin: 10px;
}


/* ######################################################## */
/* textarea rhich text */
/* ######################################################## */
.rhichtext-container div.ng-pristine.ng-valid.ta-bind.ng-not-empty, .rhichtext-container .ta-root .ta-text-editor .ta-bind, .rhichtext-textarea, .rhichtext-container textarea {
	max-height: 550px !important;
	overflow: hidden !important;
	overflow-y: scroll !important;
	border: 1px solid #cdcdcd !important;
	padding: 10px 10px 20px 10px !important;
	border-radius: 5px !important;
	.boxShaddow-inside-bright;
	margin-top: 7px;
}
.rhichtext-textarea-v2-container textarea,
.rhichtext-textarea-v2-container div.ng-pristine.ng-valid.ta-bind.ng-not-empty,
.rhichtext-textarea-v2-container .ta-root .ta-text-editor .ta-bind {

	overflow: hidden !important;
	overflow-y: scroll !important;
	border: 1px solid #cdcdcd !important;
	padding: 10px 10px 20px 10px !important;
	border-radius: 5px !important;
	.boxShaddow-inside-bright;
	margin-top: 7px;

	min-height: auto !important;
	max-height: auto !important;
	height: 200px !important;
}
.rhichtext-textarea-v2-container-small textarea {
	height: 150px !important;
}

.rhichtext-textarea-v2 {
	min-height: auto !important;
	max-height: auto !important;
	height: 150px !important;
	overflow-y: scroll !important;
	border: 1px solid #cdcdcd !important;
	padding: 10px !important;
	border-radius: 0px !important;
	.boxShaddow-inside-bright;
	margin-top: 7px;
}
/* ######################################################## */
/* contenttype-checkboxList */
/* ######################################################## */

/* contenttype-checkboxList fa-envelope */
.contenttype-checkboxList:after {
	display: none;
}
.contenttype-checkboxList .fieldset-legend {
	padding-left: 0px;
}
.contenttype-checkboxList .legend-icon {
	display: inline-block;
	width: 20px;
	text-align: center;
}
.contenttype-checkboxList ul.fieldset-content {
	.blankList;
	padding-left: 0px;
	margin-top: 10px;
}
.contenttype-checkboxList ul.fieldset-content li {
	padding: 5px 0px;
	border-top: 1px dashed @dividerColor;
	position: relative;
}
.contenttype-checkboxList ul.fieldset-content li .checkbox {
	font-size: 30px;
	line-height: 30px;
	display: block;
	height: 30px;
	position: absolute;
	left: 5px;
	top: 5px;
}
.contenttype-checkboxList ul.fieldset-content li .label {
	font-size: 13px;
	line-height: 20px !important;
	display: block;
	padding-left: 50px;
	padding-top: 5px;
	padding-bottom: 5px;
}
	.missing .label, .missing label, .missing .labellike, .missing .fieldset-legend {
		position: relative;
		color: @systemColor-error !important;
	}
	.missing .label:before, .missing label:before, .missing .labellike:before, .missing .fieldset-legend:before {
		position: relative;
		font-family: FontAwesome;
		content: '\f071 ';
	}

	/* radio */
	.contenttype-checkboxList ul.fieldset-content li.seperator {
		display: block;
		padding: 10px 5px;
	}
	.contenttype-checkboxList ul.fieldset-content li.seperator h3 {
		font-size: 18px;
		line-height: 20px !important;
		display: block;
		color: @primaryColor;
		padding: 0px;
		margin: 0px;
	}
	.contenttype-checkboxList ul.fieldset-content li.seperator small {
		font-size: 13px;
		line-height: 15px !important;
		display: block;
		color: @primaryColor;
		display: block;
		padding-top: 7px;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .label {
		font-size: 13px;
		line-height: 20px !important;
		display: block;
		padding-left: 110px;
		padding-top: 5px;
		padding-right: 50px;
		color: @textColor;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons a.label {
		color: @textColor;
		cursor: pointer;
	}
		.contenttype-checkboxList ul.fieldset-content li.radioButtons a.label:hover {
			color: @primaryColor;
		}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons a.label small {
		color: @textColor-light;
		font-size: 12px;
		line-height: 12px;
		display: block;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .sort-options {
		position: absolute;
		right: 0px;
		top: 0px;
		width: 40px;
		display: block;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .sort-options a {
		height: 15px;
		display: block;
		padding: 0px;
		margin: 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		cursor: pointer;
		line-height: 15px
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .radioGroup {
		position: absolute;
		left: 5px;
		top: 7px;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .radioGroup a {
		display: inline-block;
		width: 25px;
		margin-right: 5px;
		border-radius: 100%;
		.boxSizing;
		background-color: @dividerColor;
		color: @textColor-light;
		height: 25px;
		line-height: 25px;
		overflow: hidden;
		text-align: center;
		font-size: 15px;

	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .radioGroup a:hover {
		background-color: @textColor-light;
		color: #fff;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .radioGroup a.active {
		background-color: @systemColor-ok;
		color: #fff;
		border-color: @systemColor-ok;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .radioGroup a.active {
		background-color: @systemColor-ok;
		color: #fff;
		border-color: @systemColor-ok;
	}

	.radioGroup a.active.radioDelete {
		background-color: @systemColor-delete !important;
		color: #fff;
		svg {
		  color: #fff;
		}
		border-color: @systemColor-delete !important;
	}

	.radioGroup a.active.radioMaybe {
		background-color: @systemColor-warning !important;
		color: #fff;
		svg {
		  color: #fff;
		}
		border-color: @systemColor-warning !important;
	}

	/* //////////////// */
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .addSomethig select {
		border-radius: 7px;
		border: 1px solid @systemColor-ok;
		background: #fff;
		color: @systemColor-ok;
		margin-right: 10px;
		font-size: 13px;
		height: 30px;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .addSomethig input {
		border-radius: 7px;
		border: 1px solid @systemColor-ok;
		background: #fff;
		color: @systemColor-ok;
		margin-right: 10px;
		padding: 3px 5px;
		.boxSizing;
		width: 130px;
		font-size: 13px;
		height: 30px;
		outline: none;
	}
	.contenttype-checkboxList ul.fieldset-content li.radioButtons .addSomethig button {
		border-radius: 7px;
		border: none;
		background: @systemColor-ok;
		color: #fff;
		margin-right: 10px;
		padding: 3px 5px;
		.boxSizing;
		font-size: 13px;
		width: 30px;
		height: 30px;
	}


	.linkedItem-container {
		margin-top: 5px;
	}
	.linkedItem-container .linkedItem {
		margin-top: 5px;
		border-radius: 7px;
		.boxShaddow-small-bright;
		background-color: @dividerColor;
		padding: 0px 10px;
		font-size: 12px;
		position: relative;
		padding-right: 60px;
		line-height: 30px;
		color: @textColor;
	}
	.linkedItem-container .linkedItem .linkedItem-type {
		display: inline-block;
		width: 80px;
	}
	.linkedItem-container .linkedItem .linkedItem-title {
		display: inline-block;
		color: @primaryColor;
	}
	.linkedItem-container .linkedItem .linkedItem-unlink {
		position: absolute;
		display: block;
		right: 30px;
		width: 30px;
		line-height: 30px;
		top: 50%;
		margin-top: -15px;
		font-size: 16px;
		overflow: hidden;
		height: 30px;
		text-align: center;
		color: @systemColor-error;
	}
	.linkedItem-container .linkedItem .linkedItem-view {
		position: absolute;
		display: block;
		right: 0px;
		width: 30px;
		line-height: 20px;
		top: 50%;
		margin-top: -10px;
		font-size: 16px;
		overflow: hidden;
		height: 20px;
		text-align: center;
		color: @primaryColor;
	}


	.addNewLinkItem {
		display: inline-block;
		background-color: @accentColor;
		color: @accentColor-text;
		border-radius: 44px;
		padding: 5px 20px;
		.boxShaddow-small-bright;
	}


/* mail editing */
.placeholder-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.placeholder-container h3 {
	color: @primaryColor;
	font-size: 15px;
	line-height: 15px;
	margin: 0px;
	padding: 0px;
	padding-bottom: 10px;
	width: 100%;
	.boxSizing;
}
.placeholder-container .placeholder {
	display: block;
	padding: 5px 10px;
	margin: 10px 10px 0px 0px;
	font-size: 12px;
	line-height: 12px;
	background-color: @dividerColor;
	color: @textColor;
	border-radius: 3px;
	border: 1px solid darken(@dividerColor,10%);
}

.mail-textarea textarea {
	max-height: 350px !important;
	border: 2px solid darken(@dividerColor,15%) !important;
	overflow: scroll !important;
	padding: 10px !important;
}
.mail-textarea.changedContent textarea {
	border: 2px solid @systemColor-error !important;
}



.callToAction-Button {
	display: block;
	padding: 0px 10px;
	text-align: center;
	font-size: 18px;
	line-height: 20px;
	padding: 15px 10px;
	background-color: @accentColor;
	border-radius: 7px;
	color: @accentColor-text;
	.dropShaddow-small;
}
.callToAction-Button.error-button {
	background-color: @systemColor-error;
	color: #fff;
}
button.callToAction-Button {
	display: block;
	width: 100%;
	.boxSizing;
	border: none;
	padding: 0px 10px;
	text-align: center;
	font-size: 16px;
	line-height: 30px;
	background-color: @accentColor;
	border-radius: 7px;
	color: @accentColor-text;
	.dropShaddow-small;
}

.has-border-bottom {
	border-bottom: 5px solid @dividerColor;
}
.has-border-top {
	border-top: 5px solid @dividerColor;
}
	/* radioNeutral, radioImportant */

.account-delete-msg {
	background-color: @systemColor-error;
	margin-bottom: 20px;
	padding: 20px;
	color: #fff;
}



md-switch {
	display: flex;
}
.md-container {
	width: 50px;
}
md-switch .md-label {
	white-space: normal;
	font-size: 14px;
	line-height: 16px;
	width: ~"calc(100% - 40px)";
	color: @textColor;
}

/* ######################################################## */
/* password-check */
/* ######################################################## */

	ul.specialPasswordInfo {
		margin: 0px;
		padding: 0px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	ul.specialPasswordInfo > li {
		padding: 5px 10px;
		text-align: left;
		display: inline-block;
		margin: 5px 0px 0px 5px;
		background-color: @dividerColor;
		font-size: 13px;
		line-height: 13px;
		color:@systemColor-error;
	}
	ul.specialPasswordInfo > li.checked {
		background-color: @systemColor-ok;
		color: #fff;
	}

/* ######################################################## */
/* linkPresenter-2 */
/* ######################################################## */
.linkPresenter-2 {
	position: relative;
	display: block;
	border: 1px solid @primaryColor;
	border-radius: 10px;
	background: #fff;
	padding: 5px 44px !important;
	margin: 10px 0px 0px 0px;
	text-align: left;
	.boxShaddow-small-bright;
	word-wrap:break-word !important;
}
.linkPresenter-2 pre {
	white-space: pre-wrap !important;
	word-wrap:break-word !important;
}
.linkPresenter-2 .icon {
	display: block;
	position: absolute;
	font-size: 20px;
	left: 0px;
	top: 50%;
	line-height: 40px;
	width: 40px;
	overflow: hidden;
	height: 40px;
	text-align: center;
	margin-top: -20px;
	color: @primaryColor;
}
.linkPresenter-2 .label {
	color: @textColor;
	display: block;
	font-size: 14px;
	padding-bottom: 5px;
	line-height: 14px;
	color: @primaryColor;

	@media (max-width: 650px) {
			font-size: 12px;
			line-height: 12px;
	}
}
.linkPresenter-2 .link {
	color: @textColor-light;
	display: block;
	font-size: 16px;
	line-height: 16px;
	word-break: break-all;
	@media (max-width: 650px) {
			font-size: 14px;
			line-height: 14px;
	}
}
.linkPresenter-2 .icon-help {
	display: block;
	position: absolute;
	font-size: 20px;
	color: @accentColor;
	right: 0px;
	top: 50%;
	line-height: 40px;
	width: 40px;
	overflow: hidden;
	height: 40px;
	text-align: center;
	margin-top: -20px;
}

/* ######################################################## */
/* send Dialog */
/* ######################################################## */
.sendMail-sendCtA {
	width: 100% !important;
	border-radius: 7px  !important;
	.boxSizing;
	font-size: 18px  !important;
	line-height: 18px  !important;
	background-color: @accentColor  !important;
	color: @accentColor-text  !important;
	padding: 0px  !important;
	line-height: 40px !important;
	text-align: center !important;
	display: block !important;
	margin-bottom: 20px;
	height: 60px !important;
}
.sendMail-cancelCtA {
	width: 100% !important;
	border-radius: 7px  !important;
	.boxSizing;
	font-size: 18px  !important;
	line-height: 18px  !important;
	background-color: #fff  !important;
	color: @accentColor  !important;
	border: 1px solid @accentColor;
	padding: 0px  !important;
	line-height: 40px !important;
	text-align: center !important;
	display: block !important;
}
.hero-image {
	text-align: center;
	font-size: 150px;
	line-height: 150px;
	padding: 20px;
	padding-top: 60px;
	color: @primaryColor;
}

.sendMail-agreement {
	padding: 0px 20px;
	border: 1px dashed @textColor-light;
	border-radius: 10px;
	background: #fff;
	margin: 20px 0px;
}
.md-button {
	/*height: 50px !important;*/
	border-radius: 7px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
}

/* ######################################################## */
/* info-section-grid */
/* ######################################################## */
.info-section-grid {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width: 600px) {
		display: block;
	}
}
.info-section-grid section {
	width: 33.33%;
	.boxSizing;
	padding: 2px 10px;

	@media (max-width: 1048px) {
		width: 50%;
	}

	@media (max-width: 600px) {
		width: 100%;
	}

}

.info-section-grid section h3 {
	font-size: 22px;
	border-bottom: 1px solid @dividerColor;
	padding-top: 40px;
}


/* ######################################################## */
/* detailsJustText */
/* ######################################################## */


.info-section-shaddow {
	border-radius: 10px;
	width: 100%;
	padding: 20px 10px;
	.boxSizing;
	.boxShaddow-big-bright;
	margin-bottom: 20px;
	border: 1px solid darken(@dividerColor,5%);
}
	.info-section-shaddow.inlineModal .content-divider {
		margin: 0px;
		padding: 0px 5px;
		padding-bottom: 10px;
		padding-top: 20px;
	}

	.info-section-shaddow.inlineModal {
		display: flex;
		flex-wrap: wrap;
	}
	.info-section-shaddow.inlineModal .field {
		margin: 10px 5px 0px 5px;
		.boxSizing;
	}
		.info-section-shaddow.inlineModal .field.hasSubField {
			display: flex;
			flex-wrap: wrap;
			position: relative;
		}

		.info-section-shaddow.inlineModal .field.hasSubField .sub-width-50  {
			margin: 10px 5px 0px 5px;
			width: ~"calc(50% - 10px)";

			@media (max-width: 500px) {
				width: ~"calc(100% - 10px)";
			}
		}
		.info-section-shaddow.inlineModal .sub-width-100  {
			width: ~"calc(100% - 10px)";
			margin: 10px 5px 0px 5px;
		}


	.info-section-shaddow.inlineModal .field.field-size-50 {
		width: ~"calc(50% - 10px)";

		@media (max-width: 500px) {
			width: ~"calc(100% - 10px)";
		}
	}
	.info-section-shaddow.inlineModal .field.field-size-100 {
		width: ~"calc(100% - 10px)" !important;

		@media (max-width: 500px) {

		}
	}

.field.withIcon {
	position: relative;
}

.field.withIcon .copy-icon {
	display: block;
	text-align: center;
	line-height: 40px;
	width: 40px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	color: @primaryColor;
	font-size: 18px;
	z-index: 1;
	cursor: pointer;
	outline: none;
}






.detailsJustText {
	.blankList;
	display: flex;
	flex-wrap: wrap;
	margin: 0px -5px;
	margin-bottom: 20px;
}
.detailsJustText > li {
	width: ~"calc(50% - 10px)";
	margin: 5px;
	padding: 5px 0px;
	padding-top: 15px;
	border-bottom: 1px solid @dividerColor;
	.boxSizing;

	@media (max-width: 500px) {
		width: ~"calc(100% - 10px)";
	}
}
.detailsJustText > li.w-100 {
	width: ~"calc(100% - 10px)";
}
.detailsJustText > li.noBorder {
	border-bottom: none;
}
.detailsJustText > li .label {
	display: block;
	color: @textColor-light;
	text-transform: uppercase;
	font-size: 12px;
	padding-bottom: 10px;
}
.detailsJustText > li .info {
	display: block;
	color: @textColor;
	font-size: 15px;
}
.detailsJustText > li .info a {
	color: @primaryColor;
}
h2.order-details-headline {
	.font-special;
	font-size: 24px;
	line-height: 24px;
}

/* ######################################################## */
/* EVENT FIELD */
/* ######################################################## */
.eventField-dateAndTime {
	position: relative;
}
.eventField-dateAndTime .dateDeleteLink {
	display: block;
	position: absolute;
	left: -15px;
	font-size: 14px;
	line-height: 14px;
	bottom: 18px;
	text-align: left;
	width: 15px;
	color: @systemColor-error;
}

/* ######################################################## */
/* TAB */
/* ######################################################## */
.tabs-withIcon .md-tab .icon {
	display: block;
	width: 100%;
	text-align: center;
	font-size: 20px;
	line-height: 20px;
	padding-bottom: 5px;
}
.tabs-withIcon .md-tab small {
	font-size: 12px;
	line-height: 12px;
	display: block;
	width: 100%;
	text-align: center;
}
.tabs-withIcon	.md-tab {
	padding-top: 5px;
	padding-bottom: 5px;
}
.tabs-withIcon md-pagination-wrapper {
	height: 50px;
}

/* ######################################################## */
/* field-subinfo */
/* ######################################################## */
.field-subinfo {
	display: block;
	width: 100%;
	.boxSizing;
	margin-top: 2px;
	border: 1px solid @dividerColor;
	padding: 2px 5px;
	color: @textColor-light;
	border-radius: 7px;
	font-size: 12px;
	line-height: 14px;
}
.field-subinfo.type-abo {
	color: @systemColor-warning;
	border-color: @systemColor-warning;
	text-transform: uppercase;
	line-height: 12px;
}
.field-subinfo a {
	color: @primaryColor;
}

/* ######################################################## */
/* choose-code */
/* ######################################################## */
.choose-code {

}
.choose-code .label {
	font-size: 12px;
	color: @textColor;
	padding-bottom: 5px;
}
.choose-code .codes {
	display: flex;
	flex-wrap: wrap;
}
.choose-code .codes > a {
	display: block;
	background-color: #fff;
	border: 1px solid @primaryColor;
	color: @primaryColor;
	padding: 5px 10px;
	border-radius: 7px;
	cursor: pointer;
	margin-right: 10px;
}
.choose-code .codes > a.active, .choose-code .codes > a:hover {
	background-color: @primaryColor;
	color: @primaryColor-text;
}
.choose-code .code {
	display: block;
	font-size: 14px;
	line-height: 14px;
	padding-bottom: 3px;
	text-align: center;
}
.choose-code .feldname {
	display: inline-block;
	font-size: 10px;
	line-height: 10px;
	margin-right: 10px;
}


/* ######################################################## */
/* multiEdit-editFieldSwitcher */
/* ######################################################## */

.multiEdit-editFieldSwitcher {
	color: @textColor;
	font-size: 13px;
	background-color: #fff;
	border-radius: 7px;
	padding: 10px;
	.boxSizing;
	border: 1px solid fade(@systemColor-error,25%);
	width: 100%;
	padding-bottom: 20px;
	margin-top: 10px;
}
	.multiEdit-editFieldSwitcher.messageOnly {
		border-color: @systemColor-warning;
		color: @systemColor-warning;
		padding: 5px 10px;
		margin: 0px 0px 10px 0px;
	}
.multiEdit-editFieldSwitcher .editFieldSwitcher-seperator {
	display: block;
	margin-bottom: 30px;
	padding-bottom: 5px;
	border-bottom: 1px solid @dividerColor;
}
	.multiEdit-editFieldSwitcher.editTrue {
		border: 1px solid @systemColor-ok;
		.boxShaddow-big-bright;
	}
.multiEdit-editFieldSwitcher md-switch {
	margin: 10px 0px;
	margin-bottom: 10px;
}
.multiEdit-editFieldSwitcher small {
	font-size: 10px;
	line-height: 10px;
}


/* ######################################################## */
/* autocomplete-v01 */
/* ######################################################## */
.autocomplete-v01 {
	position: absolute;
	bottom: 80px;
	left: 20px;
	right: 0px;
	z-index: 3;
	background-color: #fff;
	.boxShaddow-big-medium;
	border: 1px solid @primaryColor;
	border-radius: 5px;
	overflow: hidden;
}
.autocomplete-v01:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 6px 6px 0;
	border-color: @primaryColor transparent;
	display: block;
	width: 0;
	z-index: 1;
	bottom: -6px;
	left: 10px;
}


.autocomplete-v01 .header {
	background-color: @primaryColor;
	color: @primaryColor-text;
	font-size: 10px;
	line-height: 30px;
	position: relative;
	padding-left: 10px;
}
.autocomplete-v01 .header .icon {
	position: absolute;
	right: 0px;
	top: 0px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	display: block;
	cursor: pointer;
	font-size: 15px;
}
.autocomplete-v01 .autocomplete-list {
	.blankList;
	max-height: 150px;
	overflow-x: hidden;
	overflow-y: auto;
}
.autocomplete-v01 .autocomplete-list .list-item {
	padding: 10px;
	border-top: 1px solid darken(@dividerColor,10%);
	cursor: pointer;
	position: relative;
}
.autocomplete-v01 .autocomplete-list .list-item:nth-child(even) {
	background-color: @dividerColor;
}
.autocomplete-v01 .autocomplete-list .list-item .hover-icon {
	display: none;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -10px;
	line-height: 20px;
	color: @primaryColor;
}
.autocomplete-v01 .autocomplete-list .list-item:hover .hover-icon {
	display: block;
}
.autocomplete-v01 .autocomplete-list .list-item .mainInfo {
	display: block;
	font-size: 16px;
	color: @primaryColor;
	line-height: 16px;
	padding-bottom: 5px;
}
.autocomplete-v01 .autocomplete-list .list-item .subInfo {
	display: block;
	font-size: 12px;
	color: @textColor-light;
	line-height: 12px;
}


/* ######################################################## */
/* Sections */
/* ######################################################## */

.addSection {
	display: block;
	font-size: 20px;
	text-align: center;
	background-color: @primaryColor;
	color: @primaryColor-text;
	padding: 10px;
	border-radius: 5px;
	.boxShaddow-default;
	cursor: pointer;
	margin-top: 20px;
}
.addSection.highlightColor {
	background-color: @accentColor;
	color: @accentColor-text;
}

.content-sections {
}
.content-sections .section-item {
	margin-top: 20px;
	border: 2px solid @dividerColor;
	padding: 10px;
	position: relative;
	z-index: 0;
}
	.content-sections .section-item.inactive {
		opacity: 0.5;
	}
	.content-sections .section-item.version2 {
		border: 1px solid @primaryColor;
		border-radius: 7px;
		background-color: #fff;
		.boxShaddow-big-bright;
	}

.content-sections .section-item:hover {
	z-index: 1;
}

/* header */
.content-sections .section-item > header {
	position: relative;
	display: flex;
	align-items: center;
	padding-right: 120px;
	.boxSizing;
}
	.content-sections .section-item.showDetails > header {
		border-bottom: 2px solid @dividerColor;
		padding-bottom: 10px;
		margin-bottom: 10px;
		.boxSizing;
	}

	.content-sections .section-item header.hasImage {
		padding-left: 70px;
		min-height: 60px;
	}
	.content-sections .section-item.showDetails header.hasImage {
		min-height: 70px;
	}

	.content-sections .section-item header .header-image {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 60px;
		height: 60px;
		overflow: hidden;
		display: block;
		.transition-default;
		color: @primaryColor;
		line-height: 60px;
		text-align: center;
		font-size: 26px;
	}
		.content-sections .section-item header .header-image:hover {
			.scaleUp-300;
			.transition-default;
		}
		.content-sections .section-item header .header-image img {
			width: 100%;
			height: auto;
		}

.content-sections .section-item header h3 {
	margin: 0px;
	padding: 0px;
	font-size: 26px;
	line-height: 28px;
	.font-special;
	color: @primaryColor;
	font-weight: 300;
}
.content-sections .section-item header h3 small {
	display: block;
	font-size: 16px;
	line-height: 18px;
	padding-top: 3px;
	color: @textColor;
}
.content-sections .section-item header .header-action-icons {
	position: absolute;
	right: 0px;
	top: 50%;
	margin-top: -20px;
	text-align: right;
	display: flex;
}
.content-sections .section-item header .header-action-icons a {
	color: @primaryColor;
	font-size: 16px;
	line-height: 40px;
	height: 40px;
	width: 40px;
	text-align: center;
	display: block;
	cursor: pointer;
	border-left: 1px solid @dividerColor;
}
.content-sections .section-item header .header-action-icons a:hover {
	color: @accentColor;
}

.content-sections .section-visibility {
	display: inline-block;
	margin-right: 5px;
}

/* conditions */

.content-sections .oneOrAll {
	margin: 10px -10px;
}


.conditionsContainer {

}

.conditionsContainer .addCondition {
	display: inline-block;
	padding: 10px 15px;
	background: @accentColor;
	color: @accentColor-text;
	text-transform: uppercase;
	.dropShaddow-small;
	font-size: 14px;
	line-height: 14px;
	border-radius: 7px;
}
.conditionsContainer .oneCondition {
	display: block;
	border: 2px solid @dividerColor;
	border-radius: 7px;
	margin: 0px;
	margin-top: 15px;
}
.conditionsContainer .oneCondition header {
	display: block;
	position: relative;
	padding: 5px 10px;
	padding-right: 90px;
	background-color: @dividerColor;
	border-radius: 5px 5px 0px 0px;
}
.conditionsContainer .oneCondition header .conditionName {
	font-size: 12px;
	line-height: 20px;
	color: @primaryColor;
	text-transform: uppercase;
}
.conditionsContainer .oneCondition header .conditionOptions {
	display: flex;
	position: absolute;
	z-index: 1;
	top: 5px;
	right: 10px;
	line-height: 20px;
}
.conditionsContainer .oneCondition header .conditionOptions a {
	display: block;
	width: 30px;
	text-align: center;
	margin-left: 5px;
	font-size: 14px;
	color: @primaryColor;
}


/* ######################################################## */
/* ticketRequirements */
/* ######################################################## */

.ticketRequirements {
	border: 1px solid @systemColor-error;
	background-color: #fff;
	.boxShaddow-big-bright;
	padding: 10px;
	padding-left: 10px;
	position: relative;
}
.ticketRequirements > .icon {
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -20px;
	line-height: 40px;

	display: none;
}
.ticketRequirements > .icon {
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -20px;
	line-height: 40px;
	color: @systemColor-error;
	font-size: 20px;
}

.ticketRequirements p {
	color: @textColor;
	font-size: 14px;
	line-height: 16px;
	margin: 0px;
	padding: 0px 0px 10px 0px;
}

.ticketRequirements ul {
	.blankList;
}
.ticketRequirements ul li {
	position: relative;
	padding-left: 20px;
	margin-top: 10px;
	color: @systemColor-error;
	font-size: 14px;
	line-height: 16px;
	text-align: left;
}
.ticketRequirements ul li a {
	display: inline-block;
	color: @primaryColor;
	padding: 2px 10px;
	border: 1px solid @primaryColor;
	margin-left: 5px;
	border-radius: 7px;
}
.ticketRequirements ul li .list-icon {
	position: absolute;
	left: 0px;
	top: 50%;
	line-height: 16px;
	margin-top: -8px;
	font-size: 15px;
	color: @systemColor-error;
}


/* ######################################################## */
/* CheckboxList-v02 */
/* ######################################################## */

.checkboxList-v02 {

}
.checkboxList-v02 cp-toggle-option {
	display: block;
	color: @textColor;
	border-top: 1px dashed @dividerColor;
}
.checkboxList-v02 cp-toggle-option .md-label {

}
.checkboxList-v02 cp-toggle-option .introIcon {
	display: inline-block;
	width: 25px;
	text-align: left;
}

/* ######################################################## */
/* SMS77 */
/* ######################################################## */

.sms-gateway-widget {
	border-radius: 10px;
	border: 1px solid @textColor-light;
	background-color: @dividerColor;
	position: relative;
	min-height: 60px;
	padding-left: 50px;
	padding-right: 50px;
	margin: 20px 0px;
	display: flex;
	align-items: center;
	width: 100%;
	.boxSizing;

}
.sms-gateway-widget .info {
	width: 100%;
}
.sms-gateway-widget .info .headline {
	font-size: 15px;
	color: @textColor-light;
	display: block;
	line-height: 15px;
}
.sms-gateway-widget .info small	 {
	font-size: 13px;
	display: block;
	padding-top: 3px;
	line-height: 13px;
	color: @textColor;
}

.sms-gateway-widget .icon {
	display: block;
	position: absolute;
	top: 50%;
	line-height: 40px;
	margin-top: -20px;
	left: 10px;
	font-size: 26px;
	color: @systemColor-ok;
}
.sms-gateway-widget .icon.error {
	color: @systemColor-error;
}
.sms-gateway-widget .refresh {
	display: block;
	position: absolute;
	top: 50%;
	line-height: 40px;
	margin-top: -20px;
	right: 10px;
	font-size: 22px;
	color: @accentColor;
	border-left: 1px solid #fff;
	padding-left: 10px;
}

/* ######################################################## */
/* MAILEDIT */
/* ######################################################## */
.options-mailedit {
	margin-bottom: 40px;
	display: flex;
}

.options-mailedit a {
	background-color: @accentColor;
	color: @accentColor-text;
	border-radius: 7px;
	display: block;
	padding: 10px 10px;
	font-size: 14px;
	line-height: 14px;
	margin-right: 20px;
	.dropShaddow-small;
}
.options-mailedit a.disabled {
	cursor: not-allowed;
	/*pointer-events: none;*/
	background-color: darken(@dividerColor,10%);
}


/* ######################################################## */
/* MAILEDIT */
/* ######################################################## */
.toggleLinks a {
	display: inline-block;
	padding: 5px 15px;
	border-radius: 7px;
	border: 1px solid @primaryColor;
	color: @primaryColor;
}




/* ######################################################## */
/* SUBVIEW PAGES */
/* ######################################################## */
/* main */
.subView-main {
  padding: 10px;
  padding-bottom: ~"calc(env(safe-area-inset-bottom) + 150px)" !important;

  @media (min-width: 1025px) {
    padding-bottom: ~"calc(env(safe-area-inset-bottom) + 120px)" !important;
  }
}

.subView-steps {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* options-container */
.options-container {
  display: flex;
  margin: 0px -10px;

  .option {
    margin: 5px;
    width: auto;
    flex-grow: 1;
    text-align: center;
    display: block;
    background: @accentColor;
    color: @accentColor-text;
    font-size: 16px;
    line-height: 50px;
    border-radius: 20px;
    border: 1px solid @accentColor;
    cursor: pointer;
    .boxShaddow-big-bright-bottomFocused;

    &.notImportant {
      background: #fff;
      border-color: @accentColor;
      color: @accentColor-text;
      color: @accentColor;
      .boxShaddow-none;
    }
  }

}

/* footer */
.subView-footer {
  position: fixed;
  bottom: ~"calc(env(safe-area-inset-bottom) + 50px)";
  padding: 5px 5px 20px 5px;
  background: @dividerColor;
  left: 0px;
  right: 0px;
  display: flex;

  @media (min-width: 1025px) {
    bottom: ~"calc(env(safe-area-inset-bottom) + 0px)";
    padding-bottom: 10px;
  }

  .footer-action {
    margin: 5px;
    width: auto;
    flex-grow: 1;
    text-align: center;
    display: block;
    background: @accentColor;
    color: @accentColor-text;
    font-size: 16px;
    line-height: 50px;
    border-radius: 20px;
    border: 1px solid @accentColor;
    cursor: pointer;
    .boxShaddow-big-bright-bottomFocused;

    &.notImportant {
      background: #fff;
      border-color: @accentColor;
      color: @accentColor-text;
      color: @accentColor;
      .boxShaddow-none;
    }
  }



}
.footer-action-inside {
  margin: 0px 0px 40px 0px;
  text-align: center;
  display: block;
  background: @accentColor;
  color: @accentColor-text;
  font-size: 16px;
  line-height: 70px;
  border-radius: 20px;
  border: 1px solid @accentColor;
  cursor: pointer;
  .boxShaddow-big-bright-bottomFocused;
}
/* ######################################################## */
/* CP-GUI-ITEMS */
/* ######################################################## */
.cp-gui-item {
  padding-bottom: 10px;
  padding-top: 10px;
}


/* ### UPLOAD ### */
.cp-gui-upload {
  border: 1px dashed @textColor-light;
  background: @dividerColor;
  .boxShaddow-inside;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  cursor: pointer;


  & > .status {
    padding: 5px 0px;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    .icon {
      font-size: 36px;
      color: fade(@textColor-light, 50%);
      padding-bottom: 5px;
    }

    .label {
      font-size: 14px;
      color: fade(@textColor-light, 50%);
    }
  }

  & > .label {
    font-size: 14px;
    color: fade(@textColor-light, 25%);
    padding: 20px 0px 0px 0px;

    &.style-big {
      font-size: 26px;
      font-weight: bold;
    }
  }

  &.status-inProgess {
    border: 1px solid @textColor;
    .boxShaddow-none;

    & > .status .icon {
      color: @textColor;
    }

    & > .status .label {
      color: @textColor;
    }
  }

  &.status-done {
    border: 1px solid @systemColor-ok;
    .boxShaddow-none;
    background: #fff;
    .boxShaddow-big-bright-bottomFocused;

    & > .status .icon {
      color: @systemColor-ok;
    }

    & > .status .label {
      color: @systemColor-ok;
    }
  }

  &.status-error {
    border: 1px solid @systemColor-error;


    & > .status .icon {
      color: @systemColor-error;
    }

    & > .status .label {
      color: @systemColor-error;
    }
  }

}
/* ### cp-gui-textinfo ### */

.cp-gui-textinfo {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;

  border: 1px solid @dividerColor;
  border-radius: 20px;
  padding: 20px;

  margin-bottom: 10px;
  .boxShaddow-big-bright-bottomFocused-small;

  .readMore {
    display: block;
    background: @primaryColor;
    color: @primaryColor-text;
    text-align: center;
    border-radius: 24px;
    line-height: 20px;
    padding: 5px 20px;
    margin: 10px -10px;
    cursor: pointer;
    font-size: 12px;

  }

  .options {
    position: absolute;
    right: 5px;
    top: 5px;

    a {
      background: @accentSecondaryColor;
      color: @accentSecondaryColor-text;
      display: block;
      border-radius: 30px;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 30px;
      .boxShaddow-big-bright-bottomFocused-small;
      cursor: pointer;
    }

  }
  .headline {
    .font-special;
    color: @primaryColor;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .text {
    color: @textColor;
    font-size: 14px;
    line-height: 18px;
  }
  .key {
    color: @textColor-light;
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 3px;
  }
  .value {
    color: @textColor;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 15px;
  }

  .signUp {

    .canvas {
      height: 150px;
      background: #f1f4f5;
      margin-top: 20px;
      position: relative;
      width: ~"calc(100% + 40px)";
      margin-left: -20px;
      margin-right: -20px;
    }
    .label {
      border-top: 1px solid @textColor-light;
      color: @textColor;
      padding: 5px 0px;
      font-size: 12px;
    }

    .signupClear {
      background: @systemColor-error;
      color: #fff;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      font-size: 15px;
      line-height: 30px;
      text-align: center;
      .boxShaddow-small;
      position: absolute;
      right: 5px;
      top: 7px;
    }

  }

  .subItem {
    flex-grow: 1;

    &.width-100 {
      width: 100%;
    }
    &.width-50 {
      width: 50%;
    }
  }

  .inputItem {

    & > .label {
      font-size: 14px;
      color: @textColor-light;
      display: block;
      margin-bottom: 5px;
    }

    & > .textarea {
      font-size: 14px;
      color: @textColor;
      padding: 10px;
      .boxSizing;
      outline: none;
      border-radius: 0px;
      .boxShaddow-inside;
      background: @dividerColor;
      border: 1px solid @textColor-light;
      min-height: 80px;
      border: none !important;
      border-bottom: 1px solid @textColor !important;
      width: ~"calc(100% + 20px)";
      margin-left: -10px;
      margin-right: -10px;
    }

  }

  .divider {
    margin-top: 30px;
    width: 100%;
    height: 31px;
    border-top: 1px solid @dividerColor;
    .boxSizing;
  }
  .selectItem {

    & > .label {
      .font-special;
      color: @primaryColor;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 10px;
    }

    ul { .blankList; }

    li {
      cursor: pointer;
      border: 1px solid @textColor-light;
      border-radius: 20px;
      position: relative;
      margin: 0px -10px 10px -10px;
      .boxSizing;
      padding: 15px 10px 15px 55px;
      background: #fff;
      color: @textColor;
      font-size: 18px;
      line-height: 18px;
      .font-special;
      .boxShaddow-big-bright-bottomFocused-small;

      &:hover {
        .boxShaddow-none;
      }

      &.positive {
        color: @systemColor-ok;
        border-color: @systemColor-ok;

        &.active {
          color: #fff;
          background: @systemColor-ok;
          .boxShaddow-none;
        }
      }
      &.negative {
        color: @systemColor-error;
        border-color: @systemColor-error;

        &.active {
          color: #fff;
          background: @systemColor-error;
          .boxShaddow-none;
        }
      }

      small {
        display: block;
        font-size: 13px;
        line-height: 13px;
        padding-top: 5px;
        .font-main;
      }

      .icon {
        position: absolute;
        left: 10px;
        top: 50%;
        line-height: 30px;
        margin-top: -15px;
        font-size: 35px;
      }

      &.active {

      }




    }

  }


}

/* ### Mesage Big ### */
.cp-gui-message-big {
  text-align: center;
  padding-top: 20px;

  .icon {
    font-size: 150px;
    color: @textColor;
    padding-bottom: 20px;
  }

  .label {
    font-size: 18px;
    color: @textColor;
  }

  &.status-ok {
    .icon {
      color: @systemColor-ok;
    }

    .label {
      color: @systemColor-ok;
    }
  }

}

/* ### cp-gui-list-v01 ### */

.cp-gui-list-v01 {

  .add-items-header {
    /*position: sticky;
    top: 50px;*/
    position: relative;
    background: #fff;
    padding: 10px 0px;
    min-height: 60px;
    z-index: 1;
    display: flex;
    align-items: center;

    .search {
      padding-right: 70px;
      .boxSizing;
      width: 100%;

      mat-form-field {
        width: 100%;
        margin-bottom: -20px;
      }
      .mat-form-field-appearance-fill .mat-form-field-flex {
        border-radius: 20px;
        .boxShaddow-inside;
      }
      .mat-form-field-underline {
        display: none !important;
      }
      .cdk-overlay-pane {
        .boxShaddow-big-bright-bottomFocused;
        border-radius: 20px 20px 10px !important;
      }
      .mat-autocomplete-panel {

      }
    }

    .scan {
      position: absolute;
      right: 0px;
      top: 50%;
      margin-top: -30px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: @accentSecondaryColor-text;
      background-color: @accentSecondaryColor;
      font-size: 25px;
      cursor: pointer;
      .boxShaddow-big-bright-bottomFocused;
      border-radius: 24px;
    }

  }



  /* ITEM LIST */
  .item-list {
    padding: 10px 0px;

    .item {
      border: 1px solid darken(@dividerColor, 15%);
      border-radius: 24px;
      padding: 10px;
      padding-bottom: 5px;
      position: relative;
      min-height: 62px;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding-left: 65px;
      padding-right: 60px;

      &.noIcon {
        padding-left: 20px;
      }

      &:nth-child(even) {
        background: @dividerColor;
      }
      &:hover {
        background: darken(@dividerColor,5%);
      }

      & > .icon {
        position: absolute;
        width: 50px;
        height: 50px;
        background: @textColor-light;
        top: 5px;
        left: 5px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
        color: #fff;

        &.positive {
          background: @systemColor-error;
        }
        &.negative {
          background: @systemColor-ok;
        }
        &.unknown {
          background: @systemColor-warning;
        }

        .graphic {
          display: block;
          font-size: 18px;
          line-height: 18px;
          text-align: center;
          width: 100%;
        }

        .description {
          display: block;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          width: 100%;

          font-weight: bold;

          span {
            display: block;
            padding-top: 5px;
            text-align: center;
          }
        }
      }

      .options {
        position: absolute;

        top: 5px;
        bottom: 5px;
        right: 0px;
        border-left: 1px solid rgba(0,0,0,0.1);

        line-height: 50px;
        text-align: center;

        a {
          display: block;
          color: @accentColor;
          width: 40px;
          text-align: center;
          margin-right: 10px;
          font-size: 26px;
        }
      }

      .options-selection {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 0px;
        border-left: 1px solid rgba(0,0,0,0.1);

        line-height: 50px;
        text-align: center;
        outline: none;

        .options-icon {
          display: block;
          color: @accentColor;
          width: 40px;
          text-align: center;
          margin-right: 10px;
          font-size: 26px;
          cursor: pointer;
        }
      }

      /* options */
      .options-selection .option-details {
        position: absolute;
        width: 225px;
        left: -225px;
        top: -5px;
        z-index: 5;
        background-color: #fff;
        .boxShaddow-big;
        .boxSizing;
        padding: 0px 10px;
        display: none;
        border-radius: 10px;
      }
      &:hover {
        z-index: 4;
      }
      .options-selection.showOptions .option-details {
        display: block;
      }
      .options-selection .option-details ul {
        .blankList;
        width: 100%;
      }
      .options-selection .option-details ul li {
        border-bottom: 2px solid @dividerColor;
        padding: 10px 0px;
        position: relative;
        text-align: left	;
        font-size: 13px;
        line-height: 13px;
      }
      .options-selection .option-details ul li.seperator {
        border-bottom: 1px solid @textColor-light;
        border-top: 1px solid @textColor-light;
        padding: 5px 10px;
        margin: 0px -10px;
        margin-top: -2px;
        z-index: 1;
        position: relative;
        text-align: left	;
        font-size: 10px;
        line-height: 10px;
        background-color: @dividerColor;
        color: @textColor;
        text-transform: uppercase;
        text-align: left;
      }
      .options-selection .option-details ul li.seperator.massEdit {
        color: @accentColor-text;
        border-bottom-color: @accentColor;
        border-top-color: @accentColor;
        background: @accentColor;
      }

      .options-selection .option-details ul li a {
        display: block;
        color: @primaryColor !important;
        cursor: pointer;
      }
      .options-selection .option-details ul li a.massEdit {
        color: @accentColor !important;
      }

      .options-selection .option-details ul li.delete,
      .options-selection .option-details ul li.delete a,
      .options-selection .option-details ul li.delete a span {
        color: @systemColor-error !important;
      }
      .options-selection .option-details ul li.positive,
      .options-selection .option-details ul li.positive a {
        color: @systemColor-ok !important;
      }

      .options-selection .option-details ul li a:hover {
        display: block;
        color: @primaryColor-dark !important;
      }

      .options-selection .option-details ul li .icon {
        display: inline-block;
        width: 20px;
      }
      .options-selection .option-details ul li:last-child {
        border-bottom: none;
      }









      .details {

        .primary {
          display: block;
          font-size: 16px;
          line-height: 16px;
          strong {
            color: @primaryColor;
            font-weight: normal;
            .font-special;
          }
        }

        .secondary {
          display: block;
          margin-left: -5px;
          .subItem {
            display: inline-block;
            border-radius: 10px;
            background: #fff;
            border: 1px solid @dividerColor;
            padding: 3px 10px;
            font-size: 12px;
            line-height: 12px;
            margin: 5px 5px 0px 0px;
            color: @textColor;
          }
        }

        .special {
          position: relative;
          background: #fff;
          border-radius: 10px;
          background: #fff;
          border: 1px solid @dividerColor;
          padding: 10px;
          margin-top: 10px;
          margin-left: -5px;
          margin-bottom: 5px;
          color: @textColor;
          font-size: 14px;
          line-height: 16px;
          word-break: break-word;

          &.error {
            border-color: @systemColor-error;
            color: @textColor !important;

            strong {
              color: @systemColor-error;
            }
          }

          &.done {
            border-color: @systemColor-ok;
          }

          strong {
            font-weight: bold;
            display: block;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 10px;
          }
        }

      }

    }


  }

  /* ITEM LIST v2 */
  .item-list-v2 {
    padding: 10px 0px;

    .item {
      border-top: 1px solid darken(@dividerColor, 15%);
      padding: 15px 10px;
      padding-left: 40px;
      padding-bottom: 0px;
      position: relative;
      min-height: 62px;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding-right: 40px;

      .mat-form-field-underline {
        display: none !important;
      }
      .mat-form-field-appearance-fill .mat-form-field-flex {
        background: @dividerColor !important;
        .boxShaddow-inside-bright;
        border-radius: 4px !important;
        padding: 10px !important;
        line-height: 15px !important;
        font-size: 15px !important;
      }
      .mat-form-field-appearance-fill .mat-form-field-infix {
        padding: 0px !important;
        line-height: 15px !important;
        font-size: 15px !important;
        margin: 0px !important;
      }


      &.noIcon {
        padding-left: 20px;
      }

      &:nth-child(even) {
        background: @dividerColor;
      }

      & > .icon {
        position: absolute;
        width: 30px;
        height: 30px;
        background: @textColor-light;
        top: 10px;
        left: 0px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        text-align: center;
        color: #fff;
        font-size: 15px !important;

        &.positive {
          background: @systemColor-error;
        }
        &.negative {
          background: @systemColor-ok;
        }
        &.unknown {
          background: @systemColor-warning;
        }

        .graphic {
          display: block;
          font-size: 18px;
          line-height: 18px;
          text-align: center;
          width: 100%;
        }

        .description {
          display: block;
          font-size: 10px;
          line-height: 10px;
          text-align: center;
          width: 100%;

          font-weight: bold;

          span {
            display: block;
            padding-top: 5px;
            text-align: center;
          }
        }
      }

      .options {
        position: absolute;

        top: 5px;
        right: 0px;
        border-left: 1px solid rgba(0,0,0,0.1);

        line-height: 30px;
        text-align: center;

        a {
          display: block;
          color: @accentColor;
          width: 30px;
          text-align: center;
          margin-right: 0px;
          font-size: 15px;
        }
      }

      .options-selection {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 0px;
        border-left: 1px solid rgba(0,0,0,0.1);

        line-height: 30px;
        text-align: center;
        outline: none;

        .options-icon {
          display: block;
          color: @accentColor;
          width: 30px;
          text-align: center;
          margin-right: 0px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      /* options */
      .options-selection .option-details {
        position: absolute;
        width: 225px;
        left: -225px;
        top: -5px;
        z-index: 5;
        background-color: #fff;
        .boxShaddow-big;
        .boxSizing;
        padding: 0px 10px;
        display: none;
        border-radius: 10px;
      }
      &:hover {
        z-index: 4;
      }
      .options-selection.showOptions .option-details {
        display: block;
      }
      .options-selection .option-details ul {
        .blankList;
        width: 100%;
      }
      .options-selection .option-details ul li {
        border-bottom: 2px solid @dividerColor;
        padding: 10px 0px;
        position: relative;
        text-align: left	;
        font-size: 13px;
        line-height: 13px;
      }
      .options-selection .option-details ul li.seperator {
        border-bottom: 1px solid @textColor-light;
        border-top: 1px solid @textColor-light;
        padding: 5px 10px;
        margin: 0px -10px;
        margin-top: -2px;
        z-index: 1;
        position: relative;
        text-align: left	;
        font-size: 10px;
        line-height: 10px;
        background-color: @dividerColor;
        color: @textColor;
        text-transform: uppercase;
        text-align: left;
      }
      .options-selection .option-details ul li.seperator.massEdit {
        color: @accentColor-text;
        border-bottom-color: @accentColor;
        border-top-color: @accentColor;
        background: @accentColor;
      }

      .options-selection .option-details ul li a {
        display: block;
        color: @primaryColor !important;
        cursor: pointer;
      }
      .options-selection .option-details ul li a.massEdit {
        color: @accentColor !important;
      }

      .options-selection .option-details ul li.delete,
      .options-selection .option-details ul li.delete a,
      .options-selection .option-details ul li.delete a span {
        color: @systemColor-error !important;
      }
      .options-selection .option-details ul li.positive,
      .options-selection .option-details ul li.positive a {
        color: @systemColor-ok !important;
      }

      .options-selection .option-details ul li a:hover {
        display: block;
        color: @primaryColor-dark !important;
      }

      .options-selection .option-details ul li .icon {
        display: inline-block;
        width: 20px;
      }
      .options-selection .option-details ul li:last-child {
        border-bottom: none;
      }









      .details {

        .primary {
          display: block;
          font-size: 15px;
          line-height: 15px;
          strong {
            color: @primaryColor;
            font-weight: normal;
            .font-special;
          }
        }

        .secondary {
          display: block;
          margin-left: -5px;
          .subItem {
            display: inline-block;
            border-radius: 10px;
            background: #fff;
            padding: 3px 5px;
            font-size: 12px;
            line-height: 12px;
            color: @textColor;
          }
        }

        .special {
          position: relative;
          background: #fff;
          border-radius: 10px;
          background: #fff;
          border: 1px solid @dividerColor;
          padding: 10px;
          margin-top: 10px;
          margin-left: -5px;
          margin-bottom: 5px;
          color: @textColor;
          font-size: 14px;
          line-height: 16px;
          word-break: break-word;

          &.error {
            border-color: @systemColor-error;
            color: @textColor !important;

            strong {
              color: @systemColor-error;
            }
          }

          &.done {
            border-color: @systemColor-ok;
          }

          strong {
            font-weight: bold;
            display: block;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 10px;
          }
        }

      }

    }


  }








}


/* ######################################################## */
/* CP-GUI-ITEMS */
/* ######################################################## */
.margin-top-small {
  margin-top: 10px;
}
.margin-top-medium {
  margin-top: 20px;
}
.margin-top-big {
  margin-top: 40px;
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px;
  width: calc(100% + 20px);

  .gridItem.giw-50 {
    padding: 0px 10px;
    width: 50%;
    box-sizing: border-box;
  }

  .gridItem.giw-100 {
    padding: 0px 10px;
    width: 100%;
    box-sizing: border-box;
  }
}

/* ######################################################## */
/* Material Override */
/* ######################################################## */
.mat-horizontal-content-container {
  margin: 0px !important;
  padding: 0px !important;
}
.mat-step-text-label {
  @media (max-width: 600px) {
    display: none;
  }
}
.mat-step-icon {
  font-size: 12px !important;
}
