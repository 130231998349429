.overviewHeader.fixThis {
	position: fixed;
	left: 0px;
	right: 0px;
	top: 30px;
	z-index: 9;
	
	@media (max-width: 650px) {
		top: 40px;
	}
}
.header-item.filterItem.filterItemSmall {
	width: 50px !important;
	overflow: hidden !important;
}
.header-item.searchItem.searchItemSmall {
	width: 650px !important;
	
	@media (max-width: 650px) {
		width: 150px !important;
	}
}
.translation .header-item input,
.translation .header-item select {
	border: 1px solid @accentColor !important;
	.boxSizing;
	border-radius: 7px;
	color: @accentColor !important;
}

.translation .header-item.addInline .add-button {
	background-color: @accentColor;
	color: @accentColor-text;
}


.header-item.addInline {
	width: ~"calc(100% - 730px)";
	display: flex;
	align-items: center;
	justify-content: center;
	.boxSizing;
	
	@media (max-width: 650px) {
		width: ~"calc(100% - 230px)";
	}
}
.header-item.addInline > div {
	width: ~"calc( (100% - 70px) / 2)";
	margin-right: 10px;
}
.header-item.addInline > div input {
	.boxSizing;
	padding: 10px;
	width: 100%;
	.boxShaddow-inside;
	outline: none;
	line-height: 30px;
	font-size: 15px;
	color: @systemColor-ok;
	background-color: #fff;
	border: none;
}
.header-item.addInline > div select {
	.boxSizing;
	padding: 10px;
	width: 100%;
	outline: none;
	line-height: 30px;
	font-size: 15px;
	color: @systemColor-ok;
	background-color: #fff;
	border-radius: 0px;
	.boxShaddow-small-bright;
	height: 50px;
	border: none;
}
.header-item.addInline .add-button {
	width: 50px;
	line-height: 50px;
	background-color: @systemColor-ok;
	color: #fff;
	display: block;
	font-size: 20px;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
}

.overviewHeaderisFixed-spacer.translation {
	display: block;
	height: 60px;
	
	@media (max-width: 650px) {
		height: 80px;
	}
	
}
.field-edit-container.translation {
	top: 130px;
	@media (max-width: 650px) {
		top: 120px;
	}
}




.translation-table {
	width: 100%;
}
.translation-table td {
	border-right: 1px solid @textColor-light;
	border-bottom: 2px solid @textColor-light;
	.boxSizing;
	padding: 10px;
}
.translation-table td:last-child {
	border-right: none;
}
.translation-table tbody tr:last-child td {
	/*border-bottom: none;*/
}
.translation-table tbody tr:nth-child(odd) td {
	background-color: @dividerColor;
}
.translation-table thead td {
	background-color: #fff;
	border-bottom: 2px solid @textColor-light;
	color: @textColor-light;
	font-size: 14px;
	line-height: 14px;
	text-transform: uppercase;
	font-weight: 700;
	padding-top: 0px !important;
}
.translation-table tbody td {
	background-color: #fff;
	color: @textColor-light;
	font-weight: 700;
	font-size: 12px;
}
.translation-table td.translation-modul-key {
	color: @textColor-light;
	font-size: 12px;
	line-height: 12px;
	width: 150px;
	word-wrap: break-word !important;
	overflow: hidden;
}
.translation-table td.translation-modul-key .key {
	color: @textColor-light;
	font-size: 12px;
	line-height: 12px;
	width: 140px;
	word-wrap: break-word !important;
	overflow: hidden;
}
.translation-table td.translation-modul-key small {
	display: inline-block;
	background-color: @textColor-light;
	font-size: 10px;
	line-height: 10px;
	color: #fff;
	padding: 2px 5px;
	border-radius: 44px;
	margin-top: 5px;
	text-transform: uppercase;
}
.translation-table td.translation-copy {
	width: 40px;
	padding: 10px 0px;
}
.translation-table td.translation-copy a {
	display: block;
	text-align: center;
	font-size: 20px;
	line-height: 40px;
	cursor: pointer;
	padding: 0px 10px;
}
.translation-table td.translation-language {
	
}
.translation-table td.translation-language textarea {
	width: 100%;
	min-width: 300px;
	background-color: #fff;
	border: 1px solid darken(@dividerColor,15%);
	border-color: @systemColor-ok;
	.boxSizing;
	padding: 5px;
	font-size: 14px;
	color: @textColor;
	outline: none;
}
.translation-table td.translation-language textarea[disabled="disabled"] {
	border-color: lighten(@systemColor-error,35%);
	border-color: darken(@dividerColor,15%);
	color: @textColor;
	background-color: #fff !important;
}
.translation-table td.translation-delete {
	color: @textColor;
	font-size: 16px;
	line-height: 16px;
	width: 30px;
	text-align: center;
}