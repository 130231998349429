/* ticket-type */

.ticket-type {
	.blankList;
	border-radius: 10px;
	padding-bottom: 1px;
	margin: 20px 0px;
	.boxSizing;
	display: block;
	margin-bottom: 0px;
	
	/*
	border: 1px solid darken(@dividerColor,15%);
	padding: 5px;
	*/
}

	.ticket-type.subTickets {
		background-color: #fff;
		/*
		border: 1px dashed darken(@dividerColor,15%);
		padding: 5px;
		*/
	}
	

.ticket-type .ticket-type-headline {
	width: 100%;
	.boxSizing;
	padding: 10px;
}
.ticket-type .ticket-type-headline h3 {
	margin: 0px;
	padding: 0px;
	color: @primaryColor;
	padding-bottom: 5px;
}
.ticket-type .ticket-type-headline p {
	margin: 0px;
	padding: 0px;
	color: @textColor;
}
.ticket-type .ticket-type-headline p a {
	color: @primaryColor;
}
.ticket-type li.ticket-choice {
	.blankList;
	border-radius: 5px;
	background-color: @dividerColor;
	padding: 15px 10px;
	margin-bottom: 10px;
	color: @textColor;
	.transition-default;
	cursor: pointer;
	margin: 5px 0px;
	.boxSizing;
	outline: none;
	width: auto;
	margin-bottom: 10px;
	position: relative;
	min-height: 62px;
}

.ticket-type li.ticket-choice .ticket-amount {
	display: flex;
	outline: none;
	position: absolute;
	top: -15px;
	left: 10px;
	background-color: #fff;
}
.ticket-type li.ticket-choice .ticket-amount .amount-button {
}
.ticket-type li.ticket-choice .ticket-amount .amount-button a  {
	width: 30px;
	text-align: center;
	line-height: 30px;
	display: block;
}
.ticket-type li.ticket-choice .ticket-amount .amount-button a.disabled  {
	color: @textColor-light;
	pointer-events: none;
}
.ticket-type li.ticket-choice .ticket-amount .amount-counter {
	line-height: 30px;
	padding: 0px 5px
}



.ticket-type li.ticket-choice.vipTicket {
	color: @primaryColor;
}
	.ticket-type.subTickets li.ticket-choice {
		margin: 5px 0px;
		margin-bottom: 10px;
		border: 1px dashed darken(@dividerColor,8%);
		background-color: #fff;
		margin-top: 20px;
	}
	.ticket-type li.ticket-choice:last-child, .ticket-type.subTickets li.ticket-choice:last-child {
		margin-bottom: 5px;
	}
	
.ticket-type.disabled li.ticket-choice {
	pointer-events: none;
}
.ticket-type li.ticket-choice:hover {
	/*.scaleUp-small;
	.boxShaddow-default-bright;
	.transition-default;*/
}
.ticket-type li.ticket-choice .ticket-name {
	display: block;
	font-size: 18px;
	line-height: 20px;
	padding-right: 150px;
	.boxSizing;
	
	@media (max-width: 550px) {
		padding-right: 80px;
	}
}
.ticket-type li.ticket-choice .ticket-name small.additional {
	display: block;
	font-size: 12px !important;
	line-height: 12px !important;
	padding-top: 5px;
	/*padding-right: 150px;*/
}
.ticket-type li.ticket-choice .ticket-price {
	display: block;
	font-size: 16px;
	line-height: 20px;
	position: absolute;
	text-align: right;
	right: 10px;
	top: 15px;
}
.ticket-type li.ticket-choice .ticket-price small {
	display: block;
	padding-top: 6px;
	color: @textColor-light;
	font-size: 10px;
	line-height: 10px;
	max-width: 150px;
	
	@media (max-width: 550px) {
		max-width: 80px;
	}
}
	.ticket-type li.ticket-choice.active .ticket-price small {
		color: @dividerColor;
	}
	.ticket-type.subTickets li.ticket-choice.active .ticket-price small {
		color: @textColor-light;
	}
	
.ticket-type li.ticket-choice.active {
	background-color: @primaryColor;
	color: @primaryColor-text;
	border-color: @primaryColor;
	.boxShaddow-default-bright;
}
	.ticket-type.subTickets li.ticket-choice.active {
		background-color: #fff;
		border: 1px solid @primaryColor;
		color: @primaryColor;
	}


/* ticket-price-table */
.ticket-price-table {
	width: ~"calc(100% - 20px)";
	border-bottom: 1px solid @dividerColor;
	margin: 0px 10px 20px 10px;
}
.ticket-price-table td {
	text-align: left;
	border-top: 1px solid @dividerColor;
	color: @textColor;
	padding: 10px 0px;
	font-size: 15px;
	line-height: 15px;
}
.ticket-price-table tr td:last-child, .ticket-price-table tr.total td, .ticket-price-table tr.mwst td {
	text-align: right;
}
.ticket-price-table tr td:last-child {
	white-space: nowrap;
	padding-left: 10px;
}
.ticket-price-table tr.total td {
	color: @textColor;
	font-size: 18px;
	line-height: 18px;
	border-top: 2px solid @textColor-light;
}
.ticket-price-table tr.mwst td {
	font-size: 12px;
	line-height: 12px;
	color: @textColor-light;
}